define('carnival-frontend/controllers/admin/apps', ['exports', 'carnival-frontend/controllers/admin/paginated'], function (exports, _paginated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginated.default.extend({
    disabled: Ember.computed.alias('filter[disabled]'),
    accountState: Ember.computed.alias('filter[account_state]'),

    queryParams: ['filter[disabled]', 'filter[account_state]'],

    'filter[disabled]': 'false',
    'filter[account_state]': null,

    disabledStates: [{ label: 'All', value: '' }, { label: 'Active Apps', value: 'false' }, { label: 'Deleted/Disabled Apps', value: 'true' }],

    accountStates: [{ label: 'Live', value: 'live' }, { label: 'In Dev', value: 'in-dev' }, { label: 'Test', value: 'test' }, { label: 'Lapsed', value: 'lapsed' }],

    actions: {
      ignoreEnter: function ignoreEnter(value, event) {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }
    }
  });
});