define('carnival-frontend/controllers/logs/devices/relink', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modelDidRelink: function modelDidRelink() {
      this.alert.success('Success!', 'A background re-link has been initiated');
      this.send('closeModal');
    },
    modelDidNotRelink: function modelDidNotRelink(error) {
      this.alert.danger('Error', error.message);
      this.send('closeModal');
    },


    actions: {
      dismiss: function dismiss() {
        this.send('closeModal');
      },
      relink: function relink() {
        var _this = this;

        var model = this.get('model');

        model.relink().then(function () {
          return _this.modelDidRelink();
        }).catch(function (error) {
          return _this.modelDidNotRelink(error);
        });
      }
    }
  });
});