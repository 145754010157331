define('carnival-frontend/initializers/main-session', ['exports', 'carnival-frontend/services/main-session'], function (exports, _mainSession) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register('session:main', _mainSession.default);
    application.inject('session:main', 'currentUserService', 'service:current-user');

    application.inject('route', 'session', 'session:main');
    application.inject('controller', 'session', 'session:main');
  }

  exports.default = {
    name: 'main-session',
    initialize: initialize
  };
});