define('carnival-frontend/services/main-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    email: null,
    csrf: null,
    userTimeZone: null,

    updateFromBackendSession: function updateFromBackendSession(session) {
      this.csrf = session.get('csrf');
      this.email = session.get('email');
      this.userTimeZone = session.get('user.timeZone');
      this.currentUserService.set('userTimeZone', session.get('user.timeZone'));

      return this.currentUserService.set('user', session.get('user'));
    },
    resetSession: function resetSession() {
      this.csrf = null;
      this.email = null;

      this.currentUserService.set('user', null);
    }
  });
});