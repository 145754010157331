define('carnival-frontend/models/custom-event-source', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    app: null,
    label: null,
    active: true,

    init: function init() {
      this.set('active', this.get('app').isCustomEventSourceActive(this.get('label')));
    },


    // eslint-disable-next-line ember/no-observers
    activeObserver: Ember.observer('active', function () {
      this.get('app').toggleCustomEventSourceState(this.get('label'));
    })
  });
});