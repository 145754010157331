define('carnival-frontend/controllers/stats/tech', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    stats: Ember.inject.controller('stats/tech/index'),
    appId: Ember.computed.oneWay('currentAppService.app.id'),

    actions: {
      downloadDevices: function downloadDevices() {
        location.href = '/web/v1/devices.csv?app_id=' + this.get('appId') + '&from=' + this.get('stats.fromDate');
      },
      downloadTags: function downloadTags() {
        location.href = '/web/v1/apps/' + this.get('appId') + '/tags.csv?from=' + this.get('stats.fromDate');
      }
    }
  });
});