define('carnival-frontend/components/messages/event-conversions', ['exports', 'carnival-frontend/components/expandable-table'], function (exports, _expandableTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _expandableTable.default.extend({
    classNames: ['message-attributed-events'],
    app: Ember.computed.alias('model.app'),

    pushOrPop: function pushOrPop(arr, item) {
      if (arr.indexOf(item) !== -1) return arr.filter(function (e) {
        return e !== item;
      });
      arr.pushObject(item);
      return arr;
    },


    actions: {
      starEvent: function starEvent(eventName, starredState) {
        var _this = this;

        var eventIndex = this.get('items').findIndex(function (elem) {
          return elem.name === eventName;
        });
        this.set('items.' + eventIndex + '.starred', !starredState);
        this.set('app.starredEvents', this.pushOrPop(this.get('app.starredEvents'), eventName));
        this.get('app').save().then(function () {
          return _this.get('model').belongsTo('analytics').reload();
        });
      },
      expand: function expand() {
        this.set('expanded', true);
      }
    }
  });
});