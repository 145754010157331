define('carnival-frontend/components/video-container', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['video-container'],
    classNameBindings: ['isHidden:hide'],

    duration: '',
    onLoad: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var video = this.$('video').on('loadeddata', function () {
        _this.set('duration', (0, _moment.default)().startOf('day').add(video[0].duration, 'seconds').format('mm:ss'));
        if (_this.get('onLoad')) {
          _this.get('onLoad')();
        }
      });
    }
  });
});