define('carnival-frontend/mixins/messages/timezones', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    label: '(GMT-12:00) International Date Line West',
    value: 'International Date Line West',
    offset: '-12:00',
    name: 'Etc/GMT+12'
  }, {
    label: '(GMT-11:00) American Samoa',
    value: 'American Samoa',
    offset: '-11:00',
    name: 'Pacific/Pago_Pago'
  }, {
    label: '(GMT-11:00) Midway Island',
    value: 'Midway Island',
    offset: '-11:00',
    name: 'Pacific/Midway'
  }, {
    label: '(GMT-10:00) Hawaii',
    value: 'Hawaii',
    offset: '-10:00',
    name: 'Pacific/Honolulu'
  }, {
    label: '(GMT-08:00) Alaska',
    value: 'Alaska',
    offset: '-08:00',
    name: 'America/Juneau'
  }, {
    label: '(GMT-07:00) Pacific Time (US & Canada)',
    value: 'Pacific Time (US & Canada)',
    offset: '-07:00',
    name: 'America/Los_Angeles'
  }, {
    label: '(GMT-07:00) Tijuana',
    value: 'Tijuana',
    offset: '-07:00',
    name: 'America/Tijuana'
  }, {
    label: '(GMT-07:00) Arizona',
    value: 'Arizona',
    offset: '-07:00',
    name: 'America/Phoenix'
  }, {
    label: '(GMT-07:00) Chihuahua',
    value: 'Chihuahua',
    offset: '-07:00',
    name: 'America/Chihuahua'
  }, {
    label: '(GMT-07:00) Mazatlan',
    value: 'Mazatlan',
    offset: '-07:00',
    name: 'America/Mazatlan'
  }, {
    label: '(GMT-06:00) Mountain Time (US & Canada)',
    value: 'Mountain Time (US & Canada)',
    offset: '-06:00',
    name: 'America/Denver'
  }, {
    label: '(GMT-06:00) Central America',
    value: 'Central America',
    offset: '-06:00',
    name: 'America/Guatemala'
  }, {
    label: '(GMT-05:00) Central Time (US & Canada)',
    value: 'Central Time (US & Canada)',
    offset: '-05:00',
    name: 'America/Chicago'
  }, {
    label: '(GMT-06:00) Guadalajara',
    value: 'Guadalajara',
    offset: '-06:00',
    name: 'America/Mexico_City'
  }, {
    label: '(GMT-06:00) Mexico City',
    value: 'Mexico City',
    offset: '-06:00',
    name: 'America/Mexico_City'
  }, {
    label: '(GMT-06:00) Monterrey',
    value: 'Monterrey',
    offset: '-06:00',
    name: 'America/Monterrey'
  }, {
    label: '(GMT-06:00) Saskatchewan',
    value: 'Saskatchewan',
    offset: '-06:00',
    name: 'America/Regina'
  }, {
    label: '(GMT-05:00) Bogota',
    value: 'Bogota',
    offset: '-05:00',
    name: 'America/Bogota'
  }, {
    label: '(GMT-04:00) Eastern Time (US & Canada)',
    value: 'Eastern Time (US & Canada)',
    offset: '-04:00',
    name: 'America/New_York'
  }, {
    label: '(GMT-04:00) Indiana (East)',
    value: 'Indiana (East)',
    offset: '-04:00',
    name: 'America/Indiana/Indianapolis'
  }, {
    label: '(GMT-05:00) Lima',
    value: 'Lima',
    offset: '-05:00',
    name: 'America/Lima'
  }, {
    label: '(GMT-05:00) Quito',
    value: 'Quito',
    offset: '-05:00',
    name: 'America/Lima'
  }, {
    label: '(GMT-03:00) Atlantic Time (Canada)',
    value: 'Atlantic Time (Canada)',
    offset: '-03:00',
    name: 'America/Halifax'
  }, {
    label: '(GMT-04:00) Caracas',
    value: 'Caracas',
    offset: '-04:00',
    name: 'America/Caracas'
  }, {
    label: '(GMT-04:00) Georgetown',
    value: 'Georgetown',
    offset: '-04:00',
    name: 'America/Guyana'
  }, {
    label: '(GMT-04:00) La Paz',
    value: 'La Paz',
    offset: '-04:00',
    name: 'America/La_Paz'
  }, {
    label: '(GMT-04:00) Puerto Rico',
    value: 'Puerto Rico',
    offset: '-04:00',
    name: 'America/Puerto_Rico'
  }, {
    label: '(GMT-03:00) Santiago',
    value: 'Santiago',
    offset: '-03:00',
    name: 'America/Santiago'
  }, {
    label: '(GMT-02:30) Newfoundland',
    value: 'Newfoundland',
    offset: '-02:30',
    name: 'America/St_Johns'
  }, {
    label: '(GMT-03:00) Brasilia',
    value: 'Brasilia',
    offset: '-03:00',
    name: 'America/Sao_Paulo'
  }, {
    label: '(GMT-03:00) Buenos Aires',
    value: 'Buenos Aires',
    offset: '-03:00',
    name: 'America/Argentina/Buenos_Aires'
  }, {
    label: '(GMT-03:00) Greenland',
    value: 'Greenland',
    offset: '-03:00',
    name: 'America/Godthab'
  }, {
    label: '(GMT-03:00) Montevideo',
    value: 'Montevideo',
    offset: '-03:00',
    name: 'America/Montevideo'
  }, {
    label: '(GMT-02:00) Mid-Atlantic',
    value: 'Mid-Atlantic',
    offset: '-02:00',
    name: 'Atlantic/South_Georgia'
  }, {
    label: '(GMT-01:00) Azores',
    value: 'Azores',
    offset: '-01:00',
    name: 'Atlantic/Azores'
  }, {
    label: '(GMT-01:00) Cape Verde Is.',
    value: 'Cape Verde Is.',
    offset: '-01:00',
    name: 'Atlantic/Cape_Verde'
  }, {
    label: '(GMT+00:00) Edinburgh',
    value: 'Edinburgh',
    offset: '+00:00',
    name: 'Europe/London'
  }, {
    label: '(GMT+00:00) Lisbon',
    value: 'Lisbon',
    offset: '+00:00',
    name: 'Europe/Lisbon'
  }, {
    label: '(GMT+00:00) London',
    value: 'London',
    offset: '+00:00',
    name: 'Europe/London'
  }, {
    label: '(GMT+00:00) Monrovia',
    value: 'Monrovia',
    offset: '+00:00',
    name: 'Africa/Monrovia'
  }, {
    label: '(GMT+00:00) UTC',
    value: 'UTC',
    offset: '+00:00',
    name: 'Etc/UTC'
  }, {
    label: '(GMT+01:00) Amsterdam',
    value: 'Amsterdam',
    offset: '+01:00',
    name: 'Europe/Amsterdam'
  }, {
    label: '(GMT+01:00) Belgrade',
    value: 'Belgrade',
    offset: '+01:00',
    name: 'Europe/Belgrade'
  }, {
    label: '(GMT+01:00) Berlin',
    value: 'Berlin',
    offset: '+01:00',
    name: 'Europe/Berlin'
  }, {
    label: '(GMT+01:00) Bern',
    value: 'Bern',
    offset: '+01:00',
    name: 'Europe/Zurich'
  }, {
    label: '(GMT+01:00) Bratislava',
    value: 'Bratislava',
    offset: '+01:00',
    name: 'Europe/Bratislava'
  }, {
    label: '(GMT+01:00) Brussels',
    value: 'Brussels',
    offset: '+01:00',
    name: 'Europe/Brussels'
  }, {
    label: '(GMT+01:00) Budapest',
    value: 'Budapest',
    offset: '+01:00',
    name: 'Europe/Budapest'
  }, {
    label: '(GMT+01:00) Casablanca',
    value: 'Casablanca',
    offset: '+01:00',
    name: 'Africa/Casablanca'
  }, {
    label: '(GMT+01:00) Copenhagen',
    value: 'Copenhagen',
    offset: '+01:00',
    name: 'Europe/Copenhagen'
  }, {
    label: '(GMT+00:00) Dublin',
    value: 'Dublin',
    offset: '+00:00',
    name: 'Europe/Dublin'
  }, {
    label: '(GMT+01:00) Ljubljana',
    value: 'Ljubljana',
    offset: '+01:00',
    name: 'Europe/Ljubljana'
  }, {
    label: '(GMT+01:00) Madrid',
    value: 'Madrid',
    offset: '+01:00',
    name: 'Europe/Madrid'
  }, {
    label: '(GMT+01:00) Paris',
    value: 'Paris',
    offset: '+01:00',
    name: 'Europe/Paris'
  }, {
    label: '(GMT+01:00) Prague',
    value: 'Prague',
    offset: '+01:00',
    name: 'Europe/Prague'
  }, {
    label: '(GMT+01:00) Rome',
    value: 'Rome',
    offset: '+01:00',
    name: 'Europe/Rome'
  }, {
    label: '(GMT+01:00) Sarajevo',
    value: 'Sarajevo',
    offset: '+01:00',
    name: 'Europe/Sarajevo'
  }, {
    label: '(GMT+01:00) Skopje',
    value: 'Skopje',
    offset: '+01:00',
    name: 'Europe/Skopje'
  }, {
    label: '(GMT+01:00) Stockholm',
    value: 'Stockholm',
    offset: '+01:00',
    name: 'Europe/Stockholm'
  }, {
    label: '(GMT+01:00) Vienna',
    value: 'Vienna',
    offset: '+01:00',
    name: 'Europe/Vienna'
  }, {
    label: '(GMT+01:00) Warsaw',
    value: 'Warsaw',
    offset: '+01:00',
    name: 'Europe/Warsaw'
  }, {
    label: '(GMT+01:00) West Central Africa',
    value: 'West Central Africa',
    offset: '+01:00',
    name: 'Africa/Algiers'
  }, {
    label: '(GMT+01:00) Zagreb',
    value: 'Zagreb',
    offset: '+01:00',
    name: 'Europe/Zagreb'
  }, {
    label: '(GMT+01:00) Zurich',
    value: 'Zurich',
    offset: '+01:00',
    name: 'Europe/Zurich'
  }, {
    label: '(GMT+02:00) Athens',
    value: 'Athens',
    offset: '+02:00',
    name: 'Europe/Athens'
  }, {
    label: '(GMT+02:00) Bucharest',
    value: 'Bucharest',
    offset: '+02:00',
    name: 'Europe/Bucharest'
  }, {
    label: '(GMT+02:00) Cairo',
    value: 'Cairo',
    offset: '+02:00',
    name: 'Africa/Cairo'
  }, {
    label: '(GMT+02:00) Harare',
    value: 'Harare',
    offset: '+02:00',
    name: 'Africa/Harare'
  }, {
    label: '(GMT+02:00) Helsinki',
    value: 'Helsinki',
    offset: '+02:00',
    name: 'Europe/Helsinki'
  }, {
    label: '(GMT+02:00) Jerusalem',
    value: 'Jerusalem',
    offset: '+02:00',
    name: 'Asia/Jerusalem'
  }, {
    label: '(GMT+02:00) Kaliningrad',
    value: 'Kaliningrad',
    offset: '+02:00',
    name: 'Europe/Kaliningrad'
  }, {
    label: '(GMT+02:00) Kyiv',
    value: 'Kyiv',
    offset: '+02:00',
    name: 'Europe/Kiev'
  }, {
    label: '(GMT+02:00) Pretoria',
    value: 'Pretoria',
    offset: '+02:00',
    name: 'Africa/Johannesburg'
  }, {
    label: '(GMT+02:00) Riga',
    value: 'Riga',
    offset: '+02:00',
    name: 'Europe/Riga'
  }, {
    label: '(GMT+02:00) Sofia',
    value: 'Sofia',
    offset: '+02:00',
    name: 'Europe/Sofia'
  }, {
    label: '(GMT+02:00) Tallinn',
    value: 'Tallinn',
    offset: '+02:00',
    name: 'Europe/Tallinn'
  }, {
    label: '(GMT+02:00) Vilnius',
    value: 'Vilnius',
    offset: '+02:00',
    name: 'Europe/Vilnius'
  }, {
    label: '(GMT+03:00) Baghdad',
    value: 'Baghdad',
    offset: '+03:00',
    name: 'Asia/Baghdad'
  }, {
    label: '(GMT+03:00) Istanbul',
    value: 'Istanbul',
    offset: '+03:00',
    name: 'Europe/Istanbul'
  }, {
    label: '(GMT+03:00) Kuwait',
    value: 'Kuwait',
    offset: '+03:00',
    name: 'Asia/Kuwait'
  }, {
    label: '(GMT+03:00) Minsk',
    value: 'Minsk',
    offset: '+03:00',
    name: 'Europe/Minsk'
  }, {
    label: '(GMT+03:00) Moscow',
    value: 'Moscow',
    offset: '+03:00',
    name: 'Europe/Moscow'
  }, {
    label: '(GMT+03:00) Nairobi',
    value: 'Nairobi',
    offset: '+03:00',
    name: 'Africa/Nairobi'
  }, {
    label: '(GMT+03:00) Riyadh',
    value: 'Riyadh',
    offset: '+03:00',
    name: 'Asia/Riyadh'
  }, {
    label: '(GMT+03:00) St. Petersburg',
    value: 'St. Petersburg',
    offset: '+03:00',
    name: 'Europe/Moscow'
  }, {
    label: '(GMT+03:30) Tehran',
    value: 'Tehran',
    offset: '+03:30',
    name: 'Asia/Tehran'
  }, {
    label: '(GMT+04:00) Abu Dhabi',
    value: 'Abu Dhabi',
    offset: '+04:00',
    name: 'Asia/Muscat'
  }, {
    label: '(GMT+04:00) Baku',
    value: 'Baku',
    offset: '+04:00',
    name: 'Asia/Baku'
  }, {
    label: '(GMT+04:00) Muscat',
    value: 'Muscat',
    offset: '+04:00',
    name: 'Asia/Muscat'
  }, {
    label: '(GMT+04:00) Samara',
    value: 'Samara',
    offset: '+04:00',
    name: 'Europe/Samara'
  }, {
    label: '(GMT+04:00) Tbilisi',
    value: 'Tbilisi',
    offset: '+04:00',
    name: 'Asia/Tbilisi'
  }, {
    label: '(GMT+04:00) Volgograd',
    value: 'Volgograd',
    offset: '+04:00',
    name: 'Europe/Volgograd'
  }, {
    label: '(GMT+04:00) Yerevan',
    value: 'Yerevan',
    offset: '+04:00',
    name: 'Asia/Yerevan'
  }, {
    label: '(GMT+04:30) Kabul',
    value: 'Kabul',
    offset: '+04:30',
    name: 'Asia/Kabul'
  }, {
    label: '(GMT+05:00) Ekaterinburg',
    value: 'Ekaterinburg',
    offset: '+05:00',
    name: 'Asia/Yekaterinburg'
  }, {
    label: '(GMT+05:00) Islamabad',
    value: 'Islamabad',
    offset: '+05:00',
    name: 'Asia/Karachi'
  }, {
    label: '(GMT+05:00) Karachi',
    value: 'Karachi',
    offset: '+05:00',
    name: 'Asia/Karachi'
  }, {
    label: '(GMT+05:00) Tashkent',
    value: 'Tashkent',
    offset: '+05:00',
    name: 'Asia/Tashkent'
  }, {
    label: '(GMT+05:30) Chennai',
    value: 'Chennai',
    offset: '+05:30',
    name: 'Asia/Kolkata'
  }, {
    label: '(GMT+05:30) Kolkata',
    value: 'Kolkata',
    offset: '+05:30',
    name: 'Asia/Kolkata'
  }, {
    label: '(GMT+05:30) Mumbai',
    value: 'Mumbai',
    offset: '+05:30',
    name: 'Asia/Kolkata'
  }, {
    label: '(GMT+05:30) New Delhi',
    value: 'New Delhi',
    offset: '+05:30',
    name: 'Asia/Kolkata'
  }, {
    label: '(GMT+05:30) Sri Jayawardenepura',
    value: 'Sri Jayawardenepura',
    offset: '+05:30',
    name: 'Asia/Colombo'
  }, {
    label: '(GMT+05:45) Kathmandu',
    value: 'Kathmandu',
    offset: '+05:45',
    name: 'Asia/Kathmandu'
  }, {
    label: '(GMT+06:00) Almaty',
    value: 'Almaty',
    offset: '+06:00',
    name: 'Asia/Almaty'
  }, {
    label: '(GMT+06:00) Astana',
    value: 'Astana',
    offset: '+06:00',
    name: 'Asia/Dhaka'
  }, {
    label: '(GMT+06:00) Dhaka',
    value: 'Dhaka',
    offset: '+06:00',
    name: 'Asia/Dhaka'
  }, {
    label: '(GMT+06:00) Urumqi',
    value: 'Urumqi',
    offset: '+06:00',
    name: 'Asia/Urumqi'
  }, {
    label: '(GMT+06:30) Rangoon',
    value: 'Rangoon',
    offset: '+06:30',
    name: 'Asia/Rangoon'
  }, {
    label: '(GMT+07:00) Bangkok',
    value: 'Bangkok',
    offset: '+07:00',
    name: 'Asia/Bangkok'
  }, {
    label: '(GMT+07:00) Hanoi',
    value: 'Hanoi',
    offset: '+07:00',
    name: 'Asia/Bangkok'
  }, {
    label: '(GMT+07:00) Jakarta',
    value: 'Jakarta',
    offset: '+07:00',
    name: 'Asia/Jakarta'
  }, {
    label: '(GMT+07:00) Krasnoyarsk',
    value: 'Krasnoyarsk',
    offset: '+07:00',
    name: 'Asia/Krasnoyarsk'
  }, {
    label: '(GMT+07:00) Novosibirsk',
    value: 'Novosibirsk',
    offset: '+07:00',
    name: 'Asia/Novosibirsk'
  }, {
    label: '(GMT+08:00) Beijing',
    value: 'Beijing',
    offset: '+08:00',
    name: 'Asia/Shanghai'
  }, {
    label: '(GMT+08:00) Chongqing',
    value: 'Chongqing',
    offset: '+08:00',
    name: 'Asia/Chongqing'
  }, {
    label: '(GMT+08:00) Hong Kong',
    value: 'Hong Kong',
    offset: '+08:00',
    name: 'Asia/Hong_Kong'
  }, {
    label: '(GMT+08:00) Irkutsk',
    value: 'Irkutsk',
    offset: '+08:00',
    name: 'Asia/Irkutsk'
  }, {
    label: '(GMT+08:00) Kuala Lumpur',
    value: 'Kuala Lumpur',
    offset: '+08:00',
    name: 'Asia/Kuala_Lumpur'
  }, {
    label: '(GMT+08:00) Perth',
    value: 'Perth',
    offset: '+08:00',
    name: 'Australia/Perth'
  }, {
    label: '(GMT+08:00) Singapore',
    value: 'Singapore',
    offset: '+08:00',
    name: 'Asia/Singapore'
  }, {
    label: '(GMT+08:00) Taipei',
    value: 'Taipei',
    offset: '+08:00',
    name: 'Asia/Taipei'
  }, {
    label: '(GMT+08:00) Ulaanbaatar',
    value: 'Ulaanbaatar',
    offset: '+08:00',
    name: 'Asia/Ulaanbaatar'
  }, {
    label: '(GMT+09:00) Osaka',
    value: 'Osaka',
    offset: '+09:00',
    name: 'Asia/Tokyo'
  }, {
    label: '(GMT+09:00) Sapporo',
    value: 'Sapporo',
    offset: '+09:00',
    name: 'Asia/Tokyo'
  }, {
    label: '(GMT+09:00) Seoul',
    value: 'Seoul',
    offset: '+09:00',
    name: 'Asia/Seoul'
  }, {
    label: '(GMT+09:00) Tokyo',
    value: 'Tokyo',
    offset: '+09:00',
    name: 'Asia/Tokyo'
  }, {
    label: '(GMT+09:00) Yakutsk',
    value: 'Yakutsk',
    offset: '+09:00',
    name: 'Asia/Yakutsk'
  }, {
    label: '(GMT+10:30) Adelaide',
    value: 'Adelaide',
    offset: '+10:30',
    name: 'Australia/Adelaide'
  }, {
    label: '(GMT+09:30) Darwin',
    value: 'Darwin',
    offset: '+09:30',
    name: 'Australia/Darwin'
  }, {
    label: '(GMT+10:00) Brisbane',
    value: 'Brisbane',
    offset: '+10:00',
    name: 'Australia/Brisbane'
  }, {
    label: '(GMT+11:00) Canberra',
    value: 'Canberra',
    offset: '+11:00',
    name: 'Australia/Melbourne'
  }, {
    label: '(GMT+10:00) Guam',
    value: 'Guam',
    offset: '+10:00',
    name: 'Pacific/Guam'
  }, {
    label: '(GMT+11:00) Hobart',
    value: 'Hobart',
    offset: '+11:00',
    name: 'Australia/Hobart'
  }, {
    label: '(GMT+11:00) Melbourne',
    value: 'Melbourne',
    offset: '+11:00',
    name: 'Australia/Melbourne'
  }, {
    label: '(GMT+10:00) Port Moresby',
    value: 'Port Moresby',
    offset: '+10:00',
    name: 'Pacific/Port_Moresby'
  }, {
    label: '(GMT+11:00) Sydney',
    value: 'Sydney',
    offset: '+11:00',
    name: 'Australia/Sydney'
  }, {
    label: '(GMT+10:00) Vladivostok',
    value: 'Vladivostok',
    offset: '+10:00',
    name: 'Asia/Vladivostok'
  }, {
    label: '(GMT+11:00) Magadan',
    value: 'Magadan',
    offset: '+11:00',
    name: 'Asia/Magadan'
  }, {
    label: '(GMT+11:00) New Caledonia',
    value: 'New Caledonia',
    offset: '+11:00',
    name: 'Pacific/Noumea'
  }, {
    label: '(GMT+11:00) Solomon Is.',
    value: 'Solomon Is.',
    offset: '+11:00',
    name: 'Pacific/Guadalcanal'
  }, {
    label: '(GMT+11:00) Srednekolymsk',
    value: 'Srednekolymsk',
    offset: '+11:00',
    name: 'Asia/Srednekolymsk'
  }, {
    label: '(GMT+13:00) Auckland',
    value: 'Auckland',
    offset: '+13:00',
    name: 'Pacific/Auckland'
  }, {
    label: '(GMT+12:00) Fiji',
    value: 'Fiji',
    offset: '+12:00',
    name: 'Pacific/Fiji'
  }, {
    label: '(GMT+12:00) Kamchatka',
    value: 'Kamchatka',
    offset: '+12:00',
    name: 'Asia/Kamchatka'
  }, {
    label: '(GMT+12:00) Marshall Is.',
    value: 'Marshall Is.',
    offset: '+12:00',
    name: 'Pacific/Majuro'
  }, {
    label: '(GMT+13:00) Wellington',
    value: 'Wellington',
    offset: '+13:00',
    name: 'Pacific/Auckland'
  }, {
    label: '(GMT+13:45) Chatham Is.',
    value: 'Chatham Is.',
    offset: '+13:45',
    name: 'Pacific/Chatham'
  }, {
    label: "(GMT+13:00) Nuku'alofa",
    value: "Nuku'alofa",
    offset: '+13:00',
    name: 'Pacific/Tongatapu'
  }, {
    label: '(GMT+14:00) Samoa',
    value: 'Samoa',
    offset: '+14:00',
    name: 'Pacific/Apia'
  }, {
    label: '(GMT+13:00) Tokelau Is.',
    value: 'Tokelau Is.',
    offset: '+13:00',
    name: 'Pacific/Fakaofo'
  }];
});