define('carnival-frontend/routes/admin/index', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    redirect: function redirect() {
      this.transitionTo('admin.apps');
    }
  });
});