define('carnival-frontend/helpers/moment-format', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentFormat = momentFormat;
  function momentFormat(params, hash) {
    var date = params[0],
        dateToFormat = date || new Date(),
        format = typeof hash.format === 'string' ? hash.format : 'D MMM YYYY';

    return (0, _moment.default)(dateToFormat).format(format);
  }

  exports.default = Ember.Helper.helper(momentFormat);
});