define('carnival-frontend/helpers/parse-file-ext', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (filename) {
    return filename.substr((~-filename.lastIndexOf('.') >>> 0) + 2);
  };
});