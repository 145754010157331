define('carnival-frontend/mixins/stats/csv-export', ['exports', 'carnival-frontend/helpers/csv-encoder', 'moment'], function (exports, _csvEncoder, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      exportCsv: function exportCsv(granularity) {
        var stats = this.get('stats'),
            oldGranularity = stats.get('granularity');

        // Temporarily set the granularity for the purposes
        // of creating the CSV at the desired frequency
        stats.set('granularity', granularity);

        var encoder = _csvEncoder.default.create({ data: stats.get('displayData') }),
            app = this.get('app');

        encoder.stringOutput();

        var filename = app.get('name') + '-' + stats.get('path') + '-' + (0, _moment.default)().format('YYYY-MM-DD');

        encoder.sendFile(filename.toLowerCase().dasherize());

        // Set the granularity back to its old value
        stats.set('granularity', oldGranularity);
      }
    }
  });
});