define('carnival-frontend/transforms/hash', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (Object.prototype.toString.call(serialized) !== '[object Object]') return {};

      for (var key in serialized) {
        if (key.indexOf('_') > -1) {
          serialized[key.camelize()] = serialized[key];
          delete serialized[key];
        }
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (Object.prototype.toString.call(deserialized) !== '[object Object]') return {};

      for (var key in deserialized) {
        if (key !== key.underscore()) {
          deserialized[key.underscore()] = deserialized[key];
          delete deserialized[key];
        }
      }

      return deserialized;
    }
  });
});