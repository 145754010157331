define('carnival-frontend/mixins/audiences/is-in-viewport', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this.addObserver('listElement.scrollOffset', this, this.checkIsInViewport);
    },
    didRender: function didRender() {
      this.checkIsInViewport();
    },
    checkIsInViewport: function checkIsInViewport() {
      if (this.get('isInViewport')) return;

      var _element$getBoundingC = this.element.getBoundingClientRect(),
          top = _element$getBoundingC.top;

      if (top > 0) {
        var _get$getBoundingClien = this.get('listElement.element').getBoundingClientRect(),
            bottom = _get$getBoundingClien.bottom;

        if (top < bottom) {
          this.set('isInViewport', true);
          this.removeObserver('listElement.scrollOffset', this, this.checkIsInViewport);
        }
      }
    }
  });
});