define('carnival-frontend/helpers/file-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    function FileApiHelpers(container, mimeTypes, onFilePick) {
      this.container = container;
      this.mimeTypes = mimeTypes;
      this.fileMask = 'image*|video*';
      this.onFilePick = onFilePick;
    }

    FileApiHelpers.prototype.addClickCallback = function (button) {
      var inputTag = '<input type="file" name="files" accept="' + this.mimeTypes + '"/>',
          fileForm = Ember.$(inputTag),
          that = this;

      this.container.wrap('<div class="image-picker"></div>');
      fileForm.insertAfter(button);
      button.on('click', function () {
        fileForm.click();
      });
      fileForm.on('change', function (e) {
        that.handleFileSelection(e.target.files);
      });
    };

    FileApiHelpers.prototype.addDnDCallback = function () {
      var that = this;
      this.container.on('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      });
      this.container.on('dragenter', function () {
        that.container.addClass('allow-dropping');
      });
      this.container.on('dragleave', function () {
        that.container.removeClass('allow-dropping');
      });
      this.container.on('drop', function (e) {
        e.stopPropagation();
        e.preventDefault();

        that.container.removeClass('allow-dropping');
        that.container.addClass('file-dropped');

        that.handleFileSelection(e.dataTransfer.files);
      });
    };

    FileApiHelpers.prototype.handleFileSelection = function (files) {
      if (!files || files.length <= 0) return;

      var file = files[0];
      if (!file.type.match(this.fileMask)) return;

      var that = this,
          reader = new FileReader();
      reader.onload = function (event) {
        var data = event.target.result,
            height = that.heightForContainerWidth(data);

        if (typeof that.onFilePick === 'function') {
          that.onFilePick.call(that, data, height, file);
        }
      };

      reader.readAsDataURL(file);
    };

    // works out the container height based on the width of the image we have uploaded
    FileApiHelpers.prototype.heightForContainerWidth = function (file) {
      var image = new Image();
      image.src = file;

      // if the image is landscape
      if (image.width > image.height) {
        return this.container.width() / image.width * image.height;
      } else {
        // we want the container to be no higher than the width
        // some clipping will occur, but thats all goodd
        return this.container.width();
      }
    };

    return FileApiHelpers;
  }();
});