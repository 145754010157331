define('carnival-frontend/mixins/reveal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    closeOnClickAway: function closeOnClickAway() {
      var viewRegistry = Ember.getOwner(this).lookup('-view-registry:main'),
          isOpen = this.get('isOpen'),
          // must be stored BEFORE closing other dropdowns
      keys = Object.keys(viewRegistry),
          views = keys.map(function (key) {
        return viewRegistry[key];
      }),
          childViews = views.filterBy('closeOnClickAway').filterBy('isDestroyed', false);

      // allow only one dropdown to be open at a time
      childViews.setEach('isOpen', false);

      if (!isOpen) this.set('isOpen', true);
    }
  });
});