define('carnival-frontend/services/s3-file-uploader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    currentApp: Ember.inject.service(),
    app: Ember.computed.oneWay('currentApp.app'),
    filename: null,
    bytesUploaded: 0,
    bytesTotal: 0,
    isUploading: false,

    percentUploaded: Ember.computed('bytesUploaded', 'bytesTotal', function () {
      var percent = parseInt(this.bytesUploaded / this.bytesTotal * 100, 10);
      return isNaN(percent) ? 0 : percent;
    }),

    cancel: function cancel() {
      var xhr = this.get('xhr');

      if (xhr && this.get('isUploading')) {
        xhr.abort();
        this.set('xhr', null);
      }

      this.setProperties({
        bytesUploaded: 0,
        bytesTotal: 0,
        isUploading: false
      });
    },


    // start upload to s3 and resolves promise containing filename
    upload: function upload(file, sign) {
      var _this = this;

      var xhr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new XMLHttpRequest();

      var fd = new FormData(),
          filename = this.fillFormDataWithSign(fd, file, sign);

      return new Promise(function (resolve, reject) {
        _this.set('filename', filename);
        _this.set('isUploading', true);
        _this.set('xhr', xhr);

        xhr.open('POST', sign.url, true);

        xhr.upload.addEventListener('progress', function (e) {
          _this.setProperties({
            bytesTotal: e.total,
            bytesUploaded: e.loaded
          });
        });
        xhr.upload.addEventListener('error', function (error) {
          reject(error);
        });

        xhr.addEventListener('load', function () {
          _this.cancel();
          resolve(filename);
        });

        xhr.send(fd);
      });
    },
    fillFormDataWithSign: function fillFormDataWithSign(fd, file, sign) {
      var appId = this.get('app').id,
          dir = appId + '/' + this.randomUUID() + '-' + file.name;

      for (var field in sign.fields) {
        // eslint-disable-next-line no-prototype-builtins
        if (!sign.fields.hasOwnProperty(field)) continue;
        if (field === 'key') continue;
        fd.append(field, sign.fields[field]);
      }

      fd.append('key', dir);
      fd.append('file', file);

      return dir;
    },
    randomUUID: function randomUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    }
  });
});