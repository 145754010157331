define('carnival-frontend/helpers/tooltip-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    ARROW_WIDTH: 16, // Can't fetch pseudo element dimensions in JS

    init: function init($graph, $tooltip, $arrow, margin, arrowWidth) {
      this.setProperties({
        graph: $graph,
        tooltip: $tooltip,
        arrow: $arrow,
        margin: margin,
        arrowWidth: arrowWidth || this.ARROW_WIDTH
      });
    },
    showTooltipAt: function showTooltipAt(x, y) {
      // elements
      var tooltip = this.get('tooltip');
      var arrow = this.get('arrow');

      // dimensions
      var gpW = this.get('graph').outerWidth();
      var arW = this.get('arrowWidth');
      var ttW = tooltip.outerWidth();
      var ttH = tooltip.outerHeight();
      var margin = this.margin;

      // reset classes
      tooltip.removeClass('top bottom');
      arrow.removeClass('center left right');

      // x axis boundary
      if (x - margin.left - ttW / 2 < 0) {
        arrow.addClass('left');
        x -= arW;
      } else if (x + ttW / 2 > gpW) {
        arrow.addClass('right');
        x -= ttW - arW;
      } else {
        arrow.addClass('center');
        x -= ttW / 2;
      }

      // y axis boundary
      if (y - margin.top - ttH / 2 < 0) {
        tooltip.addClass('bottom');
        y += arW;
      } else {
        tooltip.addClass('top');
        y -= ttH + arW;
      }

      // set position
      tooltip.addClass('show').css({
        left: x,
        top: y
      });
    },
    hideTooltip: function hideTooltip() {
      this.get('tooltip').removeClass('show');
    }
  });
});