define('carnival-frontend/mixins/fake-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    datalist: [],
    dataKey: 'sortedData',
    per: 10,
    page: 1,
    timeout: 1000,

    setupPagination: function setupPagination() {
      this.set('datalist', this.getPageSlice(1));
      this.set('page', 2);
    },
    getPageRange: function getPageRange(page) {
      var per = this.get('per');

      page = page || 1;

      return {
        begin: per * (page - 1),
        end: per * page
      };
    },
    getPageSlice: function getPageSlice(page) {
      var range = this.getPageRange(page);

      return this.get(this.dataKey).slice(range.begin, range.end);
    },
    fetchNext: function fetchNext() {
      var _this = this;

      var pageSlice = this.getPageSlice(this.get('page'));

      return new Ember.RSVP.Promise(function (resolve) {
        return Ember.run.later(_this, function () {
          return resolve(pageSlice);
        }, _this.timeout);
      });
    }
  });
});