define('carnival-frontend/controllers/messages/unpublish', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modelName: Ember.computed('model.abTest.content', function () {
      return this.get('model.abTest.content') ? 'A/B test' : 'message';
    }),

    actions: {
      unpublish: function unpublish() {
        var _this = this;

        var model = this.get('model.abTest.content') || this.get('model');

        model.unpublish().then(function () {
          _this.send('closeModal');
          _this.transitionToRoute('messages');
          _this.send('refresh');
          _this.alert.success('Unpublished', _this.get('modelName').capitalize() + ' was unpublished.');
        }, function (errors) {
          this.alert.danger('Error:', errors.toString());
        });
      }
    }
  });
});