define('carnival-frontend/components/sailthru-input', ['exports', 'carnival-frontend/components/rembr/stui-input'], function (exports, _stuiInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stuiInput.default.extend({
    classNameBindings: ['shouldRenderSTUIInput:stui-input'],
    attributeBindings: ['name', 'type', 'disabled', 'defaultValue', 'value', 'placeholder', 'max', 'min', 'dataABTestID', 'dataInsightId'],
    shouldRenderSTUIInput: Ember.computed.alias('ldClient.flags.mobile_stui_component'),
    classNames: ['stui-input-wrapper'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('elementId', this.elementId + '-wrapper');
    },
    renderInput: function renderInput() {
      if (this.shouldRenderSTUIInput) {
        this._super.apply(this, arguments);
      }
    }
  });
});