define('carnival-frontend/components/performance-stat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['performance-stat'],
    classNameBindings: ['statStatus'],

    hidePercent: Ember.computed.equal('stat.vsLastMonth', null),

    statNumDisplay: Ember.computed('stat.num', function () {
      return Math.round(this.get('stat.num'));
    }),

    status: Ember.computed('stat.vsLastMonth', function () {
      var diff = this.get('stat.vsLastMonth');

      if (diff > 0) return 'stat-positive';
      if (diff < 0) return 'stat-negative';

      return 'stat-no-change';
    }),

    percent: Ember.computed('stat.vsLastMonth', function () {
      var statVsLastMonth = this.get('stat.vsLastMonth');

      return statVsLastMonth ? statVsLastMonth : 0;
    })
  });
});