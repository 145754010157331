define('carnival-frontend/controllers/settings/set-bucket', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    bucketNameInput: '',

    bucketName: Ember.computed('bucketNameInput', function () {
      var bucketName = this.get('bucketNameInput').dasherize().replace(/[^a-z0-9-]/g, '') // use only lower case chars, numbers and dashes
      .replace(/(^-+|-+$)/g, ''); // cannot start or end with '-'

      return bucketName.length > 2 ? bucketName : null;
    }),

    actions: {
      setBucketName: function setBucketName() {
        var _this = this;

        if (this.get('bucketName.length') > 2 && this.get('bucketName.length') < 64) {
          this.set('model.settings.dataExportBucketName', this.get('bucketName'));

          this.get('model').save().then(function () {
            _this.alert.success('Success:', 'S3 Bucket name was saved.');
            _this.send('closeModal');
            _this.set('bucketNameInput', '');
          }).catch(function (response) {
            _this.alert.danger('Failed: ', response.message);
          });
        } else {
          this.alert.warning('Your bucket name length must be between 3 and 63 characters.');
        }
      },
      deactivate: function deactivate() {
        var _this2 = this;

        this.set('model.settings.dataExportBucketName', null);

        this.get('model').save().then(function () {
          _this2.alert.success('Success:', 'Date export has been disabled.');
          _this2.send('closeModal');
          _this2.set('bucketNameInput', '');
        }).catch(function (response) {
          _this2.alert.danger('Failed: ', response.message);
        });
      }
    }
  });
});