define('carnival-frontend/models/ab-test', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    messages: _emberData.default.hasMany('message'),
    app: _emberData.default.belongsTo('app'),
    targetable: _emberData.default.belongsTo('targetable', { polymorphic: true }),

    firstMessage: Ember.computed.alias('messages.firstObject'),

    isDraft: Ember.computed.equal('firstMessage.state', 'draft'),

    isValidating: false,

    isPushOnly: Ember.computed('messages.@each.isPushOnly', function () {
      return this.get('messages').every(function (message) {
        return message.get('isPushOnly');
      });
    }),

    updateMessage: function updateMessage(name, params) {
      var message = this.get('messages').findBy('variationName', name);
      message.setProperties(params);

      return message;
    },
    validate: function validate() {
      var _this = this;

      var type = this.constructor,
          adapter = this.store.adapterFor(type.modelName),
          url = adapter.buildURL('ab-test', this.get('id')) + '/validate',
          data = { ab_test: this.serialize({ includeId: true }) };

      data.ab_test.messages_attributes.forEach(function (message) {
        return delete message.asset_url;
      });

      this.set('isValidating', true);

      return adapter.ajax(url, 'POST', { data: data }).then(function (res) {
        _this.set('isValidating', false);

        return res;
      }).catch(function (error) {
        _this.set('isValidating', false);
        _this.get('errors').clear();

        if (error instanceof _emberData.default.InvalidError) {
          var errorsHash = _emberData.default.errorsArrayToHash(error.errors);
          _this.invalidateModel(errorsHash);

          _this.get('messages').forEach(function (message, index) {
            message.invalidateModel(errorsHash['messages'][index]);
          });
        }

        throw error;
      });
    },
    schedule: function schedule() {
      var _this2 = this;

      var type = this.constructor,
          adapter = this.store.adapterFor(type.modelName),
          url = adapter.buildURL('ab-test', this.get('id')) + '/schedule';

      return adapter.ajax(url, 'POST').then(function (data) {
        _this2.store.pushPayload('abTest', data);
      });
    },
    unpublish: function unpublish() {
      var _this3 = this;

      var type = this.constructor,
          adapter = this.store.adapterFor(type.modelName),
          url = adapter.buildURL('ab-test', this.get('id')) + '/unpublish';

      return adapter.ajax(url, 'POST').then(function (data) {
        _this3.store.pushPayload('abTest', data);
      });
    },
    save: function save() {
      var _this4 = this;

      var promise = this._super.apply(this, arguments);

      // remove duplicates created by DS.EmbeddedRecordsMixin bug
      // https://github.com/emberjs/data/issues/1829
      promise.then(function () {
        var messages = _this4.get('messages').filterBy('isNew');

        messages.forEach(function (m) {
          m.get('notifications').filterBy('isNew').invoke('deleteRecord');
          m.deleteRecord();
        });
      });

      return promise;
    },


    // When an error is present and it has a "belongsTo" relationship
    // the error won't go away after setting a new value as it normally would.
    // Manually clear the error to remove the targetable related error message.
    // https://github.com/emberjs/data/issues/1472
    // eslint-disable-next-line ember/no-observers
    targetDidChange: Ember.observer('targetable', function () {
      var errors = this.get('errors');

      if (errors.has('targetable_id')) {
        errors.get('targetable_id').clear();
      }
    })
  });
});