define('carnival-frontend/components/rembr/sailthru-navigation', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/sailthru-navigation', 'carnival-frontend/config/environment'], function (exports, _abstractComponent, _sailthruNavigation, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var sailthruNavigation = {
    user: {
      access: 'admin',
      first_name: '',
      is_sailthru_employee: false,
      last_name: ''
    },
    client: {
      adflight_advanced_enabled: false,
      has_shopping_cart: false,
      is_LO_enabled: false,
      is_autotag_enabled: false,
      is_concierge_enabled: false,
      is_predictions_enabled: false,
      is_smart_stratergy_on: false,
      is_spm_enabled: false,
      is_teams_enabled: false,
      show_channel_analytics: false,
      show_email_summary: false,
      show_retention_analytics: false,
      is_email_only: false,
      is_mobile_only: false
    }
  };

  var NAV_PATHS = ['/stats/[^\\?]+', '/messages[^\\?]*', '/audiences', '/settings/developer', '/settings/permissions', '/settings/logs/[^\\?]+'];

  exports.default = _abstractComponent.default.extend({
    elementId: 'rembr_unified-nav',
    apps: [],
    clients: [],
    ldKey: Ember.computed.alias('ldClient.currentUser.user.id'),
    router: Ember.inject.service(),

    // Removes query params and third level route names.
    //
    // Eg.
    // http://mobile.sailthru.com/#/apps/596c302666590a000c757aca/stats/revenue is converted to http://mobile.sailthru.com/#/apps/596c302666590a000c757aca/stats/revenue
    // http://mobile.sailthru.com/#/apps/596c302666590a000c757aca/messages?state=scheduled is converted to http://mobile.sailthru.com/#/apps/596c302666590a000c757aca/messages
    // http://mobile.sailthru.com/#/apps/596c302666590a000c757aca/settings/developer/advanced is converted to http://mobile.sailthru.com/#/apps/596c302666590a000c757aca/settings/developer
    //
    // This value is passed to the navigation component to determine which nav item/s have a selected state
    getActiveHref: function getActiveHref() {
      var _router$location$loca = this.router.location.location,
          origin = _router$location$loca.origin,
          href = _router$location$loca.href;


      var regex = new RegExp(origin + '/.+(' + NAV_PATHS.join('|') + ')');
      var res = regex.exec(href);

      return res && res.length > 1 ? res[0] : href;
    },

    init: function init() {
      this._super.apply(this, arguments);
      var clientApps = [].concat(_toConsumableArray(this.apps), _toConsumableArray(this.clients));

      var currentApp = this.currentApp;
      var currentAppId = currentApp && currentApp.id;
      var missingApp = clientApps.findBy('id', currentAppId);

      if (currentApp && !missingApp) {
        clientApps.unshiftObject(this.transformApp(currentApp));
      }

      this.setProperties({
        activeAppId: currentAppId,
        clientApps: clientApps
      });

      if (this.currentApp) this.addObserver('currentApp', this, this.currentAppDidChange);
    },
    windowHashDidChange: function windowHashDidChange() {
      this.renderNav();
    },
    currentAppDidChange: function currentAppDidChange() {
      this.set('activeAppId', this.currentApp.id);
      this.renderNav();
    },
    transformApp: function transformApp(app) {
      var props = app.getProperties('id', 'name', 'iconAssetUrl');

      return {
        id: props.id,
        name: props.name,
        icon_url: props.iconAssetUrl,
        url: '/#/apps/' + props.id,
        platform_channel: 'mobile'
      };
    },
    getAppById: function getAppById(id) {
      return this.get('clientApps').findBy('id', id);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.addEventListener('hashchange', Ember.run.bind(this, this.windowHashDidChange));
      this.renderNav();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener('hashchange', Ember.run.bind(this, this.windowHashDidChange));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderNav();
    },
    renderNav: function renderNav() {
      sailthruNavigation.user = _extends({}, sailthruNavigation.user, {
        admin: false,
        email: this.activeUser.email,
        is_authorized: this.hasAuth0,
        name: this.activeUser.name,
        super_admin: this.activeUser.superAdmin
      });

      sailthruNavigation.client = _extends({}, sailthruNavigation.client, {
        id: this.currentApp.sailthruClientID
      });

      sailthruNavigation.clientsAndApps = {
        apps: this.apps,
        clients: this.clients
      };

      if (this.activeAppId === null) {
        this.set('activeAppId', this.apps[0].id);
      }

      var urls = {
        my: _environment.default.myFrontend,
        mobile: '/#/apps/' + this.activeAppId
      };

      this.reactRender(React.createElement(_sailthruNavigation.default, {
        client: sailthruNavigation.client,
        user: sailthruNavigation.user,
        clientsAndApps: sailthruNavigation.clientsAndApps,
        activeAppId: this.activeAppId,
        urls: urls,
        ldKey: this.ldKey,
        activeHref: this.getActiveHref()
      }));
    }
  });
});