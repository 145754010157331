define('carnival-frontend/components/scroll-to-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scroller: Ember.inject.service(),
    classNames: 'scroll-to-toggle',
    pageElements: [],
    pageElementsLenght: 0,
    activePageElement: '',

    didInsertElement: function didInsertElement() {
      this.set('pageElementsLenght', this.get('pageElements.length'));

      // scroll to initially active page element
      this.get('scroller').scrollVertical(this.get('activePageElementId'), {
        duration: 500
      });

      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.bindScrolling();
      });
    },
    willDestroyElement: function willDestroyElement() {
      // if `scrolled()` is still running cancel it
      if (this.get('debounce')) Ember.run.cancel(this.get('debounce'));
      Ember.$('#scroll-to-container').off();
    },


    activePageElementId: Ember.computed('activePageElement', function () {
      return '#composer-message-' + this.get('activePageElement');
    }),

    // eslint-disable-next-line ember/no-observers
    activePageElementChanged: Ember.observer('activePageElement', function () {
      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('activePageElement') !== this.elementInScreen()) {
          this.get('scroller').scrollVertical(this.get('activePageElementId'), {
            duration: 500
          });
        }
      });
    }),

    elementInScreen: function elementInScreen() {
      var pageElements = this.get('pageElements.content');
      var containerHeight = Ember.$('#scroll-to-container').height();
      var max = 0;
      var elementInScreen = '';

      pageElements.forEach(function (pageElement) {
        var $pageElement = Ember.$('#composer-message-' + pageElement.get('variationName'));
        var elementTop = $pageElement.position().top;
        var elementEnd = elementTop + $pageElement.outerHeight();
        var pixel = Math.min(containerHeight, elementEnd) - Math.max(0, elementTop);

        if (pixel > max) {
          max = pixel;
          elementInScreen = pageElement.get('variationName');
        }
      });

      return elementInScreen;
    },
    scrolled: function scrolled() {
      this.set('activePageElement', this.elementInScreen());
    },
    bindScrolling: function bindScrolling() {
      var _this = this;

      var onScroll = function onScroll() {
        _this.set('debounce', Ember.run.debounce(_this, _this.scrolled, 50));
      };

      Ember.$('#scroll-to-container').on('scroll', onScroll);
    }
  });
});