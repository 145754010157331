define('carnival-frontend/components/stats/graph-axis', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'g',
    classNames: ['axis'],
    attributeBindings: ['transform'],

    scale: null,
    orient: 'bottom',
    ticks: 10,
    tickSize: 15,
    tickFormat: null,
    tickPadding: 15,

    d3Axis: Ember.computed('scale', 'orient', 'ticks', 'tickSize', 'tickFormat', 'tickPadding', function () {
      return d3.svg.axis().scale(this.get('scale')).orient(this.get('orient')).ticks(this.get('ticks')).tickSize(this.get('tickSize')).tickFormat(this.get('tickFormat')).tickPadding(this.get('tickPadding'));
    }),

    didInsertElement: function didInsertElement() {
      this._updateAxis();
    },


    // eslint-disable-next-line ember/no-observers
    onD3AxisChange: Ember.observer('d3Axis', function () {
      if (this._state !== 'inDOM') return;

      this._updateAxis();
    }),

    _updateAxis: function _updateAxis() {
      d3.select(this.$()[0]).call(this.get('d3Axis'));
    }
  });
});