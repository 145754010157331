define('carnival-frontend/controllers/stats/tech/index', ['exports', 'moment', 'carnival-frontend/mixins/stats/platforms'], function (exports, _moment, _platforms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_platforms.default, {
    queryParams: ['interval', 'platforms'],
    interval: '30',

    intervalLabel: Ember.computed('interval', function () {
      if (this.get('interval') === 'all') {
        return 'ALL TIME';
      } else {
        return 'LAST ' + this.get('interval') + ' DAYS';
      }
    }),

    hasData: Ember.computed('model.total', function () {
      return this.get('model.total') !== 0;
    }),

    fromDate: Ember.computed('interval', 'currentAppService.app.createdAt', function () {
      if (this.get('interval') === 'all') {
        return this.currentAppService.get('app.createdAt');
      }
      return (0, _moment.default)().subtract(parseInt(this.get('interval'), 10), 'days').toDate();
    }),

    pushEnabledUsers: Ember.computed('model.notifications_allowed', function () {
      var sumEnabled = 0;
      var enabled = this.get('model.notifications_allowed').findBy('term', 1);
      var enabledQuiet = this.get('model.notifications_allowed').findBy('term', 2);

      if (enabled) {
        sumEnabled += enabled.count;
      }
      if (enabledQuiet) {
        sumEnabled += enabledQuiet.count;
      }

      return sumEnabled;
    })
  });
});