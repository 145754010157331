define('carnival-frontend/controllers/stats/engage', ['exports', 'carnival-frontend/mixins/stats/titles', 'carnival-frontend/mixins/stats/dates', 'carnival-frontend/mixins/stats/annotations', 'carnival-frontend/mixins/stats/csv-export', 'carnival-frontend/mixins/stats/platforms'], function (exports, _titles, _dates, _annotations, _csvExport, _platforms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_titles.default, _dates.default, _annotations.default, _csvExport.default, _platforms.default, {
    queryParams: ['stat', 'from', 'to'],
    stat: 'opens',
    topbarTitle: 'Engagement',

    showPlatformFilters: true
  });
});