define('carnival-frontend/helpers/stat-titles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    opens: {
      counter1Title: 'Opens',
      counter2Title: 'Average Opens',
      counter3Title: 'Total Opens',
      tooltipTitle: 'Opens'
    },
    active_users: {
      counter1Title: 'Min Monthly Active Users',
      counter2Title: 'Average Monthly Active Users',
      counter3Title: 'Max Monthly Active Users',
      tooltipTitle: 'Active Users'
    },
    sessions: {
      counter1Title: 'Session Time',
      counter2Title: 'Average Session Time',
      counter3Title: 'Total Session Time',
      tooltipTitle: 'Session Time'
    },
    custom_event: {
      counter1Title: 'Event Count',
      counter2Title: 'Average Event Count',
      counter3Title: 'Total Event Count',
      tooltipTitle: 'Event Count'
    },
    installs: {
      counter1Title: 'Installs',
      counter2Title: 'Average Installs',
      counter3Title: 'Total Installs',
      tooltipTitle: 'Installs'
    },
    uninstalls: {
      counter1Title: 'Uninstalls',
      counter2Title: 'Average Uninstalls',
      counter3Title: 'Total Uninstalls',
      tooltipTitle: 'Uninstalls'
    },
    notifications_sent: {
      counter1Title: 'Pushes Sent',
      counter2Title: 'Average Pushes Sent',
      counter3Title: 'Total Pushes Sent',
      tooltipTitle: 'Pushes Sent'
    },
    revenue_stats: {
      counter1Title: 'Revenue',
      counter2Title: 'Average Revenue',
      tooltipTitle: 'Attributed Revenue'
    }
  };
});