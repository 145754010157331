define('carnival-frontend/components/dropdown-revealer', ['exports', 'carnival-frontend/components/content-revealer', 'carnival-frontend/mixins/reveal', 'carnival-frontend/templates/components/content-revealer'], function (exports, _contentRevealer, _reveal, _contentRevealer2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentRevealer.default.extend(_reveal.default, {
    layout: _contentRevealer2.default,

    attributeBindings: ['data-insight-id'],
    classNames: ['reveal-dropdown'],

    contentClassNames: 'reveal-menu dropdown-menu',
    toggleClassNames: 'btn btn-primary btn-icon',
    iconClassNames: 'glyphicon glyphicon-chevron-down glyphicon-sml',

    text: 'Dropdown',

    actions: {
      toggleReveal: function toggleReveal() {
        this.closeOnClickAway();
      }
    }
  });
});