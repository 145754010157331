define('carnival-frontend/components/pagination-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pagination-container'],
    model: null,
    modelName: 'device',
    totalResults: null,
    per: null,
    page: null,
    link: null,
    previousPage: null,
    numList: null,
    nextPage: null,
    numPages: null
  });
});