define('carnival-frontend/components/messages/message-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['message-date', 'reveal-popup', 'open-on-hover'],

    notLocalTime: Ember.computed.not('message.localTime'),

    popupPosition: Ember.computed('xPos', 'yPos', function () {
      var coords = this.getProperties('xPos', 'yPos');

      return new Ember.String.htmlSafe('top:' + coords.yPos + 'px;left:' + coords.xPos + 'px;');
    }),

    publishAt: Ember.computed('message.publishAt', function () {
      return this._getFormattedDateString('publishAt');
    }),

    unpublishAt: Ember.computed('message.unpublishAt', function () {
      return this._getFormattedDateString('unpublishAt');
    }),

    publishAtUTC: Ember.computed('message.publishAt.utcTime', function () {
      return this.get('message.publishAt.utcTime');
    }),

    unpublishAtUTC: Ember.computed('message.unpublishAt.utcTime', function () {
      return this.get('message.unpublishAt.utcTime');
    }),

    publishAtUser: Ember.computed('message.publishAt.userTime', function () {
      return this.get('message.publishAt.userTime');
    }),

    unpublishAtUser: Ember.computed('message.unpublishAt.userTime', function () {
      return this.get('message.unpublishAt.userTime');
    }),

    showUserTime: Ember.computed('publishAt', 'publishAtUser', function () {
      var _getProperties = this.getProperties('publishAt', 'publishAtUser'),
          publishAtUser = _getProperties.publishAtUser,
          publishAt = _getProperties.publishAt;

      return publishAtUser && publishAtUser !== publishAt;
    }),

    showUTCTime: Ember.computed('publishAt', 'publishAtUTC', function () {
      var _getProperties2 = this.getProperties('publishAt', 'publishAtUTC'),
          publishAtUTC = _getProperties2.publishAtUTC,
          publishAt = _getProperties2.publishAt;

      return publishAtUTC && publishAtUTC !== publishAt;
    }),

    _getFormattedDateString: function _getFormattedDateString(dateType) {
      var twz = this.get('message.' + dateType);
      if (!twz) return '';

      if (this.get('message.localTime')) {
        return twz.formattedValueWithoutTimezone + ' (Local Time)';
      } else {
        return twz.formattedValue;
      }
    },
    mouseMove: function mouseMove(e) {
      var rect = this.element.getBoundingClientRect();

      this.setProperties({
        xPos: e.pageX - rect.x,
        yPos: e.pageY - rect.y
      });
    }
  });
});