define('carnival-frontend/controllers/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notice: null,

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.get('model').requestPasswordReset().then(function () {
          _this.set('notice', 'Password reset instructions have been emailed to you');
        }, function (error) {
          alert(error.message);
        });
      }
    }
  });
});