define('carnival-frontend/helpers/portfolio-map-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    width: 0,
    height: 0,
    data: [],
    src: '',

    init: function init() {
      this._super();

      var img = new Image();

      img.addEventListener('load', Ember.run.bind(this, this.loadImage));
      img.src = this.get('src');
    },
    loadImage: function loadImage(e) {
      var img = e.target,
          canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d'),
          w = img.width,
          h = img.height;

      canvas.width = w;
      canvas.height = h;

      ctx.drawImage(img, 0, 0);

      var rawData = ctx.getImageData(0, 0, w, h).data,
          data = this.dataFromCanvas(rawData, w);

      this.setProperties({
        width: w,
        height: h,
        data: data
      });
    },
    dataFromCanvas: function dataFromCanvas(rawData, width) {
      var data = [[]],
          x = 0,
          y = 0,
          i = 0;

      while (i < rawData.length) {
        if (x >= width) {
          y++;
          x = 0;
          data[y] = [];
        }

        var red = rawData[i];

        if (red < 120) {
          data[y][x] = true;
        }

        x++;
        i += 4;
      }

      return data;
    }
  });
});