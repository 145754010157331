define('carnival-frontend/helpers/csv-encoder', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    data: null,
    csvString: '',

    stringOutput: function stringOutput() {
      var data = this.data,
          output = 'data:text/csv;charset=utf-8,';

      output += Object.keys(data[0]).join(',') + '\n';

      data.forEach(function (item, index) {
        var str = '';
        for (var prop in item) {
          str += item[prop] + ',';
        }var combinedStr = str.substring(0, str.length - 1);
        output += index < data.length ? combinedStr + '\n' : combinedStr;
      });
      this.set('csvString', output);
    },
    sendFile: function sendFile(filename) {
      var encodedUri = encodeURI(this.get('csvString')),
          link = document.createElement('a');

      link.download = filename + '.csv';
      link.href = encodedUri;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return link;
    }
  });
});