define('carnival-frontend/services/test-message-sender', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    messageBuilder: Ember.inject.service(),

    send: function send(originalMessage, audience) {
      var testMessage = this.messageBuilder.duplicateMessage(originalMessage);
      var targetable = audience;
      var deviceCount = audience.deviceCount.content;
      testMessage.setProperties({ targetable: targetable, deviceCount: deviceCount, test: true });
      return testMessage.save().catch(function (error) {
        testMessage.get('errors').forEach(function (_ref) {
          var attribute = _ref.attribute,
              message = _ref.message;

          originalMessage.get('errors').add(attribute, message);
        });
        throw error;
      });
    }
  });
});