define('carnival-frontend/controllers/messages/ab-tests/new', ['exports', 'carnival-frontend/controllers/messages/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _new.default.extend({
    queryParams: ['activeVariationName'],
    activeVariationName: 'a',

    messages: Ember.computed.alias('model.messages'),

    validateModel: function validateModel(model) {
      var _this = this;

      model.validate().then(function (response) {
        if (response.ab_test) response.ab_test.messages.forEach(function (m, i) {
          return _this.get('messages').objectAt(i).set('html', m.html);
        });
        _this.set('state', 'previewing');
      }, function (error) {
        _this.handleError(error);
      });
    },
    saveModel: function saveModel(model) {
      var _this2 = this;

      model.save().then(function () {
        return model.schedule();
      }).then(function () {
        _this2.send('saveSuccess', model);
      }).catch(function (error) {
        _this2.handleError(error);
      });
    },


    actions: {
      saveDraft: function saveDraft(model) {
        this.saveDraftModel(model);
      },
      transitionToMessage: function transitionToMessage(originalMessage) {
        if (originalMessage) return this.transitionToRoute('messages.edit', originalMessage);
        this.transitionToRoute('messages.new');
      }
    }
  });
});