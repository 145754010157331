define('carnival-frontend/helpers/head-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headTitle = headTitle;
  function headTitle(params) {
    var pageName = params[0],
        appName = params[1],
        title = 'Sailthru Mobile | ';

    if (typeof appName === 'string') {
      title += appName + ' - ';
    }

    title += pageName;

    Ember.$('title').text(title);
  }

  exports.default = Ember.Helper.helper(headTitle);
});