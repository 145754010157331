define('carnival-frontend/models/notification', ['exports', 'ember-data', 'carnival-frontend/models/model', 'moment', 'carnival-frontend/mixins/messages/video', 'carnival-frontend/mixins/messages/image'], function (exports, _emberData, _model, _moment, _video, _image) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_video.default, _image.default, {
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    sentAt: _emberData.default.attr('date'),
    deviceCount: _emberData.default.attr('number', { defaultValue: 0 }),
    opens: _emberData.default.attr('number'),
    progress: _emberData.default.attr('number', { defaultValue: 0 }),
    state: _emberData.default.attr('string', { defaultValue: 'awaiting_scheduling' }),
    lastErrorMessage: _emberData.default.attr('string', { defaultValue: '' }),
    deactivatedTokensCount: _emberData.default.attr('number', { defaultValue: 0 }),
    timeZones: _emberData.default.attr('string'),
    offset: _emberData.default.attr('number'),
    score: _emberData.default.attr('number', { defaultValue: 0 }),
    sent90: _emberData.default.attr('date'),
    sent95: _emberData.default.attr('date'),
    sent99: _emberData.default.attr('date'),
    pushStatusSummary: _emberData.default.attr('hash'),
    easyRichPushEnabled: _emberData.default.attr('boolean', { defaultValue: true }),

    message: _emberData.default.belongsTo('message', { inverse: 'notifications', async: false }),
    app: _emberData.default.belongsTo('app', { inverse: 'notifications', async: false }),

    title: _emberData.default.attr('string'),
    alert: _emberData.default.attr('string'),
    sound: _emberData.default.attr('string'),
    badge: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    buttonLabels: _emberData.default.attr(),
    _u: _emberData.default.attr('string'),
    selectedCategory: _emberData.default.attr({ defaultValue: undefined }),
    interruptionLevel: _emberData.default.attr('string'),
    relevanceScore: _emberData.default.attr('number'),

    customPayloadData: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    payload: Ember.computed('alert', 'sound', 'badge', 'category', 'title', 'buttonLabels', '_u', 'interruptionLevel', 'relevanceScore', 'customPayloadData.{@each.key,@each.value}', function () {
      var payloadHash = this.getProperties('alert', 'sound', 'badge', 'category', 'title', 'buttonLabels', '_u'),
          customHash = this.get('customPayloadData').reduce(function (prev, curr) {
        if (curr.key) prev[curr.key] = curr.value;
        return prev;
      }, {});

      payloadHash['interruption_level'] = this.get('interruptionLevel');
      payloadHash['relevance_score'] = this.get('relevanceScore');

      var payload = Ember.assign(payloadHash, customHash);

      Object.keys(payload).forEach(function (key) {
        if (Ember.isEmpty(payload[key])) delete payload[key];
      });

      return payload;
    }),

    eventSource: null,

    sendingProgress: Ember.computed('deviceCount', 'progress', function () {
      return this.get('deviceCount') - this.get('progress');
    }),

    payloadString: Ember.computed('payload', function () {
      return JSON.stringify(this.get('payload'));
    }),

    percentageSent: Ember.computed('progress', 'deviceCount', function () {
      var progress = this.get('progress'),
          deviceCount = this.get('deviceCount');

      if (!progress || !deviceCount) return 0;

      return Math.round(progress / deviceCount * 100);
    }),

    pushStates: {
      awaiting_scheduling: 'Scheduled',
      in_progress: 'Sending',
      done: 'Complete',
      paused: 'Paused',
      pausing: 'Pausing',
      expired: 'Expired'
    },

    pushState: Ember.computed('state', function () {
      return this.pushStates[this.get('state')];
    }),

    percentageWidthString: Ember.computed('percentageSent', function () {
      return new Ember.String.htmlSafe('width: ' + this.get('percentageSent'));
    }),

    publishAtFromOffset: Ember.computed('message', function () {
      return (0, _moment.default)(this.get('message.publishAt.utc_time')).add(this.get('offset'), 'hours');
    }),

    scoreResponse: {},

    startStatusObserver: function startStatusObserver() {
      var eventSource = new EventSource('/stream/notifications/' + this.id + '/status'),
          self = this;

      this.set('eventSource', eventSource);

      eventSource.addEventListener('open', function () {
        self.reload();
      }, false);

      eventSource.addEventListener('status_update', function (event) {
        var data = JSON.parse(event.data);
        self._updateFromObserverEvent(data);
      }, false);
    },
    stopStatusObserver: function stopStatusObserver() {
      var eventSource = this.get('eventSource');

      if (!eventSource) return;else eventSource.close();

      this.set('eventSource', null);
    },


    // eslint-disable-next-line ember/no-observers
    removeStatusObserver: Ember.observer('state', function () {
      if (this.get('state') === 'done') {
        this.stopStatusObserver();
        this.get('message').startStatusObserverForNextNotification();
      }

      this.get('message').reload();
    }),

    appId: Ember.computed('message.app.id', function () {
      return this.get('message.app.id');
    }),

    /**
     * private
     */
    _updateFromObserverEvent: function _updateFromObserverEvent(_ref) {
      var notification = _ref.notification;

      if (notification && notification.id) {
        this.store.findRecord('notification', notification.id).then(function (record) {
          record.setProperties({
            state: notification.state,
            progress: notification.progress,
            deviceCount: notification.device_count,
            deactivatedTokensCount: notification.deactivated_tokens_count
          });
        });
      }
    }
  });
});