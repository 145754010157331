define('carnival-frontend/components/select-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'select',
    classNames: ['ember-select'],

    selectedValue: null,

    items: Ember.computed('content', 'content.length', 'optionValuePath', 'optionLabelPath', function () {
      var labelPath = this.get('optionLabelPath'),
          valuePath = this.get('optionValuePath');

      if (labelPath) labelPath = labelPath.replace('content.', '');
      if (valuePath) valuePath = valuePath.replace('content.', '');

      var contentItems = this.get('content').map(function (item) {
        if (!valuePath) return { label: item, value: item };
        var object = item instanceof Ember.Object ? item : Ember.Object.create(item);
        return { label: object.get(labelPath), value: object.get(valuePath) };
      });

      if (this.get('prompt')) {
        return [{ label: this.get('prompt'), value: null }].addObjects(contentItems);
      } else {
        return contentItems;
      }
    }),

    change: function change() {
      var selectedEl = this.$()[0],
          selectedIndex = selectedEl.selectedIndex,
          items = this.get('items'),
          selectedValue = items[selectedIndex];

      this.set('value', selectedValue.value);
    }
  });
});