define('carnival-frontend/components/audiences/audience-card', ['exports', 'carnival-frontend/mixins/audiences/is-in-viewport', 'moment'], function (exports, _isInViewport, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_isInViewport.default, {
    actions: {
      delete: function _delete(model) {
        this.sendAction('delete', 'audiences/delete', model, 'audiences/crud');
      }
    },

    notTargetedAudience: Ember.computed.not('audience.targeted'),

    showUnusedPill: Ember.computed.and('notTargetedAudience'),

    isAudienceOlderThanSixtyDays: Ember.computed('audience.createdAt', function () {
      var sixtyDaysAgo = (0, _moment.default)().subtract(60, 'days');
      return (0, _moment.default)(this.audience.createdAt).isBefore(sixtyDaysAgo);
    })
  });
});