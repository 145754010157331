define('carnival-frontend/serializers/app', ['exports', 'ember-data', 'carnival-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    unserializedAttributes: ['createdAt', 'updatedAt', 'iconAssetUrl', 'isAdmin', 'isBilling', 'isAuthor', 'disabled'],

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json = this._serializeSettings(json);

      return json;
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      resourceHash = this._deserializeSettings(resourceHash);

      return this._super(modelClass, resourceHash, prop);
    },
    _serializeSettings: function _serializeSettings(json) {
      json.settings.api_rate_limit = json.api_rate_limit;
      delete json.api_rate_limit;

      json.settings.export_to_good_data = json.export_to_good_data;
      delete json.export_to_good_data;

      json.settings.currency_symbol = json.currency_symbol;
      delete json.currency_symbol;

      json.settings.purchase_attribution_window = json.purchase_attribution_window;
      delete json.purchase_attribution_window;

      return json;
    },
    _deserializeSettings: function _deserializeSettings(json) {
      if (json.settings) {
        if (json.settings.api_rate_limit) {
          json.api_rate_limit = json.settings.api_rate_limit;
          delete json.settings.api_rate_limit;
        }

        if (json.settings.export_to_good_data) {
          json.export_to_good_data = json.settings.export_to_good_data;
          delete json.settings.export_to_good_data;
        }

        if (json.settings.currency_symbol) {
          json.currency_symbol = json.settings.currency_symbol;
          delete json.settings.currency_symbol;
        }

        if (json.settings.purchase_attribution_window) {
          json.purchase_attribution_window = json.settings.purchase_attribution_window;
          delete json.settings.purchase_attribution_window;
        }
      }

      return json;
    }
  });
});