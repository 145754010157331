define('carnival-frontend/components/emoji-picker', ['exports', 'carnival-frontend/helpers/emoji-dict', 'carnival-frontend/helpers/from-code-point'], function (exports, _emojiDict, _fromCodePoint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    emojis: _emojiDict.default,
    search: '',
    selectedCategory: 'people',
    categories: ['people', 'nature', 'food', 'celebration', 'activity', 'travel', 'flag', 'objects'],

    filteredEmojis: Ember.computed('search', function () {
      var search = this.get('search'),
          emojis = this.get('emojis');

      if (!search) return [];

      return emojis.filter(function (emoji) {
        return emoji.name.indexOf(search) > -1;
      });
    }),

    sortedEmojis: Ember.computed('selectedCategory', function () {
      return this.get('emojis').filterBy('category', this.get('selectedCategory'));
    }),

    inputId: '',

    actions: {
      selectEmoji: function selectEmoji(emoji) {
        // convert emoji hex or hexes into html
        var fn = String.fromCodePoint || _fromCodePoint.default,
            str = fn.apply(null, emoji.hex),
            text = Ember.copy(this.get('value')) || '',
            pos = document.getElementById(this.inputId).selectionStart,
            // cursor position
        output = [text.slice(0, pos), str, text.slice(pos)].join('');

        this.set('value', output);
      },
      selectCategory: function selectCategory(category) {
        this.set('selectedCategory', category);
      }
    }
  });
});