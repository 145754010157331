define('carnival-frontend/models/filter', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _model.default.extend({
    name: _emberData.default.attr('string'),
    notFilter: _emberData.default.attr('boolean', { defaultValue: false }),
    criteria: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    // eslint-disable-next-line ember/no-observers
    onlyTermOrRange: Ember.observer('criteria', function () {
      var criteria = this.get('criteria'),
          isTerm = criteria.any(function (value) {
        return (typeof value === 'undefined' ? 'undefined' : _typeof(value)) !== 'object';
      }),
          isRange = criteria.any(function (value) {
        return (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object';
      });

      if (isTerm && isRange) {
        var newCriteria = criteria.filter(function (value) {
          return typeof value === 'string';
        });

        this.set('criteria', newCriteria);
      }
    }),

    hasCriteria: Ember.computed('criteria.length', function () {
      return !!this.get('criteria.length');
    })
  });
});