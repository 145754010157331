define('carnival-frontend/components/rembr/abstract-component', ['exports', 'react-dom'], function (exports, _reactDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Renders a react component as the current ember element
     * @param {React.Component} reactComponent. e.g., <HelloWorld />
     */
    reactRender: function reactRender(reactComponent) {
      _reactDom.default.render(reactComponent, this.element);
    },


    /**
     * Removes a mounted React component from the DOM and
     * cleans up its event handlers and state.
     */
    unmountReactElement: function unmountReactElement() {
      _reactDom.default.unmountComponentAtNode(this.element);
    },


    /**
     * Cleans up the rendered react component as the ember
     * component gets destroyed
     */
    willDestroyComponent: function willDestroyComponent() {
      this._super();
      this.unmountReactElement();
    }
  });
});