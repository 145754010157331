define('carnival-frontend/models/export-message-recipients-job', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    app: _emberData.default.belongsTo('app', { async: false }),
    message: _emberData.default.belongsTo('message', { async: false }),
    user: _emberData.default.belongsTo('user', { async: false }),
    name: _emberData.default.attr('string'),

    createExportRecipientsJob: function createExportRecipientsJob() {
      var type = this.constructor,
          store = this.get('store'),
          adapter = store.adapterFor(type.modelName),
          url = adapter.buildURL('message', this.get('message.id')) + '/recipients';

      return adapter.ajax(url, 'POST');
    }
  });
});