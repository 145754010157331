define('carnival-frontend/models/reactive-action', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    type: _emberData.default.attr('string', { defaultValue: 'entered' }),
    maxPushes: _emberData.default.attr('number', { defaultValue: 1 }),
    interval: _emberData.default.attr('number', { defaultValue: 3600 }), // in seconds
    delay: _emberData.default.attr('number', { defaultValue: 0 }), // in hours

    message: _emberData.default.belongsTo('message', {
      inverse: 'reactiveAction',
      async: false
    }),

    intervalInHours: Ember.computed('interval', {
      get: function get() {
        return this.get('interval') / 60 / 60;
      },
      set: function set(key, value) {
        this.set('interval', value * 60 * 60);
        return this.get('interval') / 60 / 60;
      }
    }),

    delayed: Ember.computed('delay', function () {
      return this.get('delay') > 0;
    })
  });
});