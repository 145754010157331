define('carnival-frontend/components/messages/test-message-popup-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    testMessageSender: Ember.inject.service(),
    state: 'loading',
    tagName: 'li',
    classNames: ['list-item', 'test-audience'],
    classNameBindings: ['isDisabled:disabled', 'isClickable:clickable'],

    // eslint-disable-next-line ember/no-observers
    deviceCountDidChange: Ember.observer('audience.deviceCount.{isFulfilled,content}', function () {
      if (this.getWithDefault('audience.deviceCount.isFulfilled', false)) {
        var deviceCount = this.getWithDefault('audience.deviceCount.content', 0);
        this.set('deviceCount', deviceCount);
        if (deviceCount > 50 || deviceCount < 1) {
          this.set('state', 'invalid');
        } else {
          this.set('state', 'loaded');
        }
      }
    }).on('init'),

    isLoading: Ember.computed.equal('state', 'loading'),
    isLoaded: Ember.computed.equal('state', 'loaded'),
    isSending: Ember.computed.equal('state', 'sending'),
    isDone: Ember.computed.equal('state', 'done'),
    isError: Ember.computed.equal('state', 'error'),
    isInvalid: Ember.computed.equal('state', 'invalid'),

    showLoadingCircles: Ember.computed.or('isLoading', 'isSending'),
    showDeviceCount: Ember.computed.or('isLoaded', 'isInvalid'),
    showDone: Ember.computed.or('isDone'),
    showFailed: Ember.computed.or('isError'),
    isDisabled: Ember.computed.or('isInvalid'),
    isClickable: Ember.computed.or('isLoaded', 'isDone', 'isError'),

    click: function click() {
      if (this.get('isClickable')) {
        return this.send('sendTestMessage', this.get('message'), this.get('audience'));
      }
    },


    actions: {
      sendTestMessage: function sendTestMessage(message, audience) {
        var _this = this;

        this.set('state', 'sending');
        return this.testMessageSender.send(message, audience).then(function () {
          return _this.set('state', 'done');
        }).catch(function () {
          return _this.set('state', 'error');
        });
      }
    }
  });
});