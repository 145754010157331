define('carnival-frontend/serializers/permission', ['exports', 'ember-data', 'carnival-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      audience: { embedded: 'load' }
    },

    serialize: function serialize(permission, options) {
      var json = this._super(permission, options);

      if (permission.belongsTo('audience')) {
        json.audience_id = permission.belongsTo('audience').id;
      } else {
        json.audience_id = null;
      }
      return json;
    }
  });
});