define('carnival-frontend/mixins/stats/platforms', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['platforms'],
    platforms: [],

    allActive: Ember.computed('platforms.[]', function () {
      return this.get('platforms.length') === 0;
    }),

    iosActive: Ember.computed('platforms.[]', function () {
      return this.get('platforms').includes('iOS');
    }),

    androidActive: Ember.computed('platforms.[]', function () {
      return this.get('platforms').includes('Android');
    }),

    actions: {
      setPlatforms: function setPlatforms(platform) {
        if (!platform) {
          this.set('platforms', []);
          return;
        }

        var platforms = this.get('platforms');

        if (platforms.includes(platform)) {
          platforms.removeObject(platform);
        } else {
          platforms.addObject(platform);
        }
      }
    }
  });
});