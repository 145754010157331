define('carnival-frontend/models/message-response', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    messageStreamImpressions: _emberData.default.attr('number', { defaultValue: 0 }),
    inAppImpressions: _emberData.default.attr('number', { defaultValue: 0 }),
    fullScreenMessageImpressions: _emberData.default.attr('number', { defaultValue: 0 }),
    totalImpressions: _emberData.default.attr('number', { defaultValue: 0 }),
    totalNotificationOpens: _emberData.default.attr('number', { defaultValue: 0 }),
    totalNotificationIndirectOpens: _emberData.default.attr('number', { defaultValue: 0 }),
    totalNotificationDeviceCount: _emberData.default.attr('number', { defaultValue: 0 }),
    totalNotificationDeactivatedTokensCount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    attributedEvents: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    attributedPurchases: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    attributedPurchaseAdjustments: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          tax: 0,
          shipping: 0,
          discount: 0,
          custom: 0
        };
      }
    }),
    netRevenue: _emberData.default.attr('number', { defaultValue: 0 }),

    totalPurchaseValue: Ember.computed('attributedPurchases.@each.value', function () {
      return this.get('attributedPurchases').reduce(function (acc, item) {
        return acc + item.total_value;
      }, 0);
    }),

    totalPurchaseCount: Ember.computed('attributedPurchases.@each.count', function () {
      return this.get('attributedPurchases').reduce(function (acc, item) {
        return acc + item.count;
      }, 0);
    }),

    revenuePer1kNotifications: Ember.computed('totalPurchaseValue', 'totalSuccessfullySent', function () {
      return 1000 * this.get('totalPurchaseValue') / this.get('totalSuccessfullySent');
    }),

    allNotificationOpens: Ember.computed('totalNotificationOpens', 'totalNotificationIndirectOpens', function () {
      return this.get('totalNotificationOpens') + this.get('totalNotificationIndirectOpens');
    }),

    totalSuccessfullySent: Ember.computed('totalNotificationDeviceCount', 'totalNotificationDeactivatedTokensCount', function () {
      return this.get('totalNotificationDeviceCount') - this.get('totalNotificationDeactivatedTokensCount');
    }),

    message: _emberData.default.belongsTo('message', { inverse: 'analytics', async: false })
  });
});