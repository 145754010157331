define('carnival-frontend/components/audiences/audience-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['filter-component'],
    classNameBindings: ['isSelected:selected'],
    attributeBindings: ['title'],
    tagName: 'li',
    facetFilter: null,
    selectedFacetFilter: null,
    filterName: '',
    selectedCount: Ember.computed.oneWay('facetFilter.filter.criteria.length'),

    isSelected: Ember.computed('selectedFacetFilter', 'facetFilter', function () {
      return this.get('selectedFacetFilter') === this.get('facetFilter');
    }),

    click: function click() {
      this.sendAction('action', this.get('facetFilter'));
    },
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.set('title', this.$('.name').text().trim());
      });
    }
  });
});