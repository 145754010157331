define('carnival-frontend/controllers/stats/geo/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: 'interval',
    interval: 'all',

    hasData: Ember.computed('model', function () {
      var data = this.get('model');
      for (var key in data) {
        if (data[key].length <= 0) return false;
      }
      return true;
    }),

    fromDate: Ember.computed('interval', 'currentAppService.app.createdAt', function () {
      if (this.get('interval') === 'all') {
        return this.currentAppService.get('app.createdAt');
      }
      return (0, _moment.default)().subtract(parseInt(this.get('interval')), 'days').toDate();
    })
  });
});