define('carnival-frontend/routes/audiences', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      sort: {
        refreshModel: false
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        audiences: model.get('audiences'),
        importAudienceJobs: model.get('importAudienceJobs')
      });
    }
  });
});