define('carnival-frontend/routes/app/index', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    redirect: function redirect(model, transition) {
      var app_id = transition.params.app.app_id;


      if (this.get('env.environment') === 'test') {
        this.replaceWith('stats.engage');
      } else {
        transition.abort();
        window.location.replace('/#/apps/' + app_id + '/stats/engagement');
      }
    }
  });
});