define('carnival-frontend/serializers/apps-stats-performance', ['exports', 'carnival-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.forEach(function (item, index) {
        item.id = index;
      });

      return this._super(store, primaryModelClass, { 'apps-stats-performances': payload }, id, requestType);
    }
  });
});