define('carnival-frontend/models/interruption-level', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Interruption Levels
  function Passive() {
    this.name = 'Passive';
    this.value = 'passive';
  }

  function Active() {
    this.name = 'Active (Default)';
    this.value = 'active';
  }

  function TimeSensitive() {
    this.name = 'Time Sensitive';
    this.value = 'time-sensitive';
  }

  function Critical() {
    this.name = 'Critical';
    this.value = 'critical';
  }

  var interruptionLevels = exports.interruptionLevels = [new Passive(), new Active(), new TimeSensitive(), new Critical()];
});