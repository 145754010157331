define('carnival-frontend/serializers/ab-test', ['exports', 'ember-data', 'carnival-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    root: 'ab-test',
    unserializedAttributes: ['createdAt', 'updatedAt'],
    attrs: {
      messages: { embedded: 'always' }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options),
          messages = json.messages_attributes;

      if (messages.length) {
        // Keep same publish_at, unpublish_at and local_time for all messages
        messages.forEach(function (m) {
          m.publish_at_attributes = messages[0].publish_at_attributes;
          m.unpublish_at_attributes = messages[0].unpublish_at_attributes;
          m.local_time = messages[0].local_time;
        });
      }

      return json;
    },
    serializePolymorphicType: function serializePolymorphicType(snapshot, json, relationship) {
      var key = relationship.key,
          belongsTo = snapshot.belongsTo(key);

      if (belongsTo) {
        json[key] = {
          id: json[key + '_id'],
          type: belongsTo.modelName
        };

        delete json[key + '_id'];
      }
    },
    keyForAttribute: function keyForAttribute(key, method) {
      key = this._super(key, method);

      if (method === 'serialize') {
        switch (key) {
          case 'messages':
            return 'messages_attributes';
        }
      }

      return key;
    }
  });
});