define('carnival-frontend/mixins/stats/dates', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    from: (0, _moment.default)().subtract(6, 'days').startOf('day').toDate().toISOString(),
    to: (0, _moment.default)().endOf('day').toDate().toISOString(),

    last7Days: Ember.Object.create({
      from: (0, _moment.default)().subtract(6, 'days').startOf('day').toDate().toISOString(),
      to: (0, _moment.default)().endOf('day').toDate().toISOString()
    }),

    last30Days: Ember.Object.create({
      from: (0, _moment.default)().subtract(29, 'days').startOf('day').toDate().toISOString(),
      to: (0, _moment.default)().endOf('day').toDate().toISOString()
    }),

    last60Days: Ember.Object.create({
      from: (0, _moment.default)().subtract(59, 'days').startOf('day').toDate().toISOString(),
      to: (0, _moment.default)().endOf('day').toDate().toISOString()
    }),

    range: Ember.computed('from', 'to', {
      get: function get() {
        return Ember.Object.create({
          from: new Date(this.get('from')),
          to: new Date(this.get('to'))
        });
      },
      set: function set(key, value) {
        this.set('from', value.from.toISOString());
        this.set('to', value.to.toISOString());
        return Ember.Object.create({
          from: new Date(this.get('from')),
          to: new Date(this.get('to'))
        });
      }
    }),

    actions: {
      cycleRange: function cycleRange(direction) {
        var from = (0, _moment.default)(this.get('from')),
            to = (0, _moment.default)(this.get('to')),
            diff = to.diff(from, 'days'),
            method = direction === 'forwards' ? 'add' : 'subtract',
            newFrom = from[method](diff + 1, 'days'),
            newTo = to[method](diff + 1, 'days');

        this.setProperties({
          from: newFrom.toDate().toISOString(),
          to: newTo.toDate().toISOString()
        });
      }
    }
  });
});