define('carnival-frontend/components/scroll-to-toggle-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNameBindings: [':toggle-navigation-element', 'nameClass', 'isActive:active', 'hasError:error'],
    attributeBindings: 'href',

    activePageElement: '',
    active: false,

    errorMessage: [{ message: 'Error' }],

    name: Ember.computed.alias('model.variationName'),

    nameClass: Ember.computed('name', function () {
      return 'toggle-' + this.get('name');
    }),

    href: Ember.computed('name', function () {
      return '#' + this.get('name');
    }),

    isActive: Ember.computed('activePageElement', function () {
      return this.get('activePageElement') === this.get('name');
    }),

    click: function click(event) {
      event.preventDefault();
      this.set('activePageElement', this.get('name'));
    }
  });
});