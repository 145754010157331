define('carnival-frontend/components/audiences/filter-desc', ['exports', 'moment', 'carnival-frontend/mixins/audiences/es-ranges', 'carnival-frontend/helpers/map-es-range-to-string'], function (exports, _moment, _esRanges, _mapEsRangeToString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend(_esRanges.default, {
    classNames: ['filter-desc'],
    isSingleCriteria: Ember.computed.equal('filter.criteria.length', 1),
    isMultiCriteria: Ember.computed.not('isSingleCriteria'),
    showIsNotToggle: false,

    booleanOptions: Ember.computed('isSingleCriteria', function () {
      var single = this.get('isSingleCriteria'),
          yay = single ? 'IS' : 'ARE',
          nay = single ? 'IS NOT' : 'ARE NOT';

      return [{ name: yay, id: false }, { name: nay, id: true }];
    }),

    criteriaValuesString: Ember.computed('filter.criteria.[]', function () {
      var _this = this;

      var count = void 0;

      var descriptions = this.get('filter.criteria').map(function (value) {
        if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) !== 'object') {
          return value;
        }

        if ('distance' in value) {
          return 'within ' + value.distance + ' ' + value.unit + ' of (' + value.point.latitude + ', ' + value.point.longitude + ')';
        }

        if ('polygon' in value) {
          return 'within a selected region';
        }

        if (value.range_type === 'custom_number') {
          if (value.equality === 'between') {
            return 'between ' + value.from + ' and ' + value.to;
          } else if (value.equality === 'equal') {
            return 'exactly ' + value.count;
          } else {
            return value.equality + ' than ' + value.count;
          }
        }

        if (value.range_type === 'custom_event') {
          if (value.equality === 'between') {
            return 'occurred between ' + value.from + ' and ' + value.to + ' times';
          } else if (value.equality === 'equal') {
            return 'occurred exactly ' + value.count + ' times';
          } else {
            return 'occurred ' + value.equality + ' than ' + value.count + ' times';
          }
        }

        if (['equal', 'less', 'more'].indexOf(value.equality) > -1) {
          // eslint-disable-next-line no-prototype-builtins
          count = value.hasOwnProperty('count') ? value.count : value.days;

          if (value.equality === 'equal') {
            return 'exactly ' + count + ' days ago';
          } else {
            return value.equality + ' than ' + count + ' days ago';
          }
        }

        // date range (old way - always a less than range)
        if (['one_day_ago', 'one_week_ago', 'one_month_ago', 'two_months_ago'].indexOf(value.range_index) > -1) {
          count = _this.getCount(value.gte);

          return 'less than ' + count + ' days ago';
        }
        // custom date range
        if (value.range_index === 'custom' || value.equality === 'between') {
          // eslint-disable-next-line no-prototype-builtins
          var a = value.hasOwnProperty('from') ? value.from : value.from_days,

          // eslint-disable-next-line no-prototype-builtins
          b = value.hasOwnProperty('to') ? value.to : value.to_days;

          if (!isNaN(parseInt(a)) && !isNaN(parseInt(b))) {
            return 'between ' + a + ' and ' + b + ' days ago';
          } else {
            return 'between ' + value.gte + ' and ' + value.lte;
          }
        }

        if (value.equality === 'between-dates') {
          var timezoneOffset = new Date().getTimezoneOffset();
          var _a = (0, _moment.default)(value.from).format('MMMM Do YYYY');
          var _b = (0, _moment.default)(value.to).format('MMMM Do YYYY');
          var c = _moment.default.tz.zone(_moment.default.tz.guess()).abbr(timezoneOffset);
          return 'between ' + _a + ' and ' + _b + ' ' + c + ' (inclusive)';
        }

        // filters created via API might only contain random range values
        return (0, _mapEsRangeToString.mapEsRangeToString)(value);
      });
      if (descriptions.length >= 2) {
        var orOrAnd = this.get('filter.criteria').findBy('range_type', 'custom_event') ? ' AND ' : ' OR ';

        return descriptions.slice(0, -1).join(', ') + orOrAnd + descriptions.slice(-1);
      }
      return descriptions.join(', ');
    })
  });
});