define('carnival-frontend/components/audiences/geo-map', ['exports', 'carnival-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['geo-fence-map'],
    polygons: [],
    points: [],
    isLoading: true,
    transactionalWriteLock: false,

    didInsertElement: function didInsertElement() {
      var latLngBounds = [[-90, -180], [90, 180]]; // ie bound the map view to the size of one map
      var map = L.map(this.element, { maxBounds: latLngBounds }).setView([0.0, 0.0], 2);

      L.tileLayer(_environment.default.mapbox.tilePath, {
        maxZoom: 18,
        minZoom: 2,
        noWrap: true,
        bounds: latLngBounds
      }).addTo(map);

      this.createDrawingControls(map);
      this.syncGeoWithAudience();
      this.renderShapes();
      this.set('map', map);
      this.set('isLoading', false);
    },
    willDestroyElement: function willDestroyElement() {
      this.map.remove();
      this.set('transactionalWriteLock', false);
      this.setProperties({ points: [], polygons: [] });
    },
    createDrawingControls: function createDrawingControls(map) {
      var featureGroup = new L.FeatureGroup();

      map.addLayer(featureGroup);

      var drawControl = new L.Control.Draw({
        draw: { polyline: false, marker: false, circlemarker: false },
        edit: { featureGroup: featureGroup }
      });

      this.set('featureGroup', featureGroup);
      map.addControl(drawControl);
      map.on('draw:created', this.addShapeToAudience.bind(this));
      map.on('draw:edited', this.updateAudienceShapes.bind(this));
      map.on('draw:deleted', this.updateAudienceShapes.bind(this));
    },
    renderShapes: function renderShapes() {
      var featureGroup = this.get('featureGroup');

      featureGroup.clearLayers();

      this.get('polygons').forEach(function (polygon) {
        var layer = L.polygon(polygon);
        featureGroup.addLayer(layer);
      });

      this.get('points').forEach(function (point) {
        var layer = L.circle([point.lat, point.lng], point.radius);
        featureGroup.addLayer(layer);
      });
    },
    withWriteLock: function withWriteLock(func) {
      if (this.transactionalWriteLock) return;
      this.set('transactionalWriteLock', true);
      func.call();
      this.set('transactionalWriteLock', false);
    },
    addShapeToAudience: function addShapeToAudience(feature) {
      var _this = this;

      this.withWriteLock(function () {
        if (feature.layerType === 'circle') {
          _this.addCircle(feature.layer);
        } else if (['polygon', 'rectangle'].includes(feature.layerType)) {
          _this.addPolygon(feature.layer);
        }

        _this.syncAudienceWithGeo();
        _this.renderShapes();
      });
    },
    updateAudienceShapes: function updateAudienceShapes() {
      var _this2 = this;

      this.withWriteLock(function () {
        _this2.polygons.clear();
        _this2.points.clear();

        _this2.featureGroup.eachLayer(function (layer) {
          if (layer.getRadius) {
            _this2.addCircle(layer);
          } else if (layer.getLatLngs) {
            _this2.addPolygon(layer);
          }
        });

        _this2.syncAudienceWithGeo();
      });
    },


    // Necessary because geo filters can be changed elsewhere - most notably in the sidebar, where you can click a little
    // x to delete all of the location filters
    // eslint-disable-next-line ember/no-observers
    audienceHasGeo: Ember.observer('audience.hasGeoFilters', function () {
      // We only want to fire this when the changes come from *outside* this component, ie filters being deleted from the
      // sidebar, so we 'lock' audience syncing in the `addShapeToAudience` and `updateAudienceShapes` methods below
      if (this.get('transactionalWriteLock')) return;

      this.syncGeoWithAudience();
      this.renderShapes();
    }),

    // this.geo -> audience.geo
    syncAudienceWithGeo: function syncAudienceWithGeo() {
      this.set('audience.geo', this.getProperties('points', 'polygons'));
    },


    // audience.geo -> this.geo
    syncGeoWithAudience: function syncGeoWithAudience() {
      this.setProperties({
        points: this.get('audience.geo.points'),
        polygons: this.get('audience.geo.polygons')
      });
    },
    addPolygon: function addPolygon(polygon) {
      var _this3 = this;

      polygon.getLatLngs().forEach(function (latLng) {
        return _this3.polygons.addObject(latLng);
      });
    },
    addCircle: function addCircle(circle) {
      var _circle$getLatLng = circle.getLatLng(),
          lat = _circle$getLatLng.lat,
          lng = _circle$getLatLng.lng;

      this.get('points').addObject({ radius: circle.getRadius(), lat: lat, lng: lng });
    }
  });
});