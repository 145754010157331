define('carnival-frontend/services/auth0-pinger', ['exports', '@sailthru/auth-helpers', 'carnival-frontend/config/environment'], function (exports, _authHelpers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    webAuth: new _authHelpers.WebAuth({
      auth0: {
        domain: _environment.default.auth0.domain,
        clientId: _environment.default.auth0.clientId,
        callbackUrl: _environment.default.auth0.callbackUrl,
        iframeUrl: '/web/v1/user/auth/auth0?redirect_uri=/web/v1'
      },
      sentry: {
        enabled: _environment.default.sentry.enabled,
        env: _environment.default.sentry.env
      }
    }),

    isAuth0Authenticated: function isAuth0Authenticated() {
      return this.webAuth.isUserAuthenticatedInAuth0();
    }
  });
});