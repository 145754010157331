define('carnival-frontend/helpers/to-fixed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toFixed = toFixed;
  function toFixed(params, hash) {
    var number = params[0],
        precision = hash.precision;

    precision = typeof precision === 'number' ? precision : 2;
    return Number(number).toFixed(precision);
  }

  exports.default = Ember.Helper.helper(toFixed);
});