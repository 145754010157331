define('carnival-frontend/mixins/crud', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    routeToTransitionTo: '',

    errorHandler: function errorHandler(error) {
      var msg = this.get('model.validationErrorMessage');

      if (msg) return this.alert.danger('Error:', msg);

      throw error;
    },
    didSave: function didSave() {
      this.send('dismiss');
    },
    didNotSave: function didNotSave(error) {
      this.errorHandler(error);
    },
    didDestroy: function didDestroy() {
      this.send('closeModal');
    },
    didNotDestroy: function didNotDestroy(error) {
      this.errorHandler(error);
    },


    actions: {
      dismiss: function dismiss() {
        this.transitionToRoute(this.get('routeToTransitionTo'));
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.send('dismiss');
      },
      save: function save() {
        this.get('model').save().then(Ember.run.bind(this, this.didSave), Ember.run.bind(this, this.didNotSave));
      },
      destroy: function destroy() {
        this.get('model').destroyRecord().then(Ember.run.bind(this, this.didDestroy), Ember.run.bind(this, this.didNotDestroy));
      }
    }
  });
});