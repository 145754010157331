define('carnival-frontend/components/rembr/stui-content-dropdown', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/stui-components', 'html-react-parser'], function (exports, _abstractComponent, _stuiComponents, _htmlReactParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var castValue = function castValue(value) {
    return value === 'true' || value === 'false' ? JSON.parse(value) : value;
  };

  exports.default = _abstractComponent.default.extend({
    attributeBindings: ['data-test-id', 'disabled'],
    classNameBindings: ['shouldRenderSTUIButton:stui-btn'],
    shouldRenderSTUIButton: Ember.computed.alias('ldClient.flags.mobile_stui_component'),

    isPrimary: Ember.computed('class', function () {
      return Ember.isPresent(this.toggleClassNames) && this.toggleClassNames.indexOf('btn-primary') > -1;
    }),

    buildContentDropdown: function buildContentDropdown() {
      var attrs = Object.fromEntries(Object.entries(this.attrs).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        return [key, castValue(value)];
      }));

      var icon = attrs.icon,
          text = attrs.text,
          align = attrs.align,
          props = _objectWithoutProperties(attrs, ['icon', 'text', 'align']);

      var buttonIcon = React.createElement('span', { className: 'glyphicon ' + icon });
      var buttonLabel = text && text.string ? (0, _htmlReactParser.default)(text.string) : text;
      var button = React.createElement(
        _stuiComponents.Button,
        { variant: this.isPrimary ? _stuiComponents.Button.VARIANT_PRIMARY : _stuiComponents.Button.VARIANT_SECONDARY },
        icon ? buttonIcon : buttonLabel
      );

      return React.createElement(
        _stuiComponents.ContentDropdown,
        _extends({}, props, {
          alignCenter: align === 'alignCenter',
          alignRight: align === 'alignRight',
          trigger: button
        }),
        this.buildContentDropdownChildren()
      );
    },
    buildContentDropdownChildren: function buildContentDropdownChildren() {
      return (0, _htmlReactParser.default)(this.element.innerHTML);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.reactRender(this.buildContentDropdown());
    }
  });
});