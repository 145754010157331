define('carnival-frontend/initializers/alert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'alert', 'service:alert');
    application.inject('route', 'alert', 'service:alert');
  }

  exports.default = {
    name: 'alert',
    initialize: initialize
  };
});