define('carnival-frontend/components/clipboard-copy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['clipboard-button'],
    tagName: 'button',
    attributeBindings: ['data-clipboard-target', 'data-clipboard-text'],

    didInsertElement: function didInsertElement() {
      this._clipboard = new Clipboard(this.element, {
        target: function target(trigger) {
          return trigger.nextElementSibling || trigger.previousElementSibling;
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      if (!this._clipboard) {
        return;
      }

      this._clipboard.destroy();
    },
    click: function click() {
      return false;
    }
  });
});