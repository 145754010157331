define('carnival-frontend/components/rembr/platform-switcher', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/platform-switcher'], function (exports, _abstractComponent, _platformSwitcher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractComponent.default.extend({
    elementId: 'rembr_platform-switcher',
    apps: [],
    clients: [],

    init: function init() {
      this._super.apply(this, arguments);

      var clientApps = this.get('apps').concat(this.get('clients'));

      var currentApp = this.get('currentApp');
      var currentAppId = currentApp && currentApp.id;
      var missingApp = clientApps.findBy('id', currentAppId);

      // For super admin users that have access to all apps
      // via admin screen but are not listed in the platform switcher
      // we need to grab the current app and and transform it
      // into the format we need for the platform switcher
      if (currentApp && !missingApp) {
        clientApps.unshiftObject(this.transformApp(currentApp));
      }

      this.setProperties({
        activeAppId: currentAppId,
        clientApps: clientApps
      });

      if (this.get('currentApp')) this.addObserver('currentApp', this, this.currentAppDidChange);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderPlatformSwitcher();
    },
    renderPlatformSwitcher: function renderPlatformSwitcher() {
      var activeAppId = this.get('activeAppId');
      this.reactRender(React.createElement(_platformSwitcher.default, {
        apps: this.get('clientApps'),
        activeAppId: activeAppId
      }));
    },
    currentAppDidChange: function currentAppDidChange() {
      var app = this.getAppById(this.get('currentApp.id'));

      this.set('activeAppId', app && app.id);
      this.renderPlatformSwitcher();
    },
    transformApp: function transformApp(app) {
      var props = app.getProperties('id', 'name', 'iconAssetUrl');

      return {
        id: props.id,
        name: props.name,
        icon_url: props.iconAssetUrl,
        url: '/#/apps/' + props.id,
        platform_channel: 'mobile'
      };
    },
    getAppById: function getAppById(id) {
      return this.get('clientApps').findBy('id', id);
    }
  });
});