define('carnival-frontend/initializers/ld-client', ['exports', 'carnival-frontend/services/ld-client', 'carnival-frontend/config/environment'], function (exports, _ldClient, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var launchDarklyConfig = _environment.default.launchDarkly;

    if (!launchDarklyConfig.enabled) return;

    application.register('ld-client:main', _ldClient.default);

    application.inject('route', 'ldClient', 'ld-client:main');
    application.inject('controller', 'ldClient', 'ld-client:main');
    application.inject('serializer', 'ldClient', 'ld-client:main');
    application.inject('model', 'ldClient', 'ld-client:main');
    application.inject('component', 'ldClient', 'ld-client:main');

    application.inject('ld-client:main', 'currentApp', 'service:current-app');
    application.inject('ld-client:main', 'currentUser', 'service:current-user');
  }

  exports.default = {
    name: 'ld-client',
    initialize: initialize
  };
});