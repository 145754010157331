define('carnival-frontend/components/rembr/user-menu', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/user-menu'], function (exports, _abstractComponent, _userMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractComponent.default.extend({
    elementId: 'rembr_user-menu',

    links: [{
      label: 'Documentation',
      href: 'http://docs.carnival.io'
    }, {
      label: 'Admin',
      href: '/#/admin'
    }, {
      label: 'My Account',
      href: '/#/user'
    }],

    getLinks: function getLinks(isAdmin) {
      if (isAdmin) return this.get('links');
      return this.get('links').rejectBy('label', 'Admin');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var activeUser = this.get('activeUser');

      this.set('user', {
        name: activeUser.get('name'),
        email: activeUser.get('email'),
        isAdmin: activeUser.get('superAdmin') || activeUser.get('superViewer')
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var isAdmin = this.get('user.isAdmin');

      this.reactRender(React.createElement(_userMenu.default, {
        user: this.get('user'),
        links: this.getLinks(isAdmin),
        logout: '/#/logout'
      }));
    }
  });
});