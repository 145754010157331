define('carnival-frontend/components/rembr/stui-statistic', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/stui-components'], function (exports, _abstractComponent, _stuiComponents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractComponent.default.extend({
    classNames: ['statistic'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderStatistic();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderStatistic();
    },
    getTitleEl: function getTitleEl() {
      if (this.get('icon')) {
        var iconClass = 'icon glyphicon u-inline-block ' + this.get('icon');

        return [React.createElement(
          'span',
          { key: 'statistic-title' },
          this.get('title'),
          '\xA0'
        ), React.createElement('span', { key: 'statistic-icon', className: iconClass })];
      }

      return this.get('title');
    },
    renderStatistic: function renderStatistic() {
      this.reactRender(React.createElement(
        _stuiComponents.Statistic,
        {
          size: this.get('size'),
          title: this.getTitleEl(),
          weight: this.get('weight'),
          description: this.get('description')
        },
        this.get('statValue') || ''
      ));
    }
  });
});