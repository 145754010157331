define('carnival-frontend/components/messages/message-card', ['exports', 'carnival-frontend/helpers/format-num'], function (exports, _formatNum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['card', 'message-card', 'inapp-message-card'],
    classNameBindings: ['messageType', 'message.abTest.id:has-ab-test'],
    message: null,

    messageType: Ember.computed('message', function () {
      return 'message-' + this.get('message.shortType');
    }),

    glyphicon: Ember.computed('messageType', function () {
      return 'glyphicon-' + this.get('messageType');
    }),

    showPayload: Ember.computed('message.custom', function () {
      return Object.keys(this.getWithDefault('message.custom', {})).length > 0;
    }),

    audienceName: Ember.computed('message.targetable.isFulfilled', function () {
      if (this.get('message.targetable.content.constructor.modelName') === 'app') {
        return 'Everyone';
      } else {
        if (this.get('message.targetable.content.name')) {
          return this.get('message.targetable.content.name');
        } else {
          return 'API Generated';
        }
      }
    }),

    audienceCount: Ember.computed('message', function () {
      var m = this.get('message'),
          isPushType = m.get('isPushType'),
          hasPush = m.get('hasPush'),
          pushEnabledCount = m.get('pushEnabledCount'),
          deviceCount = m.get('deviceCount');

      if (deviceCount) deviceCount = (0, _formatNum.formatNum)([deviceCount]);
      if (pushEnabledCount) pushEnabledCount = (0, _formatNum.formatNum)([pushEnabledCount]);

      if (isPushType) return pushEnabledCount + ' push enabled'; // Push Only
      if (hasPush && !deviceCount) return pushEnabledCount + ' push enabled'; // Message + Push (Not yet sent)
      if (hasPush) return deviceCount + ' users (' + pushEnabledCount + ' push enabled)'; // Message + Push
      return deviceCount + ' users'; // Message
    }),

    shouldShowApproximation: Ember.computed('message', function () {
      var m = this.get('message');

      if (m.get('isScheduled')) return true;
      if (!m.get('deviceCount') && !m.get('hasPush')) return true;
      return false;
    }),

    actions: {
      deleteMessage: function deleteMessage(message) {
        this.sendAction('deleteMessage', 'messages/delete', message);
      },
      unpublishMessage: function unpublishMessage(message) {
        this.sendAction('unpublishMessage', 'messages/unpublish', message);
      }
    }
  });
});