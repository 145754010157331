define('carnival-frontend/controllers/logs/devices', ['exports', 'carnival-frontend/mixins/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, {
    maxPageLinks: 3,
    queryParams: ['development', 'search', 'audience_id'],
    development: '',
    resultType: 'device',
    per: 100,
    audience_id: '',
    search: '',

    devicesLoaded: Ember.computed('model.isSettled', function () {
      return this.get('model') == null || this.get('model.isSettled');
    }),

    tooManyResults: Ember.computed('page', function () {
      return this.get('page') > 100;
    }),

    stateText: Ember.computed('development', function () {
      var development = this.get('development');

      if (!development) return 'all';
      if (development === 'true') return 'development';
      if (development === 'false') return 'production';

      return;
    }),

    // eslint-disable-next-line ember/require-return-from-computed
    audienceText: Ember.computed('audience_id', 'app.audiences', function () {
      var id = this.get('audience_id');

      if (!id) return 'all audiences';

      var audience = this.get('app.audiences').findBy('id', id);
      if (audience) return audience.get('name');
    })
  });
});