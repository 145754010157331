define('carnival-frontend/initializers/current-app-service', ['exports', 'carnival-frontend/services/current-app'], function (exports, _currentApp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register('service:current-app', _currentApp.default);
    application.inject('service:current-app', 'store', 'service:store');

    application.inject('route', 'currentAppService', 'service:current-app');
    application.inject('controller', 'currentAppService', 'service:current-app');
    application.inject('component', 'currentAppService', 'service:current-app');
  }

  exports.default = {
    name: 'current-app-service',
    initialize: initialize
  };
});