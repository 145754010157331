define('carnival-frontend/helpers/format-percent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPercent = formatPercent;
  function formatPercent(params, hash) {
    var count = params[0] || 0,
        total = params[1],
        opts = hash || {},
        precision = parseInt(opts.precision);

    if (!total) return 0 .toFixed(precision);

    return (count / total * 100).toFixed(precision);
  }

  exports.default = Ember.Helper.helper(formatPercent);
});