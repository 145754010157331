define('carnival-frontend/serializers/audience', ['exports', 'ember-data', 'carnival-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: { facetFilters: { embedded: 'always' } },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      // remove empty filters
      json.facet_filters = json.facet_filters.filter(function (ff) {
        return ff.criteria.length;
      });

      // Garglemesh geo stuff into term filters
      var locationKey = json.sdk_location_precision ? 'sdk_location' : 'location';
      var locationIndex = json.facet_filters.findIndex(function (filter) {
        return filter.name === locationKey;
      });
      if (json.geo.points.length || json.geo.polygons.length) {
        if (locationIndex !== -1) json.facet_filters.splice(locationIndex, 1); // Delete existing location filters if they exist
        json.facet_filters.push({
          // Replace them with the stuff in geo
          name: locationKey,
          criteria: this._extractGeoCriteriaFromHash(json.geo),
          not_filter: false
        });
      }

      return json;
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      resourceHash.geo = this._extractGeoHashFromFacetFilters(resourceHash.facet_filters);
      return this._super(modelClass, resourceHash, prop);
    },
    _extractGeoCriteriaFromHash: function _extractGeoCriteriaFromHash(geo) {
      var criteria = [];
      geo.points.forEach(function (point) {
        criteria.push({
          point: { latitude: point.lat, longitude: point.lng },
          distance: point.radius / 1000,
          unit: 'km'
        });
      });

      geo.polygons.forEach(function (poly) {
        criteria.push({
          polygon: poly.map(function (vertex) {
            return { latitude: vertex.lat, longitude: vertex.lng };
          })
        });
      });

      return criteria;
    },
    _extractGeoHashFromFacetFilters: function _extractGeoHashFromFacetFilters(facetFilters) {
      var _this = this;

      var geo = { points: [], polygons: [] };
      var geoCriteria = facetFilters.filter(function (filter) {
        return filter.name === 'location' || filter.name === 'sdk_location';
      }).map(function (filter) {
        return filter.criteria;
      });
      geoCriteria.forEach(function (criteria) {
        criteria.forEach(function (criterion) {
          // eslint-disable-next-line no-prototype-builtins
          if (criterion.hasOwnProperty('point')) {
            var newPoint = {
              radius: criterion.distance * _this.toMeters[criterion.unit],
              lat: criterion.point.latitude,
              lng: criterion.point.longitude
            };
            geo.points.push(newPoint);
            // eslint-disable-next-line no-prototype-builtins
          } else if (criterion.hasOwnProperty('polygon')) {
            geo.polygons.push(criterion.polygon.map(function (vertex) {
              return { lat: vertex.latitude, lng: vertex.longitude };
            }));
          }
        });
      });
      return geo;
    },


    toMeters: {
      m: 1,
      meters: 1,
      km: 1000,
      kilometers: 1000,
      in: 0.0254,
      inch: 0.0254,
      ft: 0.3048,
      feet: 0.3048,
      yd: 0.9144,
      yards: 0.9144,
      mi: 1609.344,
      miles: 1609.344,
      nm: 1852,
      nauticalmiles: 1852
    }
  });
});