define('carnival-frontend/controllers/messages/message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    nid: null,

    notification: Ember.computed('nid', function () {
      return this.store.peekRecord('notification', this.get('nid'));
    }),

    influencedOpens: Ember.computed('model', function () {
      return this.get('model.influencedOpens');
    }),

    recipientsList: Ember.inject.service(),

    actions: {
      exportCsv: function exportCsv() {
        var appId = this.get('model.app.id'),
            id = this.get('model.id');
        location.href = '/web/v1/messages/csv/' + id + '.csv?app_id=' + appId;
      },
      exportRecipientsList: function exportRecipientsList(message) {
        var _this = this;

        return this.recipientsList.export(message).then(function (response) {
          if (Ember.get(response, 'meta.delayed') === true) {
            return _this.send('openModal', 'messages/message/recipients-list-warning', _this.get('model'), 'messages/message');
          }

          _this.alert.success('', "We're processing your request, you'll receive an email when it's done.");
        }, function (error) {
          _this.alert.danger('Failed', error.message);
        });
      },


      // cancel action for messages/message/recipients-list-warning modal
      cancel: function cancel() {
        this.send('closeModal');
      }
    }
  });
});