define('carnival-frontend/serializers/application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    isNewSerializerAPI: true,
    unserializedAttributes: ['createdAt', 'updatedAt'],

    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (this.unserializedAttributes.indexOf(key) >= 0) return;

      return this._super(snapshot, json, key, attributes);
    }
  });
});