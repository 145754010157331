define('carnival-frontend/components/file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['media-uploader'],
    classNameBindings: ['allowDropping:is-droppable'],
    file: null,
    fileBase64: null,
    mimeTypes: 'image/gif, image/jpeg, image/png, video/mp4',
    defaultStatus: 'Drop file here or click to select',
    status: null,
    allowDropping: false,
    canRemoveImage: false,

    change: function change(event) {
      this.handleFileSelection(event.target.files);
    },
    click: function click(event) {
      if (event.target.id === 'btn-remove-img') {
        return false;
      }

      event.stopPropagation();
      event.preventDefault();
      this.$("input[type='file']").click();
    },
    dragOver: function dragOver(event) {
      event.stopPropagation();
      event.preventDefault();

      event.dataTransfer.dropEffect = 'copy';
    },
    dragEnter: function dragEnter(e) {
      if (e.target.draggable) {
        this.set('allowDropping', true);
      }
    },
    dragLeave: function dragLeave(e) {
      if (e.target.draggable) {
        this.set('allowDropping', false);
      }
    },
    drop: function drop(event) {
      event.stopPropagation();
      event.preventDefault();
      this.set('allowDropping', false);
      this.handleFileSelection(event.dataTransfer.files);
    },
    handleFileSelection: function handleFileSelection(files) {
      if (!files || files.length === 0) return;
      var file = files[0];
      this.set('file', file);
      // set fileBase64 property for using in <img src=''>
      if (file != null && file.type.substring(0, 5) === 'image') {
        var that = this;
        var fileReader = new FileReader();
        fileReader.onloadend = function () {
          that.set('fileBase64', fileReader.result);
        };
        fileReader.readAsDataURL(file);
      }
    },
    didInsertElement: function didInsertElement() {
      this.$("input[type='file']").click(function (event) {
        event.stopPropagation();
      });
    },


    actions: {
      removeImage: function removeImage() {
        this.setProperties({
          fileBase64: '',
          file: null
        });
        this.$('input[type="file"]').val('');
      }
    }
  });
});