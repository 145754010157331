define('carnival-frontend/routes/stats/device', ['exports', 'carnival-frontend/routes/route', 'carnival-frontend/models/stats'], function (exports, _route, _stats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      stat: { refreshModel: true },
      from: { refreshModel: true },
      to: { refreshModel: true },
      platforms: { refreshModel: true }
    },

    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, params) {
      var app = this.modelFor('app');

      controller.setProperties({
        app: app,
        stats: _stats.default.create({
          app: app,
          path: params.stat,
          platforms: params.platforms,
          range: Ember.Object.create({
            from: params.from,
            to: params.to
          })
        })
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('stats.graph');
      this.render('stats.device.topbar-nav', {
        into: 'stats.graph',
        outlet: 'topbar-nav'
      });
    }
  });
});