define('carnival-frontend/components/split-variation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['split'],
    attributeBindings: ['style', 'data-split'],

    // eslint-disable-next-line ember/no-observers
    ratioDidChange: Ember.observer('split.ratio', function () {
      this.sendAction('splitUpdated', this.get('split'));
    })
  });
});