define('carnival-frontend/components/alert-card', ['exports', 'carnival-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // Constants for Ember.run.later.
    // We can change this in test, so it will not slow down them.
    // REMOVE_LATENCY have to be more than transition in .alert,
    // because should remove this div when already invisible
    // VISIBLE_LATENCY needs because div has to be in DOM before
    // we attache .visible class.

    SHOW_ALERT_FOR: 4000,
    VISIBLE_LATENCY: 100,
    REMOVE_LATENCY: 1100,

    classNames: ['alert', 'alert-dismissible'],
    classNameBindings: ['type', 'visible'],

    message: null,

    visible: false,

    type: Ember.computed('message.type', function () {
      return this.get('message.type');
    }),

    init: function init() {
      this._super();

      if (_environment.default.environment === 'test') {
        this.setProperties({
          SHOW_ALERT_FOR: 0,
          VISIBLE_LATENCY: 0,
          REMOVE_LATENCY: 0
        });
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.run.later(this, function () {
        this.set('visible', true);
      }, this.VISIBLE_LATENCY);
      Ember.run.later(this, this._removeAlert, this.SHOW_ALERT_FOR);
    },
    click: function click() {
      this._removeAlert();
    },
    _removeAlert: function _removeAlert() {
      if (this.get('isDestroyed')) return;

      this.set('visible', false);

      Ember.run.later(this, function () {
        this.sendAction('removeAlert', this.get('message'));
      }, this.REMOVE_LATENCY);
    }
  });
});