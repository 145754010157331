define('carnival-frontend/models/audience', ['exports', 'ember-data', 'carnival-frontend/models/targetable'], function (exports, _emberData, _targetable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _targetable.default.extend({
    name: _emberData.default.attr('string'),
    humanReadableFilters: _emberData.default.attr('string'),
    disabled: _emberData.default.attr('boolean'),
    sdkLocationPrecision: _emberData.default.attr('boolean'),
    geo: _emberData.default.attr({ defaultValue: function defaultValue() {
        return { points: [], polygons: [] };
      } }),
    createdAt: _emberData.default.attr('date'),
    channel: _emberData.default.attr('string', { defaultValue: '' }),
    test: _emberData.default.attr('boolean'),
    targeted: _emberData.default.attr('boolean'),
    pushEnabledCount: '',
    pushQuietCount: '',

    app: _emberData.default.belongsTo('app', { inverse: 'audiences', async: false }),
    parentAudience: _emberData.default.belongsTo('audience', {
      inverse: 'childAudiences',
      async: false
    }),
    childAudiences: _emberData.default.hasMany('audience', {
      inverse: 'parentAudience',
      async: false
    }),
    facetFilters: _emberData.default.hasMany('filter', { inverse: null, async: false }),

    totalDeviceCount: Ember.computed.oneWay('app.deviceCount'),
    hasChildren: Ember.computed('childAudiences.@each.disabled', function () {
      return !!this.get('childAudiences').filterBy('disabled', false).get('length');
    }),

    hasGeoFilters: Ember.computed.or('geo.{points.length,polygons.length}'),

    fetchFacetValues: function fetchFacetValues(search, field) {
      var adapter = this.store.adapterFor('facet'),
          url = adapter.buildURL('facet'),
          app = this.get('app'),
          parent = this.get('parentAudience'),
          params = {};

      params.app_id = app.id;
      params.field = field;

      if (search) {
        params.search = search;
      }

      if (parent) {
        params.parent_audience_id = parent.id;
      }

      var promise = adapter.ajax(url, 'GET', { data: params }).then(function (response) {
        var facets = response.facets;

        if (facets.length) {
          return facets[0].values.map(function (value) {
            return Ember.Object.create(value);
          });
        } else {
          return [];
        }
      });
      return _emberData.default.PromiseArray.create({ promise: promise });
    },
    fetchFacet: function fetchFacet(field) {
      var app = this.get('app'),
          parent = this.get('parentAudience'),
          params = {};

      params.app_id = app.id;
      params.field = field;

      if (parent) {
        params.parent_audience_id = parent.id;
      }

      return this.store.query('facet', params);
    },
    createFilter: function createFilter(field) {
      var filter = this.store.createRecord('filter', { name: field });

      this.get('facetFilters').addObject(filter);

      return filter;
    },
    findCustomFilterNamesByField: function findCustomFilterNamesByField(search, customEndpoint) {
      var adapter = this.store.adapterFor('application'),
          url = adapter.buildURL(),
          app = this.get('app'),
          parent = this.get('parentAudience'),
          params = {};

      params.app_id = app.id;

      if (search) {
        params.search = search;
      }

      if (parent) {
        params.parent_audience_id = parent.id;
      }

      var promise = adapter.ajax(url + '/' + customEndpoint, 'GET', { data: params }).then(function (response) {
        return response;
      });

      return _emberData.default.PromiseArray.create({ promise: promise });
    },


    selectedFilters: Ember.computed('facetFilters.@each.hasCriteria', function () {
      return this.get('facetFilters').filterBy('hasCriteria', true);
    }),

    selectedNonGeoFilters: Ember.computed('selectedFilters', function () {
      return this.selectedFilters.filter(function (facetFilter) {
        return !['location', 'sdk_location'].includes(facetFilter.name);
      });
    }),

    selectedCustomFacetFilters: Ember.computed('selectedFilters', function () {
      return this.get('selectedFilters').filter(function (item) {
        return item.get('name').indexOf('custom.') === 0;
      });
    }),

    selectedCustomEventFilters: Ember.computed('selectedFilters', function () {
      return this.get('selectedFilters').filter(function (item) {
        return item.get('name').indexOf('events.') === 0;
      });
    }),

    /***************************************************************************
     * `deviceCount` is recomputed every times it's bound properties change.   *
     * However, we need to debounce changes related to `facetFilters` because  *
     * adding a `facetFilter` to the `facetFilters` array results in a total   *
     * of 4x requests to the server which can be very expensive for apps with  *
     * large audiences. Not only is it expensive it's also pointless because   *
     * the `facetFilter` has only been added to the array for UI purposes, it  *
     * hasn't actually got any set criteria at this point. Using the technique *
     * below we end up with only 1x request when the `facetFilter` gets added. *
     **************************************************************************/

    // eslint-disable-next-line ember/no-observers
    facetFiltersDidChange: Ember.observer('facetFilters.@each.criteria', 'facetFilters.@each.notFilter', function () {
      Ember.run.debounce(this, this.triggerFacetFilterToggle, 200);
    }),

    facetFiltersToggle: false, // it does not matter what value this is, only used to inform `deviceCount` to recompute

    triggerFacetFilterToggle: function triggerFacetFilterToggle() {
      this.notifyPropertyChange('facetFiltersToggle');
    },


    deviceCount: Ember.computed('app', 'parentAudience', 'sdkLocationPrecision', 'facetFiltersToggle', 'geo.{polygons.[],points.[]}', function () {
      var _this = this;

      if (!this.get('app')) return;

      var adapter = this.store.adapterFor('audience'),
          url = adapter.buildURL('audience', 'device_count');

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'POST', { data: { audience: this.serialize() } }).then(function (json) {
          _this.set('pushEnabledCount', json.device_count_push_enabled);
          _this.set('pushQuietCount', json.device_count_push_quiet);
          return json.device_count;
        })
      });
    }),

    selectedImportedFilters: Ember.computed('selectedFilters', function () {
      return this.get('selectedFilters').filter(function (item) {
        return item.get('name').indexOf('imported.') === 0;
      });
    }),

    isTheRestrictedParent: Ember.computed('app.permittedAudience.id', 'id', function () {
      return this.get('id') === this.get('app.permittedAudience.id');
    }),

    hasAttributeOrLocationFilters: Ember.computed('selectedFilters.length', 'hasGeoFilters', function () {
      return this.get('selectedFilters.length') !== 0 || this.hasGeoFilters;
    }),

    unixTimestamp: Ember.computed('createdAt', function () {
      return +this.get('createdAt');
    })
  });
});