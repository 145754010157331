define('carnival-frontend/components/messages/automation-options', ['exports', 'carnival-frontend/components/popup-revealer'], function (exports, _popupRevealer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _popupRevealer.default.extend({
    reactiveAction: null,

    interval: Ember.computed.alias('reactiveAction.intervalInHours'),
    maxPushes: Ember.computed.alias('reactiveAction.maxPushes'),
    delay: Ember.computed.alias('reactiveAction.delay')
  });
});