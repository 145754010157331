define('carnival-frontend/components/audiences/filter-criteria', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['filter-criteria-builder'],
    classNameBindings: ['footerIsOpen:facets-footer-open', 'isString:has-footer'],
    facetFilter: null,
    audience: null,

    filter: Ember.computed.alias('facetFilter.filter'),
    isLoading: Ember.computed.oneWay('fields.isPending'),
    isInteger: Ember.computed.equal('facetFilter.facet.type', 'integer'),
    isFloat: Ember.computed.equal('facetFilter.facet.type', 'float'),
    isBoolean: Ember.computed.equal('facetFilter.facet.type', 'boolean'),
    isDate: Ember.computed.equal('facetFilter.facet.type', 'date'),
    isString: Ember.computed.equal('facetFilter.facet.type', 'string'),
    isImported: Ember.computed.equal('facetFilter.facet.type', 'imported'),
    isEvent: Ember.computed.equal('facetFilter.facet.type', 'event'),

    showTermCriteria: Ember.computed.or('isString', 'isBoolean', 'isImported'),
    showRangeCriteria: Ember.computed.or('isFloat', 'isInteger'),
    showDateCriteria: Ember.computed.or('isDate', 'isEvent'),
    showEventCriteria: Ember.computed.or('isEvent'),

    fieldsSorting: Ember.computed('facetFilter.facet.field', function () {
      return this.get('facetFilter.facet.field') === 'group' ? ['term'] : ['count:desc'];
    }),
    sortedFields: Ember.computed.sort('fields', 'fieldsSorting'),

    searchText: '',
    newTerm: '',

    canAddString: Ember.computed('facetFilter.facet', function () {
      return this.get('isString') && this.get('facetFilter.facet.id') !== 'group';
    }),

    showSearch: Ember.computed('facetFilter.facet', function () {
      var noSearchField = ['group', 'notifications_allowed'];
      return noSearchField.indexOf(this.get('facetFilter.facet.field')) === -1 && this.get('isString');
    }),

    // eslint-disable-next-line ember/no-observers
    cleanSearchText: Ember.observer('facetFilter.filter', function () {
      this.set('searchText', '');
    }),

    fields: Ember.computed('facetFilter.facet', 'searchText', 'searchFields.content', function () {
      if (!this.get('facetFilter.facet')) return;
      if (!this.get('searchText')) {
        return this.get('facetFilter.facet.values');
      }

      return this.get('searchFields');
    }),

    searchFields: Ember.computed('searchText', function () {
      return this.get('audience').fetchFacetValues(this.get('searchText'), this.get('facetFilter.field'));
    }),

    disableAddTerm: Ember.computed('newTerm', function () {
      return Ember.isBlank(this.get('newTerm'));
    }),

    actions: {
      addFacetValue: function addFacetValue(term) {
        if (Ember.isBlank(term)) return;

        var sanitizedTerm = term.trim(),
            fields = this.get('sortedFields');

        // check if this value already exists
        if (fields.findBy('term', sanitizedTerm)) return;

        var facetValue = Ember.Object.create({
          term: sanitizedTerm,
          count: '?'
        });

        // put new value at the top/start of the stack
        fields.unshiftObject(facetValue);

        // reset term field
        this.set('newTerm', '');
      },
      toggleFooter: function toggleFooter() {
        this.toggleProperty('footerIsOpen');
      }
    }
  });
});