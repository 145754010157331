define('carnival-frontend/routes/audiences/duplicate', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    controllerName: 'audiences.crud',

    model: function model(params) {
      var store = this.store,
          audience = store.findRecord('audience', params.audience_id);

      return audience.then(function () {
        var props = audience.getProperties('app', 'parentAudience', 'geo');

        props.facetFilters = audience.get('facetFilters').map(function (filter) {
          return store.createRecord('filter', filter.getProperties('name', 'notFilter', 'criteria'));
        });

        return store.createRecord('audience', props);
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('audiences.new', {
        into: 'application',
        outlet: 'modal'
      });
    }
  });
});