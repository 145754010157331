define('carnival-frontend/initializers/inject-env', ['exports', 'carnival-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register('env:main', _environment.default, {
      singleton: true,
      instantiate: false
    });
    application.inject('controller', 'env', 'env:main');
    application.inject('route', 'env', 'env:main');
  }

  exports.default = {
    name: 'inject-env',
    initialize: initialize
  };
});