define('carnival-frontend/controllers/logs/devices/crud', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modelDidSave: function modelDidSave() {
      this.alert.success('Device ', 'This device is successfully updated.');
      this.send('closeModal');
    },
    modelDidNotSave: function modelDidNotSave(errors) {
      this.alert.danger('Error ', errors.toString());
      this.send('closeModal');
    },
    testNotificationDidSend: function testNotificationDidSend() {
      this.alert.success('Test ', 'Message sent.');
      this.send('closeModal');
    },
    testNotificationDidNotSend: function testNotificationDidNotSend(errors) {
      var messages = [];

      errors.errors.forEach(function (error) {
        messages.pushObject(error.detail);
      });

      this.alert.danger('Error:', messages.join(', '));
    },


    actions: {
      dismiss: function dismiss() {
        this.send('closeModal');
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.send('closeModal');
      },
      save: function save() {
        var model = this.get('model');

        model.get('errors').clear();
        if (!model.get('hasDirtyAttributes')) return this.send('closeModal');

        model.save().then(Ember.run.bind(this, this.modelDidSave), Ember.run.bind(this, this.modelDidNotSave));
      },
      sendTest: function sendTest() {
        this.get('model').sendTestNotification(this.get('currentAppService.app').id).then(Ember.run.bind(this, this.testNotificationDidSend), Ember.run.bind(this, this.testNotificationDidNotSend));
      }
    }
  });
});