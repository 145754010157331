define('carnival-frontend/mixins/messages/video', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    s3FileName: _emberData.default.attr('string'),
    cardMediaUrl: _emberData.default.attr('string'),
    assetDimensions: _emberData.default.attr(),

    transcoder: null,
    totalMediaSize: 0,
    uploadedMediaSize: 0,
    xhr: null,
    file: null,

    inCopyState: false,
    outputUrl: null,

    // eslint-disable-next-line ember/require-return-from-computed
    videoUrl: Ember.computed('transcoder.status', 'cardMediaUrl', function () {
      if (this.get('cardMediaUrl')) return this.get('cardMediaUrl');
      if (this.get('transcoder.status') === 'Complete') return this.get('outputUrl') + '/' + this.get('s3FileName');
    }),

    // eslint-disable-next-line ember/no-observers
    transcoderChanged: Ember.observer('transcoder.status', function () {
      this.set('s3FileName', this.get('transcoder.key'));
      this.set('assetDimensions', this.get('transcoder.dimensions'));
    }),

    isTranscoding: Ember.computed('transcoder.status', function () {
      return this.get('transcoder') !== null && this.get('transcoder.status') !== 'Complete';
    }),

    isProcessing: Ember.computed('isTranscoding', 'isUploading', function () {
      return this.get('isTranscoding') || this.get('isUploading');
    }),

    uploadSign: function uploadSign() {
      var store = this.get('store'),
          adapter = store.adapterFor('message'),
          url = adapter.buildURL('', 'upload_sign');

      return adapter.ajax(url, 'GET');
    },
    uploadCompleteHandler: function uploadCompleteHandler(xhr, fileName) {
      if (xhr.status === 204) {
        var data = { key: fileName, app_id: this.get('appId') },
            transcoder = this.store.createRecord('transcoder', data);
        this.set('transcoder', transcoder);
        transcoder.save().then(function () {
          transcoder.poll();
        });
      } else {
        this.get('errors').add('media', ['Unable to upload']);
      }
    },


    // eslint-disable-next-line ember/no-observers
    fileChanged: Ember.observer('file', function () {
      if (!this.get('file')) return;

      if (this.inCopyState) {
        this.inCopyState = false;
        return;
      }

      if (!Ember.isNone(this.get('xhr'))) {
        this.get('xhr').abort();
        this.set('xhr', null);
      }

      this.upload();
    }),

    percentageUploaded: Ember.computed('uploadedMediaSize', 'totalMediaSize', function () {
      if (this.get('totalMediaSize') === 0) return 0;
      return this.get('uploadedMediaSize') / this.get('totalMediaSize') * 100;
    }),

    isUploading: Ember.computed('percentageUploaded', function () {
      return this.get('percentageUploaded') > 0 && this.get('percentageUploaded') < 100;
    }),

    uploadOperation: function uploadOperation(sign) {
      var xhr = new XMLHttpRequest(),
          fd = new FormData(),
          fileName = this.fillFormDataWithSign(fd, sign),
          that = this;

      xhr.open('POST', sign.url, true);
      xhr.addEventListener('load', function () {
        that.uploadCompleteHandler(xhr, fileName);
      }, false);
      xhr.upload.addEventListener('progress', function (e) {
        that.progressHandler(e);
      }, false);

      this.set('xhr', xhr);
      xhr.send(fd);
    },
    progressHandler: function progressHandler(e) {
      this.setProperties({
        totalMediaSize: e.total || e.totalSize,
        uploadedMediaSize: e.loaded || e.position
      });
    },
    upload: function upload() {
      var that = this;
      this.uploadSign().then(function (sign) {
        that.set('outputUrl', sign.output_url);
        return that.uploadOperation(sign);
      });
    },
    fillFormDataWithSign: function fillFormDataWithSign(fd, sign) {
      var fileName = this.rfc4122Hash() + '.' + this.get('fileExtension');

      for (var field in sign.fields) {
        // eslint-disable-next-line no-prototype-builtins
        if (!sign.fields.hasOwnProperty(field)) continue;
        if (field === 'key') continue;
        fd.append(field, sign.fields[field]);
      }

      fd.append('key', fileName);
      fd.append('file', this.get('file'));

      return fileName;
    },


    /**
     * Returns hash compatible with RFC4122
     * refer to http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
     **/
    rfc4122Hash: function rfc4122Hash() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },


    fileExtension: Ember.computed('file', function () {
      if (!this.get('file')) return null;
      return this.get('file').name.split('.').pop();
    })
  });
});