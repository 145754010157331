define('carnival-frontend/components/stats/linear-partition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: null,
    width: 300,
    height: 50,
    topGap: 10,

    cornerRadius: 2,

    svg: null,
    colors: null,
    partition: null,
    tooltip: null,

    didInsertElement: function didInsertElement() {
      this._initChart();
      this._createChart();

      var that = this;
      this.$(window).on('debouncedresize', function () {
        that.tooltip.remove();
        that.svg.remove();
        that._initChart();
        that._createChart();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).off('debouncedresize');
    },


    // eslint-disable-next-line ember/no-observers
    dataDidChange: Ember.observer('data', function () {
      this._createChart();
    }),

    _initChart: function _initChart() {
      var width = this.$().width(),
          height = this.$().height();

      this.tooltip = d3.select(this.$()[0]).append('div').attr('class', 'popup-tooltip content bottom');

      this.g = d3.select(this.$()[0]).append('svg').attr('width', width).attr('height', height).attr('class', 'line-partition').append('g');

      this.partition = d3.partition().size([width, height]);

      this.root = d3.hierarchy({
        children: this.data
      }).sum(function (d) {
        return d.percent;
      }).sort(function (a, b) {
        return b.percent - a.percent;
      });
    },
    _createChart: function _createChart() {
      var that = this,
          height = this.$().height();

      /**
       * Chart
       */

      this.g.selectAll('path').data(this.partition(this.root).children).enter().append('rect').attr('height', height).attr('y', this.topGap).attr('width', function (d) {
        return d.x1 - d.x0;
      }).attr('x', function (d) {
        return d.x0;
      }).attr('class', function (d) {
        if (!d.parent) return null;
        return 'partition-element' + (10 - d.parent.children.indexOf(d));
      }).on('mouseenter', function (d) {
        var html = '<div class="arrow"></div><div class="wrap"><p class="stat"><span class="term">' + d.data.term + '</span> <em class="count">' + d.data.percent + '%</em></div>';
        that.tooltip.html(html);
        var tooltipWidth = that.$('.popup-tooltip').outerWidth();
        var rectWidth = d.x1 - d.x0;
        that.tooltip.classed('show', true).style('left', d.x0 + (rectWidth - tooltipWidth) / 2 + 'px');

        d3.select(this).transition().duration(400).attr('y', 0).attr('height', height + that.topGap);

        that.set('selectedItem', d.key);
      }).on('mouseleave', function () {
        that.tooltip.classed('show', false);

        d3.select(this).transition().duration(400).attr('y', that.topGap).attr('height', height);

        that.set('selectedItem', null);
      }).exit().remove();
    }
  });
});