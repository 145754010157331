define('carnival-frontend/routes/admin/history-trackers', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      page: { refreshModel: true },
      per: { refreshModel: true },
      assoc_id: { refreshModel: true }
    },

    model: function model(params) {
      return this.store.query('history-tracker', params);
    },
    redirect: function redirect() {
      if (!this.get('currentUserService.user.superAdmin')) this.transitionTo('/');
    },


    actions: {
      closeModal: function closeModal() {
        this.transitionTo('admin.history-trackers');
      }
    },

    renderTemplate: function renderTemplate(controller, model) {
      this._super(controller, model);

      this.render('sailthru-navigation', {
        outlet: 'nav',
        controller: this.controllerFor('admin')
      });
    }
  });
});