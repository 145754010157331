define('carnival-frontend/components/time-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    timeMeasure: 'hours',

    keyDown: function keyDown(key) {
      var keyCode = key.keyCode;
      var value = this.get('value');

      switch (keyCode) {
        //Backspace and Del
        case 8:
        case 46:
        //TAB
        case 9:
        // Left and Right
        case 37:
        case 39:
          return true;
        //Up
        case 38:
          value = parseInt(value) + 1;
          if (!this._isInsideValidRange(value)) return false;
          this.set('value', value);
          return true;
        //Down
        case 40:
          value = parseInt(value) - 1;
          if (!this._isInsideValidRange(value)) return false;
          this.set('value', value);
          return true;
      }

      var enteredChar = String.fromCharCode(keyCode);
      // If text is highlighted allow overwriting otherwise append the entered character to the current value
      var enteredText = window.getSelection().toString() ? enteredChar : value + enteredChar;

      if (!this._isInsideValidRange(enteredText)) return false;

      // Only allow numbers to be entered
      return !!(keyCode >= 48 && keyCode <= 57);
    },


    value: Ember.computed('displayTime', {
      get: function get() {
        var time = String(this.get('displayTime'));

        if (time.length === 0) return '00';
        if (time.length === 1) return '0' + time;
        return time;
      },
      set: function set(key, value) {
        this.set('displayTime', value);
        return this.get('displayTime');
      }
    }),

    focusOut: function focusOut() {
      var value = this.get('value');

      if (value.length === 0) {
        this.set('value', '00');
      }

      if (value.length === 1) {
        this.set('value', '0' + value);
      }
    },


    // Use 24 hour time and limit field depending on whether Hours, Minutes or Seconds
    _isInsideValidRange: function _isInsideValidRange(val) {
      switch (this.get('timeMeasure')) {
        case 'hours':
          return !!(val > -1 && val < 24);
        case 'minutes':
        case 'seconds':
          return !!(val > -1 && val < 60);
      }
    }
  });
});