define('carnival-frontend/components/messages/composer-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    elementId: 'message-compose-header',
    classNames: ['u-flex', 'u-flex-align-center']
  });
});