define('carnival-frontend/components/messages/audience-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._setupScrollTracking();
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('scroll');
    },
    _setupScrollTracking: function _setupScrollTracking() {
      this.$().on('scroll', Ember.run.bind(this, this._scrollHandler));
    },
    _scrollHandler: function _scrollHandler(e) {
      this.set('scrollOffset', e.target.scrollTop);
    }
  });
});