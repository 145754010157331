define('carnival-frontend/helpers/is-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isString = isString;
  function isString(params) {
    return typeof params[0] === 'string';
  }

  exports.default = Ember.Helper.helper(isString);
});