define('carnival-frontend/utils/audience-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.audienceFilter = audienceFilter;
  function audienceFilter(audience) {
    var testAudienceFilter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    // So that we only get messages which are test or not test depending on the filter.
    if (!!audience.test != testAudienceFilter) {
      return false;
    }

    // So that we only get parent audiences (not child audiences, which have an element parentAudience)
    if (audience.parentAudience) {
      return false;
    }

    // So that if we delete an audience we can't see it ('deleted' audiences are just marked disabled)
    if (audience.disabled) {
      return false;
    }

    // So that visiting /new does not creep into list
    if (audience.isNew) {
      return false;
    }

    // Only show audiences created via the UI
    if (audience.channel === 'api') {
      return false;
    }

    return true;
  }
});