define('carnival-frontend/components/radio-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',
    attributeBindings: ['name', 'type', 'value', 'checked', 'disabled'],

    click: function click() {
      this.set('selection', this.get('value'));
    },


    checked: Ember.computed('value', 'selection', function () {
      return this.get('value') === this.get('selection');
    })
  });
});