define('carnival-frontend/controllers/audiences/crud', ['exports', 'carnival-frontend/mixins/crud'], function (exports, _crud) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_crud.default, {
    routeToTransitionTo: 'audiences',
    audiencesController: Ember.inject.controller('audiences'),
    datalist: Ember.computed.alias('audiencesController.datalist'),

    didSave: function didSave() {
      var model = this.get('model'),
          datalist = this.get('datalist');

      // delete duplicate records - Ember Data bug
      model.get('facetFilters').filterBy('isNew').invoke('deleteRecord');

      // if audience is a parent audience and is not already in the datalist array
      // the ember array unshift method has a bug where it will add the same
      // object even if it already exists - unlike the addObject method
      if (!model.get('parentAudience') && !datalist.includes(model)) datalist.unshiftObject(model);

      this.send('dismiss');
    },
    didDestroy: function didDestroy() {
      var model = this.get('model');

      if (!model.get('parentAudience')) this.get('datalist').removeObject(model);

      this.send('closeModal');
    },


    actions: {
      cancel: function cancel() {
        var model = this.get('model');

        model.get('facetFilters').filterBy('id', null).invoke('deleteRecord');
        model.get('facetFilters').invoke('rollbackAttributes');
        model.rollbackAttributes();

        this.send('dismiss');
      },
      save: function save() {
        var model = this.get('model');

        model.save().then(Ember.run.bind(this, this.didSave), Ember.run.bind(this, this.didNotSave));
      },
      destroy: function destroy() {
        var model = this.get('model');

        // soft delete
        model.set('disabled', true);

        model.save().then(Ember.run.bind(this, this.didDestroy), Ember.run.bind(this, this.didNotDestroy));
      }
    }
  });
});