define('carnival-frontend/routes/settings/permissions', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    beforeModel: function beforeModel() {
      if (!this.modelFor('app').get('isAdmin')) {
        this.transitionTo('/');
      }
    },
    model: function model() {
      return this.modelFor('app').get('permissions');
    }
  });
});