define('carnival-frontend/components/panel-revealer', ['exports', 'carnival-frontend/components/content-revealer', 'carnival-frontend/templates/components/content-revealer'], function (exports, _contentRevealer, _contentRevealer2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentRevealer.default.extend({
    layout: _contentRevealer2.default,

    classNames: ['reveal-panel'],

    toggleClassNames: 'btn btn-default btn-lg btn-icon btn-block',
    iconClassNames: 'glyphicon glyphicon-chevron-down glyphicon-sml',

    text: 'Panel',

    actions: {
      toggleReveal: function toggleReveal() {
        if (this.get('isDisabled')) return;
        this.toggleProperty('isOpen');
      }
    }
  });
});