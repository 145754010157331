define('carnival-frontend/models/category', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  // Categories
  function YesNoCategory() {
    this.name = 'Yes/No';
    this.id = 'st_cat_yes_no';
    this.buttons = [new YesButton(), new NoButton()];
    this.customisable = false;
  }

  function LearnMoreCategory() {
    this.name = 'Learn More';
    this.id = 'st_cat_learn_more';
    this.buttons = [new LearnMoreButton()];
    this.customisable = false;
  }

  function ViewCategory() {
    this.name = 'View';
    this.id = 'st_cat_view';
    this.buttons = [new ViewButton()];
    this.customisable = false;
  }

  function ShopNowCategory() {
    this.name = 'Shop Now';
    this.id = 'st_cat_shop_now';
    this.buttons = [new ShopNowButton()];
    this.customisable = false;
  }

  function ContinueCategory() {
    this.name = 'Continue';
    this.id = 'st_cat_continue';
    this.buttons = [new ContinueButton()];
    this.customisable = false;
  }

  function AcceptDeclineCategory() {
    this.name = 'Accept/Decline';
    this.id = 'st_cat_accept_decline';
    this.buttons = [new AcceptButton(), new DeclineButton()];
    this.customisable = false;
  }

  function NextStepCategory() {
    this.name = 'Next Step';
    this.id = 'st_cat_next_step';
    this.buttons = [new NextStepButton()];
    this.customisable = false;
  }

  function AddCategory() {
    this.name = 'Add';
    this.id = 'st_cat_add';
    this.buttons = [new AddButton()];
    this.customisable = false;
  }

  function WatchCategory() {
    this.name = 'Watch';
    this.id = 'st_cat_watch';
    this.buttons = [new WatchButton()];
    this.customisable = false;
  }

  function SubscribeCategory() {
    this.name = 'Subscribe';
    this.id = 'st_cat_subscribe';
    this.buttons = [new SubscribeButton()];
    this.customisable = false;
  }

  function ShareCategory() {
    this.name = 'Share';
    this.id = 'st_cat_share';
    this.buttons = [new ShareButton()];
    this.customisable = false;
  }

  function CustomCategory() {
    this.name = 'Custom';
    this.id = '';
    this.buttons = [];
    this.customisable = true;
  }

  // Buttons
  function YesButton() {
    this.action = 'Yes';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function NoButton() {
    this.action = 'No';
    this.canSetDeeplink = false;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function LearnMoreButton() {
    this.action = 'Learn More';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function ViewButton() {
    this.action = 'View';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function ShopNowButton() {
    this.action = 'Shop Now';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function ContinueButton() {
    this.action = 'Continue';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function AcceptButton() {
    this.action = 'Accept';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function DeclineButton() {
    this.action = 'Decline';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function NextStepButton() {
    this.action = 'Next Step';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function AddButton() {
    this.action = 'Add';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function WatchButton() {
    this.action = 'Watch';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function SubscribeButton() {
    this.action = 'Subscribe';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  function ShareButton() {
    this.action = 'Share';
    this.canSetDeeplink = true;
    this.deeplink = '';
    this.settingDeeplink = false;
  }

  var categories = exports.categories = [new YesNoCategory(), new LearnMoreCategory(), new ViewCategory(), new ShopNowCategory(), new ContinueCategory(), new AcceptDeclineCategory(), new NextStepCategory(), new AddCategory(), new WatchCategory(), new SubscribeCategory(), new ShareCategory(), new CustomCategory()];
});