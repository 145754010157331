define('carnival-frontend/components/audiences/imports-popup', ['exports', 'carnival-frontend/components/popup-revealer'], function (exports, _popupRevealer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _popupRevealer.default.extend({
    arrowAlign: 'right',
    toggleClassNames: 'btn btn-default btn-sm',

    iconClassNames: Ember.computed('isOpen', function () {
      if (this.get('isOpen')) return 'glyphicon glyphicon-chevron-up glyphicon-sml';
      return 'glyphicon glyphicon-chevron-down glyphicon-sml';
    }),

    importsInProgress: Ember.computed('importAudienceJobs.@each.importedAt', function () {
      var imports = this.get('importAudienceJobs');
      return imports.filter(function (job) {
        return !job.importedAt;
      }).sort(function (job) {
        return job.audienceName;
      });
    }),

    importsRecentlyCompleted: Ember.computed('importAudienceJobs.@each.importedAt', function () {
      var imports = this.get('importAudienceJobs');
      return imports.filter(function (job) {
        return job.importedAt;
      }).sort(function (job) {
        return job.audienceName;
      });
    }),

    sortedImports: Ember.computed('importAudienceJobs.@each.importedAt', function () {
      return this.get('importsInProgress').concat(this.get('importsRecentlyCompleted'));
    }),

    buttonIsDisabled: Ember.computed('sortedImports', function () {
      return this.get('sortedImports.length') === 0;
    }),

    // eslint-disable-next-line ember/no-observers
    triggerAudienceReload: Ember.observer('importAudienceJobs.@each.importedAt', function () {
      this.sendAction('reloadAudiences'); // Handled by AudienceController
    }),

    // eslint-disable-next-line ember/no-observers
    actions: {
      toggleReveal: function toggleReveal() {
        this.get('importAudienceJobs').reload();
        this.closeOnClickAway();
      }
    }
  });
});