define('carnival-frontend/routes/apps', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model() {
      var adapter = this.store.adapterFor('application'),
          url = adapter.buildURL('', 'platform_permissions');

      return adapter.ajax(url, 'GET');
    },
    redirect: function redirect(model) {
      if (model.apps.length <= 0) {
        this.transitionTo('landing');
        return;
      }
    }
  });
});