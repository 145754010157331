define('carnival-frontend/models/device', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_TAGS_STRING_LENGTH = 140;

  exports.default = _model.default.extend({
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    registeredAt: _emberData.default.attr('date'),
    unregisteredAt: _emberData.default.attr('date'),
    locale: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    marketingName: _emberData.default.attr('string'),
    hardwareName: _emberData.default.attr('string'),
    hardwareVersion: _emberData.default.attr('string'),
    osName: _emberData.default.attr('string'),
    osVersion: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    hasToken: _emberData.default.attr('boolean'),
    alias: _emberData.default.attr('string'),
    tags: _emberData.default.attr(),
    development: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    location: _emberData.default.attr(),
    token: _emberData.default.attr('string'),
    sdkVersion: _emberData.default.attr(),
    appVersion: _emberData.default.attr(),
    custom: _emberData.default.attr(),
    userId: _emberData.default.attr(),
    userEmail: _emberData.default.attr(),
    notificationsAllowed: _emberData.default.attr(), // 2 is quiet allowed, 1 is allowed, 0 is not allowed, -1 is unknown.
    platform: _emberData.default.attr('string'),
    erasedAt: _emberData.default.attr('date'),
    sid: _emberData.default.attr('string'),
    sid_link: _emberData.default.attr('string'),

    app: _emberData.default.belongsTo('app', { inverse: 'devices' }),

    formattedTags: Ember.computed('tags', function () {
      if (!this.get('tags.length')) return '';

      var formatted = this.get('tags').join(', ');
      if (formatted.length <= MAX_TAGS_STRING_LENGTH) return formatted;

      return formatted.substring(0, MAX_TAGS_STRING_LENGTH) + '...';
    }),

    sendTestNotification: function sendTestNotification(appId) {
      var deviceId = this.get('id'),
          adapter = this.store.adapterFor('application'),
          url = adapter.buildURL('notification') + '?app_id=' + appId + '&device_id=' + deviceId;

      return adapter.ajax(url, 'POST');
    },
    erase: function erase() {
      var _this = this;

      var store = this.get('store'),
          adapter = store.adapterFor('device'),
          url = adapter.buildURL('device', this.get('id')) + '/erase';

      return adapter.ajax(url, 'POST').then(function (json) {
        store.pushPayload('device', json);
        return _this;
      });
    },
    relink: function relink() {
      var _this2 = this;

      var store = this.get('store'),
          adapter = store.adapterFor('device'),
          url = adapter.buildURL('device', this.get('id')) + '/relink';

      return adapter.ajax(url, 'POST').then(function (json) {
        store.pushPayload('device', json);
        return _this2;
      });
    },


    audiences: Ember.computed('id', function () {
      var adapter = this.store.adapterFor('device'),
          url = adapter.buildURL('device', this.get('id')) + '/audiences';

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'GET').then(function (json) {
          return json.mapBy('audience');
        })
      });
    })
  });
});