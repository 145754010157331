define('carnival-frontend/services/message-builder', ['exports', 'carnival-frontend/models/category', 'lodash.clonedeep'], function (exports, _category, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    /*************
     * Constants *
     ************/

    MESSAGE_PROPS: ['app', 'targetable', 'type', 'title', 'text', 'url', 'assetUrl', 'cardMediaUrl', 's3FileName', 'custom', 'variationName', 'variationRatio'],
    NOTIFICATION_PROPS: ['alert', 'badge', 'sound', 'category', 'title', 'assetUrl', 'cardMediaUrl', 's3FileName', 'interruptionLevel', 'relevanceScore'],

    REACTIVE_ACTION_PROPS: ['type', 'maxPushes', 'interval', 'delay'],
    AB_TEST_PROPS: ['app', 'targetable'],

    /***********
     * Message *
     **********/

    newMessage: function newMessage(params, automated) {
      var store = this.get('store'),
          message = store.createRecord('message', params);

      message.get('notifications').addObject(store.createRecord('notification'));

      if (automated) message.set('reactiveAction', store.createRecord('reactiveAction'));

      return message;
    },
    duplicateMessage: function duplicateMessage(original) {
      var store = this.get('store'),
          message = store.createRecord('message', this._getMessageProperties(original));

      if (original.get('test')) {
        message.set('targetable', null);
      }

      if (original.get('notifications.length')) {
        var notification = store.createRecord('notification', this._getNotificationProperties(original));

        notification.set('_u', original.get('notifications.firstObject').getProperties('_u')._u);
        notification.set('customPayloadData', this._getCustomPayloadData(original));
        notification.set('selectedCategory', this._getSelectedCategory(original));

        message.get('notifications').addObject(notification);
      }

      if (original.get('reactiveAction')) {
        var reactiveAction = store.createRecord('reactiveAction', this._getReactiveActionProperties(original));

        message.set('reactiveAction', reactiveAction);
      }

      return message;
    },
    rollbackMessage: function rollbackMessage(message) {
      var notifications = message.get('notifications'),
          reactiveAction = message.get('reactiveAction');

      if (notifications.get('length')) {
        notifications.invoke('rollbackAttributes');
      }

      if (reactiveAction) {
        reactiveAction.rollbackAttributes();
      }

      message.rollbackAttributes();
    },


    /***********
     * AB Test *
     **********/

    newAbTest: function newAbTest(params, a) {
      var abTest = this.get('store').createRecord('ab-test', params);

      if (!a) a = this.newMessage({ app: abTest.get('app') });

      abTest.set('targetable', a.get('targetable'));

      var b = this.duplicateMessage(a);

      a.setProperties({ variationName: 'a', variationRatio: 50 });
      b.setProperties({ variationName: 'b', variationRatio: 50 });

      abTest.get('messages').addObjects([a, b]);

      return abTest;
    },
    addMessageToAbTest: function addMessageToAbTest(abTest, params) {
      var lastMessage = abTest.get('messages.lastObject'),
          message = this.duplicateMessage(lastMessage);

      message.setProperties({
        variationName: params.name,
        variationRatio: params.ratio
      });

      abTest.get('messages').addObject(message);
    },
    removeMessageFromAbTest: function removeMessageFromAbTest(abTest) {
      var message = abTest.get('messages').popObject();

      this.rollbackMessage(message);
    },
    duplicateAbTest: function duplicateAbTest(original) {
      var _this = this;

      var store = this.get('store'),
          abTest = store.createRecord('ab-test', this._getAbTestProperties(original));

      original.get('messages').forEach(function (originalMessage) {
        var newMessage = _this.duplicateMessage(originalMessage);

        abTest.get('messages').addObject(newMessage);
      });

      return abTest;
    },
    rollbackAbTest: function rollbackAbTest(abTest, skipFirstMessage) {
      var _this2 = this;

      abTest.get('messages').toArray().forEach(function (message, i) {
        if (i === 0 && skipFirstMessage) return;
        _this2.rollbackMessage(message);
      });
      abTest.rollbackAttributes();
    },


    // Fetch message to be used in an `ab-test` as the "base" (original message).
    // `messageId` will be present when transitioning between /messages/edit/:id => /ab-tests/new
    // otherwise message is probably new (it won't have an id) /messages/new => /ab-tests/new
    originalMessage: function originalMessage(messageId) {
      var store = this.get('store'),
          message = null;

      if (messageId) message = store.peekRecord('message', messageId);

      return message || store.peekAll('message').findBy('isNew', true);
    },


    /***********
     * Private *
     **********/

    _getMessageProperties: function _getMessageProperties(message) {
      return message.getProperties(this.MESSAGE_PROPS);
    },
    _getNotificationProperties: function _getNotificationProperties(message) {
      return message.get('notifications.firstObject').getProperties(this.NOTIFICATION_PROPS);
    },
    _getReactiveActionProperties: function _getReactiveActionProperties(message) {
      return message.get('reactiveAction').getProperties(this.REACTIVE_ACTION_PROPS);
    },
    _getAbTestProperties: function _getAbTestProperties(message) {
      return message.getProperties(this.AB_TEST_PROPS);
    },
    _getCustomPayloadData: function _getCustomPayloadData(message) {
      var data = message.get('notifications.firstObject').getProperties('customPayloadData').customPayloadData,
          copy = [];

      data.forEach(function (obj) {
        return copy.addObject(Ember.Object.create({ key: obj.key, value: obj.value }));
      });

      return copy;
    },
    _getSelectedCategory: function _getSelectedCategory(message) {
      var data = message.get('notifications.firstObject').getProperties('selectedCategory').selectedCategory;
      if (!Ember.isEmpty(data)) {
        return (0, _lodash.default)(data);
      }

      var categoryId = message.get('notifications.firstObject').getProperties('category').category;
      if (Ember.isEmpty(categoryId)) {
        return undefined;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = _category.categories[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var category = _step.value;

          if (category.id === categoryId) {
            return (0, _lodash.default)(category);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var customCategory = (0, _lodash.default)(_category.categories.lastObject);
      customCategory.id = categoryId;
      return customCategory;
    }
  });
});