define('carnival-frontend/components/app-wrap', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    elementId: 'app',

    auth0Pinger: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super();

      document.addEventListener('visibilitychange', function (e) {
        return _this.visibilityChangeHandler(e);
      });
      document.addEventListener('mouseup', function (e) {
        return _this.click(e);
      });
    },
    visibilityChangeHandler: function visibilityChangeHandler() {
      var _this2 = this;

      if (document.hidden) return;

      var session = this.get('store').peekRecord('session', 'current');

      if (session) {
        this.get('auth0Pinger').isAuth0Authenticated().then(function (res) {
          if (!res) _this2.sendAction('logout');
        });
      }
    },
    click: function click(e) {
      var viewRegistry = Ember.getOwner(this).lookup('-view-registry:main'),
          keys = Object.keys(viewRegistry),
          views = keys.map(function (key) {
        return viewRegistry[key];
      });

      // if the target element or one of its parents have data-bubbles="false" prevent it from closing
      if (Ember.$(e.target).closest('[data-bubbles="false"]').length > 0) return false;

      var childViews = views.filterBy('closeOnClickAway').filterBy('isDestroyed', false);

      // close all reveals that implement 'closeOnClickAway'
      childViews.setEach('isOpen', false);

      return true;
    }
  });
});