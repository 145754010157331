define('carnival-frontend/routes/admin/history-trackers/history-tracker', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      return this.store.peekRecord('history-tracker', params.history_tracker_id);
    },
    renderTemplate: function renderTemplate() {
      this.render('admin.history-trackers.history-tracker', {
        into: 'application',
        outlet: 'modal'
      });
    },


    actions: {
      cancel: function cancel() {
        this.send('closeModal');
      }
    }
  });
});