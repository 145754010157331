define('carnival-frontend/helpers/filter-desc', ['exports', 'carnival-frontend/helpers/map-es-range-to-string', 'carnival-frontend/helpers/humanize-filter', 'moment'], function (exports, _mapEsRangeToString, _humanizeFilter, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterDescription = filterDescription;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function filterDescription(params) {
    var _this = this;

    var filter = params[0];
    var filterCriterias = filter.criteria;
    var isSingleCriteria = filterCriterias.length === 1;
    var isMultiCriteria = !isSingleCriteria;
    var customEvent = false;

    var descriptions = filterCriterias.map(function (value) {
      if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) !== 'object') {
        return value;
      }

      if ('distance' in value) {
        return 'within ' + value.distance + ' ' + value.unit + ' of (' + value.point.latitude + ', ' + value.point.longitude + ')';
      }

      if ('polygon' in value) {
        return 'within a selected region';
      }

      if (value.range_type === 'custom_number') {
        if (value.equality === 'between') {
          return 'between ' + value.from + ' and ' + value.to;
        } else if (value.equality === 'equal') {
          return 'exactly ' + value.count;
        } else {
          return value.equality + ' than ' + value.count;
        }
      }

      if (value.range_type === 'custom_event') {
        customEvent = true;
        if (value.equality === 'between') {
          return 'occurred between ' + value.from + ' and ' + value.to + ' times';
        } else if (value.equality === 'equal') {
          return 'occurred exactly ' + value.count + ' times';
        } else {
          return 'occurred ' + value.equality + ' than ' + value.count + ' times';
        }
      }

      if (['equal', 'less', 'more'].indexOf(value.equality) > -1) {
        var count = value.hasOwnProperty('count') ? value.count : value.days; // eslint-disable-line no-prototype-builtins

        if (value.equality === 'equal') {
          return 'exactly ' + count + ' days ago';
        } else {
          return value.equality + ' than ' + count + ' days ago';
        }
      }

      // date range (old way - always a less than range)
      if (['one_day_ago', 'one_week_ago', 'one_month_ago', 'two_months_ago'].indexOf(value.range_index) > -1) {
        var _count = _this.getCount(value.gte);

        return 'less than ' + _count + ' days ago';
      }
      // custom date range
      if (value.range_index === 'custom' || value.equality === 'between') {
        var a = value.hasOwnProperty('from') ? value.from : value.from_days; // eslint-disable-line no-prototype-builtins
        var b = value.hasOwnProperty('to') ? value.to : value.to_days; // eslint-disable-line no-prototype-builtins

        if (!isNaN(parseInt(a)) && !isNaN(parseInt(b))) {
          return 'between ' + a + ' and ' + b + ' days ago';
        } else {
          return 'between ' + value.gte + ' and ' + value.lte;
        }
      }

      if (value.equality === 'between-dates') {
        var timezoneOffset = new Date().getTimezoneOffset();
        var _a = (0, _moment.default)(value.from).format('MMMM Do YYYY');
        var _b = (0, _moment.default)(value.to).format('MMMM Do YYYY');
        var c = _moment.default.tz.zone(_moment.default.tz.guess()).abbr(timezoneOffset);
        return 'between ' + _a + ' and ' + _b + ' ' + c + ' (inclusive)';
      }

      // filters created via API might only contain random range values
      return (0, _mapEsRangeToString.mapEsRangeToString)(value);
    });
    var prefix = (0, _humanizeFilter.humanizeFilter)([filter.name], {
      plural: isMultiCriteria
    }) + ' ' + getSurroundingFilterText(isSingleCriteria, filter.notFilter) + ' ';

    if (descriptions.length >= 2) {
      var orOrAnd = customEvent ? ' AND ' : ' OR ';

      return prefix + ' ' + descriptions.slice(0, -1).join(', ') + ' ' + orOrAnd + ' ' + descriptions.slice(-1) + '\n\r';
    }
    return prefix + ' ' + descriptions.join(', ') + '\n\r';
  }

  function getSurroundingFilterText(isSingleCriteria, isNotFilter) {
    if (isNotFilter) {
      return isSingleCriteria ? 'IS NOT' : 'ARE NOT';
    }
    return isSingleCriteria ? 'IS' : 'ARE';
  }

  exports.default = Ember.Helper.helper(filterDescription);
});