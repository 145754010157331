define('carnival-frontend/helpers/is-super-admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      var user = params[0] || this.get('currentUserService.user');

      this.setProperties({ user: user });
      this.addObserver('user.superAdmin', this, 'recompute');

      return user.superAdmin;
    }
  });
});