define('carnival-frontend/models/stats', ['exports', 'carnival-frontend/adapters/application', 'ember-data', 'moment'], function (exports, _application, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    app: null,
    path: null,

    rawData: [],

    from: null,
    to: null,

    platforms: [],

    audienceId: null,

    range: Ember.computed('from', 'to', {
      get: function get() {
        return Ember.Object.create({
          from: this.get('from'),
          to: this.get('to')
        });
      },
      set: function set(key, value) {
        this.set('from', (0, _moment.default)(value.from).startOf('day').toDate());
        this.set('to', (0, _moment.default)(value.to).endOf('day').toDate());
        return Ember.Object.create({
          from: this.get('from'),
          to: this.get('to')
        });
      }
    }),

    formattedRange: Ember.computed('range', function () {
      var humanFormat = 'Do MMM YYYY',
          fromRangeString = (0, _moment.default)(this.get('from')).format(humanFormat),
          toRangeString = (0, _moment.default)(this.get('to')).format(humanFormat);

      return fromRangeString + ' - ' + toRangeString;
    }),

    allTimeRange: Ember.computed('app', function () {
      var humanFormat = 'Do MMM YYYY',
          fromRangeString = (0, _moment.default)(this.get('app.createdAt')).format(humanFormat),
          toRangeString = (0, _moment.default)().format(humanFormat);

      return fromRangeString + ' - ' + toRangeString;
    }),

    granularity: Ember.computed('range', {
      get: function get() {
        var from = (0, _moment.default)(this.get('from')),
            to = (0, _moment.default)(this.get('to'));

        // by default data range is 6 days 23:59:59 (6.99999), but we want to see data grouped by day
        // diff return rounded value  (6 for 6.999999)
        if (!this.get('range') || to.diff(from, 'days') < 6) return 'hour';else if (to.diff(from, 'months') < 4) return 'day';else if (to.diff(from, 'years') < 1) return 'week';else return 'month';
      },
      set: function set(key, value) {
        var from = (0, _moment.default)(this.get('from')),
            to = (0, _moment.default)(this.get('to'));

        // by default data range is 6 days 23:59:59 (6.99999), but we want to see data grouped by day
        // diff return rounded value  (6 for 6.999999)
        if (!this.get('range') || to.diff(from, 'days') < 6 || value === 'hour') return 'hour';else if (to.diff(from, 'months') < 4 || value === 'day') return 'day';else if (to.diff(from, 'years') < 1 || value === 'week') return 'week';else return 'month';
      }
    }),

    xDomain: Ember.computed('range', 'granularity', function () {
      var range = this.get('range'),
          granularity = this.get('granularity');

      return [(0, _moment.default)(range.from).startOf(granularity).toDate(), (0, _moment.default)(range.to).endOf(granularity).toDate()];
    }),

    rightTimeBoundary: Ember.computed('granularity', function () {
      return (0, _moment.default)().startOf(this.get('granularity')).toDate();
    }),

    leftTimeBoundary: Ember.computed('granularity', 'app', function () {
      var app = this.get('app');
      return (0, _moment.default)(app.get('createdAt') || new Date(0)).startOf(this.get('granularity')).toDate();
    }),

    data: Ember.computed(function () {
      var adapter = _application.default.create(),
          url = adapter.buildURL('app', this.get('app.id')) + '/stats/' + this.get('path'),
          platforms = this.get('platforms'),
          audienceId = this.get('audienceId'),
          data = {};

      data.from = this.get('from').toISOString();
      data.to = this.get('to').toISOString();

      if (platforms.length) data.platforms = platforms;
      if (audienceId) data.audience_id = audienceId;

      return _emberData.default.PromiseArray.create({
        promise: adapter.ajax(url, 'GET', { data: data })
      });
    }),

    displayData: Ember.computed('granularity', 'data', function () {
      var granularity = this.get('granularity'),
          data = this.get('data.content') || [],
          isMau = this.get('path') === 'active_users';

      // remove the first MAU datapoint
      // fixes graphing error where the number of
      // points vs timeline to map doesn't line up
      // because the data is recorded daily
      if (isMau) data.shift();

      var names = d3.keys(data[0]).filter(function (key) {
        return key !== 'date';
      });

      // group stats based on granularity
      // e.g. if granularity = 'day' and range = 6th -> 22nd (17 days)
      // running the key function might look something like this
      // { key: Date, values: [24] } where each object represents 1 day
      var keyFunction = function keyFunction(d) {
        var date = (0, _moment.default)(d.date);

        if (granularity !== 'hour') {
          return date.startOf(granularity).set('hour', 0).toString();
        } else {
          return date.toString();
        }
      };

      // bucket stats
      // e.g. if granularity was days add up all the values for each day
      // Caveat - MAU is calculated slightly differently because it is
      // calculating the AVERAGE not the sum
      return d3.nest().key(keyFunction).entries(data).map(function (d) {
        var bucket = { date: new Date(d.key) };

        names.forEach(function (name) {
          var total = d3.sum(d.values, function (v) {
            return v[name];
          });

          bucket[name] = isMau ? Math.round(total / d.values.length) : total;
        });

        return bucket;
      });
    }),

    formattedData: Ember.computed('displayData', function () {
      var data = this.get('displayData'),
          names = d3.keys(data[0]).filter(function (key) {
        return key !== 'date';
      });

      return names.map(function (name) {
        return {
          name: name,
          values: data.map(function (d) {
            return { date: d.date, count: d[name], platform: name };
          })
        };
      });
    }),

    oldFormattedData: Ember.computed('displayData', function () {
      return this.get('displayData').map(function (d) {
        return [d.date, d.all];
      });
    }),

    max: Ember.computed('formattedData', function () {
      var data = this.get('formattedData');

      return d3.sum(data, function (c) {
        return d3.max(c.values, function (v) {
          return v.count;
        });
      });
    }),

    min: Ember.computed('formattedData', function () {
      var data = this.get('formattedData');

      return d3.sum(data, function (c) {
        return d3.min(c.values, function (v) {
          return v.count;
        });
      });
    }),

    sum: Ember.computed('formattedData', function () {
      var data = this.get('formattedData');

      return d3.sum(data, function (c) {
        return d3.sum(c.values, function (v) {
          return v.count;
        });
      });
    }),

    average: Ember.computed('formattedData', function () {
      var data = this.get('formattedData');

      if (!data || data.length === 0) return 0;

      return d3.sum(data, function (c) {
        return d3.mean(c.values, function (v) {
          return v.count;
        });
      });
    }),

    roundedAverage: Ember.computed('average', function () {
      return Math.round(this.get('average'));
    }),

    _isSelectedWholeYear: Ember.computed('from', 'to', function () {
      return (0, _moment.default)(this.get('to')).diff(this.get('from'), 'days') > 365;
    }),

    _isSelectedWholeMonth: Ember.computed('from', 'to', function () {
      return (0, _moment.default)(this.get('to')).diff(this.get('from'), 'days') > 30;
    })
  });
});