define('carnival-frontend/components/messages/test-message-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    testAudiences: Ember.computed('currentAppService.app.audiences.{isFulfilled,@each.test}', function () {
      var app = this.currentAppService.app;

      if (!app.getWithDefault('audiences.isFulfilled', false)) {
        return [];
      }

      return app.getWithDefault('audiences', []).filterBy('test');
    }),

    hasTestAudiences: Ember.computed.notEmpty('testAudiences')
  });
});