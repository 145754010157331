define('carnival-frontend/controllers/settings/delete-app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      delete: function _delete() {
        var _this = this;

        this.get('model').destroyRecord().then(function () {
          _this.alert.success('Success:', 'App is deleted.');
          _this.send('closeModal');
          _this.transitionToRoute('apps.portfolio');
        }, function (response) {
          _this.alert.danger('Failed: ', response.message);
        });
      }
    }
  });
});