define('carnival-frontend/adapters/application', ['exports', 'active-model-adapter', 'carnival-frontend/models/errors'], function (exports, _activeModelAdapter, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _activeModelAdapter.default.extend({
    namespace: 'web/v1',
    record: null,

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },
    getErrorMessage: function getErrorMessage(status, payload) {
      var errorMessage = {
        400: new _errors.BadRequestError(),
        401: new _errors.UnauthorizedError(payload.error),
        403: new _errors.ForbiddenError(),
        404: new _errors.NotFoundError(),
        409: new _errors.ForbiddenError()
      }[status] || new _errors.UnhandledError();
      errorMessage.set('status', status);

      return errorMessage;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status >= 200 && status <= 300 || status === 422) return this._super(status, headers, payload);

      return this.getErrorMessage(status, headers, payload);
    },
    pathForType: function pathForType(type) {
      if (this._isMessage(type)) return 'messages';

      return this._super(type);
    },


    // Generate ajax option with formData for data with file
    ajaxOptions: function ajaxOptions(url, type, hash) {
      if (type !== 'GET' && type !== 'DELETE') {
        if (hash && hash.data && this.isContainsFile(hash.data)) return this.ajaxOptionsFormData(url, type, hash);
      }

      return this._super(url, type, hash);
    },
    createRecord: function createRecord(store, type, snapshot) {
      // Not the best solution, but we need to handle progress on model
      this.record = snapshot.record;
      return this._super(store, type, snapshot);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      this.record = snapshot.record;
      return this._super(store, type, snapshot);
    },
    ajaxOptionsFormData: function ajaxOptionsFormData(url, type, hash) {
      hash.url = url;
      hash.type = type;
      hash.dataType = 'json';
      hash.context = this;
      hash.processData = false;
      hash.contentType = false;
      hash.data = this.generateFormData(hash.data);

      var record = this.record;

      // TODO remove `savingProgress` as it's no longer used
      if (record) {
        hash.xhr = function () {
          // default jQuery jqxhr wrapper around xhr doesn't contain upload object
          // for handling progress
          var myXhr = Ember.$.ajaxSettings.xhr();

          myXhr.upload.addEventListener('progress', function (event) {
            if (event.lengthComputable) {
              record.set('savingProgress', 100 * event.loaded / event.total);
            }
          });

          return myXhr;
        };
      }

      return hash;
    },
    generateFormData: function generateFormData(data) {
      var fd = new FormData(),
          root = Object.keys(data)[0];

      this.fillFormData(fd, data[root], root, this.fillFormData);

      return fd;
    },
    fillFormData: function fillFormData(formData, object, rootName, that) {
      Object.keys(object).forEach(function (key) {
        var field = object[key],
            fieldPath = rootName + '[' + key + ']';

        if (field != null) {
          if (field.constructor === Array) {
            field.forEach(function (arrayField) {
              if ((typeof arrayField === 'undefined' ? 'undefined' : _typeof(arrayField)) === 'object') {
                // array of objects
                that(formData, arrayField, fieldPath + '[]', that);
              } else {
                // array of primitives
                formData.append(fieldPath + '[]', arrayField);
              }
            });
          }
          // ignore file object attributes (like fileSize, fileName)
          // instead put file object in formData, it should be handled automatically
          else if ((typeof field === 'undefined' ? 'undefined' : _typeof(field)) !== 'object' || field.constructor === File) {
              formData.append(fieldPath, field);
            } else if ((typeof field === 'undefined' ? 'undefined' : _typeof(field)) === 'object') {
              that(formData, field, fieldPath, that);
            }
        }
      });
    },
    isContainsFile: function isContainsFile(data) {
      var root = Object.keys(data)[0],
          object = data[root];

      for (var attr in object) {
        if (object.hasOwnProperty(attr) && // eslint-disable-line no-prototype-builtins
        object[attr] && object[attr].constructor === File) return true;
      }

      return false;
    },
    _isMessage: function _isMessage(type) {
      return ['message', 'Message', 'textMessage', 'imageMessage', 'linkMessage', 'fakeCallMessage', 'videoMessage', 'pushMessage'].includes(type);
    }
  });
});