define('carnival-frontend/components/sailthru-link-to-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    classNameBindings: ['shouldRenderSTUIButton:stui-btn'],
    attributeBindings: ['name', 'type', 'disabled'],
    shouldRenderSTUIButton: Ember.computed.alias('ldClient.flags.mobile_stui_component'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.shouldRenderSTUIButton) {
        this.set('elementId', this.get('id') + '-wrapper');
      } else {
        this.set('tagName', 'button');
      }
    }
  });
});