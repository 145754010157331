define('carnival-frontend/controllers/settings/remove-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      remove: function remove() {
        var _this = this;

        this.get('model').removeUser().then(function () {
          _this.alert.success('Success', 'You removed yourself from this app.');
          _this.send('closeModal');
          _this.transitionToRoute('logout');
        }).catch(function (err) {
          _this.alert.danger('Failed: ', err.message);
        });
      }
    }
  });
});