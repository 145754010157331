define('carnival-frontend/routes/stats/revenue', ['exports', 'carnival-frontend/routes/route', 'carnival-frontend/models/stats'], function (exports, _route, _stats) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      from: { refreshModel: true },
      to: { refreshModel: true },
      audienceId: { refreshModel: true }
    },

    model: function model(params) {
      return { params: params };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var app = this.modelFor('app');
      var audiences = app.get('audiencesThatHaveRevenueData');
      var params = model.params;
      var audienceId = params.audienceId,
          from = params.from,
          to = params.to,
          stat = params.stat;


      controller.setProperties({
        app: app,
        audiences: audiences,
        stats: _stats.default.create({
          app: app,
          path: stat,
          audienceId: audienceId,
          range: Ember.Object.create({
            from: from,
            to: to
          })
        })
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('stats.revenue-graph');
      this.render('stats.revenue.topbar-nav', {
        into: 'stats.revenue-graph',
        outlet: 'topbar-nav'
      });
    },


    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});