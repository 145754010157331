define('carnival-frontend/components/settings/fcm-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['certificate'],

    projectId: Ember.computed('serviceAccount.project_id', function () {
      return this.get('serviceAccount.project_id');
    }),

    isValid: Ember.computed('serviceAccount.is_valid', function () {
      return this.get('serviceAccount.is_valid');
    }),

    actions: {
      removeServiceAccount: function removeServiceAccount() {
        this.sendAction('removeServiceAccount');
      }
    }
  });
});