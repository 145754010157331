define('carnival-frontend/mixins/audiences/filters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    audience: null,
    facets: [],
    facetFilters: [],
    selectedFacetFilter: null,
    isLoadingFacet: false,

    mapFilters: Ember.on('init',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('facets', function () {
      this.set('facetFilters', []);
      this.get('facets').forEach(this.mapFilter, this);
    })),

    mapFilter: function mapFilter(facet) {
      var facetFilters = this.get('facetFilters'),
          facetFilter = Ember.Object.create({}),
          filters = this.get('audience.facetFilters') || [],
          filter = filters.findBy('name', facet);

      facetFilter.set('field', facet);
      facetFilter.set('filter', filter);

      if (this.isCustomFacet(facet)) {
        // Custom
        facetFilter.set('custom', true);
        facetFilters.addObject(facetFilter);
      } else {
        // Static - we need a way to order these easily within the template
        // So set each fieldname as a property e.g. facetFilters.country
        facetFilters.set(facet.camelize(), facetFilter);
      }

      return facetFilter;
    },
    updateSelectedFacetFilter: function updateSelectedFacetFilter(facetFilter) {
      if (this.get('selectedFacetFilter') === facetFilter) return false;

      var field = facetFilter.get('field'),
          audience = this.get('audience'),
          that = this;

      this.set('isLoadingFacet', true);
      audience.fetchFacet(field).then(function (facet) {
        facetFilter.set('facet', facet.findBy('field', field));

        if (!facetFilter.get('filter')) {
          var filter = audience.createFilter(field);
          facetFilter.set('filter', filter);
        }

        that.set('isLoadingFacet', false);
      });

      this.set('selectedFacetFilter', facetFilter);
    },
    isCustomFacet: function isCustomFacet(facet) {
      return facet.indexOf('custom.') === 0 || facet.indexOf('events.') === 0 || facet.indexOf('imported.') === 0;
    }
  });
});