define('carnival-frontend/utils/calendar-info', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var DAYS_IN_WEEK = 7,
        WEEKS_TO_SHOW = 6,
        HOURS_IN_DAY = 24,
        MINUTES_IN_HOUR = 60,
        DAYS_OF_THE_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    function daysOfTheMonth(options) {
      var result = Ember.A(),
          opts = options || {},
          minimalDate = opts.minimalDate,
          maximalDate = opts.maximalDate,
          fromDate = opts.fromDate && opts.fromDate.startOf('day'),
          toDate = opts.toDate && opts.toDate.startOf('day'),
          currentDate = opts.currentDate || (0, _moment.default)(),
          iteratorDate = (0, _moment.default)(currentDate).startOf('month').startOf('week');

      for (var week = 0; week < WEEKS_TO_SHOW; week++) {
        var currentWeek = Ember.A();
        for (var day = 0; day < DAYS_IN_WEEK; day++) {
          var isDisabled = minimalDate && iteratorDate.isBefore(minimalDate) || maximalDate && iteratorDate.isAfter(maximalDate);
          currentWeek.addObject({
            isDisabled: isDisabled,
            isToday: iteratorDate.isSame((0, _moment.default)().startOf('day')),
            isSelectedFromDate: fromDate && iteratorDate.isSame(fromDate),
            isSelectedToDate: toDate && iteratorDate.isSame(toDate),
            isPreviousMonth: iteratorDate.month() < currentDate.month(),
            isNextMonth: iteratorDate.month() > currentDate.month(),
            value: iteratorDate.date(),
            date: (0, _moment.default)(iteratorDate)
          });
          iteratorDate.add(1, 'day');
        }
        result.addObject(currentWeek);
      }

      return result;
    }

    function hoursOfTheDay() {
      var result = Ember.A();
      for (var hour = 0; hour < HOURS_IN_DAY; hour++) {
        result.addObject(hour);
      }
      return result;
    }

    function minutesOfTheHour() {
      var result = Ember.A();
      for (var minute = 0; minute < MINUTES_IN_HOUR; minute++) {
        result.addObject(minute);
      }
      return result;
    }

    function daysOfTheWeek() {
      return DAYS_OF_THE_WEEK;
    }

    return {
      daysOfTheMonth: daysOfTheMonth,
      daysOfTheWeek: daysOfTheWeek,
      hoursOfTheDay: hoursOfTheDay,
      minutesOfTheHour: minutesOfTheHour
    };
  }();
});