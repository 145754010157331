define('carnival-frontend/routes/settings/permissions/edit', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    controllerName: 'settings.permissions.crud',

    model: function model(params) {
      return this.modelFor('app').get('permissions').findBy('id', params.permission_id);
    },
    renderTemplate: function renderTemplate() {
      this.render('settings.permissions.new', {
        into: 'application',
        outlet: 'modal'
      });
    }
  });
});