define('carnival-frontend/controllers/logs/notifications', ['exports', 'carnival-frontend/mixins/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, {
    queryParams: ['state'],
    state: '',
    resultType: 'notification'
  });
});