define('carnival-frontend/components/sparkline-graph', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'canvas',
    ratio: 0.2,
    ctx: null,
    rawDataset: [],
    vsLastMonth: 0,

    lineColor: '#60646e',
    lineWidth: '2',
    lineJoin: 'round',

    radius: 4,

    positiveColor: '#03b3ed',
    negativeColor: '#f83767',
    noChangeColor: '#ffffff',

    maxValue: Ember.computed.max('cleanDataset'),
    minDays: 3,

    dashSize: Ember.computed('spacingRatio', function () {
      return parseInt(this.get('spacingRatio') / 2);
    }),

    dashGap: Ember.computed('spacingRatio', 'dashSize', function () {
      return this.get('spacingRatio') - this.get('dashSize');
    }),

    startIndex: Ember.computed('noDataset.length', function () {
      var i = this.get('noDataset.length');
      return i === 0 ? i : i - 1;
    }),

    spacingRatio: Ember.computed('paddedWidth', 'numPoints', function () {
      return this.get('paddedWidth') / this.get('numPoints');
    }),

    numPoints: Ember.computed('noDataset', 'cleanDataset', function () {
      return this.get('noDataset.length') + this.get('cleanDataset.length');
    }),

    noDataset: Ember.computed('rawDataset', function () {
      var dataset = this.get('rawDataset').copy(),
          minDays = this.get('minDays'),
          i = dataset.lastIndexOf(null) + 1;

      while (dataset.length - i > 0 && dataset.length - i < minDays) {
        dataset.splice(i++, 1, null);
      }

      return dataset.splice(0, dataset.lastIndexOf(null) + 1);
    }),

    cleanDataset: Ember.computed('rawDataset', function () {
      var dataset = this.get('rawDataset').copy(),
          minDays = this.get('minDays'),
          i = dataset.lastIndexOf(null);

      if (dataset.length - i <= minDays) dataset = [];

      return dataset.splice(dataset.lastIndexOf(null) + 1);
    }),

    didInsertElement: function didInsertElement() {
      this.set('ctx', this.get('element').getContext('2d'));

      this._handleResize();
      this.$(window).on('resize', Ember.run.bind(this, this._handleResize));
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).off('resize');
    },
    _handleResize: function _handleResize() {
      var el = this.get('element'),
          ratio = this.get('ratio'),
          width = el.parentNode.offsetWidth,
          height = parseInt(width * ratio);

      el.width = width;
      el.height = height;

      this.set('width', this.get('element').width);
      this.set('height', this.get('element').height);

      this._plotGraph();
    },


    paddedWidth: Ember.computed('width', 'radius', function () {
      return this.get('width') - this.get('radius') * 2;
    }),

    paddedHeight: Ember.computed('height', 'radius', function () {
      return this.get('height') - this.get('radius') * 2;
    }),

    gradientColor: Ember.computed('cleanDataset', function () {
      var dataset = this.get('cleanDataset'),
          n = dataset.length;

      if (n >= 2) {
        // Compare the two last points of data
        var z = dataset[n - 1],
            y = dataset[n - 2];

        if (z > y) {
          return this.get('positiveColor');
        } else if (z < y) {
          return this.get('negativeColor');
        }
      }

      return this.get('noChangeColor');
    }),

    gradient: Ember.computed('ctx', 'paddedWidth', 'paddedHeight', 'lineColor', 'gradientColor', function () {
      var gradient = this.get('ctx').createLinearGradient(0, 0, this.get('paddedWidth'), this.get('paddedWidth'));

      gradient.addColorStop(0, this.get('lineColor'));
      gradient.addColorStop(1, this.get('gradientColor'));

      return gradient;
    }),

    getX: function getX(x) {
      var offset = this.get('radius');

      if (!x) {
        x = 0;
      }

      return offset + x;
    },
    getY: function getY(dataPoint) {
      var height = this.get('paddedHeight'),
          offset = this.get('radius'),
          maxValue = this.get('maxValue'),
          y = offset + height;

      if (dataPoint) {
        y -= parseInt(dataPoint / maxValue * height);
      }

      return y;
    },
    _plotGraph: function _plotGraph() {
      var noData = this.generateCoords(this.get('noDataset')),
          cleanData = this.generateCoords(this.get('cleanDataset'), this.get('startIndex')),
          ctx = this.get('ctx');

      ctx.strokeStyle = this.get('gradient');
      ctx.lineWidth = this.get('lineWidth');
      ctx.lineJoin = this.get('lineJoin');

      this.drawNoData(noData);
      this.drawCleanData(cleanData, noData);

      if (cleanData.length) {
        this.drawCircle(cleanData.get('lastObject').x, cleanData.get('lastObject').y, this.get('radius'));
      }
    },
    drawNoData: function drawNoData(noData) {
      if (!noData.length) return;

      var ctx = this.get('ctx');

      ctx.beginPath();

      // Not all browsers support this drawing method
      // Check before using so no errors are thrown
      if (ctx.setLineDash) ctx.setLineDash([this.get('dashSize'), this.get('dashGap')]);

      ctx.moveTo(noData[0].x, noData[0].y);

      for (var i = 1, n = noData.length; i < n; i++) {
        ctx.lineTo(noData[i].x, noData[i].y);
      }

      ctx.stroke();
    },
    drawCleanData: function drawCleanData(cleanData, noData) {
      if (!cleanData.length) return;

      var ctx = this.get('ctx'),
          startPoint = noData.length ? noData.get('lastObject') : cleanData[0];

      ctx.beginPath();

      if (ctx.setLineDash) ctx.setLineDash([]);

      ctx.moveTo(startPoint.x, startPoint.y);

      for (var i = 1, n = cleanData.length - 2; i < n; i++) {
        var xc = (cleanData[i].x + cleanData[i + 1].x) / 2;
        var yc = (cleanData[i].y + cleanData[i + 1].y) / 2;
        ctx.quadraticCurveTo(cleanData[i].x, cleanData[i].y, xc, yc);
      }
      ctx.quadraticCurveTo(cleanData[i].x, cleanData[i].y, cleanData[i + 1].x, cleanData[i + 1].y);

      ctx.stroke();
    },
    generateCoords: function generateCoords(dataset, offset) {
      var spacingRatio = this.get('spacingRatio'),
          i = 0,
          n = dataset.length,
          points = [];

      if (!offset) {
        offset = 0;
      }

      while (i < n) {
        var point = {};

        point.x = this.getX(parseInt(offset * spacingRatio));
        point.y = this.getY(dataset[i]);

        points.push(point);

        offset++;
        i++;
      }

      return points;
    },
    drawCircle: function drawCircle(x, y, radius) {
      var ctx = this.get('ctx');
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = this.get('gradientColor');
      ctx.fill();
    }
  });
});