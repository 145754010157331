define('carnival-frontend/components/media-picker', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    MEDIA_FILE_FIELD: 'media-file-field',

    IMAGE_MIMES: ['image/gif', 'image/jpeg', 'image/png'],
    VIDEO_MIMES: ['video/mp4', 'video/quicktime'],

    MAX_IMAGE_SIZE: 5 * 1024 * 1024,

    acceptedMimes: Ember.computed(function () {
      return this.IMAGE_MIMES.concat(this.VIDEO_MIMES).join(', ');
    }),

    humanMimes: Ember.computed('acceptedMimes', function () {
      return this.get('acceptedMimes').replace(/image\/|video\//g, '');
    }),

    classNames: ['media-picker'],
    classNameBindings: ['isDroppable:is-droppable', 'disableDropzone:disabled', 'hasMedia:has-media', 'error:has-error', 'isUploadingImage:disabled'],

    attributeBindings: ['style'],

    fillPercentage: Ember.computed('model.percentageUploaded', function () {
      return Math.floor(this.get('model.percentageUploaded'));
    }),

    fillStyle: Ember.computed('fillPercentage', function () {
      var fillPercentage = this.get('fillPercentage');
      return new Ember.String.htmlSafe('background-image: -webkit-linear-gradient(#9ca6af ' + (100 - fillPercentage) + '%, #03b3ed ' + (100 - fillPercentage) + '%, #03b3ed ' + fillPercentage + '%);');
    }),

    isDroppable: false,
    error: '',

    hasMedia: Ember.computed('model.{assetUrl,videoUrl}', function () {
      return this.get('model.assetUrl') || this.get('model.videoUrl');
    }),

    hasPushMedia: Ember.computed('model.notifications.firstObject.{assetUrl,videoUrl}', function () {
      return this.get('model.notifications.firstObject.assetUrl') || this.get('model.notifications.firstObject.videoUrl');
    }),

    disableDropzone: Ember.computed('hasMedia', 'model.{file,isProcessing}', function () {
      return !this.get('hasMedia') && (this.get('model.file') || this.get('model.isProcessing'));
    }),

    didInsertElement: function didInsertElement() {
      this.$('#' + this.MEDIA_FILE_FIELD).click(function (e) {
        return e.stopPropagation();
      });
    },
    getMediaType: function getMediaType(file) {
      if ((typeof file === 'undefined' ? 'undefined' : _typeof(file)) !== 'object') return;
      if (this.IMAGE_MIMES.indexOf(file.type) !== -1) return 'image';
      if (this.VIDEO_MIMES.indexOf(file.type) !== -1) return 'video';
    },
    change: function change(e) {
      this.handleSelection(e.target.files);
    },
    stopEventBubbling: function stopEventBubbling(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    click: function click(e) {
      if (e.target.id === 'btn-remove-media' || e.target.id === 'btn-copy-media') return false;

      this.stopEventBubbling(e);
      this.$('#' + this.MEDIA_FILE_FIELD).click();
    },
    dragOver: function dragOver(e) {
      this.stopEventBubbling(e);

      e.dataTransfer.dropEffect = 'copy';
    },
    dragEnter: function dragEnter(e) {
      this.stopEventBubbling(e);
      this.set('isDroppable', true);
    },
    dragLeave: function dragLeave(e) {
      this.stopEventBubbling(e);
      this.set('isDroppable', false);
    },
    drop: function drop(e) {
      this.stopEventBubbling(e);
      this.set('isDroppable', false);
      this.handleSelection(e.dataTransfer.files);
    },
    handleSelection: function handleSelection(files) {
      var file = files[0];

      if (!file) {
        return false;
      }

      this.clearVideo();
      this.clearImage();

      this.set('error', '');

      switch (this.getMediaType(file)) {
        case 'video':
          this.handleVideo(file);
          break;
        case 'image':
          this.handleImage(file);
          break;
        default:
          this.handleInvalid(file);
      }
    },
    handleVideo: function handleVideo(file) {
      var model = this.get('model');
      model.set('file', file);
    },
    handleImage: function handleImage(file) {
      var _this = this;

      if (file.size > this.MAX_IMAGE_SIZE) return this.set('error', 'File size is too large. Please select a file less than 5MB');

      var model = this.get('model');

      this.set('isUploadingImage', true);

      model.uploadImage(file).then(function (response) {
        // load the image then set the url so
        // the container height doesn't jar
        _this._loadImage(response.image_url, function () {
          model.set('assetUrl', response.image_url);
          _this.set('isUploadingImage', false);
        });
      }, function (error) {
        if (error instanceof _emberData.default.InvalidError) _this.handleInvalid();else throw error;
        _this.set('isUploadingImage', false);
      });
    },
    handleInvalid: function handleInvalid() {
      this.set('error', 'File type not supported. <br>Select a valid file type (' + this.get('humanMimes') + ')');
    },
    clearVideo: function clearVideo() {
      this.get('model').setProperties({
        file: undefined,
        s3FileName: undefined,
        assetDimensions: undefined,
        transcoder: null,
        cardMediaUrl: null
      });
    },
    clearImage: function clearImage() {
      this.set('model.assetUrl', undefined);
    },
    clearPushMedia: function clearPushMedia() {
      if (this.get('model.notifications.firstObject.assetUrl')) {
        this.set('model.notifications.firstObject.assetUrl', null);
      }

      if (this.get('model.notifications.firstObject.videoUrl')) {
        this.get('model.notifications.firstObject').setProperties({
          file: undefined,
          s3FileName: undefined,
          assetDimensions: undefined,
          transcoder: null,
          cardMediaUrl: null
        });
      }
    },
    copyImage: function copyImage() {
      if (this.get('model.assetUrl')) {
        this.set('model.notifications.firstObject.assetUrl', this.get('model.assetUrl'));
      }
    },
    copyVideo: function copyVideo() {
      if (this.get('model.videoUrl')) {
        this.get('model.notifications.firstObject').setProperties({
          file: this.get('model.file'),
          s3FileName: this.get('model.s3FileName'),
          transcoder: this.get('model.transcoder'),
          assetDimensions: this.get('model.assetDimensions'),
          cardMediaUrl: this.get('model.cardMediaUrl'),
          outputUrl: this.get('model.outputUrl'),
          inCopyState: true
        });
      }
    },


    // Necessary in order to stub for test
    _newImage: function _newImage() {
      return new Image();
    },
    _loadImage: function _loadImage(url, callback) {
      var img = this._newImage();

      img.onload = callback;
      img.src = url;
    },


    actions: {
      removeMedia: function removeMedia() {
        this.clearVideo();
        this.clearImage();
        this.$('#' + this.MEDIA_FILE_FIELD).val('');
      },
      copyMedia: function copyMedia() {
        this.clearPushMedia();
        this.copyImage();
        this.copyVideo();
      }
    }
  });
});