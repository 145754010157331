define('carnival-frontend/components/password-sheriff', ['exports', 'password-sheriff', 'util'], function (exports, _passwordSheriff, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    POLICY_OPTIONS: ['none', 'low', 'fair', 'good', 'excellent'],

    elementId: 'password-sheriff',
    strength: 'excellent',
    password: '',
    passwordPolicy: null,

    showSheriff: Ember.computed.and('password', 'passwordPolicy'),

    // eslint-disable-next-line ember/require-return-from-computed
    passwordIsValid: Ember.computed('password', 'passwordPolicy', function () {
      if (this.get('passwordPolicy') && this.get('password')) {
        return this.get('passwordPolicy').check(this.get('password'));
      }
    }),

    glyphicon: Ember.computed('passwordIsValid', function () {
      return this.get('passwordIsValid') ? 'glyphicon-tick' : 'glyphicon-cross';
    }),

    // eslint-disable-next-line ember/require-return-from-computed
    passwordRules: Ember.computed('passwordPolicy', function () {
      var _this = this;

      if (this.get('passwordPolicy')) {
        var rules = this.get('passwordPolicy').explain();

        return rules.map(function (rule) {
          return _this._mapRule(rule);
        });
      }
    }),

    didInsertElement: function didInsertElement() {
      var policy = new _passwordSheriff.default(this.get('strength'));

      this.set('passwordPolicy', policy);
    },


    /***********
     * Private *
     **********/

    _mapRule: function _mapRule(rule) {
      var _this2 = this;

      var formattedRule = {};

      formattedRule.message = this._formatRule(rule);

      if (rule.items) {
        formattedRule.items = rule.items.map(function (item) {
          return _this2._mapRule(item);
        });
      }

      return formattedRule;
    },
    _formatRule: function _formatRule(rule) {
      if (rule.format) {
        return _util.default.format.apply(null, [rule.message].concat(rule.format));
      } else {
        return rule.message;
      }
    }
  });
});