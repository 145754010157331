define('carnival-frontend/components/radio-w-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['data-insight-id'],
    classNames: ['radio'],
    labelId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-radio';
    })
  });
});