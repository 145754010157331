define('carnival-frontend/components/stats/geo-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    topography: null,
    names: null,

    svg: null,
    tooltip: null,
    path: null,
    group: null,

    selectedPolygon: null,

    didInsertElement: function didInsertElement() {
      this._initMap();

      var that = this;
      this.$(window).on('debouncedresize', function () {
        that.svg.remove();
        that.tooltip.remove();
        that._initMap();
        that._createMap(that.topography);
        that.countriesDidChange();
      });

      var world = d3.json('/assets/geo/world-110m.json', function (data) {
        return data;
      });
      var names = d3.tsv('/assets/geo/world-country-names.tsv', function (data) {
        return data;
      });

      Promise.all([world, names]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            world = _ref2[0],
            names = _ref2[1];

        that.topography = topojson.feature(world, world.objects.countries).features;
        that._createMap(that.topography);

        if (!that.isDestroying && !that.isDestroyed) that.set('names', names);
        that.countriesDidChange();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).off('debouncedresize');
    },
    _initMap: function _initMap() {
      var projection = d3.geoMercator().center([0, 55]).rotate([-10, 0, 0]).scale(180 * this.$().height() / 700).translate([this.$().width() / 2, this.$().height() / 2 - 50]);

      this.path = d3.geoPath().projection(projection);

      this.svg = d3.select(this.$()[0]).append('svg').attr('width', this.$().width()).attr('height', this.$().height());

      this.group = this.svg.append('g');
      this.tooltip = d3.select(this.$()[0]).append('div').attr('class', 'popup-tooltip content top');
    },
    _createMap: function _createMap(countries) {
      var that = this;

      this.group.selectAll('.country').data(countries).enter().insert('path').attr('class', 'country').attr('d', this.path).attr('id', function (datum) {
        return 'country' + datum.id;
      }).on('mousemove', function (datum) {
        var stats = that._countrieStatsForId(datum.id);
        if (!stats) return;

        var openPlural = stats.count === 1 ? 'user ' : 'users ';

        var html = '<div class="arrow center"></div><div class="wrap"><p class="stat"><em class="count">' + stats.count + '</em> ' + openPlural + ' in <strong class="term">' + stats.term + '</strong></p></div>';

        that.tooltip.html(html);

        var width = that.$('.popup-tooltip').outerWidth(),
            height = that.$('.popup-tooltip').outerHeight(),
            x = d3.event.layerX - width / 2,
            y = d3.event.layerY;

        that.$('.popup-tooltip').removeClass('top bottom');

        if (y < 60) {
          that.$('.popup-tooltip').addClass('bottom');
          y += 20;
        } else {
          that.$('.popup-tooltip').addClass('top');
          y -= height + 20;
        }

        that.tooltip.classed('show', true).style('left', x + 'px').style('top', y + 'px');
      }).on('mouseout', function () {
        that.tooltip.classed('show', false);
      });
    },


    // eslint-disable-next-line ember/no-observers
    countriesDidChange: Ember.observer('countries', function () {
      if (!this.get('names')) return;
      if (!this.get('countries')) return;

      var that = this,
          countries = this.get('countries'),
          numCountries = countries.get('length');

      this._resetCountriesStyles();

      countries.forEach(function (country, index) {
        var polygon = that._polygonForCountryName(country.term);
        if (!polygon) return;

        var heatCoefficient = 10 - Math.floor(index / numCountries * 10);
        polygon.classed('heat' + heatCoefficient, true);
      });
    }),

    _polygonForCountryName: function _polygonForCountryName(countryName) {
      var country = this.get('names').find(function (el) {
        return el.name.toLowerCase() === countryName.toLowerCase();
      });

      if (!country) return;
      return d3.select('#country' + country.id);
    },
    _resetCountriesStyles: function _resetCountriesStyles() {
      this.get('names').forEach(function (country) {
        var polygon = d3.select('#country' + country.id);
        if (polygon) polygon.attr('class', 'country');
      });
    },
    _countrieStatsForId: function _countrieStatsForId(countryId) {
      var country = this.get('names').find(function (country) {
        return country.id === countryId.toString();
      });
      return this.get('countries').find(function (stats) {
        return stats.term === country.name;
      });
    }
  });
});