define('carnival-frontend/components/portfolio-map', ['exports', 'carnival-frontend/helpers/portfolio-map-plotter'], function (exports, _portfolioMapPlotter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    MAX_BLIPS: 200,
    BLIP_DURATION: 30000,
    apps: [],
    appIds: [],
    width: Ember.computed.reads('worldMap.maxWidth'),
    height: Ember.computed.reads('worldMap.maxHeight'),
    stream: null,
    worldMap: null,
    blips: [],
    activeBlip: null,
    blipTimeout: null,
    colors: ['blue', 'purple', 'magenta', 'orange', 'yellow', 'green', 'teal', 'pink'],
    colorMap: {},

    tooltipStyle: Ember.computed('activeBlip.{x,y}', function () {
      var x = this.get('activeBlip.x') || 0,
          y = this.get('activeBlip.y') || 0;

      return new Ember.String.htmlSafe('left:' + x + 'px;top:' + y + 'px;');
    }),

    didInsertElement: function didInsertElement() {
      var that = this;

      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', this, function () {
        that.set('worldMap', _portfolioMapPlotter.default.create({ maxWidth: that.get('element').offsetWidth }));
        that.startStream();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.stopStream();
    },
    startStream: function startStream() {
      this.stream = new EventSource('/stream/portfolio/' + this.get('appIds'));
      this.stream.addEventListener('app_open', Ember.run.bind(this, this.handleAppOpen));
    },
    stopStream: function stopStream() {
      this.stream.close();
    },


    // eslint-disable-next-line ember/no-observers
    renderMap: Ember.observer('worldMap.mapPoints', function () {
      var points = this.get('worldMap.mapPoints');

      if (Ember.isEmpty(points)) return;

      var canvas = this.$('canvas')[0],
          ctx = canvas.getContext('2d'),
          interval = this.get('worldMap.interval'),
          radius = Math.floor(interval / 2.25),
          offset = interval / 2,
          i = points.length;

      ctx.fillStyle = '#6a737e';

      while (i--) {
        var point = points[i];

        ctx.beginPath();
        ctx.arc(point[0] + offset, point[1] + offset, radius, 0, 2 * Math.PI);
        ctx.fill();
      }
    }),

    handleAppOpen: function handleAppOpen(e) {
      var blips = this.get('blips');

      if (blips === null) return;
      if (blips.length >= this.MAX_BLIPS) return;

      var blip = this.parseBlip(e.data);

      blips.pushObject(blip);

      // schedule blip removal
      Ember.run.later(this, function () {
        blips.removeObject(blip);
      }, this.BLIP_DURATION);
    },
    parseBlip: function parseBlip(json) {
      var data = JSON.parse(json),
          pos = this.get('worldMap').positionFromGeo([data.location.lon, data.location.lat]),
          interval = this.get('worldMap.interval'),
          appId = data.app_id,
          color = this.getBlipColorClass(appId);

      return Ember.Object.create({
        x: Math.floor(pos[0] + interval / 2) || 0,
        y: Math.floor(pos[1] + interval / 2) || 0,
        w: Math.floor(interval / 2.25) * 2 || 0,
        h: Math.floor(interval / 2.25) * 2 || 0,
        appId: appId,
        color: 'blip-' + color
      });
    },
    getBlipColorClass: function getBlipColorClass(appId) {
      var colorMap = this.colorMap,
          colors = this.colors;

      if (!colorMap[appId]) {
        var color = colors.shift();

        colorMap[appId] = color;
        colors.push(color);
      }

      return colorMap[appId];
    },


    actions: {
      blipEnter: function blipEnter(blip) {
        var blipTimeout = this.get('blipTimeout');

        if (blipTimeout) {
          Ember.run.cancel(blipTimeout);
          this.set('blipTimeout', null);
        }

        if (blip.isVisible && blip === this.get('activeBlip')) return;

        var app = this.get('apps').findBy('id', blip.appId);

        blip.set('app', app);
        blip.set('isVisible', true);

        this.set('activeBlip', blip);
      },
      blipLeave: function blipLeave(blip) {
        this.set('blipTimeout', Ember.run.later(this, function () {
          blip.set('isVisible', false);
        }, 3000));
      }
    }
  });
});