define('carnival-frontend/routes/login', ['exports', 'carnival-frontend/routes/route', 'carnival-frontend/models/errors'], function (exports, _route, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model() {
      return this.store.createRecord('session');
    },
    afterLogin: function afterLogin(session) {
      var transition = this.session.loginTransition;

      this.session.updateFromBackendSession(session);

      if (transition && transition.targetName !== 'login') {
        this.session.loginTransition = null;
        transition.retry();
      } else {
        this.transitionTo('index');
      }
    },
    redirect: function redirect() {
      var transition = this.session.loginTransition;

      // store the url of the intended target url so
      // if a user navigates to a protected page we can
      // transition them to that page after they log in
      if (transition && transition.targetName !== 'index') {
        Ember.$.cookie('transitionURL', transition.intent.url, {
          expires: new Date(new Date().getTime() + 5 * 60 * 1000) // 5 min from NOW
        });
      }

      this.replaceWith('login-auth0');
    },


    actions: {
      login: function login() {
        var _this = this;

        var session = this.controller.get('model'),
            errors = session.get('errors');

        /**
         * HACK: some browsers and extensions set form fields
         * in weird way, so bindings are not getting fired.
         * We are trying to read value from DOM in this case
         **/
        if (!session.get('email')) session.set('email', Ember.$('#email').val());
        if (!session.get('password')) session.set('password', Ember.$('#password').val());

        errors.clear();

        // Basic validation for blank fields
        if (!session.get('email')) errors.add('email', ['Please enter an email address']);
        if (!session.get('password')) errors.add('password', ['Please enter a password']);
        if (!session.get('isValid')) return;

        session.save().then(function () {
          _this.afterLogin(session);
        }, function (reason) {
          // restore login window back
          if (reason instanceof _errors.UnauthorizedError) {
            if (reason.message) {
              errors.add('login', reason.message);
            } else {
              errors.add('login', ['Please check your email address and password']);
            }
          }

          session.set('password', null);
        });
      }
    }
  });
});