define('carnival-frontend/serializers/notification', ['exports', 'carnival-frontend/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    unserializedAttributes: ['createdAt', 'updatedAt', 'offset', 'assetDimensions'],
    payloadWhitelist: ['alert', 'sound', 'badge', 'category', 'title', '_u', 'interruption_level', 'relevance_score'],

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.payload = snapshot.record.get('payload');

      this.payloadWhitelist.forEach(function (key) {
        return delete json[key];
      });
      delete json.custom_payload_data;

      return json;
    },
    normalize: function normalize(modelClass, resourceHash, prop) {
      var payload = resourceHash.payload;

      if (payload) {
        for (var key in payload) {
          if (this.payloadWhitelist.indexOf(key) > -1) {
            resourceHash[key] = payload[key];
          } else {
            resourceHash.custom_payload_data = resourceHash.custom_payload_data || [];
            resourceHash.custom_payload_data.push({
              key: key,
              value: payload[key]
            });
          }
        }
      }

      // set default payload keys to `undefined` to avoid rollback dramas
      // e.g. after saving we must ensure that if we delete a `badge` for
      // example the returning payload is `notification.badge = undefined`
      // in order for the save to be correctly persisted.
      this.payloadWhitelist.forEach(function (key) {
        if (payload && payload[key] === undefined) resourceHash[key] = undefined;
      });

      if (resourceHash.custom_payload_data === undefined) resourceHash.custom_payload_data = [];

      return this._super(modelClass, resourceHash, prop);
    }
  });
});