define('carnival-frontend/controllers/settings/developer/index', ['exports', 'carnival-frontend/helpers/certificate-type', 'carnival-frontend/helpers/environment'], function (exports, _certificateType, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      deleteWhitelistedDomain: function deleteWhitelistedDomain(key) {
        var app = this.get('model');
        app.get('whitelistedDomains').removeObject(key);
        app.save();
      },
      error: function error(title, msg) {
        this.alert.danger(title, msg);
      },
      success: function success(title, msg) {
        this.alert.success(title, msg);
      },
      save: function save() {
        var _this = this;

        var model = this.get('model');

        if (!model.get('hasDirtyAttributes')) return;
        model.get('errors').clear();

        model.save().then(function () {
          _this.alert.success('Success', 'Your app was successfully updated.');
        }, function () {
          _this.alert.danger('Failed', 'There was a problem updating your app.');
        });
      },
      testFCMConnection: function testFCMConnection() {
        var _this2 = this;

        var app = this.get('model');

        app.testFCMConnection().then(function (response) {
          if (Ember.isBlank(response.errors.key)) {
            _this2.alert.success('Success', 'Connection with FCM server succeeded.');
          } else {
            var humanError = response.errors.key.join(',');
            _this2.alert.danger('Failed', 'Connection with FCM failed: ' + humanError + '.');
          }
        }, function () {
          _this2.alert.danger('Failed', 'There was a problem while attempting to verify FCM key. Please try again.');
        });
      },
      testFCMServiceAccountConnection: function testFCMServiceAccountConnection() {
        var _this3 = this;

        var app = this.get('model');

        app.testFCMServiceAccountConnection().then(function (response) {
          if (Ember.isBlank(response.errors)) {
            _this3.alert.success('Success', 'Connection with FCM server succeeded.');
          } else {
            var humanError = response.errors;
            _this3.alert.danger('Failed', 'Connection with FCM failed: ' + humanError + '.');
          }
        }, function () {
          _this3.alert.danger('Failed', 'There was a problem while attempting to verify your service account FCM key. Please try again.');
        });
      },
      testCertConnection: function testCertConnection(type) {
        var _this4 = this;

        var environment = type === _certificateType.default.DEV_P12 ? _environment.default.DEV : _environment.default.PROD;
        var app = this.get('model');

        app.testCertConnection(type).then(function (response) {
          if (Ember.isBlank(response.errors.certificate)) {
            _this4.alert.success('Success', 'Connection with APNS ' + environment + ' server succeeded.');
          } else {
            var humanError = response.errors.certificate.join(',');
            _this4.alert.danger('Failed', 'Connection with APNS ' + environment + ' failed: certificate ' + humanError + '.');
          }
        }, function () {
          _this4.alert.danger('Failed', 'There was a problem while attempting to verify APNS ' + environment + ' certificate. Please try again.');
        });
      }
    }
  });
});