define('carnival-frontend/components/stats/bar-col', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rowsCount: 10,
    dataCounters: Ember.computed.mapBy('data', 'count'),
    maximumCount: Ember.computed.max('dataCounters'),

    filteredData: Ember.computed('maximumCount', function () {
      var maxValue = this.get('maximumCount');

      if (!this.get('data')) return null;

      return this.get('data').slice(0, this.get('rowsCount')).map(function (element, i) {
        var currentValue = element.count / maxValue * 100;
        element.relativePercent = currentValue.toFixed();
        element.partitionRange = 'partition-element' + (10 - i);
        return Ember.Object.create(element);
      });
    })
  });
});