define('carnival-frontend/router', ['exports', 'carnival-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    notifyVendoredServices: Ember.on('didTransition', function () {
      if (_environment.default.spm.enabled) {
        window.Sailthru.init({
          customerId: _environment.default.spm.clientId,
          autoTrackPageview: true,
          fragmentEnabled: true,
          isCustom: true
        });
      }
    })
  });

  Router.map(function () {
    this.route('login');
    this.route('login-auth0');
    this.route('perform-login');
    this.route('forgot-password', { path: '/forgot-password' }); // eslint-disable-line ember/no-unnecessary-route-path-option
    this.route('reset-password', { path: '/reset-password/:password_token' });
    this.route('landing');
    this.route('four-o-four', { path: '/four-o-four' }); // eslint-disable-line ember/no-unnecessary-route-path-option
    this.route('unlock-account', { path: '/unlock-account/:unlock_token' });
    this.route('blocked-account');
    this.route('missing', { path: '*path' });

    this.route('apps', function () {
      this.route('portfolio');
      this.route('app', { path: '/:app_id', resetNamespace: true }, function () {
        this.route('device-preview', {
          path: '/device-preview/:message_id',
          resetNamespace: true
        });

        this.route('stats', { resetNamespace: true }, function () {
          this.route('custom-events', { path: '/custom-events' }); // eslint-disable-line ember/no-unnecessary-route-path-option
          this.route('engage', { path: '/engagement' });
          this.route('device', { path: '/device-stats' });
          this.route('geo', { path: '/geography' }, function () {});
          this.route('tech', { path: '/technology' }, function () {});
          this.route('notifications', function () {});
          this.route('revenue', function () {});
        });

        this.route('messages', { resetNamespace: true }, function () {
          this.route('message', { path: '/:message_id' });

          this.route('new', { path: '/new' }); // eslint-disable-line ember/no-unnecessary-route-path-option
          this.route('edit', { path: '/:message_id/edit' });
          this.route('duplicate', { path: '/:message_id/duplicate' });

          //ab test
          this.route('ab-tests', function () {
            this.route('new');
            this.route('edit', { path: '/:ab_test_id/edit' });
            this.route('duplicate', { path: '/:ab_test_id/duplicate' });
          });
        });

        this.route('audiences', { resetNamespace: true }, function () {
          this.route('edit', { path: '/:audience_id/edit' });
          this.route('new');
          this.route('new-child', { path: '/:audience_id/new-child' });
          this.route('duplicate', { path: '/:audience_id/duplicate' });
        });

        this.route('settings', { resetNamespace: true }, function () {
          this.route('permissions', function () {
            this.route('new');
            this.route('edit', { path: '/:permission_id/edit' });
          });
          this.route('developer', function () {
            this.route('api-clients', function () {
              this.route('new');
              this.route('edit', { path: '/:api_client_id/edit' });
            });
            this.route('advanced');
          });
          this.route('logs', { resetNamespace: true }, function () {
            this.route('devices', function () {
              this.route('device', { path: '/:device_id' });
            });
            this.route('notifications');
          });
        });
      });
    });

    this.route('admin', function () {
      this.route('apps', function () {
        this.route('stats', { path: '/:app_id' });
      });
      this.route('users');
      this.route('history-trackers', function () {
        this.route('history-tracker', { path: '/:history_tracker_id' });
      });
    });

    this.route('user');
    this.route('invitations', { path: '/invitations/:invitation_token' });
    this.route('logout');
    this.route('support');
  });

  exports.default = Router;
});