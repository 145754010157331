define('carnival-frontend/models/invitation', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: _emberData.default.attr('string', { defaultValue: '' }),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string', { defaultValue: '' }),
    passwordConfirmation: _emberData.default.attr('string', { defaultValue: '' }),
    timeZone: _emberData.default.attr('string'),
    invitationToken: _emberData.default.attr('string'),
    hasAcceptedTerms: _emberData.default.attr('boolean'),

    timeZones: Ember.computed(function () {
      var adapter = this.get('store').adapterFor('user'),
          url = adapter.buildURL('user', 'time_zones');

      return _emberData.default.PromiseArray.create({
        promise: adapter.ajax(url, 'GET')
      });
    })
  });
});