define('carnival-frontend/components/rembr/device-preview', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/device-preview', 'moment', 'carnival-frontend/models/category'], function (exports, _abstractComponent, _devicePreview, _moment, _category) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _abstractComponent.default.extend({
    classNames: ['device-preview'],

    timeZones: Ember.inject.service(),

    isFullyLoaded: false,
    audienceName: Ember.computed.alias('model.targetable.content.name'),
    deviceCount: Ember.computed.alias('model.targetable.content.deviceCount.content'),
    pushEnabledCount: Ember.computed.alias('model.targetable.content.pushEnabledCount'),
    pushQuietCount: Ember.computed.alias('model.targetable.content.pushQuietCount'),

    isReactiveEnteredAudience: Ember.computed.alias('model.isReactiveEntered'),
    isReactiveExitedAudience: Ember.computed.alias('model.isReactiveExited'),
    isReactiveEnteredInclAudienceAudience: Ember.computed.alias('model.isReactiveEnteredInclAudience'),

    pushEnabledPercent: Ember.computed('pushEnabledCount', 'deviceCount', function () {
      var deviceCount = this.deviceCount,
          pushEnabledCount = this.pushEnabledCount;

      return (pushEnabledCount / deviceCount * 100 || 0).toFixed(1);
    }),

    pushQuietPercent: Ember.computed('pushEnabledCount', 'pushQuietCount', function () {
      var pushQuietCount = this.pushQuietCount,
          pushEnabledCount = this.pushEnabledCount;

      return (pushQuietCount / pushEnabledCount * 100 || 0).toFixed(1);
    }),

    // eslint-disable-next-line ember/no-observers
    onDeviceCountChanged: Ember.observer('model.targetable.content.deviceCount.content', 'model.targetable.content.{pushQuietCount,pushEnabledCount}', 'timeZones.isLoading', function () {
      this.renderIfFullyLoaded();
    }),

    renderIfFullyLoaded: function renderIfFullyLoaded() {
      var pushQuietCount = this.pushQuietCount,
          pushEnabledCount = this.pushEnabledCount,
          deviceCount = this.deviceCount;


      if (!(Ember.isEmpty(pushQuietCount) || Ember.isEmpty(pushEnabledCount) || Ember.isEmpty(deviceCount) || this.timeZones.isLoading)) {
        this.set('isFullyLoaded', true);
        this.renderDevicePreview();
      }
    },
    asMomentDate: function asMomentDate(d) {
      var asUTC = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var date = d.date,
          hour = d.hour,
          minute = d.minute,
          timezone = d.timezone;

      var _date$split = date.split('-'),
          _date$split2 = _slicedToArray(_date$split, 3),
          year = _date$split2[0],
          month = _date$split2[1],
          day = _date$split2[2];

      if (!asUTC) {
        return (0, _moment.default)({ year: year, month: month - 1, day: day, hour: hour, minute: minute });
      }

      var tz = this.timeZones.findBy('value', timezone);
      var offset = (0, _moment.default)().utcOffset(tz.offset).utcOffset();
      return _moment.default.utc({ year: year, month: month - 1, day: day, hour: hour, minute: minute }).add(offset * -1, 'minutes');
    },
    longMomentDate: function longMomentDate(d) {
      var longMomentDate = this.asMomentDate(d);
      return '' + longMomentDate.format('dddd DD MMMM YYYY');
    },
    formatDate: function formatDate(d) {
      var momentDate = this.asMomentDate(d);
      return '' + momentDate.format('h:mma');
    },
    getComparativeDates: function getComparativeDates(d) {
      if (this.get('model.localTime')) {
        return {
          date: this.asMomentDate(d),
          now: (0, _moment.default)()
        };
      }

      return {
        date: this.asMomentDate(d, true),
        now: (0, _moment.default)().utc()
      };
    },
    getRelativeDate: function getRelativeDate(d) {
      if (Ember.isEmpty(d)) {
        return '';
      }

      var _getComparativeDates = this.getComparativeDates(d),
          date = _getComparativeDates.date,
          now = _getComparativeDates.now;

      return date.from(now);
    },
    getDateDiff: function getDateDiff(d) {
      var _getComparativeDates2 = this.getComparativeDates(d),
          date = _getComparativeDates2.date,
          now = _getComparativeDates2.now;

      return date.diff(now);
    },
    getTimezoneLabel: function getTimezoneLabel(d) {
      var isLocalTime = this.get('model.localTime');
      var tz = this.timeZones.findBy('value', d.timezone);
      var timezoneLabel = isLocalTime ? 'local time' : tz.value;
      return '' + timezoneLabel;
    },
    getDateTimeString: function getDateTimeString(d) {
      return this.getRelativeDate(d) + ' at ' + this.formatDate(d) + ' (' + this.getTimezoneLabel(d) + ')';
    },
    getTimeDateString: function getTimeDateString(d) {
      return this.formatDate(d) + ' on ' + this.longMomentDate(d) + ' (' + this.getTimezoneLabel(d) + ')';
    },
    getPublishDateText: function getPublishDateText() {
      var _get = this.get('model'),
          publishAt = _get.publishAt,
          unpublishAt = _get.unpublishAt;

      var publishAtInThePast = publishAt && this.getDateDiff(publishAt) < 0;
      var publishAtText = publishAtInThePast ? 'immediately (' + this.getTimezoneLabel(publishAt) + ')' : publishAt ? '' + this.getDateTimeString(publishAt) : 'immediately';

      var unpublishAtText = unpublishAt ? ', and expire ' + this.getDateTimeString(unpublishAt) : '';

      return 'It will be published <emphasised>' + publishAtText + unpublishAtText + '</emphasised>.';
    },
    getReactivePublishDateText: function getReactivePublishDateText() {
      var _get2 = this.get('model'),
          publishAt = _get2.publishAt,
          unpublishAt = _get2.unpublishAt,
          isReactiveExited = _get2.isReactiveExited,
          isReactiveEnteredInclAudience = _get2.isReactiveEnteredInclAudience;

      var publishAtInThePast = publishAt && this.getDateDiff(publishAt) < 0;
      var publishAtText = publishAtInThePast ? 'now onward (' + this.getTimezoneLabel(publishAt) + ')' : publishAt ? this.getTimeDateString(publishAt) + ' onward' : 'now onward';

      var unpublishAtText = unpublishAt ? '. This message will expire ' + this.getDateTimeString(unpublishAt) : '';

      var exitVsEnterText = isReactiveExited ? ' exit from ' : ' entry into ';
      var enterInclAudienceText = isReactiveEnteredInclAudience ? ' both to <emphasised>this audience</emphasised> and' : '';

      return 'It will be published' + enterInclAudienceText + ' upon a device\'s <emphasised>' + exitVsEnterText + '</emphasised>this audience from <emphasised>' + publishAtText + unpublishAtText + '</emphasised>.';
    },
    getAudienceText: function getAudienceText() {
      return 'This message will be sent to the <emphasised>' + this.audienceName + '</emphasised> audience             containing <emphasised>' + this.deviceCount + '</emphasised> devices of which <emphasised>' + this.pushEnabledPercent + '%</emphasised>             of devices are push enabled and of that <emphasised>' + this.pushQuietPercent + '%</emphasised> of             devices are quiet.\n            \n\n\n            ' + this.getPublishDateText();
    },
    getReactiveEnteredAudienceText: function getReactiveEnteredAudienceText() {
      return 'This message will be sent to devices as they enter the <emphasised>' + this.audienceName + '</emphasised> audience.\n              \n\n\n              ' + this.getReactivePublishDateText();
    },
    getReactiveExitedAudienceText: function getReactiveExitedAudienceText() {
      return 'This message will be sent to devices as they exit the <emphasised>' + this.audienceName + '</emphasised> audience.\n              \n\n\n              ' + this.getReactivePublishDateText();
    },
    getReactiveEnteredInclAudienceText: function getReactiveEnteredInclAudienceText() {
      return 'This message will be sent to devices as they enter the <emphasised>' + this.audienceName + '</emphasised> audience.\n            \n\n\n            ' + this.getReactivePublishDateText();
    },
    getInstructionsText: function getInstructionsText() {
      var isReactiveEnteredAudience = this.isReactiveEnteredAudience,
          isReactiveExitedAudience = this.isReactiveExitedAudience,
          isReactiveEnteredInclAudienceAudience = this.isReactiveEnteredInclAudienceAudience;


      if (this.get('isFullyLoaded') === false) {
        return '';
      }
      if (isReactiveEnteredAudience) {
        return '' + this.getReactiveEnteredAudienceText();
      } else if (isReactiveExitedAudience) {
        return '' + this.getReactiveExitedAudienceText();
      } else if (isReactiveEnteredInclAudienceAudience) {
        return '' + this.getReactiveEnteredInclAudienceText();
      } else {
        return '' + this.getAudienceText();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderIfFullyLoaded();

      var _get3 = this.get('model'),
          publishAt = _get3.publishAt;

      var isInThePast = publishAt ? this.getDateDiff(publishAt) < 0 : false;
      var willBeScheduled = publishAt && !isInThePast;

      this.model.set('publishDateIsInThePast', isInThePast);
      this.model.set('willBeScheduled', willBeScheduled);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderIfFullyLoaded();
    },
    getButtonLabels: function getButtonLabels(category) {
      if (!category) {
        return [];
      }
      var buttonCategory = _category.categories.find(function (c) {
        return c.id === category;
      });
      if (buttonCategory && buttonCategory.name === 'Custom') {
        return ['[Custom Button Label]'];
      } else if (buttonCategory && buttonCategory !== 'Custom') {
        return buttonCategory.name.split('/');
      } else {
        return ['[Custom Button Label]'];
      }
    },
    getNotificationData: function getNotificationData() {
      if (!this.get('model.notification')) {
        return null;
      }

      var _get4 = this.get('model.app'),
          appName = _get4.name,
          appIcon = _get4.iconAssetUrl;

      var _get5 = this.get('model.notification'),
          alert = _get5.alert,
          title = _get5.title,
          category = _get5.category,
          assetUrl = _get5.assetUrl,
          videoUrl = _get5.videoUrl;

      var buttonLabels = this.getButtonLabels(category);

      return {
        appName: appName,
        appIcon: appIcon,
        time: 'now',
        title: title,
        alert: alert,
        image: assetUrl,
        video: videoUrl,
        buttonLabels: buttonLabels
      };
    },
    getInAppData: function getInAppData() {
      if (!this.get('model.isInapp')) {
        return null;
      }

      var _get6 = this.get('model.app'),
          appName = _get6.name,
          appIcon = _get6.iconAssetUrl;

      var _get7 = this.get('model'),
          text = _get7.text,
          title = _get7.title,
          image = _get7.assetUrl,
          video = _get7.videoUrl,
          link = _get7.url;

      return {
        appName: appName,
        appIcon: appIcon,
        time: (0, _moment.default)().format('lll'),
        title: title,
        text: text,
        image: image,
        video: video,
        link: link
      };
    },
    renderDevicePreview: function renderDevicePreview() {
      var notificationData = this.getNotificationData();
      var inAppData = this.getInAppData();

      var settingsData = {
        caveatText: '',
        instructionsText: this.getInstructionsText()
      };

      this.reactRender(React.createElement(_devicePreview.App, {
        data: _extends({}, notificationData, {
          inApp: inAppData,
          notification: notificationData
        }),
        settingsData: settingsData
      }));
    }
  });
});