define('carnival-frontend/components/audiences/filter-criteria/numeric-range-criteria', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['numeric-range-criteria-item'],
    filter: null,
    from: '',
    to: '',
    keyName: 'custom_date',

    filterDidChange: Ember.on('init',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('filter', function () {
      var criteria = this.get('filter.criteria');

      if (Ember.isPresent(criteria)) {
        var range = criteria.findBy('range_type', this.get('keyName'));

        if (range) {
          // eslint-disable-next-line no-prototype-builtins
          var a = range.hasOwnProperty('from') ? range.get('from') : range.get('from_days'),

          // eslint-disable-next-line no-prototype-builtins
          b = range.hasOwnProperty('to') ? range.get('to') : range.get('to_days');

          if (!isNaN(parseFloat(a)) && !isNaN(parseFloat(b))) {
            this.setProperties({
              from: a,
              to: b
            });
          }
        }
      }
    })),

    // eslint-disable-next-line ember/no-observers
    clearInputOnFilterDeletion: Ember.observer('filter.criteria', function () {
      var criteria = this.get('filter.criteria');

      if (this.get('from') !== '' && this.get('to') !== '' && criteria && !criteria.findBy('range_type', this.get('keyName'))) {
        this.setProperties({
          from: '',
          to: ''
        });
      }
    }),

    actions: {
      clearFilter: function clearFilter() {
        if (this.get('from') === '' || this.get('to') === '') this.sendAction('clearFilter');
      },
      updateFilter: function updateFilter() {
        var a = this.get('from'),
            b = this.get('to');

        if (a !== '' && b !== '') this.sendAction('updateFilter', a + '-' + b, 'between');
      }
    }
  });
});