define('carnival-frontend/initializers/component-attributes', ['exports', 'carnival-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  /*
   * Adds additional data-ab-test-id` attribute bindings to Component.
   *
   * ## Usage:
   *
   * ### Add a data-test-id attribute
   *
   * Adding a `testID` attribute to any component will cause the addition of a
   * `data-test-id` attribute being added to the Element.
   *
   * NOTE: `testID` is ignored when the environment is `production`.
   *
   * ```handlebars
   * {{my-awesome-component testID="my-awesome-component-button"}}
   * ```
   *
   * ### Add data-ab-test-id attribute
   *
   * Adding a `dataABTestID` property will cause the `data-ab-test-id` property to be
   * added to the element.
   *
   */

  function initialize() {
    Ember.Component.reopen({
      init: function init() {
        this._super.apply(this, arguments);

        // Don't set the attributeBindings property if the component is tagless
        // else Ember throws an error.
        if (Ember.isPresent(Ember.get(this, 'tagName'))) {
          var existingAttributeBindings = Ember.getWithDefault(this, 'attributeBindings', []);
          Ember.set(this, 'attributeBindings', existingAttributeBindings.concat(['environmentProtectedTestID:data-test-id', 'dataABTestID:data-ab-test-id', 'dataInsightId:data-insight-id']));
        }
      },


      // Don't annotate `data-test-id` attributes onto elements in production.
      environmentProtectedTestID: Ember.computed('testID', function () {
        if (Ember.get(_environment.default, 'environment') === 'production') {
          return;
        }
        return Ember.get(this, 'testID');
      })
    });
  }

  exports.default = {
    name: 'component-attributes',
    initialize: initialize
  };
});