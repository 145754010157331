define('carnival-frontend/controllers/messages', ['exports', 'carnival-frontend/mixins/stats/dates', 'moment'], function (exports, _dates, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dates.default, {
    queryParams: ['push', 'triggered', 'state', 'audience', 'test', 'publish_after', 'publish_before'],
    push: false,
    triggered: false,
    state: 'published',
    audience: '',
    datalist: [],
    page: 2,
    test: false,
    dateFormat: 'll',
    publish_after: null,
    publish_before: null,

    // eslint-disable-next-line ember/no-observers
    messagesResolved: Ember.observer('messages.isFulfilled', function () {
      this.set('datalist', this.get('messages').toArray());
    }),

    fetchNext: function fetchNext() {
      var app = this.get('app');

      var params = this.getProperties(this.get('queryParams'));
      // The API doesn't handle empty date range parameters properly.
      params['publish_after'] == null && delete params['publish_after'];
      params['publish_before'] == null && delete params['publish_before'];
      params.page = this.get('page');
      params.app_id = app.id;

      return this.store.query('message', params);
    },
    generateCsvLink: function generateCsvLink(mode) {
      var state = this.get('state'),
          appId = this.get('app.id'),
          audience = this.get('audience'),
          triggered = this.get('triggered'),
          publish_after = this.get('publish_after'),
          publish_before = this.get('publish_before');

      var queryString = 'app_id=' + appId + '&audience=' + audience + '&triggered=' + triggered + '&mode=' + mode;

      if (publish_after) {
        queryString += '&start_at=' + publish_after;
      }

      if (publish_before) {
        queryString += '&end_at=' + publish_before;
      }

      return '/web/v1/messages/csv/' + state + '.csv?' + queryString;
    },


    summaryCsvLink: Ember.computed('state', 'app.id', 'audience', 'triggered', 'publish_after', 'publish_before', function () {
      return this.generateCsvLink('summary');
    }),

    detailedCsvLink: Ember.computed('state', 'app.id', 'audience', 'triggered', 'publish_after', 'publish_before', function () {
      return this.generateCsvLink('detailed');
    }),

    inappOrPushText: Ember.computed('push', function () {
      if (this.get('push')) return 'push';
      return 'in-app';
    }),

    stateText: Ember.computed('push', 'state', 'triggered', function () {
      var params = this.getProperties('push', 'state', 'triggered');

      if (params.push && params.state === 'published') return 'sent';
      if (params.push && params.state === 'unpublished' && params.triggered) return 'disabled';
      if (params.state === 'unpublished') return 'archived';

      return params.state;
    }),

    // eslint-disable-next-line ember/require-return-from-computed
    audienceText: Ember.computed('audience', 'app.audiences', function () {
      var id = this.get('audience');

      if (!id) return 'All Audiences';
      if (id === 'transient') return 'All API Queries';
      if (id === 'everyone') return 'Everyone';

      var audience = this.get('app.audiences').findBy('id', id);
      if (audience) return audience.get('name');
    }),

    dateText: Ember.computed('publish_after', 'publish_before', function () {
      if (this.get('publish_after') && this.get('publish_before')) {
        var afterText = (0, _moment.default)(this.get('publish_after')).format(this.get('dateFormat'));
        var beforeText = (0, _moment.default)(this.get('publish_before')).format(this.get('dateFormat'));
        return new Ember.String.htmlSafe(afterText + ' &ndash; ' + beforeText);
      } else if (this.get('publish_after')) {
        var _afterText = (0, _moment.default)(this.get('publish_after')).format(this.get('dateFormat'));
        return 'From ' + _afterText;
      } else if (this.get('publish_before')) {
        var _beforeText = (0, _moment.default)(this.get('publish_before')).format(this.get('dateFormat'));
        return 'Until ' + _beforeText + ' (inclusive)';
      } else {
        return 'All Time';
      }
    })
  });
});