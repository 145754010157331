define('carnival-frontend/components/messages/composer-inapp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['inapp-editable-container', 'editable-container'],

    messageKeyFields: Ember.computed('model', {
      get: function get() {
        return this.get('model').get('messageKeyFields');
      }
    }),

    hasEditableFields: Ember.computed('showLink', 'messageKeyFields.length', function () {
      return this.get('showLink') || this.get('messageKeyFields.length') > 0;
    }),

    showLink: Ember.computed('model.url', function () {
      return typeof this.get('model.url') === 'string';
    }),

    // eslint-disable-next-line ember/no-observers
    messageKeyFieldsDidChange: Ember.observer('messageKeyFields.@each.value', function () {
      var fields = this.get('messageKeyFields');

      var hash = fields.reduce(function (map, obj) {
        var val = obj.get('value').trim();
        if (val) map[obj.get('key')] = val;
        return map;
      }, {});

      this.set('model.custom', hash);
    }),

    actions: {
      addInapp: function addInapp(model) {
        model.set('title', '');
      },
      deleteInapp: function deleteInapp(model) {
        // Message will not be a 'push_message' when notifications are deleted.
        // this prevents a case where we could have a 'no data'
        // state if a user was to deleted both push and inapp
        if (!model.get('notifications.length')) return;

        // Reset properties, pushes don't use custom message attributes
        this.set('messageKeyFields', []);

        model.resetInappProperties();
      },
      addLink: function addLink(model) {
        model.set('url', '');
      },
      deleteLink: function deleteLink(model) {
        model.set('url', undefined);
      },
      addMessageKeyField: function addMessageKeyField(key) {
        var fields = this.get('messageKeyFields');

        if (!fields.findBy('key', key)) {
          fields.addObject(Ember.Object.create({ key: key, value: '' }));
        }
      },
      deleteMessageKeyField: function deleteMessageKeyField(field) {
        this.get('messageKeyFields').removeObject(field);
      }
    }
  });
});