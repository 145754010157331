define('carnival-frontend/models/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function ForbiddenError() {
    this.message = "You don't have permission for this action";
  }

  ForbiddenError.prototype = Ember.Object.create(Error.prototype);

  function UnauthorizedError(error) {
    this.message = error || 'Login invalid or expired';
  }

  UnauthorizedError.prototype = Ember.Object.create(Error.prototype);

  function NotFoundError() {
    this.message = 'Requested resource is not found';
  }

  NotFoundError.prototype = Ember.Object.create(Error.prototype);

  function BadRequestError() {
    this.message = 'Invalid request';
  }

  BadRequestError.prototype = Ember.Object.create(Error.prototype);

  function UnhandledError() {
    this.message = 'Unknown Error';
  }

  UnhandledError.prototype = Ember.Object.create(Error.prototype);

  exports.ForbiddenError = ForbiddenError;
  exports.UnauthorizedError = UnauthorizedError;
  exports.NotFoundError = NotFoundError;
  exports.BadRequestError = BadRequestError;
  exports.UnhandledError = UnhandledError;
});