define('carnival-frontend/components/map-blip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['color'],
    attributeBindings: ['style'],
    rect: null,

    color: Ember.computed('rect', function () {
      return this.get('rect.color');
    }),

    style: Ember.computed('rect', function () {
      var rect = this.get('rect');

      return new Ember.String.htmlSafe('left:' + rect.x + 'px;top:' + rect.y + 'px;width:' + rect.w + 'px;height:' + rect.h + 'px;');
    }),

    mouseEnter: function mouseEnter() {
      this.sendAction('rectEnter', this.get('rect'));
    },

    mouseLeave: function mouseLeave() {
      this.sendAction('rectLeave', this.get('rect'));
    }
  });
});