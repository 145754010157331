define('carnival-frontend/routes/app', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    beforeModel: function beforeModel() {
      this.store.unloadAll('annotation');
    },
    model: function model(params) {
      return this.store.findRecord('app', params.app_id);
    },
    afterModel: function afterModel(model) {
      this.get('currentAppService').updateCurrentApp(model);
    },
    renderTemplate: function renderTemplate(controller, model) {
      this._super(controller, model);

      var appsController = this.controllerFor('apps');

      this.render('platform-switcher', {
        outlet: 'platform-switcher',
        controller: appsController
      });

      this.render('sailthru-navigation', {
        outlet: 'nav',
        controller: appsController
      });
    }
  });
});