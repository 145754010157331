define('carnival-frontend/components/settings/select-audience', ['exports', 'carnival-frontend/components/dropdown-revealer'], function (exports, _dropdownRevealer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dropdownRevealer.default.extend({
    classNames: ['select-box'],

    isDisabled: Ember.computed.equal('selectOptions.length', 0),

    selection: null,
    selectOptions: [],
    actionName: '',

    iconClassNames: 'glyphicon glyphicon-chevron-down glyphicon-sml',
    toggleClassNames: 'btn btn-icon btn-tertiary',
    contentClassNames: 'reveal-menu dropdown-menu',

    updateText: Ember.on('init',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('selection', 'selection.isFulfilled', function () {
      var selection = this.get('selection');

      if (selection.get('name')) {
        this.set('text', selection.get('name'));
      } else {
        this.set('text', 'No Restrictions');
      }
    })),

    actions: {
      selectOption: function selectOption(option) {
        this.set('selection', option);
        this.sendAction('action', this.get('selection'));
        this.set('isOpen', false);
      }
    }
  });
});