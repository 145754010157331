define('carnival-frontend/routes/logs/notifications', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      per: { refreshModel: true },
      page: { refreshModel: true },
      state: { refreshModel: true }
    },

    model: function model(params) {
      params.app_id = this.modelFor('app').id;

      return this.store.query('notification', params);
    }
  });
});