define('carnival-frontend/routes/four-o-four', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    renderTemplate: function renderTemplate() {
      this.render('missing');
    }
  });
});