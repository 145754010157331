define('carnival-frontend/helpers/map-es-range-to-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapEsRangeToString = mapEsRangeToString;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function mapEsRangeToString(es_range) {
    if ((typeof es_range === 'undefined' ? 'undefined' : _typeof(es_range)) !== 'object') return '';

    var ES_RANGE_MAP = {
      gt: 'more than',
      gte: 'more than or equal to',
      lt: 'less than',
      lte: 'less than or equal to'
    };

    return Object.keys(ES_RANGE_MAP).reduce(function (arr, k) {
      if (!(k in es_range) || es_range[k] === '') return arr;
      arr.push(ES_RANGE_MAP[k] + ' ' + es_range[k]);
      return arr;
    }, []).join(' and ');
  } /* 
      Accepts an elastic search range object and tries to map
      its values to a human readable string
      Any keys not defined in the `ES_RANGE_MAP` are ignored.
      Any values that contain empty strings are ignored.
    
      e.g.
    
      var es_range = {
        gt: 0,
        lt: 10,
        yolo: 60, // will be ignored because it's not in `ES_RANGE_MAP`
        lte: ""   // will be ignored because it's an empty string
      };
    
      var result = ESRangeToString(es_range);
    
      result === 'greater than 0 and less than 10'
    */

  exports.default = Ember.Helper.helper(mapEsRangeToString);
});