define('carnival-frontend/components/audiences/filter-criteria/term-criteria', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['facet-value-list-item'],
    classNameBindings: ['isSelected:selected'],

    facetValue: null,
    filter: null,

    isSelected: Ember.computed('filter.criteria.[]', {
      get: function get() {
        if (!this.get('filter')) return false;
        var term = this.get('facetValue.term');
        return this.get('filter.criteria').includes(term);
      },
      set: function set(key, value) {
        if (!this.get('filter')) return false;
        var term = this.get('facetValue.term');

        var criteria = Ember.copy(this.get('filter.criteria').rejectBy('range_index'));

        if (value === true) {
          criteria.addObject(term);
          // reassign values to fire deviceCount observer
          this.set('filter.criteria', criteria);
        } else if (value === false) {
          criteria.removeObject(term);
          // reassign values to fire deviceCount observer
          this.set('filter.criteria', criteria);
        }

        return this.get('filter.criteria').includes(term);
      }
    })
  });
});