define('carnival-frontend/controllers/admin/users/crud', ['exports', 'ember-data', 'carnival-frontend/helpers/regexes'], function (exports, _emberData, _regexes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('model', this, 'modelDidChange');
    },


    userEmail: Ember.computed.reads('model.email'),

    disablePrimaryAction: Ember.computed('model.email', function () {
      var email = this.get('model.email') || '';
      return !email.match(_regexes.default.EMAIL);
    }),

    subscriptions: [],

    // Because `User#subscriptions` is a volatile computed that always returns a
    // promise it can't be passed into `updateSubscriptions` so instead we cache
    // a copy of the subscriptions on this object and refer to that instead.
    // We also can't trigger this in `init` because the the model property is set
    // *after* the object is created.
    modelDidChange: function modelDidChange() {
      var _this = this;

      var model = this.get('model');
      if (Ember.isPresent(model) && !model.get('isNew')) {
        this.get('model.subscriptions').then(function (subs) {
          return _this.set('subscriptions', subs);
        });
      } else {
        this.set('subscriptions', []);
      }
    },
    modelDidSave: function modelDidSave() {
      this.send('closeModal');
    },
    modelDidNotSave: function modelDidNotSave() {},
    modelDidDelete: function modelDidDelete() {
      this.send('closeModal');
    },
    modelDidNotDelete: function modelDidNotDelete() {},
    inviteDidSend: function inviteDidSend(response) {
      this.send('closeModal');
      this.send('openModal', 'admin/users/invite-sent', Ember.Object.create({ invitationToken: response.token }), 'admin/users/crud');
    },
    inviteDidNotSend: function inviteDidNotSend(response) {
      if (response instanceof _emberData.default.InvalidError) {
        var errorsHash = _emberData.default.errorsArrayToHash(response.errors);
        this.get('model').invalidateModel(errorsHash);

        return this.alert.danger('Error while trying to invite user', this.get('model').get('validationErrorMessage'));
      }

      throw response;
    },
    inviteDidResend: function inviteDidResend(response) {
      this.send('closeModal');
      this.send('openModal', 'settings/permissions/invite-alert', Ember.Object.create({ invitationToken: response.token }), 'admin/users/crud');
    },
    inviteDidNotResend: function inviteDidNotResend() {},
    permissionsDidUpdate: function permissionsDidUpdate() {
      this.send('closeModal');
    },
    permissionsDidNotUpdate: function permissionsDidNotUpdate() {},
    subscriptionsDidUpdate: function subscriptionsDidUpdate() {
      this.send('closeModal');
    },
    subscriptionsDidNotUpdate: function subscriptionsDidNotUpdate() {},


    actions: {
      dismiss: function dismiss() {
        this.send('closeModal');
      },
      dismissAndRefresh: function dismissAndRefresh() {
        this.send('closeModal');
        this.send('refresh');
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.send('closeModal');
      },
      save: function save() {
        this.get('model').save().then(Ember.run.bind(this, this.modelDidSave), Ember.run.bind(this, this.modelDidNotSave));
      },
      delete: function _delete() {
        this.get('model').destroyRecord().then(Ember.run.bind(this, this.modelDidDelete), Ember.run.bind(this, this.modelDidNotDelete));
      },
      send: function send() {
        this.get('model').sendInvite().then(Ember.run.bind(this, this.inviteDidSend), Ember.run.bind(this, this.inviteDidNotSend));
      },
      resend: function resend() {
        this.get('model').sendInvite().then(Ember.run.bind(this, this.inviteDidResend), Ember.run.bind(this, this.inviteDidNotResend));
      },
      updateSuperPermissions: function updateSuperPermissions() {
        this.get('model').updateSuperRights().then(Ember.run.bind(this, this.permissionsDidUpdate), Ember.run.bind(this, this.permissionsDidNotUpdate));
      },


      updateSubscriptions: function updateSubscriptions(subscriptions) {
        this.get('model').updateSubscriptions(subscriptions).then(Ember.run.bind(this, this.subscriptionsDidUpdate), Ember.run.bind(this, this.subscriptionsDidNotUpdate));
      }
    }
  });
});