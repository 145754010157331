define('carnival-frontend/components/debounced-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    debounceWait: 300,
    debounce: null,
    fireAtStart: false,

    _elementValueDidChange: function _elementValueDidChange() {
      this.set('debounce', Ember.run.debounce(this, this._setValue, this.debounceWait, this.fireAtStart));
    },
    _setValue: function _setValue() {
      this.set('value', this.$().val());
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('debounce'));
    }
  });
});