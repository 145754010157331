define('carnival-frontend/components/stats/response-graph', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    margin: { top: 40, right: 20, bottom: 30, left: 50 },

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$(window).on('debouncedresize', function () {
        return _this.draw();
      });

      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$(window).trigger('debouncedresize');
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('debouncedresize');
    },
    draw: function draw() {
      // Graph dimensions
      // ****************

      var margin = this.margin,
          width = this.$().width() - margin.left - margin.right,
          height = this.$().height() - margin.top - margin.bottom;

      // Data
      // ****

      var data = this.get('data'),
          xDomain = this.get('xDomain');

      // Scales
      // ******

      var x = d3.scaleTime().domain(xDomain).range([0, width]);

      // add some buffer to max value so spa
      var yMax = Math.ceil(d3.max(data, function (d) {
        return d[1];
      }) * 1.1);

      var y = d3.scaleLinear().domain([0, yMax || 4]).range([height, 0]);

      // Axises
      // ******

      var xAxis = d3.axisBottom().scale(x);

      var yAxis = d3.axisLeft().scale(y).ticks(5).tickSize(-width).tickPadding(5);

      // Render SVG elements
      // *******************

      var elementId = '#' + this.get('elementId');

      d3.select(elementId + '> svg').remove();

      var svg = d3.select(elementId).append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom).append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      svg.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);

      svg.append('g').attr('class', 'y axis').call(yAxis);

      // Bars
      // ****

      var barWidth = width / d3.timeHours(xDomain[0], xDomain[1]).length;

      var bars = svg.append('g').attr('class', 'bars');

      bars.selectAll('.bar').data(data).enter().append('rect').attr('class', 'bar').attr('x', function (d, i) {
        return i * barWidth;
      }).attr('y', function (d) {
        return y(d[1]);
      }).attr('width', barWidth - 1).attr('height', function (d) {
        return height - y(d[1]);
      });

      // Flag
      // ****

      var publishedAt = this.get('publishedAt'),
          flagX = x(publishedAt),
          flagH = 24,
          flagY = flagH - this.margin.top;

      var flag = svg.append('g').attr('class', 'flag').attr('transform', 'translate(' + flagX + ',0)');

      flag.append('line').attr('class', 'mast').attr('x1', 0).attr('y1', height).attr('x2', 0).attr('y2', flagY);

      flag.append('rect').attr('x', 0).attr('y', flagY - flagH).attr('width', 140).attr('height', flagH);

      flag.append('text').attr('x', 15).attr('y', flagY - flagH + 16).text('Published ' + (0, _moment.default)(publishedAt).format('LT'));
    }
  });
});