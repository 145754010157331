define('carnival-frontend/components/audiences/audience-list', ['exports', 'carnival-frontend/mixins/sort'], function (exports, _sort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sort.default, {
    tagName: 'li',
    sort: 'newest',
    childAudiences: Ember.computed.filterBy('audience.childAudiences', 'disabled', false),
    sortedAudiences: Ember.computed('childAudiences', 'sort', function () {
      var sort = this.get('sort'),
          audiences = this.get('childAudiences');

      return this.sortArray(sort, audiences);
    }),

    actions: {
      // propagate action from child components
      openModal: function openModal(modalName, model, controller) {
        this.sendAction('openModal', modalName, model, controller);
      }
    }
  });
});