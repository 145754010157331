define('carnival-frontend/models/annotation', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    comment: _emberData.default.attr('string'),
    annotatedAt: _emberData.default.attr('date'),
    annotationType: _emberData.default.attr('string', { defaultValue: 'custom' }),
    app: _emberData.default.belongsTo('app', { async: false }),
    targetable: _emberData.default.belongsTo('targetable', { polymorphic: true }),

    isInDomain: function isInDomain(domain) {
      if (!domain || domain.length !== 2) return false;
      return this.get('annotatedAt') >= domain[0] && this.get('annotatedAt') <= domain[1];
    },


    annotationTypeAsString: Ember.computed('annotationType', function () {
      switch (this.get('annotationType')) {
        case 'message':
          return 'In App Message';
        case 'push':
          return 'Push Notification';
      }
      return '';
    }),

    iconName: Ember.computed('annotationType', function () {
      return 'annotation-' + this.get('annotationType') + '-sml';
    }),

    glyphiconName: Ember.computed('annotationType', function () {
      var annotationType = '';

      switch (this.get('annotationType')) {
        case 'message':
          annotationType = 'message-text';
          break;
        case 'push':
          annotationType = 'message-push';
          break;
      }

      return 'glyphicon-' + annotationType;
    }),

    visibleForAudience: function visibleForAudience(audienceId) {
      if (audienceId && this.get('targetable').get('id') && this.get('annotationType') !== 'custom') {
        return audienceId === this.get('targetable').get('id');
      } else {
        return true;
      }
    }
  });
});