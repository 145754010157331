define('carnival-frontend/components/messages/composer-datepicker', ['exports', 'moment', 'carnival-frontend/models/time-with-zone'], function (exports, _moment, _timeWithZone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var COMPACT_DATE_TIME_FORMAT = 'MMM D YYYY HH:mm';

  exports.default = Ember.Component.extend({
    COMPACT_DATE_TIME_FORMAT: COMPACT_DATE_TIME_FORMAT,

    timeZones: Ember.inject.service(),

    showLocalTime: null,
    pickerName: null,
    localTime: null,
    errors: null,
    selectedDatePicker: null,
    hideTimezones: false,
    placeholder: '',
    value: null,
    notBefore: null,
    defaultText: null,
    state: 'blank',

    init: function init() {
      this._super.apply(this, arguments);

      // If we already have a value, then let's use it, rather than being blank.
      if (this.value) {
        if (this.localTime) {
          this.set('state', 'local_time');
        } else {
          this.set('state', 'scheduled');
        }
      }
    },


    isBlank: Ember.computed.equal('state', 'blank'),
    isLocalTime: Ember.computed.equal('state', 'local_time'),
    isScheduled: Ember.computed.equal('state', 'scheduled'),

    isShowingDatePicker: Ember.computed('selectedDatePicker', 'type', function () {
      return this.selectedDatePicker === this.type;
    }),

    date: Ember.computed.alias('value.date'),
    minute: Ember.computed.alias('value.minute'),
    hour: Ember.computed.alias('value.hour'),
    timezone: Ember.computed.alias('value.timezone'),

    // Because `moment-datepicker` doesn't emit an action when switching between
    // localtime and scheduled we have to use changes to this CP to mutate our
    // state.  It's gross, but it's not the grossest.
    sendAtLocalTime: Ember.computed('state', {
      get: function get() {
        return this.isLocalTime;
      },
      set: function set(_, value) {
        if (value) {
          this.setProperties({
            state: 'local_time',
            localTime: true,
            previousPublishAtTimezone: this.get('value.timezone')
          });
          this.set('value.timezone', 'UTC');
        } else {
          var previousPublishAtTimezone = this.get('previousPublishAtTimezone');
          if (Ember.isPresent(previousPublishAtTimezone)) {
            this.set('value.timezone', previousPublishAtTimezone);
          }
          this.setProperties({
            state: 'scheduled',
            localTime: false,
            previousPublishAtTimezone: null
          });
        }
        return value;
      }
    }),

    toggler: function toggler() {
      this.set('selectedDatePicker', this.get('isShowingDatePicker') ? null : this.get('type'));
    },
    timeWithZoneNow: function timeWithZoneNow() {
      var now = (0, _moment.default)();
      var timezone = this.timeZones.findBy('name', _moment.default.tz.guess());
      timezone = timezone ? timezone.value : this.currentUserService.userTimeZone ? this.currentUserService.userTimeZone : 'UTC';

      return _timeWithZone.default.create({
        date: now.format('YYYY-MM-DD'),
        hour: now.hour(),
        minute: now.minute(),
        timezone: timezone
      });
    },


    actions: {
      toggleDatePicker: function toggleDatePicker() {
        if (this.isDestroyed) return;

        if (Ember.isBlank(this.value)) {
          this.setProperties({
            state: 'scheduled',
            value: this.timeWithZoneNow()
          });
        }
        this.toggler();
      },
      removeSelection: function removeSelection() {
        if (this.isDestroyed) return;

        this.setProperties({
          state: 'blank',
          value: null
        });

        this.toggler();
      }
    }
  });
});