define('carnival-frontend/models/facet', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    field: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    values: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });
});