define('carnival-frontend/components/max-height-toggler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['max-height-toggler'],
    classNameBindings: ['minimized'],
    attributeBindings: ['style'],

    minimized: true,
    maxHeight: 200,
    hideToggler: true,

    style: Ember.computed('minimized', function () {
      var value = this.get('minimized') ? this.get('maxHeight') + 'px' : 'none';

      return new Ember.String.htmlSafe('max-height:' + value);
    }),

    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        if (this.$().outerHeight() > this.get('maxHeight')) this.set('hideToggler', false);
      });
    },


    actions: {
      toggleHeight: function toggleHeight() {
        this.toggleProperty('minimized');
      }
    }
  });
});