define("carnival-frontend/components/rembr/message-export-dropdown", ["exports", "carnival-frontend/components/rembr/stui-content-dropdown"], function (exports, _stuiContentDropdown) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stuiContentDropdown.default.extend({
    summaryCsvLink: null,
    detailedCsvLink: null,

    buildContentDropdownChildren: function buildContentDropdownChildren() {
      return React.createElement(
        "div",
        { className: "popup-menu" },
        React.createElement(
          "ul",
          { className: "list-group nav-list" },
          React.createElement(
            "li",
            { className: "list-item" },
            React.createElement(
              "a",
              { href: this.get('summaryCsvLink') },
              "Messages - Summary data"
            )
          ),
          React.createElement(
            "li",
            { className: "list-item" },
            React.createElement(
              "a",
              { href: this.get('detailedCsvLink') },
              "Messages - Detailed data"
            )
          )
        )
      );
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this.reactRender(this.buildContentDropdown());
    }
  });
});