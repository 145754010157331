define('carnival-frontend/routes/messages/duplicate', ['exports', 'carnival-frontend/routes/messages/edit'], function (exports, _edit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _edit.default.extend({
    messageBuilder: Ember.inject.service(),

    transitionedFromDevicePreview: function transitionedFromDevicePreview(transition) {
      return transition.router.currentHandlerInfos.lastObject.name == 'device-preview';
    },
    model: function model(params, transition) {
      var previewModel = this.modelFor('device-preview');
      if (transition && this.transitionedFromDevicePreview(transition) && previewModel) {
        return previewModel;
      }

      var original = this.store.peekRecord('message', params.message_id);

      if (!original) return this.transitionTo('messages');

      return this.get('messageBuilder').duplicateMessage(original);
    }
  });
});