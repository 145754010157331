define('carnival-frontend/helpers/sum-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sumBy = sumBy;


  /*
   * Sum a collection by a named property.
   *
   * First argument: The collection of items to sum.
   * Second argument: The property whose value to sum.
   *
   * Example:
   *     {{sum-by model.notifications 'pushStatusSummary.enabled'}}
   */

  function sumBy(params) {
    var collection = params[0];
    var property = params[1];
    if (!(collection && property)) {
      return 0;
    }

    var total = 0;

    // Note: `Array.prototype.reduce` doesn't work in CI.
    collection.forEach(function (record) {
      return total += Ember.getWithDefault(record, property, 0);
    });

    return total;
  }

  exports.default = Ember.Helper.helper(sumBy);
});