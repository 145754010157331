define('carnival-frontend/models/message', ['exports', 'ember-data', 'carnival-frontend/mixins/messages/image', 'carnival-frontend/mixins/messages/timezones', 'carnival-frontend/mixins/messages/link', 'carnival-frontend/models/model', 'moment', 'carnival-frontend/mixins/messages/text', 'carnival-frontend/mixins/messages/video', 'carnival-frontend/models/time-with-zone', 'carnival-frontend/components/moment-datepicker'], function (exports, _emberData, _image, _timezones, _link, _model, _moment, _text, _video, _timeWithZone, _momentDatepicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_text.default, _image.default, _link.default, _video.default, {
    benchmark: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    deviceCount: _emberData.default.attr('number'), // Audience size at time of publishing message
    html: _emberData.default.attr('string'),
    localTime: _emberData.default.attr('boolean'),
    poster: _emberData.default.attr('string'),
    previousPublishAtTimezone: _emberData.default.attr('string'),
    publishAt: _emberData.default.attr('time-with-zone'),
    publishedAt: _emberData.default.attr('date'),
    pushState: _emberData.default.attr('string'),
    state: _emberData.default.attr('string', { defaultValue: 'draft' }),
    test: _emberData.default.attr('boolean'),
    text: _emberData.default.attr('string'),
    title: _emberData.default.attr('string', { defaultValue: '' }),
    type: _emberData.default.attr('string'),
    unpublishAt: _emberData.default.attr('time-with-zone', {
      defaultValue: function defaultValue() {
        var now = (0, _moment.default)().add(1, 'month');
        var result = _timezones.default.findBy('name', _moment.default.tz.guess());
        var zone = result ? result.value : window.sessionStorage.getItem('currentUserTimezone') ? window.sessionStorage.getItem('currentUserTimezone') : 'UTC';

        return _timeWithZone.default.create({
          date: now.format(_momentDatepicker.stringDateFormat),
          hour: now.hour(),
          minute: now.minute(),
          timezone: zone
        });
      }
    }),

    variationName: _emberData.default.attr('string'),
    variationRatio: _emberData.default.attr('number'),

    abTest: _emberData.default.belongsTo('ab-test'),
    analytics: _emberData.default.belongsTo('message-response', {
      inverse: 'message',
      async: true
    }),
    app: _emberData.default.belongsTo('app', { inverse: 'messages', async: false }),
    notifications: _emberData.default.hasMany('notification', {
      inverse: 'message',
      async: false
    }),
    reactiveAction: _emberData.default.belongsTo('reactive-action', {
      inverse: 'message',
      async: false
    }),
    targetable: _emberData.default.belongsTo('targetable', { polymorphic: true }),

    isDraft: Ember.computed.equal('state', 'draft'),
    isPublished: Ember.computed.equal('state', 'published'),
    isScheduled: Ember.computed.equal('state', 'scheduled'),
    isUnpublished: Ember.computed.equal('state', 'unpublished'),

    isReactiveEntered: Ember.computed.equal('reactiveAction.type', 'entered'),
    isReactiveEnteredInclAudience: Ember.computed.equal('reactiveAction.type', 'entered_incl_audience'),
    isReactiveExited: Ember.computed.equal('reactiveAction.type', 'exited'),
    notReactive: Ember.computed.not('isReactive'),

    isFakeCallType: Ember.computed.equal('shortType', 'fake_call'),
    isImageType: Ember.computed.equal('shortType', 'image'),
    isLinkType: Ember.computed.equal('shortType', 'link'),
    isPushType: Ember.computed.equal('shortType', 'push'),
    isTextType: Ember.computed.equal('shortType', 'text'),
    isVideoType: Ember.computed.equal('shortType', 'video'),

    hasImage: Ember.computed.or('isImageType', 'isLinkType'),
    hasVideo: Ember.computed.or('isVideoType', 'isFakeCallType'),

    isReactive: Ember.computed.or('isReactiveEntered', 'isReactiveExited', 'isReactiveEnteredInclAudience'),
    isScheduledOrReactive: Ember.computed.or('isScheduled', 'isReactive'),
    isValidating: false,
    shouldNotShowResponseGraph: Ember.computed.or('isReactive', 'isScheduled'),

    notification: Ember.computed.alias('notifications.firstObject'),

    hasPush: Ember.computed.bool('notifications.length'),

    isInapp: Ember.computed('title', function () {
      return typeof this.get('title') === 'string';
    }),

    isPushOnly: Ember.computed('hasPush', 'isInapp', function () {
      return this.get('hasPush') && !this.get('isInapp');
    }),

    influencedOpens: Ember.computed('model', function () {
      var store = this.get('store'),
          adapter = store.adapterFor('message'),
          url = adapter.buildURL('message', this.get('id')) + '/influenced_opens';

      return _emberData.default.PromiseArray.create({
        promise: adapter.ajax(url, 'GET')
      });
    }),

    isEditable: Ember.computed('isDraft', 'isScheduled', 'isPushType', 'isReactive', function () {
      if (this.get('isPushType')) {
        return this.get('isDraft') || this.get('isScheduled') || this.get('isReactive');
      } else {
        return !this.get('isUnpublished');
      }
    }),

    custom: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    pushStates: {
      awaiting_scheduling: 'Scheduled',
      in_progress: 'Sending',
      done: 'Complete',
      paused: 'Paused',
      pausing: 'Pausing...',
      expired: 'Expired',
      stuck: 'Complete'
    },

    humanPushState: Ember.computed('pushState', function () {
      return this.pushStates[this.get('pushState')];
    }),

    attributedEventsTotal: Ember.computed('analytics.attributedEvents', function () {
      var events = this.get('analytics.attributedEvents') || [],
          counts = events.map(function (e) {
        return e.count;
      });
      return counts.reduce(function (prev, curr) {
        return prev + curr;
      }, 0);
    }),

    humanAttributes: {
      reactiveAction: 'Behavioral Triggers'
    },

    shortType: Ember.computed('type', function () {
      var type = this.get('type') || 'text_message';

      return type.replace('_message', '');
    }),

    validate: function validate() {
      var _this = this;

      var type = this.constructor,
          adapter = this.store.adapterFor(type.modelName),
          url = adapter.buildURL('message', this.get('id')) + '/validate',
          data = { message: this.serialize({ includeId: true }) };

      delete data.message.asset_url;
      if (data.message.notifications_attributes) {
        data.message.notifications_attributes.forEach(function (n) {
          return delete n.asset_url;
        });
      }

      this.set('isValidating', true);

      return adapter.ajax(url, 'POST', { data: data }).then(function (res) {
        _this.set('isValidating', false);

        return res;
      }).catch(function (error) {
        _this.set('isValidating', false);
        _this.get('errors').clear();

        if (error instanceof _emberData.default.InvalidError) {
          var errorsHash = _emberData.default.errorsArrayToHash(error.errors);
          _this.invalidateModel(errorsHash);
        }

        throw error;
      });
    },
    validateUnpublishAt: function validateUnpublishAt() {
      if (this.get('unpublishAt') && this.get('notReactive') && this.get('isPushType')) this.set('unpublishAt', null);
    },
    pause: function pause() {
      var store = this.get('store'),
          adapter = store.adapterFor('message'),
          url = adapter.buildURL('message', this.get('id')) + '/pause';

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'POST').then(function (json) {
          store.pushPayload('message', { message: json.message || json });
        })
      });
    },
    resume: function resume() {
      var store = this.get('store'),
          adapter = store.adapterFor('message'),
          url = adapter.buildURL('message', this.get('id')) + '/resume';

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'POST').then(function (json) {
          store.pushPayload('message', { message: json.message || json });
        })
      });
    },
    unpublish: function unpublish() {
      var store = this.get('store'),
          adapter = store.adapterFor('message'),
          url = adapter.buildURL('message', this.get('id')) + '/unpublish',
          that = this;

      return adapter.ajax(url, 'POST').then(function () {
        that.set('state', 'unpublished');
        return that;
      });
    },
    uploadImage: function uploadImage(file) {
      var adapter = this.store.adapterFor('application'),
          url = adapter.buildURL() + '/upload_image',
          data = { image: { file: file } };

      return adapter.ajax(url, 'POST', { data: data });
    },
    save: function save() {
      var _this2 = this;

      var promise = this._super.apply(this, arguments);

      // remove duplicates created by DS.EmbeddedRecordsMixin bug
      // https://github.com/emberjs/data/issues/1829
      promise.then(function () {
        _this2.get('notifications').filterBy('isNew').invoke('deleteRecord');

        // Mark audience as targeted (remove unused label)
        var targetableId = _this2.get('targetable.id');
        var audience = _this2.getWithDefault('app.audiences', []).find(function (a) {
          return a.id === targetableId;
        });

        if (audience) {
          audience.set('targeted', true);
        }
      });

      return promise;
    },


    formattedPublishAt: Ember.computed('createdAt', 'publishAt', function () {
      if (this.get('publishAt.formattedValue')) return this.get('publishAt.formattedValue');
      if (!this.get('createdAt')) return '';
      return (0, _moment.default)(this.get('createdAt')).format('MMM D, YYYY HH:mm');
    }),

    unformattedPublishAt: Ember.computed('createdAt', 'publishAt', function () {
      return (0, _moment.default)(this.get('publishAt.utc_time'));
    }),

    sentAt: Ember.computed('createdAt', 'publishAt', function () {
      if (this.get('publishAt.utc_time')) return (0, _moment.default)(this.get('publishAt.utc_time'));
      return (0, _moment.default)(this.get('createdAt'));
    }),

    cardTitle: Ember.computed('title', 'notifications.firstObject.payload', function () {
      if (!this.get('isPushType')) return this.get('title');

      var notification = this.get('notifications.firstObject');
      if (!notification) return '';

      var title = notification.get('payload.title'),
          alert = notification.get('payload.alert'),
          sound = notification.get('payload.sound'),
          badge = notification.get('payload.badge');

      if (!Ember.isEmpty(title)) return title;
      if (!Ember.isEmpty(alert)) return alert;
      if (!Ember.isEmpty(sound)) return 'Sound ' + sound;
      if (!Ember.isEmpty(badge)) return 'Badge ' + badge;

      return '';
    }),

    cardBody: Ember.computed('text', 'notifications.firstObject.payload', function () {
      if (!this.get('isPushType')) return this.get('html');

      var notification = this.get('notifications.firstObject');
      if (!notification) return '';

      if (notification.get('payload.title')) return notification.get('payload.alert');
      return null;
    }),

    startStatusObserverForNextNotification: function startStatusObserverForNextNotification() {
      var pendingNotifications = this.get('notifications').rejectBy('state', 'done');
      if (pendingNotifications.length === 0) return;
      pendingNotifications.get('firstObject').startStatusObserver();
    },
    stopNotificationsStatusObsersever: function stopNotificationsStatusObsersever() {
      this.get('notifications').invoke('stopStatusObserver');
    },


    pushEnabledCount: Ember.computed('notifications.[]', function () {
      return this.get('notifications').mapBy('deviceCount').reduce(function (prev, curr) {
        return prev + curr;
      }, 0);
    }),

    messageKeyFields: Ember.computed('custom', function () {
      var obj = this.get('custom'),
          fields = [];

      for (var key in obj) {
        fields.push(Ember.Object.create({ key: key, value: obj[key] }));
      }

      return fields;
    }),

    // When an error is present and it has a "belongsTo" relationship
    // the error won't go away after setting a new value as it normally would.
    // Manually clear the error to remove the targetable related error message.
    // https://github.com/emberjs/data/issues/1472
    // eslint-disable-next-line ember/no-observers
    targetDidChange: Ember.observer('targetable', function () {
      var errors = this.get('errors');

      if (errors.has('targetable_id')) {
        errors.get('targetable_id').clear();
      }

      // reactive action errors are normally related to targetable
      // e.g. you must target an audience with a reactive action
      if (errors.has('reactive_action')) {
        errors.get('reactive_action').clear();
      }
    }),

    // eslint-disable-next-line ember/no-observers
    reactiveActionDidChange: Ember.observer('reactiveAction.type', 'reactiveAction.maxPushes', 'reactiveAction.interval', 'reactiveAction.delay', function () {
      var errors = this.get('errors');

      if (errors.has('reactive_action')) {
        errors.get('reactive_action').clear();
        // eslint-disable-next-line ember/no-observers
      }
    }),

    // eslint-disable-next-line ember/no-observers
    pushAlertDidChange: Ember.observer('notification.alert', function () {
      var errors = this.get('errors');

      if (errors.has('notifications')) {
        errors.get('notifications').clear();
      }
    }),

    // eslint-disable-next-line ember/no-observers
    inappContentDidChange: Ember.observer('text', 'assetUrl', 'url', 's3FileName', function () {
      var errors = this.get('errors');

      if (errors.has('base')) {
        errors.get('base').clear();
      }
    }),

    // eslint-disable-next-line ember/no-observers
    publishAtDidChange: Ember.observer('publishAt.date', function () {
      var errors = this.get('errors');

      if (errors.has('publish_at')) {
        errors.get('publish_at').clear();
      }
    }),

    // clearing `notification` errors does not seem to update so watch for alert and badge changes...
    hasError: Ember.computed('errors.@each', 'notification.{alert,badge}', function () {
      var errors = this.get('errors');

      return errors.has('notifications') || errors.has('base') || errors.has('title') || errors.has('url');
    }),

    // If a user wants to just send a push but has entered these fields we
    // need to reset them so we don't POST unnecessary when creating a message
    // By default `DS.attr` is `undefined` unless a `defaultValue` is provided
    resetInappProperties: function resetInappProperties() {
      this.set('title', undefined); // text
      this.set('text', undefined); // text
      this.set('html', undefined); // text

      this.set('url', undefined); // link

      this.set('asset', undefined); // image
      this.set('assetUrl', undefined); // image

      this.set('s3FileName', undefined); // video
      this.set('cardMediaUrl', undefined); // video
      this.set('assetDimensions', undefined); // video

      this.set('custom', {}); // custom message attributes hash

      // setting to `undefined` does not seem to trigger bindings to clear errors...
      var errors = this.get('errors');

      if (errors.has('title')) errors.get('title').clear();
      if (errors.has('base')) errors.get('base').clear();
      if (errors.has('url')) errors.get('url').clear();
    },


    appId: Ember.computed('app.id', function () {
      return this.get('app.id');
    })
  });
});