define('carnival-frontend/helpers/humanize-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeFilter = humanizeFilter;
  function humanizeFilter(params, hash) {
    var string = params[0],
        plural = hash.plural;

    if (string.indexOf('custom.') === 0) {
      var parts = string.split('.'),
          type = parts[1],
          name = parts.slice(2).join('.');

      return name + ' (' + type + ')';
    } else if (string.indexOf('events.') === 0) {
      return string.replace('events.', '');
    } else if (string.indexOf('imported.') === 0) {
      return string.replace('imported.', '');
    }

    switch (string) {
      case 'app_version':
        return plural ? 'App Versions' : 'App Version';
      case 'badge':
        return plural ? 'Badges' : 'Badge';
      case 'country':
        return plural ? 'Countries' : 'Country';
      case 'created_at':
        return plural ? 'Installed' : 'Installed';
      case 'device_id':
        return plural ? 'Device IDs' : 'Device ID';
      case 'group':
        return plural ? 'Test Groups' : 'Test Group';
      case 'hardware_version':
        return plural ? 'Hardware Versions' : 'Hardware Version';
      case 'marketing_name':
        return plural ? 'Device Models' : 'Device Model';
      case 'locale':
        return plural ? 'Locales' : 'Locale';
      case 'os_name':
        return plural ? 'OS Names' : 'OS Name';
      case 'os_version':
        return plural ? 'OS Versions' : 'OS Version';
      case 'registered_at':
        return plural ? 'Last Opened' : 'Last Opened';
      case 'sdk_version':
        return plural ? 'SDK Versions' : 'SDK Version';
      case 'tags':
        return plural ? 'Tags' : 'Tags';
      case 'time_zone':
        return plural ? 'Time Zones' : 'Time Zone';
      case 'user_id':
        return plural ? 'User IDs' : 'User ID';
      case 'platform':
        return plural ? 'Platforms' : 'Platform';
      case 'notifications_allowed':
        return 'Push Status';
      case 'sailthru_lists':
        return plural ? 'Sailthru Lists' : 'Sailthru List';
      case 'alias':
        return plural ? 'Device Aliases' : 'Device Alias';
      case 'language':
        return plural ? 'Languages' : 'Language';
      case 'development':
        return plural ? 'Development Mode' : 'Development Mode';
      default:
        string = string.replace('_', ' ').capitalize();
        return plural ? string + 's' : string;
    }
  }

  exports.default = Ember.Helper.helper(humanizeFilter);
});