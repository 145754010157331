define('carnival-frontend/helpers/duration-from-secs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.durationFromSecs = durationFromSecs;
  function durationFromSecs(params, hash) {
    if (!hash) hash = {};

    var seconds = params[0] || 0,
        max_words = typeof hash.max_words !== 'undefined' ? hash.max_words : 2;
    // parseInt takes a second argument, the base of the number system
    seconds = parseInt(seconds, 10);
    if (seconds === 0) return '0 seconds';
    function pluralization(str) {
      if (str[0] === '0') {
        return '';
      }
      if (!(str[0] === '1' && str[1] === ' ')) {
        return str + 's';
      }
      return str;
    }
    var date = {};
    var seconds_per_year = 60 * 60 * 24 * 365;
    var seconds_per_month = 60 * 60 * 24 * 30;
    var seconds_per_day = 60 * 60 * 24;
    var seconds_per_hour = 60 * 60;
    var seconds_per_minute = 60;
    date.year = Math.floor(seconds / seconds_per_year);
    seconds -= seconds_per_year * date.year;
    date.month = Math.floor(seconds / seconds_per_month);
    seconds -= seconds_per_month * date.month;
    date.day = Math.floor(seconds / seconds_per_day);
    seconds -= seconds_per_day * date.day;
    date.hour = Math.floor(seconds / seconds_per_hour);
    seconds -= seconds_per_hour * date.hour;
    date.min = Math.floor(seconds / seconds_per_minute);
    seconds -= seconds_per_minute * date.min;
    date.sec = seconds;
    var str = '';
    var length = 0;
    for (var key in date) {
      if (date[key] > 0 && length < max_words) {
        str += pluralization(date[key] + ' ' + key) + ' ';
        length++;
      }
    }
    return str.trim();
  }

  exports.default = Ember.Helper.helper(durationFromSecs);
});