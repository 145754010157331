define('carnival-frontend/adapters/message', ['exports', 'carnival-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var options = snapshot.adapterOptions;
      if (options && options.draft) {
        return this._super.apply(this, arguments) + '/draft';
      }
      return this._super.apply(this, arguments);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var options = snapshot.adapterOptions;
      if (options && options.draft) {
        return this._super.apply(this, arguments) + '/draft';
      }
      return this._super.apply(this, arguments);
    }
  });
});