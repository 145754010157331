define('carnival-frontend/components/form-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    onSubmit: null,
    onError: null,
    onReset: null,
    fields: new Set([]),

    registerField: function registerField(field) {
      var fields = this.fields;


      if (field) {
        fields.add(field);
      }
    },
    unregisterField: function unregisterField(field) {
      var fields = this.fields;


      if (field) {
        fields.delete(field);
      }
    },


    actions: {
      // when reset action is called, reset action is called on all children
      reset: function reset() {
        var fields = this.fields,
            onReset = this.onReset;


        Promise.all(Array.from(fields).map(function (field) {
          if (field.isDestroyed) {
            return null;
          }
          return field.reset();
        })).then(onReset);
      },


      // when submit action is called on this form, beforeSubmit of each field is going to be called
      //   if any field fail the form onError property is going to be called
      //   if all fields succeed the form onSuccess is called
      submit: function submit() {
        var fields = this.fields,
            onError = this.onError,
            onSubmit = this.onSubmit;


        return Promise.all(Array.from(fields).map(function (field) {
          return field.submit();
        })).then(function () {
          if (onSubmit) {
            onSubmit();
          }
        }, function (error) {
          if (onError) {
            onError(error);
          }
        });
      }
    }
  });
});