define('carnival-frontend/controllers/audiences/import/locations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    app: Ember.computed.oneWay('currentAppService.app'),
    fileIsValid: null,
    jobCreated: false,
    isSubmitting: null,
    filename: null,

    notSubmitting: Ember.computed.not('isSubmitting'),
    dismissOnly: Ember.computed.or('jobCreated', 'errorOccurred'),
    enableUpload: Ember.computed.and('fileIsValid', 'notSubmitting'),

    actions: {
      loadPresignedForm: function loadPresignedForm() {
        return this.app.getImportLocationsSign();
      },
      error: function error() {
        this.set('errorOccurred', true);
        this.set('isSubmitting', false);
      },
      trackSubmission: function trackSubmission() {
        this.set('isSubmitting', true);
      },
      submit: function submit() {
        var _this = this;

        var app = this.app,
            filename = this.filename;


        this.set('isSubmitting', false); // Transmission has completed

        return app.sendImportLocationsJob(filename).then(function () {
          _this.set('jobCreated', true);
        }, function () {
          _this.send('error');
        });
      },
      cancel: function cancel() {
        this.setProperties({
          errorOccurred: false,
          jobCreated: false
        });
        this.send('closeModal');
      }
    }
  });
});