define('carnival-frontend/controllers/settings/developer/advanced/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    newKey: '',

    actions: {
      cancel: function cancel() {
        this.set('newKey', '');
        this.send('closeModal');
      },

      save: function save() {
        var _this = this;

        var model = this.get('model');

        model.get('messageAttributeKeys').addObject(this.get('newKey'));
        model.save().then(function () {
          _this.set('newKey', '');
          _this.send('closeModal');
        }, function () {
          _this.send('closeModal');
        });
      }
    }
  });
});