define('carnival-frontend/components/audiences/audience-form', ['exports', 'carnival-frontend/mixins/audiences/filters', 'carnival-frontend/helpers/format-percentage-string', 'carnival-frontend/helpers/format-num', 'carnival-frontend/helpers/pluralize-string'], function (exports, _filters, _formatPercentageString, _formatNum, _pluralizeString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_filters.default, {
    facets: ['app_version', 'badge', 'country', 'created_at', 'device_id', 'group', 'marketing_name', 'locale', 'os_name', 'os_version', 'registered_at', 'sdk_version', 'tags', 'time_zone', 'user_id', 'user_email', 'platform', 'notifications_allowed', 'sailthru_lists', 'alias', 'language', 'development'],

    otherFacets: ['geo', 'custom', 'event', 'imported'],

    init: function init() {
      this._super();

      if (this.get('audience.parentAudience.test')) this.set('audience.test', true);
    },


    showGeoFacet: Ember.computed.equal('selectedFacetFilter', 'geo'),
    showCustomFacet: Ember.computed.equal('selectedFacetFilter', 'custom'),
    showEventFacet: Ember.computed.equal('selectedFacetFilter', 'event'),
    showImportedFacet: Ember.computed.equal('selectedFacetFilter', 'imported'),

    hideNoFiltersMsg: Ember.computed.or('audience.{selectedFilters.length,hasGeoFilters}'),
    showNoResultsMsg: Ember.computed.equal('audience.deviceCount.content', 0),

    geoFeatureCount: Ember.computed('audience.geo.{points.length,polygons.length}', function () {
      return this.get('audience.geo.points.length') + this.get('audience.geo.polygons.length');
    }),

    moreThanFiftyDevices: Ember.computed.gt('audience.deviceCount.content', 50),
    saveButtonIsDisabled: Ember.computed.and('audience.test', 'moreThanFiftyDevices'),

    testAudienceDisabled: Ember.computed.oneWay('audience.parentAudience.test'),

    facetTemplate: Ember.computed('selectedFacetFilter', function () {
      var facet = this.get('selectedFacetFilter'),
          template = 'static';

      if (this.get('otherFacets').indexOf(facet) > -1) template = facet;

      return 'audiences/facets/' + template;
    }),

    statistics: Ember.computed('audience.deviceCount.content', function () {
      var audience = this.get('audience');
      var deviceCount = audience.get('deviceCount.content');
      var pushEnabledCount = audience.get('pushEnabledCount');
      var pushQuietCount = audience.get('pushQuietCount');
      var formattedDeviceCount = (0, _formatNum.formatNum)([deviceCount]);
      var deviceInflection = (0, _pluralizeString.pluralizeString)([deviceCount], {
        singular: 'device'
      });

      return {
        total: {
          title: 'This ' + (this.get('hideNoFiltersMsg') ? 'filtered' : '') + ' audience targets',
          description: deviceInflection,
          formattedValue: formattedDeviceCount
        },
        pushEnabled: {
          description: 'out of ' + formattedDeviceCount + ' ' + deviceInflection,
          percentage: (0, _formatPercentageString.formatPercentString)([pushEnabledCount, deviceCount])
        },
        quiet: {
          description: 'out of ' + (0, _formatNum.formatNum)([pushEnabledCount]) + ' ' + (0, _pluralizeString.pluralizeString)([pushQuietCount], { singular: 'device' }),
          percentage: (0, _formatPercentageString.formatPercentString)([pushQuietCount, pushEnabledCount])
        }
      };
    }),

    // Updates app device count if audience becomes larger
    // e.g. new devices registered while audience modal is open
    // eslint-disable-next-line ember/no-observers
    updateTotalDeviceCount: Ember.observer('audience.deviceCount.content', function () {
      var audience = this.get('audience'),
          deviceCount = audience.get('deviceCount.content');

      if (!deviceCount) return;

      var parent = audience.get('parentAudience') || audience.get('app'),
          totalDeviceCount = parent.get('deviceCount.content') || 0;

      if (totalDeviceCount < deviceCount) {
        parent.notifyPropertyChange('deviceCount');
      }
    }),

    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      save: function save() {
        this.sendAction('save');
      },
      selectOther: function selectOther(otherFacet) {
        this.set('selectedFacetFilter', otherFacet);
      },
      clearGeo: function clearGeo(audience) {
        audience.set('geo', { points: [], polygons: [] });
      },
      removeFilter: function removeFilter(filter) {
        filter.set('criteria', []);
      },
      selectFacetFilter: function selectFacetFilter(facetFilter) {
        this.updateSelectedFacetFilter(facetFilter);
      }
    }
  });
});