define('carnival-frontend/components/file-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['file-selector'],
    filename: '',
    file: null,
    acceptedFiles: '',
    nothingSelectedText: 'No file chosen',

    displayFilename: Ember.computed('filename', function () {
      return this.get('filename') || this.get('nothingSelectedText');
    }),

    change: function change(e) {
      var file = e.target.files[0];

      if (file) {
        this.set('file', file);
      } else {
        this.set('file', null);
      }
    },


    // eslint-disable-next-line ember/no-observers
    fileChanged: Ember.observer('file', function () {
      var file = this.get('file');

      if (file) {
        this.set('filename', file.name);
      } else {
        this.set('filename', '');
        this.$('input[type="file"]').val('');
      }
    }),

    actions: {
      selectFile: function selectFile() {
        this.$("input[type='file']").click();
      },
      deselectFile: function deselectFile() {
        this.set('file', null);
      }
    }
  });
});