define('carnival-frontend/components/alert-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['alert-box'],
    messages: [],
    incommingMessage: '',

    // eslint-disable-next-line ember/no-observers
    alertMessageChangedObserver: Ember.observer('incommingMessage', function () {
      this.get('messages').addObject(this.get('incommingMessage'));
    }),

    actions: {
      removeMessage: function removeMessage(message) {
        this.get('messages').removeObject(message);
      }
    }
  });
});