define('carnival-frontend/models/api-client', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: _emberData.default.attr('string'),
    ipAddress: _emberData.default.attr('string'),
    apiKey: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    pushAccess: _emberData.default.attr('boolean'),
    analyticsAccess: _emberData.default.attr('boolean'),
    interfaceable: _emberData.default.belongsTo('app', { inverse: 'apiClients' })
  });
});