define('carnival-frontend/components/messages/composer-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['composer-message'],

    attributeBindings: ['id'],

    id: Ember.computed('model.variationName', function () {
      return 'composer-message-' + this.get('model.variationName');
    }),

    actions: {
      // propagate action from child components
      openModal: function openModal(modalName, model, controller) {
        this.sendAction('openModal', modalName, model, controller);
      }
    }
  });
});