define('carnival-frontend/serializers/message', ['exports', 'ember-data', 'carnival-frontend/serializers/application'], function (exports, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    root: 'message',
    unserializedAttributes: ['createdAt', 'updatedAt', 'html', 'cardMediaUrl', 'deviceCount', 'poster'],
    attrs: {
      notifications: { embedded: 'always' },
      reactiveAction: { embedded: 'always' }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.message_content = [];

      if (json.text) json.message_content.push('text');
      if (typeof json.url === 'string') json.message_content.push('link');
      if (json.asset_url) json.message_content.push('image');
      if (json.s3_file_name) json.message_content.push('video');
      if (json.notifications_attributes.length) json.message_content.push('push');else json.notifications_attributes = null;

      if (json.benchmark != true) {
        delete json.benchmark;
      }

      return json;
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'multimedia-message') {
        return this._super('message');
      } else {
        return this._super(payloadKey);
      }
    },
    serializePolymorphicType: function serializePolymorphicType(snapshot, json, relationship) {
      var key = relationship.key,
          belongsTo = snapshot.belongsTo(key);

      if (belongsTo) {
        json[key] = {
          id: json[key + '_id'],
          type: belongsTo.modelName
        };

        delete json[key + '_id'];
      }
    },
    keyForAttribute: function keyForAttribute(key, method) {
      key = this._super(key, method);

      if (method === 'serialize') {
        switch (key) {
          case 'notifications':
            return 'notifications_attributes';
          case 'publish_at':
            return 'publish_at_attributes';
          case 'unpublish_at':
            return 'unpublish_at_attributes';
        }
      }

      return key;
    }
  });
});