define('carnival-frontend/controllers/invitations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sendButtonDisabled: Ember.computed('model.{hasAcceptedTerms,isSaving}', function () {
      return !this.get('model.hasAcceptedTerms') || this.get('model.isSaving');
    }),

    actions: {
      createAccount: function createAccount() {
        var _this = this;

        var model = this.get('model');

        model.get('errors').clear();

        model.save().then(function () {
          _this.alert.success('Thanks for registering.', 'Please log in to continue.');
          _this.transitionToRoute('login');
        });
      }
    }
  });
});