define('carnival-frontend/components/popup-date-range', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    from: null,
    to: null,
    fromDate: null,
    toDate: null,

    init: function init() {
      this._super();

      this.set('fromDate', this.get('from') ? (0, _moment.default)(this.get('from')) : null);
      this.set('toDate', this.get('to') ? (0, _moment.default)(this.get('to')) : null);
    },


    actions: {
      apply: function apply() {
        this.set('from', this.get('fromDate') ? this.get('fromDate').startOf('day').toISOString() : null);
        this.set('to', this.get('toDate') ? this.get('toDate').endOf('day').toISOString() : null);
      }
    }
  });
});