define('carnival-frontend/components/audiences/filter-criteria/range-criteria', ['exports', 'moment', 'carnival-frontend/mixins/audiences/es-ranges'], function (exports, _moment, _esRanges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_esRanges.default, {
    classNames: ['multi-range-criteria-item'],
    filter: null,

    isEqual: Ember.computed.equal('equality', 'equal'),
    isLess: Ember.computed.equal('equality', 'less'),
    isMore: Ember.computed.equal('equality', 'more'),
    isBetween: Ember.computed.equal('equality', 'between'),
    isBetweenDates: Ember.computed.equal('equality', 'between-dates'),

    keyName: 'custom_date',
    equality: 'none',
    value: '',
    dataType: 'integer',

    clearFilterOnEqualityChange: function clearFilterOnEqualityChange() {
      this.updateFilter();
    },
    getRange: function getRange() {
      var criteria = this.get('filter.criteria');

      if (Ember.isPresent(criteria)) {
        var keyName = this.get('keyName');

        // find the range type
        return criteria.find(function (item) {
          // new
          if (item.range_type === keyName) {
            return item;
          }

          // old
          if (typeof item.range_type === 'undefined') {
            return item.set('range_type', keyName);
          }
        });
      }
    },


    filterDidChange: Ember.on('init',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('filter', function () {
      var range = this.getRange(),

      // eslint-disable-next-line ember/no-observers
      equality = this.getEquality(range);

      this.removeObserver('equality', this, this.clearFilterOnEqualityChange);
      this.set('equality', equality);

      if (range && !this.get('isBetween')) {
        this.set('value', this.getCountFromRange(range));
      }

      this.addObserver('equality', this, this.clearFilterOnEqualityChange);
    })),

    // eslint-disable-next-line ember/no-observers
    clearInputOnFilterDeletion: Ember.observer('filter.criteria', function () {
      var criteria = this.get('filter.criteria');

      if (this.get('value') !== '' && criteria && !criteria.findBy('range_type', this.get('keyName'))) {
        this.set('value', '');
      }
    }),

    getDateRange: function getDateRange(count) {
      var equality = this.get('equality'),
          keyName = this.get('keyName'),
          range = Ember.Object.create({}),
          gte = '',
          lte = '';

      if (['equal', 'less', 'more'].includes(equality)) {
        count = parseInt(count);
      }

      switch (equality) {
        case 'none':
          return range;
        case 'equal':
          gte = this.getDateRangeFmt(count + 1);
          lte = this.getDateRangeFmt(count);
          break;
        case 'less':
          gte = this.getDateRangeFmt(count);
          lte = this.getDateRangeFmt('now');
          break;
        case 'more':
          gte = this.getDateRangeFmt('');
          lte = this.getDateRangeFmt(count);
          break;
        case 'between':
          var fromTo = count.split('-'),
              a = parseInt(fromTo[0]),
              b = parseInt(fromTo[1]);

          gte = this.getDateRangeFmt(b);
          lte = this.getDateRangeFmt(a);

          range.set('from', a);
          range.set('to', b);

          break;
        case 'between-dates':
          {
            var _count$split = count.split('-'),
                _count$split2 = _slicedToArray(_count$split, 2),
                _a = _count$split2[0],
                _b = _count$split2[1];

            var _ref = [(0, _moment.default)(parseInt(_a)).startOf('day'), (0, _moment.default)(parseInt(_b)).endOf('day')],
                from = _ref[0],
                to = _ref[1];


            gte = from.toISOString();
            lte = to.toISOString();

            range.set('from', from);
            range.set('to', to);

            break;
          }
      }

      range.setProperties({
        range_type: keyName,
        equality: equality,
        count: count,
        gte: gte,
        lte: lte
      });

      return range;
    },
    getNumericRange: function getNumericRange(count) {
      var equality = this.get('equality'),
          keyName = this.get('keyName'),
          dataType = this.get('dataType'),
          range = Ember.Object.create({});

      if (dataType === 'integer' && equality !== 'between') count = parseInt(count);

      switch (equality) {
        case 'none':
          return range;
        case 'equal':
          range.setProperties({ gte: count, lte: count });
          break;
        case 'less':
          range.setProperties({ gt: '', lt: count });
          break;
        case 'more':
          range.setProperties({ gt: count, lt: '' });
          break;
        case 'between':
          var fromTo = count.split('-'),
              a = dataType === 'integer' ? parseInt(fromTo[0]) : parseFloat(fromTo[0]),
              b = dataType === 'integer' ? parseInt(fromTo[1]) : parseFloat(fromTo[1]);

          range.setProperties({ gte: a, lte: b, from: a, to: b });
          break;
      }

      range.setProperties({
        range_type: keyName,
        equality: equality,
        count: count
      });

      return range;
    },
    getDateRangeFmt: function getDateRangeFmt(value) {
      if (!value && value !== 0) return '';
      return value === 'now' ? value : 'now-' + value + 'd';
    },
    updateFilter: function updateFilter(range) {
      var criteria = this.get('filter.criteria'),
          rejectedArray = criteria.rejectBy('range_type', this.get('keyName')),
          copiedCriteria = Ember.copy(rejectedArray);

      if (range) {
        copiedCriteria.addObject(range);
      }

      this.set('filter.criteria', copiedCriteria);
    },


    actions: {
      clearFilter: function clearFilter() {
        this.updateFilter();
      },
      setFilter: function setFilter(count) {
        var range = this.get('dataType') === 'date' ? 'getDateRange' : 'getNumericRange';

        this.updateFilter(this[range](count));
      }
    }
  });
});