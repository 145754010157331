define('carnival-frontend/routes/admin/apps/stats', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    renderTemplate: function renderTemplate() {
      this.render('admin.apps.stats', { into: 'application', outlet: 'modal' });
    },


    actions: {
      cancel: function cancel() {
        this.send('closeModal');
      }
    }
  });
});