define('carnival-frontend/components/rembr/concrete-component', ['exports', 'carnival-frontend/components/rembr/abstract-component', 'carnival-frontend/components/rembr/react-component'], function (exports, _abstractComponent, _reactComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractComponent.default.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.reactRender(React.createElement(_reactComponent.default, this.attrs));
    }
  });
});