define('carnival-frontend/components/audiences/filter-criteria/date-range-criteria', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['date-range-criteria'],
    filter: null,
    from: '',
    to: '',
    keyName: 'custom_date',

    filterDidChange: Ember.on('init',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('filter', function () {
      var criteria = this.get('filter.criteria');

      if (Ember.isPresent(criteria)) {
        var range = criteria.findBy('range_type', this.get('keyName'));

        if (range) {
          var _ref = [range.get('from'), range.get('to')],
              a = _ref[0],
              b = _ref[1];


          if (a && b) {
            this.setProperties({
              from: (0, _moment.default)(a),
              to: (0, _moment.default)(b)
            });
          }
        }
      }
    })),

    // eslint-disable-next-line ember/no-observers
    clearInputOnFilterDeletion: Ember.observer('filter.criteria', function () {
      var criteria = this.get('filter.criteria');
      var criteriaIsAnotherType = criteria && !criteria.findBy('range_type', this.get('keyName'));
      var fromAndToAreFilled = this.get('from') !== '' && this.get('to') !== '';

      if (criteriaIsAnotherType && fromAndToAreFilled) {
        this.setProperties({
          from: '',
          to: ''
        });
      }
    }),

    actions: {
      updateFilter: function updateFilter() {
        var _ref2 = [this.get('from'), this.get('to')],
            a = _ref2[0],
            b = _ref2[1];


        if (a !== '' && b !== '') {
          var count = (0, _moment.default)(a).valueOf() + '-' + (0, _moment.default)(b).valueOf();
          this.sendAction('updateFilter', count, 'between-dates');
        }
      }
    }
  });
});