define('carnival-frontend/components/apps-menu', ['exports', 'carnival-frontend/components/popup-revealer'], function (exports, _popupRevealer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _popupRevealer.default.extend({
    elementId: 'apps-menu',
    searchText: '',
    appIndex: -1, // Starting this at minus one so nothing is selected in the 0..n array
    keyboardSelected: null,
    indexUntilScrollApps: { down: 3, up: 4 },
    keyboardControls: {
      upArrow: 38,
      downArrow: 40,
      enterKey: 13,
      escapeKey: 27
    },

    filteredApps: Ember.computed('apps.@each.isNew', 'searchText', function () {
      var apps = this.get('apps').filterBy('isNew', false),
          searchText = this.get('searchText').toLowerCase(),
          filteredApps = [];

      if (this.get('searchText')) {
        filteredApps = apps.filter(function (el) {
          if (typeof el.get('name') === 'undefined') return -1;
          var name = el.get('name').toLowerCase();
          return name.indexOf(searchText) > -1;
        });
      } else {
        filteredApps = apps;
      }

      return filteredApps.sortBy('name');
    }),

    shouldShowFilter: Ember.computed('searchText', function () {
      if (this.get('filteredApps.length') < this.get('apps.length') && this.get('filteredApps.length') > 0) {
        return true;
      } else {
        return false;
      }
    }),

    // eslint-disable-next-line ember/no-observers
    isOpenDidChange: Ember.observer('isOpen', function () {
      if (this.get('isOpen')) {
        Ember.run.later(this, function () {
          this.$('#app-search').focus();
        });
      }
    }),

    actions: {
      createApp: function createApp() {
        this.sendAction('createApp', 'apps/create', 'app');
      }
    },

    keyUp: function keyUp(e) {
      var i = this.get('appIndex'),
          keys = this.get('keyboardControls');

      if (e.keyCode === keys.downArrow && i < this.get('filteredApps').length - 1) {
        this.incrementProperty('appIndex');
      } else if (e.keyCode === keys.upArrow && i > 0) {
        this.decrementProperty('appIndex');
      } else if (e.keyCode === keys.enterKey) {
        this.sendAction('goToApp', this.get('keyboardSelected'));
        this.set('isOpen', false);
      } else if (e.keyCode === keys.escapeKey) {
        this.set('isOpen', false);
      }

      this.set('keyboardSelected', this.get('filteredApps').get(this.get('appIndex')));

      Ember.run.later(this, function () {
        var appIdx = this.get('appIndex'),
            idxUntilScroll = this.get('indexUntilScrollApps');

        if (appIdx > idxUntilScroll.down && e.keyCode === keys.downArrow) {
          var el = this.$('.list-item')[appIdx - idxUntilScroll.down];
          el.scrollIntoView(true);
        } else if (appIdx < this.get('filteredApps').length - idxUntilScroll.up && e.keyCode === keys.upArrow) {
          var elTwo = this.$('.list-item')[appIdx];
          elTwo.scrollIntoView(true);
        }
      });
    }
  });
});