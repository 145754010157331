define('carnival-frontend/helpers/portfolio-map-plotter', ['exports', 'carnival-frontend/helpers/portfolio-map-loader'], function (exports, _portfolioMapLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.Evented, {
    maxWidth: 0,
    cropTop: 0,
    cropBottom: 0.3,

    // async data loader
    _bitmap: Ember.computed(function () {
      return _portfolioMapLoader.default.create({ src: '/assets/images/world-map200.png' });
    }),

    data: Ember.computed('_bitmap.data', function () {
      var height = this.get('_bitmap.height');

      if (!height) return;

      var srt = Math.floor(height * this.get('cropTop')),
          end = Math.floor(height * (1 - this.get('cropBottom')));

      return this.get('_bitmap.data').slice(srt, end);
    }),

    // set width divisible by bitmap width
    // need to avoid antialiasing artifacts
    width: Ember.computed('maxWidth', '_bitmap.width', function () {
      var width = this.get('maxWidth') - this.get('maxWidth') % this.get('_bitmap.width');
      if (width < this.get('_bitmap.width')) width = this.get('_bitmap.width');
      return width;
    }),

    // set height based on width and aspect ratio
    height: Ember.computed('width', '_bitmap.{height,width}', 'cropTop', 'cropBottom', function () {
      var bitmapRenderedHeight = this.get('_bitmap.height') * (1 - this.get('cropTop') - this.get('cropBottom'));
      return this.get('width') * bitmapRenderedHeight / this.get('_bitmap.width');
    }),

    maxHeight: Ember.computed('maxWidth', 'width', 'height', function () {
      if (!this.get('width')) return 616;
      if (!this.get('height')) return 616;
      if (!this.get('maxWidth')) return 616;
      return Math.floor(this.get('height') * this.get('maxWidth') / this.get('width'));
    }),

    horizontalPadding: Ember.computed('width', 'maxWidth', function () {
      if (!this.get('width')) return 0;
      return Math.floor((this.get('maxWidth') - this.get('width')) / 2);
    }),

    verticalPadding: Ember.computed('height', 'maxHeight', function () {
      if (!this.get('height')) return 0;
      return Math.floor((this.get('maxHeight') - this.get('height')) / 2);
    }),

    _xScale: Ember.computed('_bitmap.width', 'width', 'horizontalPadding', function () {
      return d3.scaleLinear().domain([0, this.get('_bitmap.width')]).rangeRound([this.get('horizontalPadding'), this.get('width') + this.get('horizontalPadding')]);
    }),

    _yScale: Ember.computed('_bitmap.height', 'height', 'cropTop', 'cropBottom', 'verticalPadding', function () {
      var min = this.get('_bitmap.height') * this.get('cropTop');
      var max = this.get('_bitmap.height') * (1 - this.get('cropBottom'));
      return d3.scaleLinear().domain([min, max]).rangeRound([this.get('verticalPadding'), this.get('height') + this.get('verticalPadding')]);
    }),

    interval: Ember.computed('_xScale', '_yScale', function () {
      return this.get('_xScale')(1) - this.get('_xScale')(0);
    }),

    mapPoints: Ember.computed('data', '_xScale', '_yScale', function () {
      var data = this.get('data');

      if (!data) return;

      var points = [],
          xScale = this.get('_xScale'),
          yScale = this.get('_yScale');

      data.forEach(function (row, y) {
        row.forEach(function (value, x) {
          points.push([xScale(x), yScale(y)]);
        });
      });

      return points;
    }),

    // pos = [lon, lat]
    positionFromGeo: function positionFromGeo(pos) {
      var w = this.get('_bitmap.width'),
          h = this.get('_bitmap.height');

      var projection = d3.geoMercator().scale((w + 1) / 2 / Math.PI).translate([w / 2, h / 2]);

      var coords = projection(pos),
          x = this.get('_xScale')(Math.round(coords[0])),
          y = this.get('_yScale')(Math.round(coords[1]));

      return [x, y];
    }
  });
});