define('carnival-frontend/services/time-zones', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.all.then) {
        this.all.then(function () {
          return _this.set('isLoading', false);
        });
      }
    },


    currentUser: Ember.inject.service('current-user'),
    all: Ember.computed.alias('currentUser.user.timeZones'),
    isLoading: true,

    findBy: function findBy(key, value) {
      return this.getWithDefault('all', []).findBy(key, value);
    }
  });
});