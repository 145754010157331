define('carnival-frontend/routes/messages/new', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      automated: { refreshModel: true }
    },

    messageBuilder: Ember.inject.service(),

    model: function model(params) {
      var message = this.get('messageBuilder').originalMessage(params.originalMessage);

      return message || this.get('messageBuilder').newMessage({ app: this.modelFor('app') }, params.automated);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          automated: false,
          state: 'authoring'
        });
      }
    },


    actions: {
      saveSuccess: function saveSuccess(model) {
        this.transitionTo('messages', {
          queryParams: {
            state: model.get('state'),
            triggered: !!model.get('reactiveAction'),
            push: !model.get('isInapp')
          }
        });
        this.send('refresh');
      },
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model');

        // if we are transitioning to new ab-test route don't rollback message so we can use in ab-test
        if (transition.targetName !== 'messages.ab-tests.new' && transition.targetName !== 'device-preview') {
          this.get('messageBuilder').rollbackMessage(model);
        }
      }
    }
  });
});