define('carnival-frontend/components/popup-revealer', ['exports', 'carnival-frontend/components/content-revealer', 'carnival-frontend/mixins/reveal'], function (exports, _contentRevealer, _reveal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _contentRevealer.default.extend(_reveal.default, {
    classNames: ['reveal-popup'],

    arrowClass: 'arrow',

    offset: 6,
    popupAlign: 'bottom',
    arrowAlign: 'right',

    toggleClassNames: 'btn btn-glyphicon glyphicon glyphicon-settings glyphicon-xs',
    contentClassNames: 'popup-menu',

    text: '',

    xPos: null,
    yPos: null,

    popupPosition: Ember.computed('xPos', 'yPos', function () {
      var x = this.get('xPos'),
          y = this.get('yPos');

      return new Ember.String.htmlSafe('left:' + x + 'px;top:' + y + 'px;');
    }),

    init: function init() {
      this._super();
      this.addObserver('isOpen', this, this.setupAfterRender);
    },
    setupAfterRender: function setupAfterRender() {
      if (this.get('isOpen')) {
        Ember.run.schedule('afterRender', this, this.calculatePopupPosition);
      }
    },
    calculatePopupPosition: function calculatePopupPosition() {
      var toggle = this.$('.' + this.toggleClassName),
          toggleWidth = toggle.outerWidth(),
          toggleHeight = toggle.outerHeight(),
          content = this.$('.' + this.contentClassName),
          contentWidth = content.outerWidth(),
          contentHeight = content.outerHeight(),
          arrow = this.$('.' + this.contentClassName + ' > .' + this.get('arrowClass')),
          arrowOffset = 0,
          arrowWidth = arrow.outerWidth(),
          arrowHeight = arrow.outerHeight(),
          offset = this.get('offset'),
          a = this.get('popupAlign'),
          b = this.get('arrowAlign'),
          xPos = 0,
          yPos = 0;

      if (a === 'top' || a === 'bottom') {
        if (a === 'top') {
          yPos = -(contentHeight + offset);
        } else if (a === 'bottom') {
          yPos = toggleHeight + offset;
        }

        if (b === 'center') {
          xPos = -((contentWidth - toggleWidth) / 2);
        } else if (b === 'left') {
          xPos = 0;

          if (toggleWidth < contentWidth) {
            arrowOffset = arrow.position().left;
            xPos -= arrowOffset + (arrowWidth / 2 - toggleWidth / 2);
          }
        } else if (b === 'right') {
          xPos = toggleWidth - contentWidth;

          if (toggleWidth < contentWidth) {
            arrowOffset = contentWidth - arrow.position().left - arrowWidth;
            xPos += arrowOffset + (arrowWidth / 2 - toggleWidth / 2);
          }
        }
      } else if (a === 'left' || a === 'right') {
        if (a === 'left') {
          xPos = -(contentWidth + offset);
        } else if (a === 'right') {
          xPos = toggleWidth + offset;
        }

        if (b === 'center') {
          yPos = -((contentHeight - toggleHeight) / 2);
        } else if (b === 'top') {
          yPos = 0;

          if (toggleHeight < contentHeight) {
            arrowOffset = arrow.position().top;
            yPos -= arrowOffset + (arrowHeight / 2 - toggleHeight / 2);
          }
        } else if (b === 'bottom') {
          yPos = toggleHeight - contentHeight;

          if (toggleHeight < contentHeight) {
            arrowOffset = contentHeight - arrow.position().top - arrowHeight;
            yPos += arrowOffset + (arrowHeight / 2 - toggleHeight / 2);
          }
        }
      }

      this.setProperties({
        xPos: xPos,
        yPos: yPos
      });
    },


    actions: {
      toggleReveal: function toggleReveal() {
        this.closeOnClickAway();
      }
    }
  });
});