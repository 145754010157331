define('carnival-frontend/helpers/glyph-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.glyphIcon = glyphIcon;
  function glyphIcon(params) {
    return new Ember.String.htmlSafe('<span class="glyphicon ' + params[0] + '"></span>');
  }

  exports.default = Ember.Helper.helper(glyphIcon);
});