define('carnival-frontend/routes/apps/portfolio', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    beforeModel: function beforeModel() {
      this.get('currentAppService').updateCurrentApp(null);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        breakdowns: this.store.findAll('apps-stats-breakdown'),
        performances: this.store.findAll('apps-stats-performance')
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this._super(controller, model);

      this.render('platform-switcher', {
        outlet: 'platform-switcher'
      });
    }
  });
});