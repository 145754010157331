define('carnival-frontend/controllers/admin/history-trackers', ['exports', 'carnival-frontend/controllers/admin/paginated'], function (exports, _paginated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paginated.default.extend({
    queryParams: ['page', 'per', 'assoc_id'],

    page: 1,
    per: 30,
    assoc_id: '',

    actions: {
      details: function details(history_tracker_id) {
        this.transitionToRoute('admin.history-trackers.history-tracker', history_tracker_id);
      }
    }
  });
});