define('carnival-frontend/components/admin/user-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    isHimself: Ember.computed(function () {
      return this.get('user.id') === this.get('currentUser.id');
    }),

    actions: {
      openModal: function openModal(modalName, model, controller) {
        this.sendAction('openModal', modalName, model, controller);
      }
    }
  });
});