define('carnival-frontend/controllers/settings/permissions/crud', ['exports', 'carnival-frontend/mixins/crud', 'carnival-frontend/helpers/regexes'], function (exports, _crud, _regexes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_crud.default, {
    routeToTransitionTo: 'settings.permissions',
    userEmail: Ember.computed.reads('model.userEmail'),

    disablePrimaryAction: Ember.computed('model.userEmail', function () {
      var email = this.get('model.userEmail') || '';
      return !email.match(_regexes.default.EMAIL);
    }),

    didResend: function didResend(res) {
      this.send('closeModal');
      this.send('openModal', 'settings/permissions/invite-alert', Ember.Object.create({ invitationToken: res.token }), 'settings/permissions/crud');
    },
    didNotResend: function didNotResend() {},


    actions: {
      resend: function resend() {
        this.get('model').resendInvite().then(Ember.run.bind(this, this.didResend), Ember.run.bind(this, this.didNotResend));
      },
      removeSelected: function removeSelected() {
        this.set('model.audience', null);
      }
    }
  });
});