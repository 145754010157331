define('carnival-frontend/models/permission', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    userName: _emberData.default.attr('string'),
    userEmail: _emberData.default.attr('string', { defaultValue: '' }),
    billing: _emberData.default.attr('boolean', { defaultValue: true }),
    author: _emberData.default.attr('boolean'),
    admin: _emberData.default.attr('boolean'),
    developer: _emberData.default.attr('boolean'),

    audience: _emberData.default.belongsTo('audience'),
    app: _emberData.default.belongsTo('app', { inverse: 'permissions' }),

    isFilthy: Ember.computed('hasDirtyAttributes', 'audience.id', function () {
      return this.get('hasDirtyAttributes') || this.get('_data.audience') !== this.get('audience.id');
    }),

    resendInvite: function resendInvite() {
      var store = this.get('store'),
          adapter = store.adapterFor('permission'),
          url = adapter.buildURL('permission', this.get('id')) + '/resend_invite';

      return adapter.ajax(url, 'POST', { data: { app_id: this.get('app.id') } });
    }
  });
});