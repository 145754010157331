define('carnival-frontend/controllers/logs/devices/erase', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modelDidErase: function modelDidErase() {
      this.alert.success('Device', 'This device is successfully erased.');
      this.send('closeModal');
    },
    modelDidNotErase: function modelDidNotErase(error) {
      this.alert.danger('Error', error.message);
      this.send('closeModal');
    },


    actions: {
      dismiss: function dismiss() {
        this.send('closeModal');
      },
      erase: function erase() {
        var _this = this;

        var model = this.get('model');

        model.erase().then(function () {
          _this.modelDidErase();
        }, function (error) {
          _this.modelDidNotErase(error);
        });
      }
    }
  });
});