define('carnival-frontend/models/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    savingProgress: null,
    isNotDirty: Ember.computed.not('hasDirtyAttributes'),
    humanAttributes: {},

    customErrorMessages: {
      targetable_id: 'You must select an audience',
      user_id: 'This user has already been added or invited to this app.'
    },

    validationErrorMessage: Ember.computed('errors.content.[]', function () {
      var messages = [],
          that = this;

      this.get('errors').forEach(function (error) {
        var attr = that.humanAttributes[error.attribute] || error.attribute,
            msg = error.message;

        if (that.customErrorMessages[error.attribute]) {
          messages.addObject(that.customErrorMessages[error.attribute]);
        } else if (attr === 'base') {
          messages.addObject(msg);
        } else {
          messages.addObject('' + attr + ': ' + msg);
        }
      });

      return messages.join(', ');
    }),

    validationErrorArray: Ember.computed('errors.content.[]', function () {
      var messages = [];

      this.get('errors.content').forEach(function (error) {
        messages.addObject('' + S(error.attribute).humanize().s + ': ' + S(error.message).humanize().s + '.');
      });

      return messages;
    }),

    invalidFields: Ember.computed('errors.content.[]', function () {
      var invalidFields = Ember.Object.create();

      this.get('errors.content').forEach(function (error) {
        invalidFields[error.attribute] = true;
      });

      return invalidFields;
    }),

    isSavingInProgress: Ember.computed('savingProgress', function () {
      var savingProgress = this.get('savingProgress');

      return !!savingProgress && savingProgress > 0 && savingProgress < 100;
    }),

    invalidateModel: function invalidateModel(errorsHash) {
      // invalidating a "clean" record will throw an error
      // manually make the record "dirty" - useful for relationships
      // where 1 dirty record in a hasMany means the entire hierarchy
      // should become dirty.
      if (!this.get('hasDirtyAttributes')) this.send('becomeDirty');

      this.store.recordWasInvalid(this._internalModel, errorsHash);
    }
  });
});