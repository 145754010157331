define('carnival-frontend/routes/perform-login', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model() {
      return this.store.findRecord('session', 'current');
    },
    redirect: function redirect(session) {
      if (!session) {
        this.transitionTo('login');
        return;
      }

      this.session.updateFromBackendSession(session);
      var transition = this.session.loginTransition;
      this.session.loginTransition = null;
      transition.retry();
    }
  });
});