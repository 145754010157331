define('carnival-frontend/components/moment-datepicker', ['exports', 'carnival-frontend/utils/calendar-info', 'moment'], function (exports, _calendarInfo, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    timeZones: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('calendarInfo', _calendarInfo.default);
    },


    showTime: true,
    showLocalTime: false,
    localTime: null,
    type: 'from',

    currentDate: (0, _moment.default)(),
    fromDate: null,
    toDate: null,

    minimalDate: (0, _moment.default)().subtract(1, 'day'),
    maximalDate: null,

    selectedHour: 0,
    selectedMinute: 0,
    selectedTimezone: null,

    stringDateFormat: 'YYYY-MM-DD',
    hideTimezones: false,
    showTimezones: Ember.computed.not('hideTimezones'),

    fromDateString: Ember.computed('fromDate', {
      get: function get() {
        if (!this.get('fromDate')) return '';
        return this.get('fromDate').format(this.stringDateFormat);
      },
      set: function set(key, value) {
        this.set('fromDate', (0, _moment.default)(value));
        if (!this.get('fromDate')) return '';
        return this.get('fromDate').format(this.stringDateFormat);
      }
    }),

    toDateString: Ember.computed('toDate', {
      get: function get() {
        if (!this.get('toDate')) return '';
        return this.get('toDate').format(this.stringDateFormat);
      },
      set: function set(key, value) {
        this.set('toDate', (0, _moment.default)(value));
        if (!this.get('toDate')) return '';
        return this.get('toDate').format(this.stringDateFormat);
      }
    }),

    daysOfTheMonth: Ember.computed('calendarInfo', 'currentDate', 'fromDate', 'toDate', function () {
      return this.calendarInfo.daysOfTheMonth(this.getProperties('currentDate', 'minimalDate', 'maximalDate', 'fromDate', 'toDate'));
    }),

    hoursOfTheDay: Ember.computed.alias('calendarInfo.hoursOfTheDay'),
    minutesOfTheHour: Ember.computed.alias('calendarInfo.minutesOfTheHour'),
    daysOfTheWeek: Ember.computed.alias('calendarInfo.daysOfTheWeek'),

    didInsertElement: function didInsertElement() {
      var screenHeight = this.$('.modal-backdrop').height();
      this.$('.date-picker-backdrop').height(screenHeight);
    },


    actions: {
      backdropClick: function backdropClick() {
        this.sendAction('hideDatePickerAction');
      },
      previousMonth: function previousMonth() {
        var newDate = (0, _moment.default)(this.get('currentDate')).subtract(1, 'month');
        this.set('currentDate', newDate);
      },
      nextMonth: function nextMonth() {
        var newDate = (0, _moment.default)(this.get('currentDate')).add(1, 'month');
        this.set('currentDate', newDate);
      },
      pickDate: function pickDate(date) {
        if (this.get('type') === 'from') this.set('fromDate', date);
        if (this.get('type') === 'to') this.set('toDate', date);
        if (!this.get('showTime')) {
          this.sendAction('pickDate', date);
          this.sendAction('hideDatePickerAction');
        }
      },
      confirmSelection: function confirmSelection() {
        this.sendAction('hideDatePickerAction');
      },
      removeSelection: function removeSelection() {
        this.sendAction('removeSelectionAction');
      }
    }
  });
});