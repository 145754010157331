define('carnival-frontend/components/variant-test', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //Tagless component.
    tagName: '',
    //Set the initial value and set up an observer.
    init: function init() {
      this._super.apply(this, arguments);
      this.computeValue();
      var flagName = this.get('flagName');
      this.addObserver('ldClient.flags.' + flagName, this, this.computeValue);
    },


    isVariantA: Ember.computed.equal('variantName', 'A'),
    isVariantB: Ember.computed.equal('variantName', 'B'),
    isVariantC: Ember.computed.equal('variantName', 'C'),
    variantName: Ember.computed.oneWay('defaultVariantName'),
    defaultVariantName: 'A',
    //The name of the flag on LaunchDarkly.
    flagName: null,

    //Look up the specified flag in LaunchDarkly and set the value.
    computeValue: function computeValue() {
      var flags = this.getWithDefault('ldClient.flags', {});
      var defaultValue = this.get('defaultVariantName');
      var flagName = this.get('flagName');
      this.set('variantName', Ember.getWithDefault(flags, flagName, defaultValue));
    }
  });
});