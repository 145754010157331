define('carnival-frontend/helpers/escape-css-prop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.escapeCssProp = escapeCssProp;
  function escapeCssProp(params, hash) {
    var value = params[0],
        prop = hash.cssProp,
        units = hash.cssUnits;

    return new Ember.String.htmlSafe(prop + ': ' + value + units);
  }

  exports.default = Ember.Helper.helper(escapeCssProp);
});