define('carnival-frontend/controllers/messages/push-scoring-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    backupAlert: null,

    messageState: Ember.computed.alias('model.notification.message.state'),
    inputDisabled: Ember.computed('model.notification.state', function () {
      return this.get('messageState') !== 'scheduled' && this.get('messageState') !== 'draft' && this.get('model.notReactive');
    }),

    actions: {
      cancel: function cancel() {
        this.set('model.notification.alert', this.get('backupAlert'));
        this.send('closeModal');
      },
      confirm: function confirm() {
        this.send('closeModal');
      },
      backupAlert: function backupAlert(alert) {
        this.set('backupAlert', alert);
      }
    }
  });
});