define('carnival-frontend/controllers/apps/create', ['exports', 'carnival-frontend/models/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    disablePrimaryAction: false,

    modelDidSave: function modelDidSave() {
      this.set('disablePrimaryAction', false);
      this.send('closeModal');
      this.transitionToRoute('app', this.get('model'));
    },
    modelDidNotSave: function modelDidNotSave(error) {
      this.set('disablePrimaryAction', false);

      if (error instanceof _errors.UnauthorizedError) {
        this.send('closeModal');
        this.send('error', error);
      }
    },


    actions: {
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.send('closeModal');
      },
      save: function save() {
        var _this = this;

        if (this.get('disablePrimaryAction')) return false;

        this.set('disablePrimaryAction', true);
        this.get('model').save().then(function () {
          _this.modelDidSave();
        }, function () {
          _this.modelDidNotSave();
        });
      }
    }
  });
});