define('carnival-frontend/controllers/messages/delete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    modelName: Ember.computed('model.abTest.content', function () {
      return this.get('model.abTest.content') ? 'A/B test' : 'message';
    }),

    actions: {
      delete: function _delete() {
        var _this = this;

        var model = this.get('model.abTest.content') || this.get('model');

        model.destroyRecord().then(function () {
          _this.send('closeModal');
          _this.transitionToRoute('messages');
          _this.send('refresh');
          _this.alert.success('Delete', _this.get('modelName').capitalize() + ' was deleted.');
        });
      }
    }
  });
});