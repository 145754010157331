define('carnival-frontend/routes/unlock-account', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      var that = this;

      Ember.$.ajax({
        url: '/web/v1/user/unlock',
        data: { unlock_token: params.unlock_token }
      }).then(function () {
        that.transitionTo('login');
        that.alert.info('Account unlocked successfully');
      }, function () {
        that.transitionTo('four-o-four');
      });

      return null;
    }
  });
});