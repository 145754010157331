define('carnival-frontend/routes/stats/tech/index', ['exports', 'moment', 'carnival-frontend/routes/route'], function (exports, _moment, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      interval: { refreshModel: true },
      platforms: { refreshModel: true }
    },

    model: function model(params) {
      var app = this.modelFor('app'),
          interval = params.interval,
          options = { platforms: params.platforms };

      options.to = new Date();

      if (interval === 'all') {
        options.from = app.get('createdAt');
      } else {
        options.from = (0, _moment.default)().subtract(parseInt(interval), 'days').toDate();
      }

      return app.fetchTechnology(options);
    }
  });
});