define('carnival-frontend/components/modal-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['modal'],

    didInsertElement: function didInsertElement() {
      var dialog = this.$('.modal-dialog'),
          backdrop = this.$('.modal-backdrop');

      // Backdrop should always cover the entire area
      // of the modal dialog. By default it is set to
      // cover the viewports width and height but will
      // need to be longer for taller modals
      if (dialog.outerHeight(true) > window.innerHeight) {
        dialog.css('margin-top', '30px');

        // Check if it is still taller than the viewport height
        // and set to the modal dialog height if it is
        if (dialog.outerHeight(true) > window.innerHeight) {
          backdrop.height(dialog.outerHeight(true));
        }
      }

      Ember.$('body').addClass('modal-open');
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('body').removeClass('modal-open');
    },


    actions: {
      backdropClick: function backdropClick() {
        this.sendAction();
      }
    }
  });
});