define('carnival-frontend/routes/messages/ab-tests/edit', ['exports', 'carnival-frontend/routes/messages/ab-tests/new'], function (exports, _new) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _new.default.extend({
    messageBuilder: Ember.inject.service(),
    controllerName: 'messages.ab-tests.new',

    model: function model(params) {
      return this.store.findRecord('ab-test', params.ab_test_id);
    },
    renderTemplate: function renderTemplate() {
      this.render('messages.ab-tests.new');
    }
  });
});