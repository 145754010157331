define('carnival-frontend/components/stats/analytics-graph', ['exports', 'moment', 'carnival-frontend/helpers/tooltip-helper'], function (exports, _moment, _tooltipHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    margin: {
      top: 60,
      right: 20,
      bottom: 40,
      left: 50
    },

    yAxisPrefix: '',

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$(window).on('debouncedresize', function () {
        return _this.draw();
      });

      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$(window).trigger('debouncedresize');
      });

      var tooltip = new _tooltipHelper.default(this.$(), this.$('.popup-tooltip'), this.$('.arrow', this.$('.popup-tooltip')), this.margin);

      this.set('tooltip', tooltip);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('debouncedresize');
    },
    draw: function draw() {
      var _this2 = this;

      // Graph dimensions
      // ****************

      var margin = this.margin,
          width = this.$().width() - margin.left - margin.right,
          height = this.$().height() - margin.top - margin.bottom;

      // Data
      var stats = this.get('stats'),
          data = stats.get('formattedData');

      // Scales
      // ******

      // x axis represents the time based on user selection
      // e.g. from one date to another
      var from = stats.get('from'),
          to = stats.get('to');

      var x = d3.scaleTime().domain([from, to]).range([0, width]);

      // aggregated values from all platforms
      var yMax = d3.max(data, function (c) {
        return d3.max(c.values, function (v) {
          return v.count;
        });
      });

      // add 10% to total so there is some "roof" to the graph
      // round to the nearest whole number
      yMax = Math.ceil(yMax * 1.1);

      var y = d3.scaleLinear().domain([0, yMax || 4]) // if the max value is zero then use the default number of ticks on the y axis e.g. 4
      .range([height, 0]);

      // Axises
      // ******

      var xAxis = d3.axisBottom().scale(x).tickSize(10).tickPadding(10);

      // extra logic so the graph displays nicer with low values
      // we don't want to show decimal values when it doesn't
      // make sense e.g. 1.5 installs
      var numTicks = yMax > 0 && yMax < 4 ? yMax : 4;

      var yAxis = d3.axisLeft().scale(y).ticks(numTicks).tickSize(-width).tickPadding(10).tickFormat(function (d) {
        return '' + _this2.yAxisPrefix + d3.format('~s')(d);
      });

      // Line generator
      // **************

      var line = d3.line().curve(d3.curveLinear).x(function (d) {
        return x(d.date);
      }).y(function (d) {
        return y(d.count);
      });

      // Render SVG elements
      // *******************

      var elementId = '#' + this.get('elementId');

      // remove svg on re-render otherwise multiple instances of
      // the graph will appear onscreen if the window is resized
      d3.select(elementId + '> svg').remove();

      var svg = d3.select(elementId).append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom).append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // Axises
      // ******

      svg.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);

      svg.append('g').attr('class', 'y axis').call(yAxis);

      // No data left
      // ************

      // only render the no data area if necessary
      // for left hand side this will only appear if a user
      // selects a date range where the 'from' date is before
      // the apps creation date

      var leftBoundary = x(stats.get('leftTimeBoundary'));

      if (leftBoundary > 0) {
        svg.append('line').attr('x1', leftBoundary).attr('x2', leftBoundary).attr('y1', 0).attr('y2', height).attr('class', 'no-data-line');

        svg.append('rect').attr('x', 0).attr('y', 0).attr('width', leftBoundary).attr('height', height).attr('class', 'no-data-rect');
      }

      // No data right
      // *************

      // similar to left hand side except this will only appear
      // if the 'to' range is in the future
      var rightBoundary = x(stats.get('rightTimeBoundary'));

      if (rightBoundary < width) {
        svg.append('line').attr('x1', rightBoundary).attr('x2', rightBoundary).attr('y1', 0).attr('y2', height).attr('class', 'no-data-line');

        svg.append('rect').attr('x', rightBoundary).attr('y', 0).attr('width', width - rightBoundary).attr('height', height).attr('class', 'no-data-rect');
      }

      // Graph lines
      // ***********

      // graph lines are made up of a line (path) and
      // plot points (circles) which are grouped together
      // by platform ios, android etc.
      var platform = svg.selectAll('.platform').data(data).enter().append('g').attr('class', function (d) {
        return 'platform platform-' + d.name;
      });

      platform.append('path').attr('d', function (d) {
        return line(d.values);
      });

      platform.selectAll('circle').data(function (d) {
        return d.values;
      }).enter().append('circle').attr('r', 4).attr('cx', function (d) {
        return x(d.date);
      }).attr('cy', function (d) {
        return y(d.count);
      });
    },
    mouseMove: function mouseMove(e) {
      var tooltip = this.get('tooltip');

      if (e.target.tagName !== 'circle') return tooltip.hideTooltip();

      var d = e.target.__data__;

      this.set('tooltipEventData', Ember.Object.create({
        date: this._tooltipDateFormatted(d.date),
        value: d.count,
        platform: d.platform
      }));

      var offset = this.$().offset();

      tooltip.showTooltipAt(e.pageX - offset.left, e.pageY - offset.top);
    },
    _tooltipDateFormatted: function _tooltipDateFormatted(date) {
      var from = date,
          to = (0, _moment.default)(date).endOf(this.get('stats.granularity'));

      if (this.get('stats.path') === 'active_users') {
        from = (0, _moment.default)(from).subtract(1, 'month').toDate();
      }

      return { from: from, to: to };
    }
  });
});