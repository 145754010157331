define('carnival-frontend/components/stats/response-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['response-tooltip'],
    classNameBindings: ['arrowleft:left', 'arrowRight:right'],
    attributeBindings: ['style'],

    anchorX: 0,
    anchorY: 0,

    arrowWidth: 20,
    arrowleft: false,
    arrowRight: false,

    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.setProperties({
          offsetX: this.getOffsetX(),
          offsetY: this.getOffsetY()
        });
      });
    },


    // eslint-disable-next-line ember/no-observers
    anchorXDiDChange: Ember.observer('anchorX', function () {
      this.set('offsetX', this.getOffsetX());
    }),

    // eslint-disable-next-line ember/no-observers
    anchorYDiDChange: Ember.observer('anchorY', function () {
      this.set('offsetY', this.getOffsetY());
    }),

    getOffsetX: function getOffsetX() {
      var boundaryLeft = this.get('boundaryLeft'),
          boundaryRight = this.get('boundaryRight'),
          width = this.element.offsetWidth,
          offset = this.get('anchorX') - width / 2;

      if (offset < boundaryLeft) {
        this.setProperties({
          arrowleft: true,
          arrowRight: false
        });
        offset += width / 2 - this.get('arrowWidth');
      } else if (offset > boundaryRight - width) {
        this.setProperties({
          arrowleft: false,
          arrowRight: true
        });
        offset -= width / 2 - this.get('arrowWidth');
      } else {
        this.setProperties({
          arrowleft: false,
          arrowRight: false
        });
      }

      return offset;
    },
    getOffsetY: function getOffsetY() {
      var height = this.element.offsetHeight,
          offset = this.get('anchorY') - height;

      return offset;
    },


    style: Ember.computed('offsetX', 'offsetY', function () {
      return new Ember.String.htmlSafe('top:' + this.get('offsetY') + 'px;left:' + this.get('offsetX') + 'px;');
    })
  });
});