define('carnival-frontend/routes/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      var cssClass = this.toCssClass();

      if (cssClass !== 'application') {
        Ember.$('body').addClass(cssClass);
      }
    },
    deactivate: function deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },
    toCssClass: function toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize();
    }
  });
});