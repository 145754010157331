define('carnival-frontend/helpers/format-num', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatNum = formatNum;
  function formatNum(params) {
    var value = params[0];

    if (!value) return '0';
    return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  exports.default = Ember.Helper.helper(formatNum);
});