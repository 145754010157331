define('carnival-frontend/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    user: null,

    // eslint-disable-next-line ember/no-observers
    userDidChange: Ember.observer('user', function () {
      var user = this.get('user');

      if (!user) return;

      var userData = user.getProperties('id', 'email', 'name');
      userData.user_id = userData.id;
      delete userData.id;

      // eslint-disable-next-line no-prototype-builtins
      if (window.hasOwnProperty('heap')) {
        window.heap.identify(userData.email);
        window.heap.addUserProperties(userData);
      }
    })
  });
});