define('carnival-frontend/components/numeric-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KEY_MATCHER = /^([0-9]|Backspace|Del(ete)?|Cut)$/;

  exports.default = Ember.TextField.extend({
    classNames: ['form-control', 'input-xs'],

    keyUp: function keyUp(event) {
      // Only allow backspace and numbers
      if (!KEY_MATCHER.test(event.key)) {
        return;
      }

      var text = this.value.trim();

      if (text === '') {
        this.sendAction('inputIsEmpty');
      } else {
        var num = parseFloat(text);
        if (!isNaN(num)) this.sendAction('inputIsPresent', num);
      }
    }
  });
});