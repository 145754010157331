define('carnival-frontend/components/messages/audience-li', ['exports', 'carnival-frontend/mixins/audiences/is-in-viewport'], function (exports, _isInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_isInViewport.default, {
    tagName: 'li',
    classNames: ['list-item'],
    classNameBindings: ['isSelected:active'],
    target: null,
    searchText: '',

    // Used for IsInViewportMixin
    // Use parentView by default (list)
    // We pass parentView to nested children (child audiences)
    listElement: Ember.computed({
      get: function get() {
        return this.parentView;
      },

      // eslint-disable-next-line ember/require-return-from-computed
      set: function set(key, value) {
        if (value) return value;
      }
    }),

    nestedAudiences: Ember.computed('target.childAudiences', function () {
      if (!this.get('target.childAudiences')) return;

      var search = this.get('searchText').toLowerCase();

      return this.get('target.childAudiences').filter(function (a) {
        return !a.get('disabled') && a.get('name').toLowerCase().indexOf(search) >= 0;
      });
    }),

    isSelected: Ember.computed('selectedTarget', 'target', function () {
      if (!this.get('selectedTarget')) return;

      return this.get('selectedTarget.id') === this.get('target.id');
    }),

    text: Ember.computed('target', function () {
      if (this.get('target.constructor.modelName') === 'app') return 'Send to Everyone';

      return this.get('target.name');
    }),

    actions: {
      selectTarget: function selectTarget() {
        this.set('selectedTarget', this.get('target'));
      }
    }
  });
});