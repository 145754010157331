define('carnival-frontend/components/super-admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    highlight: true,
    user: Ember.computed.oneWay('currentUserService.user'),
    isSuperAdmin: Ember.computed.alias('user.superAdmin')
  });
});