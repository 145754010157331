define('carnival-frontend/controllers/audiences/import/user-ids', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    app: Ember.computed.oneWay('currentAppService.app'),
    nameIsValid: Ember.computed.notEmpty('name'),
    fileIsValid: null,
    jobCreated: false,
    isSubmitting: null,
    file: null,
    filename: null,
    name: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('file.name', this, this.friendlyFileName);
    },
    friendlyFileName: function friendlyFileName() {
      if (this.get('file.name')) {
        var name = this.get('file.name').slice(0, -4); // Slice off the `.csv`
        this.set('name', name.replace(/-|_/g, ' '));
      }
    },


    notSubmitting: Ember.computed.not('isSubmitting'),
    dismissOnly: Ember.computed.or('jobCreated', 'errorOccurred'),
    enableUpload: Ember.computed.and('nameIsValid', 'fileIsValid', 'notSubmitting'),
    showNameField: Ember.computed.and('fileIsValid', 'notSubmitting'),

    actions: {
      loadPresignedForm: function loadPresignedForm() {
        return this.app.getImportAudienceSign();
      },
      error: function error() {
        this.set('errorOccurred', true);
        this.set('isSubmitting', false);
      },
      trackSubmission: function trackSubmission() {
        this.set('isSubmitting', true);
      },
      submit: function submit() {
        var _this = this;

        var app = this.app,
            name = this.name,
            filename = this.filename;


        this.set('isSubmitting', false); // Transmission has completed

        return app.sendImportAudienceName(filename, name).then(function () {
          _this.set('jobCreated', true);
        }, function () {
          this.send('error');
        });
      },
      cancel: function cancel() {
        this.setProperties({
          errorOccurred: false,
          jobCreated: false,
          name: ''
        });
        this.send('closeModal');
      }
    }
  });
});