define('carnival-frontend/adapters/permission', ['exports', 'carnival-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var appId = snapshot.belongsTo('app').id,
          id = snapshot.id,
          url = this.buildURL(type.modelName, id, snapshot) + '?app_id=' + appId;

      return this.ajax(url, 'DELETE');
    }
  });
});