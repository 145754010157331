define('carnival-frontend/components/s3-file-selector', ['exports', 'carnival-frontend/components/form-field'], function (exports, _formField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formField.default.extend({
    s3FileUploader: Ember.inject.service(),
    file: null,
    value: null,
    isValid: false,
    acceptedFiles: null,
    presignedForm: null, //promise to load presigned s3 form data

    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('file', this, this.validate);
    },
    validate: function validate() {
      var file = this.file;


      this.set('isValid', file != null && file.size > 0);
    },


    // overwrite from FormField, called before submiting the form
    beforeSubmit: function beforeSubmit() {
      var _this = this;

      var presignedForm = this.presignedForm,
          file = this.file;


      return new Promise(function (resolve, reject) {
        if (presignedForm === null) {
          reject('presignedForm must be set to a promise loading s3 presigned form data');
        }
        presignedForm().then(function (sign) {
          return _this.s3FileUploader.upload(file, sign);
        }).then(function (uploadedFilename) {
          _this.set('value', uploadedFilename);
          resolve(uploadedFilename);
        }, function (error) {
          reject(error);
        });
      });
    },

    actions: {
      // from FormField, called when form is reset
      reset: function reset() {
        this.s3FileUploader.cancel();

        this.setProperties({
          file: null
        });
      }
    }
  });
});