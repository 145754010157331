define('carnival-frontend/components/messages/send-message-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    messageTypeText: Ember.computed('model.constructor.modelName', function () {
      if (this.model.constructor.modelName == 'ab-test') {
        return 'A/B test message';
      }
      return 'message';
    }),

    messageTargetsApp: Ember.computed.equal('model.targetable.content.constructor.modelName', 'app'),
    totalDevices: Ember.computed.alias('model.targetable.deviceCount.content'),
    pushEnabledDevices: Ember.computed.alias('model.targetable.pushEnabledCount'),
    noTargetDevices: Ember.computed.equal('totalDevices', 0),

    init: function init() {
      this._super();
      this.set('revealClasses', ['send-popup']);
    },


    revealClassNames: Ember.computed('revealClasses.[]', function () {
      return this.revealClasses.join(' ');
    }),

    actions: {
      next: function next() {
        this.onNext();
      }
    }
  });
});