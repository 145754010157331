define('carnival-frontend/mixins/messages/text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    preview: Ember.computed('text', function () {
      if (Ember.isEmpty(this.get('text'))) return '';
      return this.get('text').slice(0, 100) + '...';
    })
  });
});