define('carnival-frontend/transforms/time-with-zone', ['exports', 'ember-data', 'carnival-frontend/models/time-with-zone'], function (exports, _emberData, _timeWithZone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isNone(serialized)) return null;
      return _timeWithZone.default.create(serialized);
    },
    serialize: function serialize(deserialized) {
      if (Ember.isNone(deserialized)) return null;
      if (!deserialized.get) return null;
      return {
        date: deserialized.get('date'),
        hour: deserialized.get('hour'),
        minute: deserialized.get('minute'),
        timezone: deserialized.get('timezone').replace('(Local Time)', 'Etc/UTC')
      };
    }
  });
});