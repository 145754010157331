define('carnival-frontend/components/hover-reveal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['data-insight-id'],
    classNames: ['reveal-popup', 'open-on-hover'],
    popupAlign: 'top',
    arrowAlign: 'center',
    mouseEnter: function mouseEnter() {
      // eslint-disable-next-line no-prototype-builtins
      if (window.hasOwnProperty('heap') && this.attrs.hoverName) {
        window.heap.track(this.attrs.hoverName);
      }
    }
  });
});