define('carnival-frontend/helpers/format-money', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMoney = formatMoney;
  function formatMoney(params) {
    var symbol = params[0] || '$',
        value = params[1] || 0,
        precision = Number.isInteger(params[2]) ? params[2] : 2;

    return symbol + value.toFixed(precision).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  exports.default = Ember.Helper.helper(formatMoney);
});