define('carnival-frontend/models/history-tracker', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    action: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    userName: _emberData.default.attr('string'),
    userEmail: _emberData.default.attr('string'),
    scope: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),

    original: _emberData.default.attr(),
    modified: _emberData.default.attr(),
    association_chain: _emberData.default.attr()
  });
});