define('carnival-frontend/helpers/split-variation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    name: '',
    left: 0,
    right: 0,

    // The adjacent left `SplitVariation`, we need this reference
    // to update it's position when it's `left` value changes since
    // changes to this value affect the previous nodes `right` value
    // NOTE: The first split will always be `null`
    prevSplit: null,

    style: Ember.computed('left', 'right', function () {
      return Ember.String.htmlSafe('left:' + this.get('left') + '%;right:' + this.get('right') + '%;');
    }),

    ratio: Ember.computed('left', 'right', function () {
      return 100 - Math.ceil(this.get('left')) - Math.floor(this.get('right'));
    }),

    // When `left` changes, update adjacent nodes `right` value
    // eslint-disable-next-line ember/no-observers
    leftDidChange: Ember.observer('left', function () {
      var prevSplit = this.get('prevSplit'),
          left = this.get('left');

      if (prevSplit) prevSplit.set('right', 100 - left);
    }),

    // When adding a variation, split evenly between adjacent node (in half)
    // eslint-disable-next-line ember/no-observers
    prevSplitDidChange: Ember.observer('prevSplit', function () {
      var left = this.get('prevSplit.left');

      this.set('left', left + (100 - left) / 2);
    })
  });
});