define('carnival-frontend/controllers/apps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    searchText: '',

    actions: {
      transitionToApp: function transitionToApp(app) {
        this.transitionToRoute('stats.engage', app);
      }
    }
  });
});