define('carnival-frontend/controllers/stats/revenue', ['exports', 'carnival-frontend/mixins/stats/titles', 'carnival-frontend/mixins/stats/dates', 'carnival-frontend/mixins/stats/annotations', 'carnival-frontend/mixins/stats/csv-export', 'carnival-frontend/mixins/stats/platforms', 'carnival-frontend/models/errors'], function (exports, _titles, _dates, _annotations, _csvExport, _platforms, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_titles.default, _dates.default, _annotations.default, _csvExport.default, _platforms.default, {
    queryParams: ['stat', 'from', 'to', 'audienceId'],
    stat: 'revenue_stats',
    topbarTitle: 'Revenue',
    topbarSubtitle: 'Directly attributed to mobile messages',

    // eslint-disable-next-line ember/no-observers
    annotationsShouldUpdate: Ember.observer('app', 'from', 'to', 'audienceId', function () {
      if (this.get('app.id') === this.get('currentAppService.app.id')) {
        this.refreshAnnotations();
      }
    }),

    refreshAnnotations: function refreshAnnotations() {
      var _this = this;

      this.get('app').fetchAnnotations(this.get('from'), this.get('to'), {
        revenueAttributableOnly: true,
        audienceId: this.get('audienceId')
      }).then(function (annotations) {
        _this.set('annotations', annotations);
      }, function (error) {
        if (!(error instanceof _errors.BadRequestError)) throw error;
      });
    },


    actions: {
      reloadRevenueData: function reloadRevenueData() {
        this.send('refreshModel');
      }
    }
  });
});