define('carnival-frontend/controllers/settings/developer/whitelisted-domains/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    domain: '',

    actions: {
      cancel: function cancel() {
        this.set('domain', '');
        this.send('closeModal');
      },
      save: function save() {
        var app = this.get('model'),
            that = this;

        app.get('whitelistedDomains').addObject(this.get('domain'));
        app.save().then(function () {
          that.send('cancel');
        });
      }
    }
  });
});