define('carnival-frontend/mixins/stats/annotations', ['exports', 'carnival-frontend/models/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    annotations: [],

    // eslint-disable-next-line ember/no-observers
    annotationsShouldUpdate: Ember.observer('app', 'from', 'to', function () {
      if (this.get('app.id') === this.get('currentAppService.app.id')) this.refreshAnnotations();
    }),

    refreshAnnotations: function refreshAnnotations() {
      var _this = this;

      this.get('app').fetchAnnotations(this.get('from'), this.get('to')).then(function (annotations) {
        _this.set('annotations', annotations);
      }, function (error) {
        if (!(error instanceof _errors.BadRequestError)) throw error;
      });
    }
  });
});