define('carnival-frontend/models/transcoder', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    status: _emberData.default.attr('string'),
    key: _emberData.default.attr('string'),
    app_id: _emberData.default.attr('string'),
    dimensions: _emberData.default.attr(),

    pollInterval: 1000,
    pollTimer: null,

    poll: function poll() {
      var timer = setInterval(this._reload.bind(this), this.pollInterval);
      this.set('pollTimer', timer);
    },
    _reload: function _reload() {
      var _this = this;

      this.reload().then(function () {
        if (_this.get('status') === 'Submitted' || _this.get('status') === 'Progressing') {
          return;
        }

        clearInterval(_this.get('pollTimer'));
      });
    }
  });
});