define('carnival-frontend/routes/invitations', ['exports', 'carnival-frontend/routes/route', 'carnival-frontend/models/errors'], function (exports, _route, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      return this.store.findRecord('invitation', params.invitation_token);
    },


    actions: {
      error: function error(reason, transition) {
        transition.abort();

        if (reason instanceof _errors.NotFoundError) {
          this.alert.danger('Error:', 'The invitation is invalid. Please contact support@carnivalmobile.com .');
        } else {
          this.alert.danger('Error:', reason.responseText);
        }

        this.transitionTo('index');
      }
    }
  });
});