define('carnival-frontend/components/loading-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn-loading', 'btn', 'btn-primary'],
    classNameBindings: ['isDisabled:disabled', 'isLoading:is-loading', 'shouldRenderSTUIButton:stui-btn'],
    attributeBindings: ['type'],
    shouldRenderSTUIButton: Ember.computed.alias('ldClient.flags.mobile_stui_component'),

    click: function click() {
      this.sendAction('action');
    }
  });
});