define('carnival-frontend/components/rembr/stui-button', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/stui-components', 'html-react-parser'], function (exports, _abstractComponent, _stuiComponents, _htmlReactParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var stylesAsObject = function stylesAsObject(styles) {
    return styles.replace(/\s/g, '').split(';').map(function (s) {
      return s.split(':');
    }).reduce(function (obj, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          val = _ref2[1];

      return _extends({}, obj, _defineProperty({}, key, val));
    }, {});
  };
  exports.default = _abstractComponent.default.extend({
    attributeBindings: ['data-test-id', 'disabled'],
    classNameBindings: ['shouldRenderSTUIButton:stui-btn'],
    shouldRenderSTUIButton: Ember.computed.alias('ldClient.flags.mobile_stui_component'),
    isPrimary: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('btn-primary') > -1;
    }),
    isSecondary: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('btn-secondary') > -1;
    }),
    isTertiary: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('stui-tertiary') > -1;
    }),
    isDestructive: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('btn-destruct') > -1;
    }),
    isIcon: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('btn-glyphicon') > -1;
    }),
    isFixedWidth: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('fixed-width') > -1;
    }),
    isDisabled: Ember.computed('attrs.disabled', function () {
      return Boolean(this.disabled);
    }),
    getVariant: function getVariant() {
      if (this.isTertiary) {
        return _stuiComponents.Button.VARIANT_TERTIARY;
      }
      if (this.isSecondary) {
        return _stuiComponents.Button.VARIANT_SECONDARY;
      }
      return _stuiComponents.Button.VARIANT_PRIMARY;
    },
    // Overrides to address issues with the current STUI Button. This can be removed once https://sailthru.atlassian.net/browse/EXG-5 is complete
    getStyleOverrides: function getStyleOverrides() {
      var style = this.attrs.style ? stylesAsObject(this.attrs.style) : {};

      if (this.isDestructive) {
        style.borderColor = '#e62d61';
        style.background = '#e62d61';
        style.color = '#ffffff';
      }
      if (this.isFixedWidth) {
        style.width = 160;
      }

      return style;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('childElements', this.element.innerHTML);
      this.renderButton();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderButton();
    },
    renderButton: function renderButton() {
      var _attrs = this.attrs,
          className = _attrs.class,
          attrs = _objectWithoutProperties(_attrs, ['class']);

      var props = _extends({}, attrs, {
        variant: this.getVariant(),
        disabled: this.isDisabled,
        style: this.getStyleOverrides()
      });

      this.reactRender(React.createElement(
        _stuiComponents.Button,
        props,
        (0, _htmlReactParser.default)(this.childElements)
      ));
    }
  });
});