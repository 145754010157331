define('carnival-frontend/models/reset-password', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    resetPasswordToken: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string')
  });
});