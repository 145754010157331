define('carnival-frontend/components/split-slider', ['exports', 'carnival-frontend/helpers/split-variation'], function (exports, _splitVariation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isDisabled:disabled'],

    isDisabled: false,
    isRemoveDisabled: false,

    SLIDER_CLASS: 'slider',
    HANDLE_CLASS: 'handle',
    SPLIT_CLASS: 'split',

    SPLIT_NAMES: ['a', 'b', 'c', 'd', 'e'],
    RANGE: { min: 1, max: 99 },

    classNames: ['split-slider'],

    handle: null,
    splits: [],
    groups: [],

    canAdd: Ember.computed('splits.{[],lastObject.ratio}', function () {
      var splits = this.get('splits');

      return splits.get('length') < this.SPLIT_NAMES.length && splits.get('lastObject.ratio') >= 2;
    }),

    canRemove: Ember.computed('splits.[]', 'isRemoveDisabled', function () {
      return this.get('splits.length') > 1 && !this.get('isRemoveDisabled');
    }),

    init: function init() {
      this._super();

      var groups = this.get('groups');

      if (groups.get('length')) {
        this.parseGroupsIntoSplits(groups);
      } else {
        this.createSplit();
      }
    },
    parseGroupsIntoSplits: function parseGroupsIntoSplits(groups) {
      var splits = groups.map(function (item) {
        return _splitVariation.default.create({ name: item.get('variationName') });
      });

      var i = splits.get('length'),
          left = 100;

      while (--i) {
        splits[i].set('prevSplit', splits[i - 1]);
        splits[i].set('left', left -= groups.objectAt(i).get('variationRatio'));
      }

      this.set('splits', splits);
    },
    mouseUp: function mouseUp() {
      this.set('handle', null);
    },
    mouseLeave: function mouseLeave() {
      this.set('handle', null);
    },
    mouseDown: function mouseDown(e) {
      e.preventDefault();

      if (e.target.classList.contains(this.HANDLE_CLASS)) {
        this.set('handle', e.target);
      }
    },
    mouseMove: function mouseMove(e) {
      var handle = this.get('handle');

      if (!this.get('handle')) return;

      var split = this.getSplitByName(handle.dataset.split),
          offset = this.getOffset(e),
          position = this.getPosition(split, offset);

      split.set('left', position);
    },
    getPosition: function getPosition(split, offset) {
      var min = this.RANGE.min,
          max = this.RANGE.max;

      var prevSplit = split.get('prevSplit'),
          nextSplit = this.getNextSplit(split);

      if (offset <= min && !prevSplit) return min;
      if (offset >= max && !nextSplit) return max;
      if (prevSplit && prevSplit.get('left') + min >= offset) return prevSplit.get('left') + min;
      if (nextSplit && nextSplit.get('left') - min <= offset) return nextSplit.get('left') - min;

      return offset;
    },
    getOffset: function getOffset(e) {
      var slider = this.element.querySelector('.' + this.SLIDER_CLASS),
          rect = slider.getBoundingClientRect(),
          width = slider.offsetWidth;

      return (e.pageX - rect.left) / width * 100;
    },
    getSplitByName: function getSplitByName(name) {
      return this.get('splits').findBy('name', name);
    },
    getNextSplit: function getNextSplit(split) {
      var splits = this.get('splits');

      return splits.objectAt(splits.indexOf(split) + 1);
    },
    createNamedSplit: function createNamedSplit() {
      return _splitVariation.default.create({
        name: this.SPLIT_NAMES[this.get('splits.length')]
      });
    },
    createSplit: function createSplit() {
      var splits = this.get('splits'),
          prevSplit = splits.get('lastObject') || splits.pushObject(this.createNamedSplit()),
          newSplit = this.createNamedSplit();

      newSplit.set('prevSplit', prevSplit);
      splits.pushObject(newSplit);
      this.sendAction('splitAdded', {
        ratio: newSplit.get('ratio'),
        name: newSplit.get('name')
      });
    },
    deleteSplit: function deleteSplit() {
      var splits = this.get('splits');

      splits.popObject();
      splits.set('lastObject.right', 0);
    },


    actions: {
      addSplit: function addSplit() {
        if (this.get('canAdd')) {
          this.createSplit();
        }
      },
      removeSplit: function removeSplit() {
        if (this.get('canRemove')) {
          this.deleteSplit();
          this.sendAction('splitRemoved');
        }
      },
      splitUpdated: function splitUpdated(split) {
        this.sendAction('splitUpdated', split);
      }
    }
  });
});