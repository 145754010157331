define('carnival-frontend/routes/reset-password', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      return this.store.findRecord('resetPassword', params.password_token);
    },


    actions: {
      submitPasswordChange: function submitPasswordChange() {
        var that = this,
            model = this.modelFor('resetPassword');

        if (!model.get('hasDirtyAttributes')) return;

        model.get('errors').clear();

        var formFields = ['password', 'passwordConfirmation'];

        formFields.forEach(function (field) {
          if (!model.get(field)) {
            model.get('errors').add(field, 'Please enter ' + field);
          }
        });

        if (model.get('password') !== model.get('passwordConfirmation')) {
          model.get('errors').add('passwordConfirmation', 'Your password and password confirmation must match.');
        }

        if (model.get('errors.length') > 0) return;

        model.save().then(function () {
          that.controller.set('success', true);
        }, function (reason) {
          if (reason.errors) {
            model.get('errors').add(reason.errors);
          }
        });
      }
    }
  });
});