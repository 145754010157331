define('carnival-frontend/controllers/apps/portfolio', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    MAX_APPS: 40,

    appIds: Ember.computed('model.apps', function () {
      return this.get('model.apps').mapBy('id').slice(0, this.MAX_APPS);
    })
  });
});