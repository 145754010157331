define('carnival-frontend/routes/logs/devices', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      per: { refreshModel: true },
      page: { refreshModel: true },
      development: { refreshModel: true },
      search: { refreshModel: true },
      audience_id: { refreshModel: true }
    },

    model: function model(params) {
      params.app_id = this.modelFor('app').id;

      return Ember.RSVP.hash({ params: params });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        app: this.modelFor('app'),
        model: model.params.page > 100 ? null : this.store.query('device', model.params)
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('search', '');
      }
    }
  });
});