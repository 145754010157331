define('carnival-frontend/routes/login-auth0', ['exports', 'carnival-frontend/routes/login'], function (exports, _login) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _login.default.extend({
    redirect: function redirect() {
      window.location.replace('/web/v1/user/auth/auth0');
    }
  });
});