define('carnival-frontend/models/annotation-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    timeInterval: d3.timeHour,

    init: function init() {
      this._super();

      this.set('annotations', Ember.A());
    },


    lastAnnotation: Ember.computed('annotations.[]', function () {
      return this.get('annotations.lastObject');
    }),

    clampedDate: Ember.computed('lastAnnotation.annotatedAt', 'timeInterval', function () {
      var timeInterval = this.get('timeInterval'),
          annotation = this.get('lastAnnotation');

      if (typeof timeInterval !== 'function') return null;
      if (!annotation) return null;

      return timeInterval(annotation.get('annotatedAt'));
    }),

    x: Ember.computed('scale', 'clampedDate', function () {
      var scale = this.get('scale'),
          clampedDate = this.get('clampedDate');

      if (typeof scale !== 'function') return null;
      if (!clampedDate) return null;

      return scale(this.get('clampedDate'));
    }),

    hasMultipleAnnotations: Ember.computed('annotations.length', function () {
      return this.get('annotations.length') > 1;
    })
  });
});