define('carnival-frontend/helpers/json-2-html', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.json2Html = json2Html;
  function json2Html(params /*, hash*/) {
    var json = params[0];

    if (typeof json === 'undefined') json = '';

    var replacer = function replacer(match, pIndent, pKey, pVal, pEnd) {
      var key = '<span class=json-key>';
      var val = '<span class=json-value>';
      var str = '<span class=json-string>';
      var r = pIndent || '';
      if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
      if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
      return r + (pEnd || '');
    };

    var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
    var innerHtml = JSON.stringify(json, null, 3).replace(/&/g, '&amp;').replace(/\\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(jsonLine, replacer);

    return new Ember.String.htmlSafe('<pre><code>' + innerHtml + '</code></pre>');
  }

  exports.default = Ember.Helper.helper(json2Html);
});