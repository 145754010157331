define('carnival-frontend/mixins/stats/titles', ['exports', 'carnival-frontend/helpers/stat-titles'], function (exports, _statTitles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isOpens: Ember.computed.equal('stat', 'opens'),
    isMau: Ember.computed.equal('stat', 'active_users'),
    isSessions: Ember.computed.equal('stat', 'sessions'),
    isEvents: Ember.computed.equal('stat', 'custom_event'),
    isInstalls: Ember.computed.equal('stat', 'installs'),
    isUninstalls: Ember.computed.equal('stat', 'uninstalls'),
    isNotificationsSent: Ember.computed.equal('stat', 'notifications_sent'),
    isRevenue: Ember.computed.equal('stat', 'revenue_stats'),

    title: Ember.computed('stat', function () {
      return _statTitles.default[this.get('stat')];
    }),

    total: Ember.computed('totals.isFulfilled', function () {
      return this.get('totals.' + this.get('stat'));
    }),

    totals: Ember.computed('app', 'platforms.[]', function () {
      return this.get('app').fetchStats(this.getTotalsParams());
    }),

    totalsInRange: Ember.computed('app', 'platforms.[]', 'from', 'to', function () {
      return this.get('app').fetchStats(this.getTotalsParams(true));
    }),

    averageTime: Ember.computed('totalsInRange.isFulfilled', function () {
      var totals = this.get('totalsInRange.content');

      if (!totals || !totals['sessions']) return 0;
      return totals['total_session_time'] / totals['sessions'] || 0;
    }),

    eventTotal: Ember.computed('eventId', function () {
      var eventId = this.get('eventId');

      return this.get('app').customEventTotal(eventId);
    }),

    notificationsSentTotal: Ember.computed(function () {
      return this.get('app').notificationsSentTotal();
    }),

    getTotalsParams: function getTotalsParams(range) {
      var params = {
        platforms: this.get('platforms'),
        from: this.get('from'),
        to: this.get('to')
      };

      if (range) params.respect_range = true;
      return params;
    }
  });
});