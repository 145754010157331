define('carnival-frontend/components/stats/graph-line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'g',
    attributeBindings: ['style'],

    style: Ember.computed(function () {
      return new Ember.String.htmlSafe('clip-path: url(#clipPath)');
    }),

    xScaler: Ember.computed('xScale', function () {
      var xScale = this.get('xScale');
      return function (d) {
        return xScale(d[0]);
      };
    }),

    yScaler: Ember.computed('yScale', function () {
      var yScale = this.get('yScale');
      return function (d) {
        return yScale(d[1]);
      };
    }),

    dataDidChange: Ember.on('didInsertElement',
    // eslint-disable-next-line ember/no-observers
    Ember.observer('data', 'yScale', 'xScale', function () {
      var debounceFn = Ember.run.debounce(this, this._drawLine, 100, false);

      // need a reference to this so we can cancel in tests during teardown
      // otherwise it will break aysnc tests
      this.set('debounceFn', debounceFn);
    })),

    _drawLine: function _drawLine() {
      var data = this.get('data');

      if (!data) return;

      var xScaler = this.get('xScaler'),
          yScaler = this.get('yScaler'),
          dAttr = data.length > 0 ? d3.line().x(xScaler).y(yScaler) : 'M0,0',
          dotsJoin = d3.select(this.$('g')[0]).selectAll('circle').data(data);

      d3.select(this.$('path')[0]).datum(data).attr('d', dAttr);

      dotsJoin.enter().append('circle').attr('r', 4).attr('class', 'dot');

      dotsJoin.attr('cx', xScaler).attr('cy', yScaler);

      dotsJoin.exit().remove();
    },
    willDestroyElement: function willDestroyElement() {
      var fn = this.get('debounceFn');

      if (fn) Ember.run.cancel(fn);
    }
  });
});