define('carnival-frontend/routes/settings/permissions/new', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    controllerName: 'settings.permissions.crud',

    model: function model() {
      return this.store.createRecord('permission', {
        app: this.modelFor('app')
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'application',
        outlet: 'modal'
      });
    }
  });
});