define('carnival-frontend/components/rembr/stui-layout-list', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/stui-layouts', 'carnival-frontend/helpers/format-percentage-string', 'carnival-frontend/helpers/filter-desc', '@sailthru/stui-components'], function (exports, _abstractComponent, _stuiLayouts, _formatPercentageString, _filterDesc, _stuiComponents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var audienceIsUsed = function audienceIsUsed(value) {
    return value ? 'Yes' : 'No';
  };

  exports.default = _abstractComponent.default.extend({
    elementId: 'rembr_stui-layout-list',
    classNames: 'stui-layout-list-container',
    datalist: [],
    audiences: [],
    deviceCount: [],
    totalDeviceCount: [],

    // eslint-disable-next-line ember/no-observers
    deviceCounts: Ember.observer('audiences.@each.deviceCount', function () {
      return this.audiences.mapBy('deviceCount');
    }),

    // eslint-disable-next-line ember/no-observers
    deviceCountsContent: Ember.observer('deviceCounts.@each.content', function () {
      this.deviceCount = this.deviceCounts.mapBy('content');
      this.renderList();
      return this.deviceCounts.mapBy('content');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderList();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderList();
    },
    getFilterText: function getFilterText(hasAttributeOrLocationFilters, hasGeoFilters, selectedNonGeoFilters) {
      if (hasAttributeOrLocationFilters) {
        var filterString = '';
        selectedNonGeoFilters.map(function (filter) {
          filterString = '' + filterString + (0, _filterDesc.filterDescription)([filter]);
        });
        if (hasGeoFilters) {
          filterString = filterString + ' Location IS within selected regions';
        }
        return filterString;
      } else {
        return 'Everyone';
      }
    },
    buildAudienceTable: function buildAudienceTable() {
      var _this = this;

      var tableHeadData = ['Name', 'Filters', 'Used', 'Active Devices', 'Active %'];
      var tableBodyData = [];
      this.datalist.map(function (audience, index) {
        tableBodyData.push({
          columns: [audience.name, _this.getFilterText(audience.hasAttributeOrLocationFilters, audience.hasGeoFilters, audience.selectedNonGeoFilters), audienceIsUsed(audience.targeted), _this.deviceCount[index], (0, _formatPercentageString.formatPercentString)([audience.pushEnabledCount, _this.deviceCount[index]])]
        });
      });
      return React.createElement(_stuiComponents.Table, { version: 1, head: tableHeadData, body: tableBodyData });
    },
    buildListActions: function buildListActions() {
      return React.createElement(
        _stuiComponents.ButtonGroup,
        { alignItems: 'flex-end' },
        React.createElement(
          _stuiComponents.ContentDropdown,
          { trigger: React.createElement(
              _stuiComponents.Button,
              { variant: _stuiComponents.Button.VARIANT_SECONDARY },
              'Recent Imports'
            ), openOnClick: true },
          React.createElement(
            'div',
            { className: 'import-title' },
            'Recent Imports'
          ),
          React.createElement(
            'ul',
            { className: 'imports-list' },
            this.buildRecentImportsList()
          ),
          this.shouldDisplayImportFooter && React.createElement(
            'div',
            { className: 'import-footer' },
            'We\'re currently importing the audiences shown here - you can navigate away from this page safely, and we\'ll send you an email when they\'re done.'
          )
        ),
        React.createElement(
          _stuiComponents.ButtonDropdown,
          {
            label: 'Import Segment',
            variant: _stuiComponents.Button.VARIANT_SECONDARY
          },
          React.createElement(
            _stuiComponents.Button,
            {
              variant: _stuiComponents.Button.VARIANT_SECONDARY,
              onClick: this.openUserIdsModalAction
            },
            'User IDs'
          ),
          React.createElement(
            _stuiComponents.Button,
            {
              variant: _stuiComponents.Button.VARIANT_SECONDARY,
              onClick: this.openLocationsModalAction
            },
            'Locations'
          )
        ),
        React.createElement(
          _stuiComponents.Button,
          {
            onClick: this.createAudienceAction,
            variant: _stuiComponents.Button.VARIANT_PRIMARY
          },
          'Create Audience'
        )
      );
    },
    buildRecentImportsList: function buildRecentImportsList() {
      if (this.sortedImports.length === 0) {
        return React.createElement(
          'div',
          { className: 'import-nodata' },
          'No recently imported audiences'
        );
      } else return this.sortedImports.map(function (job) {
        return React.createElement(
          'li',
          { className: 'list-item' },
          React.createElement(
            'span',
            null,
            job.audienceName
          ),
          React.createElement('span', {
            className: job.importedAt ? 'glyphicon glyphicon-tick' : 'import-inprog'
          })
        );
      });
    },


    importsInProgress: Ember.computed('importAudienceJobs.@each.importedAt', function () {
      return this.importAudienceJobs.filter(function (job) {
        return !job.importedAt;
      }).sort(function (job) {
        return job.audienceName;
      });
    }),

    importsRecentlyCompleted: Ember.computed('importAudienceJobs.@each.importedAt', function () {
      return this.importAudienceJobs.filter(function (job) {
        return job.importedAt;
      }).sort(function (job) {
        return job.audienceName;
      });
    }),

    sortedImports: Ember.computed('importAudienceJobs.@each.importedAt', function () {
      return [].concat(_toConsumableArray(this.importsInProgress), _toConsumableArray(this.importsRecentlyCompleted));
    }),

    shouldDisplayImportFooter: Ember.computed('importsInProgress', function () {
      return this.importsInProgress.length > 0;
    }),

    buildFilterPanel: function buildFilterPanel() {
      var _this2 = this;

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(_stuiComponents.SearchInput, {
          value: this.searchText,
          placeholder: 'Search by audience name...',
          onChange: function onChange(str) {
            return _this2.searchAction(str);
          }
        }),
        React.createElement(_stuiComponents.RadioButtonGroup, {
          onChange: function onChange(e) {
            return _this2.sortAudienceAction(e.target.value);
          },
          display: 'grid',
          options: [{
            name: 'newest',
            value: 'newest',
            labelText: 'Newest',
            margin: '5px 0',
            checked: this.sort === 'newest'
          }, {
            name: 'oldest',
            value: 'oldest',
            labelText: 'Oldest',
            margin: '5px 0',
            checked: this.sort === 'oldest'
          }, {
            name: 'name',
            value: 'name',
            labelText: 'Name',
            margin: '5px 0',
            checked: this.sort === 'name'
          }]
        }),
        React.createElement(_stuiComponents.Checkbox, {
          size: 'big',
          labelText: 'Test Audiences Only',
          onChange: this.toggleOnlyTestAudiences,
          isChecked: this.onlyTest
        })
      );
    },
    renderList: function renderList() {
      var _this3 = this;

      this.reactRender(React.createElement(
        _stuiLayouts.ListLayout,
        {
          pageTitle: 'Mobile App Segments',
          cta: this.buildListActions(),
          filters: this.buildFilterPanel(),
          pagination: {
            currentPage: this.currentPage,
            totalItems: this.totalItems,
            perPage: this.perPage,
            showPageNumbers: true,
            onPageChange: function onPageChange(e, pageChangeType, pageNum) {
              _this3.onPageChange(pageNum);
            }
          }
        },
        this.buildAudienceTable()
      ));
    }
  });
});