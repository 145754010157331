define('carnival-frontend/controllers/audiences', ['exports', 'carnival-frontend/mixins/sort', 'carnival-frontend/mixins/fake-pagination', 'carnival-frontend/utils/audience-filter'], function (exports, _sort, _fakePagination, _audienceFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sort.default, _fakePagination.default, {
    queryParams: ['sort'],
    sort: 'newest',
    onlyTest: false,
    canCreateAudience: Ember.computed.or('model.isAdmin', 'model.isAuthor', 'currentUserService.user.superAdmin'),
    restrictedAudiences: Ember.computed.filterBy('filteredAudiences', 'isTheRestrictedParent', true),
    restrictedAudience: Ember.computed.alias('restrictedAudiences.firstObject'),
    noAudiences: Ember.computed.empty('datalist'),
    filteredAudiences: [],
    sortedAudiences: [],
    dataKey: 'sortedAudiences',
    datalist: [],
    searchText: '',
    per: 20,
    currentPage: Ember.computed('page', function () {
      return this.page - 1;
    }),
    showLayoutList: Ember.computed.alias('ldClient.flags.stui_layout_list'),

    // eslint-disable-next-line ember/no-observers
    filterAudience: Ember.observer('audiences.isFulfilled', 'onlyTest', 'searchText', function () {
      var _this = this;

      var audiences = this.audiences.filter(function (audience) {
        return (0, _audienceFilter.audienceFilter)(audience, _this.onlyTest);
      });

      var search = this.get('searchText').trim().toLowerCase();

      if (search !== '') {
        var matcher = function matcher(t) {
          return t.get('name').toLowerCase().indexOf(search) >= 0;
        };

        audiences = audiences.filter(function (t) {
          return matcher(t) || t.get('childAudiences') && t.get('childAudiences').any(matcher);
        });
      }

      this.set('filteredAudiences', audiences);
      this.setupPagination();
    }),

    // eslint-disable-next-line ember/no-observers
    sortDidChange: Ember.observer('sort', function () {
      this.setupPagination();
    }),

    setupPagination: function setupPagination() {
      var audiences = this.get('filteredAudiences');

      this.set('sortedAudiences', this.sortArray(this.get('sort'), audiences));
      this._super();
    },


    actions: {
      updatePage: function updatePage(pageNum) {
        this.set('page', pageNum + 1);
        this.set('datalist', this.getPageSlice(pageNum));
      },
      reloadAudiences: function reloadAudiences() {
        this.get('audiences').reload();
      },
      applyOnlyTest: function applyOnlyTest() {
        this.toggleProperty('onlyTest');
      },
      createAudience: function createAudience() {
        this.transitionToRoute('audiences.new');
      },
      sortAudience: function sortAudience(sort) {
        this.transitionToRoute('audiences', {
          queryParams: { sort: sort }
        });
      },
      openImportByUserIdsModal: function openImportByUserIdsModal(modal) {
        var controller = Ember.getOwner(this).lookup('controller:audiences.import.user-ids');
        this.send('openModal', modal, this.get('model'), controller);
      },
      openImportByLocationsModal: function openImportByLocationsModal(modal) {
        var controller = Ember.getOwner(this).lookup('controller:audiences.import.locations');
        this.send('openModal', modal, this.get('model'), controller);
      },
      updateSearchText: function updateSearchText(searchText) {
        this.set('searchText', searchText);
      }
    }
  });
});