define('carnival-frontend/app', ['exports', 'carnival-frontend/resolver', 'ember-load-initializers', 'carnival-frontend/config/environment', 'carnival-frontend/models/errors', 'ember-data'], function (exports, _resolver, _emberLoadInitializers, _environment, _errors, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  Ember.onerror = function (error) {
    if (error instanceof _errors.UnauthorizedError) {
      return window.location.replace('/');
    }

    if (error instanceof _emberData.default.InvalidError) {
      return;
    }

    throw error;
  };

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});