define('carnival-frontend/controllers/user', ['exports', 'carnival-frontend/models/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');

        if (!model.get('hasDirtyAttributes')) return;

        model.save().then(function () {
          _this.alert.success('Success!', 'Your details have been updated');
        }, function (error) {
          if (error instanceof _errors.UnauthorizedError) throw error;
        });
      },
      resetPassword: function resetPassword() {
        var _this2 = this;

        this.get('model').resetPassword().then(function () {
          _this2.alert.success('Success!', 'A reset password email has been sent to your inbox');
          _this2.send('closeModal');
        }, function (error) {
          _this2.alert.success('Error!', error && error.message || 'Whoops something went wrong');
          _this2.send('closeModal');
        });
      }
    }
  });
});