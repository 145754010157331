define('carnival-frontend/initializers/current-user-service', ['exports', 'carnival-frontend/services/current-user'], function (exports, _currentUser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.register('service:current-user', _currentUser.default);
    application.inject('service:current-user', 'store', 'service:store');

    application.inject('route', 'currentUserService', 'service:current-user');
    application.inject('controller', 'currentUserService', 'service:current-user');
    application.inject('component', 'currentUserService', 'service:current-user');
    application.inject('helper', 'currentUserService', 'service:current-user');
  }

  exports.default = {
    name: 'current-user-service',
    initialize: initialize
  };
});