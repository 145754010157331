define('carnival-frontend/helpers/pluralize-model', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pluralizeModel = pluralizeModel;
  function pluralizeModel(params) {
    var number = params[0],
        modelType = params[1],
        type = modelType.replace('-', ' '),
        singular = type,
        plural = (0, _emberInflector.pluralize)(type);

    if (number < 2) return singular;

    return typeof plural === 'string' ? plural : singular + 's';
  }

  exports.default = Ember.Helper.helper(pluralizeModel);
});