define('carnival-frontend/routes/logout', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model() {
      return this.store.peekRecord('session', 'current');
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.destroyRecord().then(function () {
        _this.logout();
      }).catch(function () {
        _this.logout();
      });
    },
    logout: function logout() {
      this.store.unloadAll();
      this.session.resetSession();

      if (this.env.environment !== 'test') {
        window.location.replace('https://' + this.env.auth0.domain + '/v2/logout?returnTo=' + window.location.origin);
      } else {
        this.replaceWith('login');
      }
    },


    actions: {
      error: function error() {
        this.logout();
      }
    }
  });
});