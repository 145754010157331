define('carnival-frontend/controllers/stats/custom-events', ['exports', 'carnival-frontend/mixins/stats/titles', 'carnival-frontend/mixins/stats/dates', 'carnival-frontend/mixins/stats/annotations', 'carnival-frontend/mixins/stats/csv-export', 'carnival-frontend/mixins/stats/platforms'], function (exports, _titles, _dates, _annotations, _csvExport, _platforms) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_titles.default, _dates.default, _annotations.default, _csvExport.default, _platforms.default, {
    queryParams: ['stat', 'from', 'to', 'eventId'],
    eventId: null,
    stat: 'custom_events',
    topbarTitle: 'Engagement',

    showPlatformFilters: true,

    // eslint-disable-next-line ember/no-observers
    resetEventId: Ember.observer('customEvents.isFulfilled', 'eventId', function () {
      if (this.get('customEvents.isFulfilled') && !this.get('eventId')) {
        var eventId = this.get('customEvents.content.0.id'),
            platforms = this.get('platforms');
        this.transitionToRoute({
          queryParams: { eventId: eventId, platforms: platforms }
        });
      }
    }),

    noEvents: Ember.computed('isEvents', 'customEvents.isFulfilled', function () {
      return this.get('isEvents') && Ember.isEmpty(this.get('customEvents'));
    })
  });
});