define('carnival-frontend/components/rembr/stui-input', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/stui-components'], function (exports, _abstractComponent, _stuiComponents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _objectWithoutProperties(obj, keys) {
    var target = {};

    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }

    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var castValue = function castValue(value) {
    return value === 'true' || value === 'false' ? JSON.parse(value) : value;
  };
  var stylesAsObject = function stylesAsObject(styles) {
    return styles.replace(/\s/g, '').split(';').map(function (s) {
      return s.split(':');
    }).reduce(function (obj, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          val = _ref2[1];

      return _extends({}, obj, _defineProperty({}, key, val));
    }, {});
  };

  exports.default = _abstractComponent.default.extend({
    attributeBindings: ['value', 'disabled', 'max', 'min'],
    classNameBindings: ['shouldRenderSTUIInput:stui-input'],
    shouldRenderSTUIInput: Ember.computed.alias('ldClient.flags.mobile_stui_component'),
    isDark: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('dark-input') > -1;
    }),
    isXS: Ember.computed('class', function () {
      return Ember.isPresent(this.class) && this.class.indexOf('input-xs') > -1;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('value', this, this.valueDidChange);
    },
    valueDidChange: function valueDidChange() {
      this.renderInput();
    },

    getStyleOverrides: function getStyleOverrides() {
      var style = this.attrs.style ? stylesAsObject(this.attrs.style) : {};

      style.padding = '8px';
      style.height = '36px';

      if (this.isDark) {
        style.backgroundColor = '#1e2124';
        style.color = '#fff';
        style.borderColor = '#1a1c1f';
      }
      if (this.isXS) {
        style.fontSize = '11px';
        style.padding = '2px 4px';
        style.lineHeight = '14px';
      }

      style.marginBottom = 0;

      return style;
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderInput();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderInput();
    },
    renderInput: function renderInput() {
      var _attrs = this.attrs,
          className = _attrs.class,
          attrs = _objectWithoutProperties(_attrs, ['class']);

      var props = Object.fromEntries(Object.entries(attrs).map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            key = _ref4[0],
            value = _ref4[1];

        return [key, castValue(value)];
      }));
      var value = this.get('value') || '';
      var comp = this;
      this.reactRender(React.createElement(_stuiComponents.Input, _extends({}, props, {
        'data-insight-id': this.dataInsightId,
        'data-test-id': this.dataABTestID,
        style: this.getStyleOverrides(),
        id: this.id,
        value: value.toString() || '',
        placeholder: this.placeholder,
        max: this.max,
        min: this.min,
        onChange: function onChange(e) {
          comp.set('value', e.target.value);
        }
      })));
    }
  });
});