define('carnival-frontend/services/scroller', ['exports', 'ember-scroll-to/services/scroller'], function (exports, _scroller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scroller.default.extend({
    // property needs to be `volatile` so it
    // gets recomputed on each `get` request
    // otherwise it will only work the first
    // time it's rendered e.g. when destroyed
    // the destroyed element is still the
    // referenced DOM element.
    scrollable: Ember.computed(function () {
      return Ember.$('#scroll-to-container');
    }).volatile() // eslint-disable-line ember/no-volatile-computed-properties
  });
});