define('carnival-frontend/components/form-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    form: null, // reference to form wrapper
    beforeSubmit: null, // to be called before submitting the form
    onReset: null, // to be called when the form is reset

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var form = this.form;


      if (form) {
        form.registerField(this);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var form = this.form;


      if (form) {
        form.unregisterField(this);
      }
    },
    submit: function submit() {
      var beforeSubmit = this.beforeSubmit;


      if (beforeSubmit) {
        return beforeSubmit.bind(this)();
      }
      return true;
    },
    reset: function reset() {
      var onReset = this.onReset;


      if (onReset) {
        onReset();
      }
    }
  });
});