define('carnival-frontend/components/stats/annotation-graph', ['exports', 'carnival-frontend/models/annotation-group'], function (exports, _annotationGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    app: Ember.computed.reads('currentAppService.app'),
    user: Ember.computed.reads('currentUserService.user'),
    canEdit: Ember.computed.or('user.isSuperSomething', 'app.isAdmin', 'app.isAuthor'),

    tooltipHidden: true,
    shadowAnnotationVisible: false,
    granularity: 'hour',

    timeInterval: Ember.computed('granularity', function () {
      var granularity = Ember.String.capitalize(this.get('granularity'));
      return d3['time' + granularity];
    }),

    scaleX: Ember.computed('domain', 'graphWidth', function () {
      if (!this.get('graphWidth')) return null;
      if (!this.get('domain')) return null;
      return d3.scaleTime().domain(this.get('domain')).rangeRound([0, this.get('graphWidth')]);
    }),

    annotationGroups: Ember.computed('annotations.[]', 'scaleX', 'timeInterval', function () {
      if (!this.get('annotations')) return null;
      if (!this.get('scaleX')) return null;

      var groups = Ember.A(),
          self = this;

      this.get('annotations').forEach(function (annotation) {
        if (!annotation.isInDomain(self.get('domain'))) return;

        var group = self.groupForAnnotation(groups, annotation);
        group.get('annotations').addObject(annotation);
        groups.addObject(group);
      });

      return groups;
    }),

    selectedAnnotationGroup: Ember.computed('annotationGroups', 'selectedIndex', function () {
      var selectedIndex = this.get('selectedIndex');
      if (typeof selectedIndex === 'undefined') return null;
      if (selectedIndex < 0) return null;
      if (selectedIndex >= this.get('annotationGroups.length')) return null;
      return this.get('annotationGroups')[this.get('selectedIndex')];
    }),

    actions: {
      showTooltip: function showTooltip(annotationGroup) {
        var selectedIndex = this.get('annotationGroups').indexOf(annotationGroup);
        this.set('selectedIndex', selectedIndex);
        this.set('tooltipHidden', false);
        return false;
      },
      editAnnotation: function editAnnotation(annotation) {
        this.sendAction('editAnnotationAction', annotation);
        this.set('tooltipHidden', true);
      },
      removeAnnotation: function removeAnnotation(annotation) {
        this.sendAction('removeAnnotationAction', annotation);
        this.set('tooltipHidden', true);
      }
    },

    didInsertElement: function didInsertElement() {
      Ember.$(window).on('debouncedresize', Ember.run.bind(this, this.resizeGraph));
      Ember.run.scheduleOnce('afterRender', this, this.resizeGraph);
    },
    resizeGraph: function resizeGraph() {
      this.set('graphWidth', this.$().width());
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).off('debouncedresize');
    },
    click: function click(event) {
      if (!this.get('canEdit')) return;
      this.set('tooltipHidden', true);
      if (!this.get('scaleX')) return;
      var offsetX = event.pageX - this.$().offset().left,
          date = this.get('scaleX').invert(offsetX || 0);
      this.sendAction('dateSelectAction', date);
    },
    mouseMove: function mouseMove(event) {
      if (!this.get('scaleX')) return;

      var offsetX = event.pageX - this.$().offset().left;

      this.$('.shadow').css('left', offsetX + 'px');
      this.set('selectedDate', this.get('scaleX').invert(offsetX));
    },
    mouseEnter: function mouseEnter(e) {
      this.set('shadowAnnotationVisible', e.target === this.$().get(0));
    },
    mouseLeave: function mouseLeave() {
      this.set('shadowAnnotationVisible', false);
    },
    groupForAnnotation: function groupForAnnotation(groups, annotation) {
      var scaleX = this.get('scaleX'),
          timeInterval = this.get('timeInterval');

      if (!groups) return null;
      if (!scaleX) return null;
      if (typeof timeInterval !== 'function') return null;

      var clampedDate = timeInterval(annotation.get('annotatedAt')),
          x = scaleX(clampedDate);
      var group = groups.find(function (group) {
        return group.get('x') === x;
      });

      return group || _annotationGroup.default.create({
        scale: scaleX,
        timeInterval: timeInterval
      });
    }
  });
});