define('carnival-frontend/mixins/pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['page', 'per'],
    page: 1,
    per: 18,
    maxPageLinks: 10,
    resultType: '',

    totalResults: Ember.computed('model', function () {
      return this.get('model.meta').total;
    }),

    showPrevious: Ember.computed('page', 'numPages', function () {
      return this.get('page') > 1;
    }),

    showNext: Ember.computed('page', 'numPages', function () {
      return this.get('page') < this.get('numPages');
    }),

    numPages: Ember.computed('per', 'totalResults', function () {
      return Math.ceil(this.get('totalResults') / this.get('per'));
    }),

    numPageLinks: Ember.computed('numPages', 'maxPageLinks', function () {
      var numPages = this.get('numPages'),
          maxPageLinks = this.get('maxPageLinks');

      return numPages <= maxPageLinks ? numPages : maxPageLinks;
    }),

    numList: Ember.computed('page', 'numPageLinks', 'maxPageLinks', function () {
      var numPageLinks = this.get('numPageLinks'),
          maxPageLinks = this.get('maxPageLinks'),
          page = this.get('page'),
          pages = [],
          value;

      for (var i = 0; i < numPageLinks; i++) {
        value = i;
        if (page > maxPageLinks) {
          value = page + i - maxPageLinks;
        }
        pages[i] = value + 1;
      }

      return pages;
    }),

    previousPage: Ember.computed('page', function () {
      var currentPage = this.get('page');
      return currentPage === 1 ? currentPage : currentPage - 1;
    }),

    nextPage: Ember.computed('page', 'numPages', function () {
      var currentPage = this.get('page');
      return currentPage === this.get('numPages') ? currentPage : currentPage + 1;
    })
  });
});