define('carnival-frontend/helpers/certificate-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    P8: 'apns_p8_certificate',
    PROD_P12: 'apns_certificate',
    DEV_P12: 'apns_dev_certificate'
  };
});