define('carnival-frontend/helpers/format-percentage-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPercentString = formatPercentString;
  function formatPercentString(params) {
    var count = params[0],
        total = params[1],
        type = params[2],
        percentage = Math.floor(count / total * 100, -1);

    if (type == 'push-enabled') {
      if (count == 0 || total == 0) return 'No push enabled devices';
      if (percentage < 1) {
        return 'Less than 1% are push enabled';
      }
      return percentage.toFixed() + '% are push enabled.';
    } else if (type == 'devices') {
      if (percentage < 1) {
        return 'Less than 1% of all';
      }
      return percentage > 99.99 ? 'all' : percentage.toFixed() + '% of all';
    }

    return (count / total * 100 || 0).toFixed(1) + '%';
  }

  exports.default = Ember.Helper.helper(formatPercentString);
});