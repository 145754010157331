define('carnival-frontend/routes/application', ['exports', 'carnival-frontend/routes/route', 'carnival-frontend/models/errors'], function (exports, _route, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    beforeModel: function beforeModel(transition) {
      if (this.session.csrf) return;
      if (transition.targetName.match(/missing|login|forgot-password|invitations|reset-password|unlock-account|support|blocked-account/)) return;

      // store the url of the intended target url so
      // if a user navigates to a protected page we can
      // transition them to that page after they log in
      // `transitionURL` is set in Login routes `redirect` hook
      if (Ember.$.cookie('transitionURL')) {
        var url = Ember.$.cookie('transitionURL');
        Ember.$.removeCookie('transitionURL');
        transition.abort();
        window.location.hash = url;

        // must reload if the url has query params
        // otherwise ember blows up (no idea why)
        return window.location.reload(true);
      }

      this.session.loginTransition = transition;
      this.transitionTo('perform-login');
    },


    actions: {
      logout: function logout() {
        this.transitionTo('logout');
      },
      error: function error(reason, transition) {
        if (reason instanceof _errors.UnauthorizedError) {
          if (transition) transition.abort();
          if (this.session) this.session.resetSession();
          this.replaceWith('login');
          return;
        }

        if (reason instanceof _errors.ForbiddenError) {
          transition.abort();
          this.replaceWith('no_access');
          return;
        }

        if (reason instanceof _errors.NotFoundError) {
          transition.abort();
          this.replaceWith('four-o-four');
          return;
        }

        throw reason;
      },
      openModal: function openModal(modalName, model, controller) {
        // Example usage from within your templates
        // 1. <button {{action 'openModal' 'apps/create' 'app'}}>Create App</button>
        // 2. <button {{action 'openModal' 'apps/edit' appModel}}>Edit App</button>
        // 3. <button {{action 'openModal' 'apps/delete' appModel}}>Delete App</button>
        // 4. <button {{action 'openModal' 'apps/edit' appModel 'apps/someOtherController'}}>Edit App</button>

        var params = {
          outlet: 'modal',
          into: 'application'
        };

        // If you dont pass an actual model assume you want to create one
        // eg. if you pass the string 'audience' rather than an actual model
        // this will create an audience model
        if (model) {
          if (typeof model === 'string') {
            model = this.store.createRecord(model);
          }
          params.model = model;
        }

        // If you dont pass a controller Ember will use matching template controller by default
        // eg. If you passed 'audiences/create' as a template (modalName) then Ember will
        // look for the 'audiences/create' controller.
        // This allows us to override the controller if we need to
        if (controller) params.controller = controller;

        return this.render(modalName, params);
      },
      closeModal: function closeModal() {
        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }
    }
  });
});