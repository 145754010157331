define('carnival-frontend/components/audiences/facet-search', ['exports', 'carnival-frontend/mixins/audiences/filters'], function (exports, _filters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_filters.default, {
    classNames: ['custom-facets-search'],
    classNameBindings: ['footerIsOpen:facets-footer-open'],
    footerIsOpen: false,
    searchText: '',
    customEndpoint: 'custom_facets',
    isEventType: Ember.computed.equal('customEndpoint', 'custom_events_facets'),
    showAddCustomForm: true,

    newAttrName: '',
    newAttrType: '',
    dataTypes: ['integer', 'float', 'string', 'boolean', 'date'],

    facets: Ember.computed('searchQuery.content', function () {
      return this.get('searchQuery');
    }),

    searchQuery: Ember.computed('searchText', function () {
      return this.get('audience').findCustomFilterNamesByField(this.get('searchText'), this.get('customEndpoint'));
    }),

    // eslint-disable-next-line ember/no-observers
    deselectFacetFilter: Ember.observer('searchText', function () {
      if (this.get('selectedFacetFilter')) this.set('selectedFacetFilter', null);
    }),

    disableAddAttr: Ember.computed('newAttrName', 'newAttrType', function () {
      if (Ember.isBlank(this.get('newAttrName'))) return true;
      if (Ember.isBlank(this.get('newAttrType')) && !this.get('isEventType')) return true;
      return false;
    }),

    resetAddAttr: function resetAddAttr() {
      this.setProperties({
        newAttrName: '',
        newAttrType: ''
      });
    },
    sanitizeAttr: function sanitizeAttr(str, isEvent) {
      return isEvent ? str.replace(/\./g, '\uFF0E').replace(/\$/g, '\uFF04') : str.replace(/[^\w\s.-]/g, '').trim().replace(/\s+|\.+/g, '_').substring(0, 255);
    },


    actions: {
      selectFacetFilter: function selectFacetFilter(facetFilter) {
        this.updateSelectedFacetFilter(facetFilter);
      },
      addAttribute: function addAttribute(type, name) {
        if (Ember.isBlank(name)) return;

        var isEvent = this.get('isEventType'),
            sanitizedName = this.sanitizeAttr(name, isEvent);

        if (this.get('dataTypes').indexOf(type) === -1 && !isEvent) return;

        // if no type is passed assume it's an event
        var key = type ? 'custom.' + type + '.' : 'events.',
            ffs = this.get('facetFilters');

        key += sanitizedName;

        // check if this attr already exists
        if (ffs.findBy('field', key)) return;

        // will implicity add to facet filters during mapping
        this.mapFilter(key);

        // put new facet filter at the top of the stack
        var ff = ffs.popObject();
        ffs.unshiftObject(ff);

        // preselect facet filter
        this.updateSelectedFacetFilter(ff);

        // set add attr values
        this.resetAddAttr();
      },
      toggleFooter: function toggleFooter() {
        this.toggleProperty('footerIsOpen');
      }
    }
  });
});