define('carnival-frontend/components/messages/composer-ab-test-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    messageBuilder: Ember.inject.service(),

    elementId: 'ab-test-header',

    messages: Ember.computed.alias('model.messages'),

    activeVariationName: '',

    activeVariation: Ember.computed('activeVariationName', 'messages.@each', function () {
      return this.get('messages').findBy('variationName', this.get('activeVariationName'));
    }),

    popupText: Ember.computed('activeVariation', 'activeVariation.variationRatio', function () {
      var message = this.get('activeVariation');

      if (!message || this.get('messages.length') <= 1) return 'AB testing';

      return 'Variation ' + message.get('variationName') + ' (' + message.get('variationRatio') + '%)';
    }),

    actions: {
      addMessage: function addMessage(params) {
        this.get('messageBuilder').addMessageToAbTest(this.get('model'), params);
        this.set('activeVariationName', params.name);
      },
      removeMessage: function removeMessage() {
        this.get('messageBuilder').removeMessageFromAbTest(this.get('model'));
        this.set('activeVariationName', this.get('messages.lastObject').get('variationName'));

        if (this.get('messages.length') <= 1) this.sendAction('oneMessageRemaining', this.get('messages.firstObject.id'));
      },
      updateMessage: function updateMessage(params) {
        this.get('model').updateMessage(params.get('name'), {
          variationRatio: params.get('ratio')
        });
      }
    }
  });
});