define('carnival-frontend/components/settings/select-cert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',
    classNames: 'certificate-container',
    classNameBindings: ['certificate.cn:has-certificate', 'model.bundleIdentifier::disabled'],
    type: '',
    certificate: {},
    isDevType: Ember.computed.equal('type', 'dev'),
    defaultErrorMsg: 'This is not a valid apns certificate.',

    change: function change(event) {
      var file = event.target.files[0];

      if (!file) return;

      // assume single file selection only
      this.handleFileSelection(file);
    },
    click: function click() {
      this.$('input[type="file"]').val(null);
    },
    handleFileSelection: function handleFileSelection(file) {
      var that = this,
          reader = new FileReader(),
          fileName = file.name || file.fileName || '',
          fileExt = fileName.split('.').pop().toLowerCase(),
          model = this.get('model');

      model.save().then(function () {
        if (fileExt === 'p12' || file.type === 'application/x-pkcs12') {
          reader.onload = function () {
            that.processCertificate(reader.result);
          };
          reader.readAsDataURL(file);
        } else if (fileExt === 'p8') {
          reader.onload = function () {
            that.save(reader.result);
          };
          reader.readAsText(file);
        } else if (fileExt === 'pem') {
          reader.onload = function () {
            that.save(reader.result);
          };
          reader.readAsText(file);
        } else {
          that.sendAction('alertError', 'Certificate Error: ', that.get('defaultErrorMsg'));
        }
      });
    },
    processCertificate: function processCertificate(encodedCert) {
      var that = this;

      this.get('model').convertCertificateToPem(encodedCert).then(function (response) {
        that.save(response.certificate);
      }, function (reason) {
        var errors = reason.errors;

        if (errors && errors.length) {
          var errorText = errors.mapBy('detail').join(' ');

          that.sendAction('alertError', 'Error: ', errorText);
        }
      });
    },
    save: function save(cert) {
      var model = this.get('model'),
          that = this;
      var type = this.get('type');

      model.saveCertificate(cert, type).then(function (response) {
        that.set('certificate', response.certificate);
        that.sendAction('alertSuccess', 'Certificate: ', 'Uploaded and saved successfully.');
      }, function (response) {
        var errors = response.errors.errors || response.errors[0].detail || that.get('defaultErrorMsg'),
            message = errors.certificate || errors.apns_dev_certificate || errors.apns_certificate || errors;

        that.sendAction('alertError', 'Certificate Error: ', S(message).humanize().s);
      });
    },


    actions: {
      removeCertificate: function removeCertificate() {
        var model = this.get('model'),
            that = this;

        model.removeCertificate(this.get('type')).then(function () {
          that.set('certificate', {});
          that.sendAction('alertSuccess', 'Certificate: ', 'Removed successfully.');
        });
      }
    }
  });
});