define('carnival-frontend/routes/logs', ['exports', 'carnival-frontend/routes/route', 'carnival-frontend/models/errors'], function (exports, _route, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    redirect: function redirect() {
      if (!this.modelFor('app').get('isAdminOrDeveloper') && !this.get('currentUserService.user.isSuperSomething')) {
        this.transitionTo('/');
      }
    },


    actions: {
      error: function error(_error) {
        if (_error && _error instanceof _errors.ForbiddenError) {
          this.transitionTo('settings.developer.no-access');
        }

        return true;
      }
    }
  });
});