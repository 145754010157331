define('carnival-frontend/helpers/pluralize-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pluralizeString = pluralizeString;
  function pluralizeString(params, hash) {
    var number = params[0],
        singular = hash.singular,
        plural = hash.plural;

    if (number === 1) return singular;

    return typeof plural === 'string' ? plural : singular + 's';
  }

  exports.default = Ember.Helper.helper(pluralizeString);
});