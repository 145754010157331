define('carnival-frontend/components/messages/composer-push', ['exports', 'carnival-frontend/models/category', 'lodash.clonedeep', 'carnival-frontend/models/interruption-level'], function (exports, _category, _lodash, _interruptionLevel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['push-editable-container', 'editable-container'],
    classNameBindings: ['uneditable:disabled'],

    categoryButtonsEnabled: Ember.computed.alias('ldClient.flags.mobile_notification_buttons'),

    hasEditableFields: Ember.computed('notification.{badge,category,sound,_u,interruptionLevel,relevanceScore,customPayloadData.length}', function () {
      if (this.get('notification')) {
        var presetFields = this.get('notification').getProperties('badge', 'category', 'sound', '_u', 'interruptionLevel', 'relevanceScore');
        var presetFieldCount = Object.keys(presetFields).filter(function (field) {
          return presetFields[field] !== undefined;
        }).length;
        var customFieldCount = this.get('notification.customPayloadData.length');

        return presetFieldCount > 0 || customFieldCount > 0;
      }

      return false;
    }),

    canAddBadge: Ember.computed.equal('notification.badge', undefined),
    canAddSound: Ember.computed.equal('notification.sound', undefined),
    canAddCategory: Ember.computed.equal('notification.category', undefined),
    canAddUrl: Ember.computed.equal('notification._u', undefined),
    canAddInterruptionLevel: Ember.computed.equal('notification.interruptionLevel', undefined),
    canAddRelevanceScore: Ember.computed.equal('notification.relevanceScore', undefined),

    categoryNotSelected: Ember.computed.equal('notification.selectedCategory', undefined),
    selectedCategory: Ember.computed.alias('notification.selectedCategory'),
    categories: _category.categories,
    categoryDropdownText: Ember.computed('notification.selectedCategory', function () {
      if (this.categoryNotSelected) {
        return 'Select an option';
      }
      return this.get('notification.selectedCategory').name;
    }),

    interruptionLevels: _interruptionLevel.interruptionLevels,
    interruptionLevel: Ember.computed.alias('notification.interruptionLevel'),
    interruptionLevelDropdownText: Ember.computed('notification.interruptionLevel', function () {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = _interruptionLevel.interruptionLevels[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var level = _step.value;

          if (level.value === this.interruptionLevel) {
            return level.name;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return 'Select an option';
    }),

    relevanceScore: Ember.computed.alias('notification.relevanceScore'),
    relevanceScoreRange: {
      min: 0.0,
      max: 1.0,
      step: 0.01,
      digits: 2,
      labels: [0, 0.25, 0.5, 0.75, 1]
    },
    relevanceScoreDisplay: Ember.computed('notification.relevanceScore', function () {
      var relevanceScore = this.get('notification.relevanceScore');
      return Number.parseFloat(relevanceScore).toFixed(2);
    }),

    response: Ember.computed.alias('notification.scoreResponse'),
    isLoadingResponse: Ember.computed.alias('notification.scoreResponse.loading'),
    alert: Ember.computed.alias('notification.alert'),

    actions: {
      addPush: function addPush(model) {
        var notification = this.store.createRecord('notification');
        model.get('notifications').addObject(notification);
      },
      deletePush: function deletePush(model) {
        // In the case where a notification is a draft or scheduled and has already been saved
        // rollbackAttributes will only delete if the notifications are that new
        // we need to explicitly set notifications to an empty array in that case
        model.get('notifications').invoke('rollbackAttributes');
        model.set('notifications', []);
      },
      addPayloadField: function addPayloadField(field) {
        this.set('notification.' + field, '');
      },
      deletePayloadField: function deletePayloadField(field) {
        this.set('notification.' + field, undefined);
      },
      setPlaceholderCategory: function setPlaceholderCategory() {
        this.set('notification.category', '');
      },
      addCustomField: function addCustomField() {
        this.get('notification.customPayloadData').addObject(Ember.Object.create());
      },
      deleteCustomField: function deleteCustomField(field) {
        this.get('notification.customPayloadData').removeObject(field);
      },
      openModal: function openModal(modalName, model, controller) {
        this.sendAction('openModal', modalName, model, controller);
      },
      selectCategory: function selectCategory(category) {
        if (category) {
          var clone = (0, _lodash.default)(category);
          this.set('notification.selectedCategory', clone);
          this.set('notification.category', clone.id);
        } else {
          this.set('notification.selectedCategory', undefined);
          this.set('notification.category', undefined);
        }
      },
      selectInterruptionLevel: function selectInterruptionLevel(interruptionLevel) {
        this.set('notification.interruptionLevel', interruptionLevel.value);
      },
      selectRelevanceScore: function selectRelevanceScore(relevanceScore) {
        this.set('notification.relevanceScore', relevanceScore);
      }
    }
  });
});