define('carnival-frontend/components/settings/cert-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['certificate', 'certType'],

    cn: Ember.computed('certificate.cn', function () {
      var cn = this.get('certificate.cn');
      return cn.substr(0, cn.indexOf(':'));
    }),

    isValidP8Cert: Ember.computed('certificate.{has_key?,has_team_id?,has_key_id?}', function () {
      if (this.get('certificate.has_key?') && this.get('certificate.has_team_id?') && this.get('certificate.has_key_id?')) {
        return JSON.parse(this.get('certificate.has_key?')) && JSON.parse(this.get('certificate.has_team_id?')) && JSON.parse(this.get('certificate.has_key_id?'));
      }
      return false;
    }),

    isValid: Ember.computed('certificate.expires', function () {
      return +new Date() < +new Date(this.get('certificate.expires'));
    }),

    actions: {
      removeCertificate: function removeCertificate() {
        this.sendAction('removeCertificate');
      }
    }
  });
});