define('carnival-frontend/components/breakdown-stat', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['breakdown-stat'],

    statBarStyles: Ember.computed('stat.percent', function () {
      var percent = Math.round(this.get('stat.percent')) || 0;

      return new Ember.String.htmlSafe('width:' + percent + '%;');
    })
  });
});