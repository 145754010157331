define('carnival-frontend/components/expandable-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['expandable-table'],
    items: [],
    expanded: false,

    displayedItems: Ember.computed('items', 'expanded', function () {
      if (this.get('expanded')) return this.get('items');
      return this.get('items').slice(0, 10);
    }),

    more: Ember.computed('items.length', 'expanded', function () {
      var morePurchases = this.get('items.length') - 10;
      if (morePurchases <= 0 || this.get('expanded')) return null;
      return morePurchases + ' More \u2026';
    }),

    actions: {
      expand: function expand() {
        this.set('expanded', true);
      }
    }
  });
});