define('carnival-frontend/mixins/messages/link', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    url: _emberData.default.attr('string'),

    // eslint-disable-next-line ember/no-observers
    urlChanged: Ember.observer('url', function () {
      if (!this.get('url')) return;
      this.set('url', this.get('url').trim());
    })
  });
});