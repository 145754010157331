define('carnival-frontend/services/recipients-list', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    recipientsList: Ember.inject.service(),

    export: function _export(message) {
      var exportMessageRecipientsJob = this.get('store').createRecord('export-message-recipients-job', { message: message });

      return new Promise(function (resolve, reject) {
        exportMessageRecipientsJob.createExportRecipientsJob().then(function (response) {
          resolve(response);
        }, function (error) {
          if (error instanceof _emberData.default.InvalidError) {
            var errorsHash = _emberData.default.errorsArrayToHash(error.errors);

            if (Ember.isPresent(errorsHash.base)) {
              return reject(new Error('Couldn\'t request recipients list because ' + errorsHash.base.join(', ') + '.'));
            }

            if (Ember.isPresent(errorsHash.message)) {
              return reject(new Error('Message: ' + errorsHash.message.join(', ') + '.'));
            }
          }

          return reject(new Error('An unexpected error happened, please try again.'));
        });
      });
    }
  });
});