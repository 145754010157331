define('carnival-frontend/components/stats/opens-graph', ['exports', 'moment', 'carnival-frontend/helpers/tooltip-helper'], function (exports, _moment, _tooltipHelper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    margin: { top: 40, right: 20, bottom: 30, left: 50 },

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$(window).on('debouncedresize', function () {
        return _this.draw();
      });

      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$(window).trigger('debouncedresize');
      });

      var tooltip = new _tooltipHelper.default(this.$(), this.$('.popup-tooltip'), this.$('.arrow', this.$('.popup-tooltip')), this.margin);

      this.set('tooltip', tooltip);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('debouncedresize');
    },


    // eslint-disable-next-line ember/no-observers
    draw: Ember.observer('data', function () {
      // Graph dimensions
      // ****************

      var margin = this.margin,
          width = this.$().width() - margin.left - margin.right,
          height = this.$().height() - margin.top - margin.bottom;

      // Data
      // ****

      var data = this.get('data');

      // Scales
      // ******

      var min = (0, _moment.default)(d3.min(data, function (d) {
        return (0, _moment.default)(d.date).valueOf();
      })).startOf('hour'),
          max = (0, _moment.default)(d3.max(data, function (d) {
        return (0, _moment.default)(d.date).valueOf();
      })).endOf('hour');

      var x = d3.scaleTime().domain([min, max]).range([0, width]);

      var fields = d3.keys(data[0]).filter(function (key) {
        return key !== 'date';
      });

      var y = d3.scaleLinear().range([height, 0]);

      // Axises
      // ******

      var xAxis = d3.axisBottom().scale(x);

      var yAxis = d3.axisLeft().scale(y).ticks(5).tickSize(-width).tickPadding(5);

      // Render SVG elements
      // *******************

      var elementId = '#' + this.get('elementId');

      d3.select(elementId + '> svg').remove();

      var svg = d3.select(elementId).append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom).append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // Bars
      // ****

      var stack = d3.stack().keys(fields);
      var series = stack(data);

      var yMax = d3.max(series[series.length - 1], function (d) {
        return d[1];
      });

      y.domain([0, Math.ceil(yMax * 1.1) || 4]);

      svg.append('g').attr('class', 'x axis').attr('transform', 'translate(0,' + height + ')').call(xAxis);

      svg.append('g').attr('class', 'y axis').call(yAxis);

      var barWidth = width / d3.timeHours(min, max).length;

      var bars = svg.selectAll('.bars').data(series).enter().append('g').attr('class', function (d) {
        return 'bars-' + d.key;
      });

      bars.selectAll('.bar').data(function (d) {
        return d;
      }).enter().append('rect').attr('class', 'bar').attr('x', function (d, i) {
        return i * barWidth;
      }).attr('y', function (d) {
        return y(d[1]);
      }).attr('width', barWidth - 1).attr('height', function (d) {
        return y(d[0]) - y(d[1]);
      });

      // Flag
      // ****

      var publishAt = this.get('publishAt'),
          flagX = x(publishAt),
          flagH = 24,
          flagY = flagH - this.margin.top;

      var flag = svg.append('g').attr('class', 'flag').attr('transform', 'translate(' + flagX + ',0)');

      flag.append('line').attr('class', 'mast').attr('x1', 0).attr('y1', height).attr('x2', 0).attr('y2', flagY);

      flag.append('rect').attr('x', 0).attr('y', flagY - flagH).attr('width', 140).attr('height', flagH);

      flag.append('text').attr('x', 15).attr('y', flagY - flagH + 16).text('Published ' + publishAt.format('LT'));
    }),

    mouseMove: function mouseMove(e) {
      var tooltip = this.get('tooltip');

      if (!Ember.$(e.target).hasClass('bar')) return tooltip.hideTooltip();

      var d = e.target.__data__;
      var key = e.target.parentNode.__data__.key;

      this.set('tooltipData', { count: d.data[key], open: key });

      var offset = this.$().offset();

      tooltip.showTooltipAt(e.pageX - offset.left, e.pageY - offset.top);
    }
  });
});