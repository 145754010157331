define('carnival-frontend/controllers/stats/geo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    stats: Ember.inject.controller('stats/geo/index'),
    appId: Ember.computed.oneWay('currentAppService.app.id'),

    actions: {
      downloadCountries: function downloadCountries() {
        location.href = '/web/v1/apps/' + this.get('appId') + '/countries.csv?from=' + this.get('stats.fromDate');
      },
      downloadLocales: function downloadLocales() {
        location.href = '/web/v1/apps/' + this.get('appId') + '/locales.csv?from=' + this.get('stats.fromDate');
      },
      downloadTimeZones: function downloadTimeZones() {
        location.href = '/web/v1/apps/' + this.get('appId') + '/time_zones.csv?from=' + this.get('stats.fromDate');
      }
    }
  });
});