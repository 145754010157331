define('carnival-frontend/components/infinite-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['infinite-list'],
    dataSource: null,
    isRequesting: false,
    triggerGap: 50,

    // eslint-disable-next-line ember/no-observers
    datalistDidChange: Ember.observer('dataSource.datalist', function () {
      this.$().scrollTop(0);
      this._setupScrollTracking();
    }),

    didInsertElement: function didInsertElement() {
      this._setupScrollTracking();
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('scroll');
    },
    _setupScrollTracking: function _setupScrollTracking() {
      this.$().on('scroll', Ember.run.bind(this, this._scrollHandler));
    },
    _scrollHandler: function _scrollHandler() {
      if (this.get('isRequesting')) return;

      Ember.run.debounce(this, this._setOffset, 500);

      var el = this.element,
          offset = el.scrollHeight - el.scrollTop - el.clientHeight;

      if (!this.get('isLoaded') && offset <= this.get('triggerGap')) {
        this._fetchNext();
      }
    },
    _setOffset: function _setOffset() {
      this.set('scrollOffset', this.element.scrollTop);
    },
    _fetchNext: function _fetchNext() {
      var _this = this;

      var source = this.get('dataSource');

      this.set('isRequesting', true);
      source.fetchNext().then(function (models) {
        if (Ember.isPresent(models)) {
          source.get('datalist').addObjects(models);
        } else {
          _this.set('isLoaded', true);
        }
        _this.set('isRequesting', false);
        source.incrementProperty('page');
      });
    }
  });
});