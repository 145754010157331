define('carnival-frontend/services/alert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    message: null,

    info: function info(title, message) {
      this._createMessage(title, message, 'alert-info');
    },
    success: function success(title, message) {
      this._createMessage(title, message, 'alert-success');
    },
    warning: function warning(title, message) {
      this._createMessage(title, message, 'alert-warning');
    },
    danger: function danger(title, message) {
      this._createMessage(title, message, 'alert-danger');
    },
    _createMessage: function _createMessage(title, message, type) {
      this.set('message', Ember.Object.create({ title: title, text: message, type: type }));
    }
  });
});