define('carnival-frontend/components/admin/app-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    // eslint-disable-next-line ember/no-observers
    autosave: Ember.observer('app.accountState', function () {
      var model = this.get('app');

      if (model.get('hasDirtyAttributes')) model.save();
    })
  });
});