define('carnival-frontend/components/step-slider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['step-slider'],
    classNameBindings: ['disabled:disabled'],
    attributeBindings: ['style'],
    min: 0,
    max: 100,
    step: 1,
    digits: 0,
    width: 400,
    handled: false,
    disabled: false,

    style: Ember.computed(function () {
      return new Ember.String.htmlSafe('width:' + this.width + 'px;');
    }),

    handleStyle: Ember.computed('xPos', function () {
      return new Ember.String.htmlSafe('left:' + this.get('xPos') + 'px;');
    }),

    fillStyle: Ember.computed('xPos', function () {
      return new Ember.String.htmlSafe('width:' + this.get('xPos') + 'px;');
    }),

    xPos: Ember.computed('value', function () {
      return (this.get('value') - this.min) / (this.max - this.min) * this.width;
    }),

    value: Ember.computed({
      get: function get() {
        return this.min;
      },
      set: function set(key, value) {
        if (isNaN(parseFloat(value))) return this.min;
        return this.getStep(value).toFixed(this.digits) / 1;
      }
    }),

    click: function click(e) {
      this.updateValue(e);
    },
    mouseDown: function mouseDown() {
      this.set('handled', true);
    },
    mouseUp: function mouseUp() {
      this.set('handled', false);
    },
    mouseLeave: function mouseLeave() {
      this.set('handled', false);
    },
    mouseMove: function mouseMove(e) {
      if (!this.get('handled')) return;
      this.updateValue(e);
    },
    updateValue: function updateValue(e) {
      var rect = this.element.getBoundingClientRect(),
          xPos = this.getCappedXPos(e.pageX - rect.left),
          value = this.getValueByXPos(xPos);

      this.set('value', value);
    },
    getCappedXPos: function getCappedXPos(xPos) {
      if (xPos < 0) xPos = 0;else if (xPos > this.width) xPos = this.width;
      return xPos;
    },
    getValueByXPos: function getValueByXPos(xPos) {
      return this.min + (this.max - this.min) * (xPos / this.width);
    },
    getStep: function getStep(value) {
      var step = Math.round(value / this.step) * this.step;
      if (step < this.min) step = this.min;else if (step > this.max) step = this.max;
      return step;
    }
  });
});