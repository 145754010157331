define('carnival-frontend/mixins/audiences/es-ranges', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getCountFromRange: function getCountFromRange(range) {
      if (Ember.isEmpty(range) || Object.keys(range).length === 0) return 0;

      if (!isNaN(parseInt(range.days))) return range.days;
      if (!isNaN(parseInt(range.count))) return range.count;

      switch (this.getEquality(range)) {
        case 'less':
          return this.getCount(range.gte);
        case 'more':
          return this.getCount(range.lte);
      }
    },
    getCount: function getCount(equalityString) {
      if (typeof equalityString !== 'string' || equalityString.indexOf('now-') === -1) return 0;

      var str = equalityString.replace('now-', ''),
          num = parseInt(str),
          freq = str.substr(str.length - 1);

      switch (freq) {
        case 'd':
          return num;
        case 'w':
          return num * 7;
        case 'M':
          return num * 30;
        case 'y':
          return num * 365;
      }
    },
    getEquality: function getEquality(range) {
      if (Ember.isEmpty(range) || Object.keys(range).length === 0) return 'none';

      if (range.equality) return range.equality;

      // OLD "Between" payloads
      if (range.range_index === 'custom' && (range.from_days || range.to_days)) return 'between';

      // OLD "Less than"
      if (['one_day_ago', 'one_week_ago', 'one_month_ago', 'two_months_ago'].indexOf(range.range_index) > -1) return 'less';
    }
  });
});