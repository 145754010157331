define('carnival-frontend/helpers/timezone-offset-formatter', ['exports', 'carnival-frontend/models/time-with-zone', 'moment'], function (exports, _timeWithZone, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    timeZones: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.timeZones.addObserver('isLoading', this, 'recompute');
    },
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          value = _ref2[0];

      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // If any components of the TimeWithZone change then we should recompute the
      // helper value.
      value.addObserver('date', this, 'recompute');
      value.addObserver('hour', this, 'recompute');
      value.addObserver('minute', this, 'recompute');
      value.addObserver('timezone', this, 'recompute');

      var format = hash.format || _timeWithZone.DATE_FORMAT;
      var localTime = hash.localTime || false;

      var hour = value.hour,
          minute = value.minute,
          date = value.date,
          timezone = value.timezone;

      var _date$split$map = date.split(/[^0-9]0*/).map(function (n) {
        return Number.parseInt(n);
      }),
          _date$split$map2 = _slicedToArray(_date$split$map, 3),
          year = _date$split$map2[0],
          month = _date$split$map2[1],
          day = _date$split$map2[2];

      var dateObject = { year: year, day: day, hour: hour, minute: minute, month: month - 1 };

      if (localTime) {
        return (0, _moment.default)(dateObject).format(format + ' [(Local Time)]');
      }

      if (timezone === 'UTC') return (0, _moment.default)(dateObject).format(format + ' [UTC]');

      var tz = this.timeZones.findBy('value', timezone);

      // If we can't find the timezone then we have to format the time without an offset.
      if (!tz) {
        if (this.timeZones.isLoading) {
          return (0, _moment.default)(dateObject).format(format + ' [...]');
        }
        return (0, _moment.default)(dateObject).format(format);
      }

      return (0, _moment.default)(dateObject).format(format + ' [(GMT' + tz.offset + ')]');
    }
  });
});