define('carnival-frontend/routes/messages', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      push: { refreshModel: true },
      triggered: { refreshModel: true },
      state: { refreshModel: true },
      audience: { refreshModel: true },
      test: { refreshModel: true },
      publish_after: { refreshModel: true },
      publish_before: { refreshModel: true }
    },

    model: function model(params) {
      // The API doesn't handle empty date range parameters properly.
      params['publish_after'] == null && delete params['publish_after'];
      params['publish_before'] == null && delete params['publish_before'];

      return Ember.RSVP.hash({
        // We have to fetch audiences before entering the route
        // because each message will load its associated audience
        // which results in the app.get('audiences') not re-fetching
        audiences: this.modelFor('app').get('audiences'),
        permittedAudience: this.modelFor('app').get('permittedAudience'),
        params: params
      });
    },
    setupController: function setupController(controller, model) {
      var permittedAudience = model.permittedAudience,
          params = model.params,
          app = this.modelFor('app');

      params.page = 1;
      params.app_id = app.id;

      // if a permitted audience exists
      if (permittedAudience) {
        // set default audience filter to parent
        if (!params.audience) params.audience = permittedAudience.id;

        controller.set('permittedAudience', permittedAudience);
      }

      controller.setProperties({
        app: app,
        messages: this.store.query('message', params),
        audience: params.audience,
        page: 2
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});