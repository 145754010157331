define('carnival-frontend/services/current-app', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    app: null,
    cookieKey: 'corepush-selected-app',

    _setSelectedAppCookie: function _setSelectedAppCookie(app) {
      return Ember.$.cookie(this.cookieKey, app.get('id'), {
        expires: 1000,
        path: '/'
      });
    },
    _getSelectedAppCookie: function _getSelectedAppCookie() {
      var app_id = Ember.$.cookie(this.cookieKey);
      if (Ember.isEmpty(app_id) === true) this._removeSelectedAppCookie();
      return app_id;
    },
    _removeSelectedAppCookie: function _removeSelectedAppCookie() {
      Ember.$.removeCookie(this.cookieKey, {
        path: '/'
      });
    },
    restoreLastAccessedApp: function restoreLastAccessedApp() {
      if (!this._getSelectedAppCookie()) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          reject();
        });
      }

      var that = this;
      return this.store.findRecord('app', this._getSelectedAppCookie()).then(null, function (reason) {
        that._removeSelectedAppCookie();
        throw reason;
      });
    },


    updateCurrentApp: function updateCurrentApp(app) {
      this.set('app', app);

      if (app) {
        this._setSelectedAppCookie(app);
      } else {
        this._removeSelectedAppCookie();
      }
    }
  });
});