define('carnival-frontend/models/session', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    email: _emberData.default.attr('string'),
    csrf: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    remember: _emberData.default.attr('boolean'),
    authProvider: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', { async: false }),

    // eslint-disable-next-line ember/require-return-from-computed
    loginError: Ember.computed('errors.login.content.[]', function () {
      if (this.get('errors.login')) {
        return S(this.get('errors.login.0.message')).humanize().s;
      }
    }),

    requestPasswordReset: function requestPasswordReset() {
      var store = this.get('store'),
          adapter = store.adapterFor('session'),
          url = adapter.buildURL('sessions', 'reset_password'),
          data = { session: { email: this.get('email') } },
          record = this;

      return adapter.ajax(url, 'POST', { data: data }).then(null, function (reason) {
        if (reason instanceof _emberData.default.InvalidError) {
          store.recordWasInvalid(record, reason.errors);
        }
        throw reason;
      });
    }
  });
});