define('carnival-frontend/controllers/logs/devices/device', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    app: Ember.computed.reads('model.app'),
    canEraseDevice: Ember.computed.or('app.isAdmin', 'currentUserService.user.superAdmin'),

    hasValidCertificate: Ember.computed('app.developmentCertificate.isFulfilled', function () {
      var expires = this.get('app.developmentCertificate.expires');

      if (!expires) return false;
      return +new Date() < +new Date(expires);
    }),

    showEraseButton: Ember.computed('canEraseDevice', 'model.erasedAt', function () {
      return this.get('canEraseDevice') && !this.get('model.erasedAt');
    }),

    enableTestPush: Ember.computed('app', 'model.platform', 'hasValidCertificate', function () {
      var platform = this.get('model.platform'),
          app = this.get('app');

      if (platform === 'iOS' && this.get('hasValidCertificate')) return true;
      if (platform === 'Android' && app.get('googleGcmApiKey')) return true;

      return false;
    })
  });
});