define('carnival-frontend/routes/audiences/new-child', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    controllerName: 'audiences.crud',

    model: function model(params) {
      var app = this.modelFor('app'),
          store = this.store,
          audience = store.findRecord('audience', params.audience_id);

      return audience.then(function () {
        return store.createRecord('audience', {
          app: app,
          parentAudience: audience
        });
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('audiences.new', {
        into: 'application',
        outlet: 'modal'
      });
    }
  });
});