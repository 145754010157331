define('carnival-frontend/controllers/admin/paginated', ['exports', 'carnival-frontend/mixins/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, {
    queryParams: ['page', 'per', 'search', 'sort[by]', 'sort[dir]'],

    page: 1,
    per: 30,
    'sort[by]': 'name',
    'sort[dir]': 'asc',
    search: '',
    filter: {},

    maxPageLinks: 20,

    // eslint-disable-next-line ember/no-observers
    searchOrFilterChanged: Ember.observer('search', 'filter', function () {
      this.setProperties({
        page: 1,
        per: 10
      });
    }),

    actions: {
      sorting: function sorting(field) {
        if (this.get('sort[by]') === field) this._toggleDirection();
        this.set('sort[by]', field);
      }
    },

    _toggleDirection: function _toggleDirection() {
      if (this.get('sort[dir]') === 'asc') {
        this.set('sort[dir]', 'desc');
      } else {
        this.set('sort[dir]', 'asc');
      }
    }
  });
});