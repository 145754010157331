define('carnival-frontend/routes/messages/message', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    model: function model(params) {
      return this.store.findRecord('message', params.message_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      // reload analytics when switching message route
      if (model.get('analytics.isLoaded')) {
        model.belongsTo('analytics').reload();
      }

      var notifications = model.get('notifications');

      // set default notification to show - used for showing notification stats
      // if no notification exists `nid` will be falsey thus view with hide notification related UI
      controller.set('nid', notifications.get('firstObject.id'));

      // Set notification to listen for push progress via SSE
      // Don't bother listening to notifications that have already sent
      var notification = notifications.rejectBy('state', 'done').get('firstObject');

      if (notification) {
        notification.startStatusObserver();
      }
    },
    resetController: function resetController(controller) {
      controller.get('model').stopNotificationsStatusObsersever();
    }
  });
});