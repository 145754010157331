define('carnival-frontend/components/settings/device-map', ['exports', 'carnival-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['map'],
    map: null,

    didInsertElement: function didInsertElement() {
      this.renderMap();
    },


    // eslint-disable-next-line ember/no-observers
    modelDidChange: Ember.observer('device', function () {
      this.renderMap();
    }),

    renderMap: function renderMap() {
      // eslint-disable-next-line  no-prototype-builtins
      if (!window.hasOwnProperty('L')) return;

      var lat = this.get('device.location.lastObject') || 0,
          lon = this.get('device.location.firstObject') || 0,
          map = this.get('map');

      if (map) map.remove();

      if (!this.element) return;

      map = L.map(this.element).setView([lat, lon], 13);

      this.set('map', map);

      L.tileLayer(_environment.default.mapbox.tilePath, {}).addTo(map);
      L.marker([lat, lon]).addTo(map);
    }
  });
});