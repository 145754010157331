define('carnival-frontend/mixins/sort', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getSortType: function getSortType(sort) {
      switch (sort) {
        case 'newest':
          return ['unixTimestamp', true];
        case 'oldest':
          return ['unixTimestamp'];
        case 'name':
          return ['name'];
      }
    },
    getSortFn: function getSortFn(prop, invert) {
      return function (a, b) {
        if (a.get(prop) > b.get(prop)) return invert ? -1 : 1;else if (a.get(prop) < b.get(prop)) return invert ? 1 : -1;else return 0;
      };
    },
    sortArray: function sortArray(sort, array) {
      var args = this.getSortType(sort),
          fn = this.getSortFn.apply(this, args);

      return array.sort(fn);
    }
  });
});