define('carnival-frontend/components/rembr/stui-error-message', ['exports', 'carnival-frontend/components/rembr/abstract-component', '@sailthru/stui-components'], function (exports, _abstractComponent, _stuiComponents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractComponent.default.extend({
    attributeBindings: 'data-test-id',
    elementId: 'rembr_stui-error-message',
    classNames: 'error-message',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderErrorMessage();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.renderErrorMessage();
    },


    getStyles: function getStyles() {
      return {
        padding: '80px 56px 32px',
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        justifyContent: 'space-between',
        alignItems: 'stretch'
      };
    },
    renderErrorMessage: function renderErrorMessage() {
      this.reactRender(React.createElement(_stuiComponents.ErrorMessage, {
        errorName: this.get('errorName'),
        errorCode: this.get('errorCode'),
        onPrimaryCTAClick: this.reloadPageAction,
        style: this.getStyles()
      }));
    }
  });
});