define('carnival-frontend/components/sailthru-button', ['exports', 'carnival-frontend/components/rembr/stui-button'], function (exports, _stuiButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stuiButton.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      if (this.shouldRenderSTUIButton) {
        this.set('elementId', this.elementId + '-wrapper');
      } else {
        this.set('tagName', 'button');
      }
    },

    click: function click() {
      if (this.disabled) {
        return false;
      }
      if (this.onClick) {
        this.onClick();
      }
      return false;
    },
    renderButton: function renderButton() {
      if (this.shouldRenderSTUIButton) {
        this._super.apply(this, arguments);
      }
    }
  });
});