define('carnival-frontend/routes/admin/users', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    queryParams: {
      page: { refreshModel: true },
      per: { refreshModel: true },
      'sort[by]': { refreshModel: true },
      'sort[dir]': { refreshModel: true },
      search: { refreshModel: true }
    },

    model: function model(params) {
      return this.store.query('user', params);
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      willTransition: function willTransition() {
        this.send('closeModal');
      }
    },

    renderTemplate: function renderTemplate(controller, model) {
      this._super(controller, model);

      this.render('sailthru-navigation', {
        outlet: 'nav',
        controller: this.controllerFor('admin')
      });
    }
  });
});