define('carnival-frontend/components/stats/no-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'g',
    attributeBindings: ['transform'],
    maxLabelWidth: 160,

    clampedWidth: Ember.computed('width', function () {
      return d3.max([this.get('width'), 0]);
    }),

    transform: Ember.computed('x', function () {
      return 'translate(' + this.get('x') + ', 0)';
    }),

    labelX: Ember.computed('clampedWidth', function () {
      return 0.5 * this.get('clampedWidth');
    }),

    labelY: Ember.computed('height', function () {
      return 0.5 * this.get('height') + 16;
    }),

    didInsertElement: function didInsertElement() {
      this.labelVisibilityDidChange();
    },


    /**
     * This method is a workaround for the problems with some WEB API on SVG node:
     * - jQuery api for toggling classes is not working with SVG.
     * - Due to jQuery issues, Ember bindings on class is not working
     * - classList API is not supported in PhantomJS, so tests are failing
     */
    // eslint-disable-next-line ember/no-observers
    labelVisibilityDidChange: Ember.observer('clampedWidth', 'maxLabelWidth', function () {
      var labelHidden = this.get('clampedWidth') < this.get('maxLabelWidth'),
          textLabel = this.$('text');

      if (labelHidden) {
        textLabel.attr('class', 'border-text hidden');
      } else {
        textLabel.attr('class', 'border-text');
      }
    })
  });
});