define('carnival-frontend/components/stats/annotation-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'annotation-tooltip',
    classNameBindings: ['position', 'isHidden:invisible'],

    isHidden: false,
    currentIndex: 1,
    sideThreshold: 150,
    flagThreshold: 20,
    maxWidth: 1000,

    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$('body').on('click', function () {
        if (self._state === 'inDOM') self.set('isHidden', true);
      });
    },


    position: Ember.computed('annotationGroup.x', function () {
      if (!this.get('annotationGroup')) return 'center';
      var maxWidth = this.get('maxWidth'),
          left = this.get('annotationGroup.x');

      if (left < this.sideThreshold) return 'left';
      if (maxWidth - left < this.sideThreshold) return 'right';

      return 'center';
    }),

    currentAnnotation: Ember.computed('currentIndex', 'annotationGroup', function () {
      var annotations = this.get('annotationGroup.annotations');
      if (!annotations) return null;

      if (this.get('currentIndex') > annotations.length) return null;
      return annotations[this.get('currentIndex')];
    }),

    displayedIndex: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') + 1;
    }),

    leftArrowIsHidden: Ember.computed('currentIndex', function () {
      return this.get('currentIndex') <= 0;
    }),

    rightArrowIsHidden: Ember.computed('currentIndex', function () {
      var annotations = this.get('annotationGroup.annotations');
      return this.get('currentIndex') >= annotations.length - 1;
    }),

    actions: {
      previousAnnotation: function previousAnnotation() {
        var currentIndex = this.get('currentIndex'),
            newIndex = Math.max(0, currentIndex - 1);

        this.set('currentIndex', newIndex);
      },
      nextAnnotation: function nextAnnotation() {
        var annotations = this.get('annotationGroup.annotations'),
            currentIndex = this.get('currentIndex'),
            newIndex = Math.min(annotations.length - 1, currentIndex + 1);

        this.set('currentIndex', newIndex);
      },
      contextMenuItemSelected: function contextMenuItemSelected(item) {
        var annotation = this.get('currentAnnotation');
        if (item.toLowerCase() === 'edit') {
          this.sendAction('editAnnotationAction', annotation);
        }
        if (item.toLowerCase() === 'remove') {
          this.sendAction('removeAnnotationAction', annotation);
        }
      }
    },

    // eslint-disable-next-line ember/no-observers
    annotationGroupDidChange: Ember.observer('annotationGroup', function () {
      var annotation = this.get('annotationGroup.lastAnnotation'),
          annotations = this.get('annotationGroup.annotations');

      if (!annotations) return;

      this.set('currentIndex', annotations.indexOf(annotation));
    }),

    // eslint-disable-next-line ember/no-observers
    leftPositionDidChange: Ember.observer('annotationGroup.x', function () {
      var positionX = this.get('annotationGroup.x'),
          left;

      if (positionX < 0) return;

      switch (this.get('position')) {
        case 'left':
          left = positionX + 5;
          break;
        case 'right':
          left = positionX - this.$().width();
          break;
        case 'center':
          left = positionX - 0.5 * this.$().width();
          break;
      }

      this.$().css('left', left - this.flagThreshold + 'px');
    }),

    click: function click() {
      return false;
    }
  });
});