define('carnival-frontend/models/time-with-zone', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.DATE_FORMAT = undefined;
  var DATE_FORMAT = exports.DATE_FORMAT = 'MMM Do, YYYY HH:mm ';

  exports.default = Ember.Object.extend({
    DATE_FORMAT: DATE_FORMAT,

    assignValuesFromDate: function assignValuesFromDate(date) {
      this.setProperties({
        date: (0, _moment.default)(date).format('YYYY-MM-DD'),
        hour: date.getHours(),
        minute: date.getMinutes()
      });
    },


    utcTime: Ember.computed('utc_time', function () {
      return _moment.default.utc(this.get('utc_time')).format(this.DATE_FORMAT) + 'Etc/UTC';
    }),

    userTime: Ember.computed('user_time', function () {
      return this.get('user_time');
    }),

    formattedValue: Ember.computed('date', 'hour', 'minute', 'timezone', function () {
      if (!this.get('date')) return null;
      if (this.get('hour') === undefined) return null;
      if (!this.get('timezone')) return null;
      return this._compositeDateAsString(this.get('date'), this.get('hour'), this.get('minute') || 0, this.get('timezone'));
    }),

    formattedValueWithoutTimezone: Ember.computed('date', 'hour', 'minute', 'timezone', function () {
      if (!this.get('date')) return null;
      if (this.get('hour') === undefined) return null;
      return this._compositeDateAsString(this.get('date'), this.get('hour'), this.get('minute') || 0, '');
    }),

    _compositeDateAsString: function _compositeDateAsString(date, hour, minute, timezone) {
      return (0, _moment.default)(date).hours(hour).minutes(minute).format(this.DATE_FORMAT) + (timezone === 'UTC' ? 'Etc/UTC' : timezone);
    }
  });
});