define('carnival-frontend/components/content-revealer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isOpen:open', 'isDisabled:disabled'],

    isOpen: false,
    isDisabled: false,

    textClassName: 'text',
    subtextClassName: 'subtext',

    contentClassName: 'content',
    contentWrapClassName: 'wrap',
    toggleClassName: 'toggle',
    iconClassName: 'icon',

    // Additional Classes
    contentClassNames: '',
    contentWrapClassNames: '',
    toggleClassNames: '',
    iconClassNames: '',
    openIconClassName: '',

    text: 'Toggle',
    subtext: '',
    subtextKey: '',

    actions: {
      toggleReveal: function toggleReveal() {}
    }
  });
});