define('carnival-frontend/helpers/format-purchase-adjustment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPurchaseAdjustment = formatPurchaseAdjustment;
  function formatPurchaseAdjustment(params) {
    var symbol = params[0] || '$';
    var value = params[1] || 0;
    var precision = Number.isInteger(params[2]) ? params[2] : 2;

    var money = symbol + Math.abs(value).toFixed(precision).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

    return value < 0 ? '(' + money + ')' : money;
  }

  exports.default = Ember.Helper.helper(formatPurchaseAdjustment);
});