define('carnival-frontend/helpers/moment-from', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentFrom = momentFrom;
  function momentFrom(params) {
    return (0, _moment.default)(params[0]).fromNow();
  }

  exports.default = Ember.Helper.helper(momentFrom);
});