define('carnival-frontend/transforms/json-array', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isNone(serialized)) {
        return [];
      }

      return serialized.map(function (object) {
        if ((typeof object === 'undefined' ? 'undefined' : _typeof(object)) === 'object') {
          return Ember.Object.create(object);
        }
        return object;
      });
    },
    serialize: function serialize(deserialized) {
      return Ember.isNone(deserialized) ? [] : deserialized;
    }
  });
});