define('carnival-frontend/helpers/ember-leaflet-assign-to', ['exports', 'ember-leaflet/helpers/ember-leaflet-assign-to'], function (exports, _emberLeafletAssignTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberLeafletAssignTo.default;
    }
  });
  Object.defineProperty(exports, 'emberLeafletAssignTo', {
    enumerable: true,
    get: function () {
      return _emberLeafletAssignTo.emberLeafletAssignTo;
    }
  });
});