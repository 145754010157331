define('carnival-frontend/components/messages/push-progress', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['row', 'row-body', 'reveal-popup', 'open-on-hover', 'push-progress'],

    notifications: Ember.computed.oneWay('model.notifications'),
    payload: Ember.computed.oneWay('notifications.firstObject.payload'),
    alertText: Ember.computed.oneWay('payload.alert'),
    isSending: Ember.computed.equal('model.pushState', 'in_progress'),
    isScheduled: Ember.computed.equal('model.pushState', 'awaiting_scheduling'),
    isPaused: Ember.computed.equal('model.pushState', 'paused'),
    isComplete: Ember.computed.equal('model.pushState', 'done'),
    isStuck: Ember.computed.equal('model.pushState', 'stuck'),

    // "To be sent"
    sendingProgress: Ember.computed('notifications', function () {
      return this.get('notifications').rejectBy('state', 'stuck').mapBy('sendingProgress');
    }),
    aggregatedSendingProgress: Ember.computed.sum('sendingProgress'),

    // "Rejected (Incorrect tokens)"
    deactivatedTokensCount: Ember.computed.mapBy('notifications', 'deactivatedTokensCount'),
    aggregatedDeactivatedTokensCount: Ember.computed.sum('deactivatedTokensCount'),

    // "Rejected (Internal Error)"
    internalErrorCount: Ember.computed('notifications', function () {
      return this.get('notifications').filterBy('state', 'stuck').mapBy('sendingProgress');
    }),
    aggregatedInternalErrorCount: Ember.computed.sum('internalErrorCount'),

    // "Total progress"
    progress: Ember.computed.mapBy('notifications', 'progress'),
    aggregatedProgress: Ember.computed.sum('progress'),

    // "Successfully sent"
    successfullySent: Ember.computed('aggregatedProgress', 'aggregatedDeactivatedTokensCount', function () {
      return this.get('aggregatedProgress') - this.get('aggregatedDeactivatedTokensCount');
    }),

    // "Total"
    deviceCount: Ember.computed.mapBy('notifications', 'deviceCount'),
    aggregatedDeviceCount: Ember.computed.sum('deviceCount'),

    aggregatedPercentageSent: Ember.computed('notifications.{@each.percentageSent,@each.state}', function () {
      var notifications = this.get('notifications'),
          totalNotifications = notifications.get('length');

      if (totalNotifications > 1) {
        return Math.round(notifications.filterBy('state', 'done').get('length') / totalNotifications * 100);
      } else {
        return notifications.get('firstObject').get('percentageSent');
      }
    }),

    canControlPushState: Ember.computed('model.{state,pushState}', function () {
      return this.get('model.isPublished') && (this.get('isScheduled') || this.get('isSending') || this.get('isPaused'));
    }),

    totalTimeToSend: Ember.computed('notifications.{@each.sentAt}', function () {
      var timeInMillis = this.get('sortedNotifications.lastObject.sentAt') - this.get('model.publishedAt');
      return this.humanizeTime(timeInMillis);
    }),

    // eslint-disable-next-line ember/require-return-from-computed
    sent90Time: Ember.computed('notifications.firstObject.sent90', function () {
      if (this.get('notifications.firstObject.sent90')) {
        var timeInMillis = this.get('notifications.firstObject.sent90') - this.get('model.publishedAt');
        return this.humanizeTime(timeInMillis);
      }
    }),

    // eslint-disable-next-line ember/require-return-from-computed
    sent95Time: Ember.computed('notifications.firstObject.sent95', function () {
      if (this.get('notifications.firstObject.sent95')) {
        var timeInMillis = this.get('notifications.firstObject.sent95') - this.get('model.publishedAt');
        return this.humanizeTime(timeInMillis);
      }
    }),

    // eslint-disable-next-line ember/require-return-from-computed
    sent99Time: Ember.computed('notifications.firstObject.sent99', function () {
      if (this.get('notifications.firstObject.sent90')) {
        var timeInMillis = this.get('notifications.firstObject.sent99') - this.get('model.publishedAt');
        return this.humanizeTime(timeInMillis);
      }
    }),

    successfullySent90: Ember.computed('successfullySent', function () {
      return Math.round(this.get('successfullySent') * 0.9);
    }),

    successfullySent95: Ember.computed('successfullySent', function () {
      return Math.round(this.get('successfullySent') * 0.95);
    }),

    successfullySent99: Ember.computed('successfullySent', function () {
      return Math.round(this.get('successfullySent') * 0.99);
    }),

    humanizeTime: function humanizeTime(timeInMillis) {
      // Moment durations don't support strptime out of the box :(
      var duration = _moment.default.duration(timeInMillis);
      var hours = Math.floor(duration.as('hours'));
      var minutes = duration.minutes();
      var seconds = duration.seconds();
      return hours + ' hrs ' + minutes + ' mins ' + seconds + ' secs';
    },


    sortedNotifications: Ember.computed('notifications', function () {
      var re = /^GMT([+-]\d{2}):(\d{2}).*$/; // Match timezone offsets, grouping on the hours and minutes
      return this.get('notifications').toArray().sort(function (a, b) {
        var aOffset = parseFloat(a.get('timeZones').replace(re, '$1.$2')),
            bOffset = parseFloat(b.get('timeZones').replace(re, '$1.$2'));
        return aOffset < bOffset ? -1 : aOffset > bOffset ? 1 : 0;
      });
    }),

    actions: {
      pause: function pause() {
        var _this = this;

        this.get('model').pause().catch(function (error) {
          return _this.alert.danger('Error', error.message);
        });
      },
      play: function play() {
        var _this2 = this;

        this.get('model').resume().catch(function (error) {
          return _this2.alert.danger('Error', error.message);
        });
      }
    }
  });
});