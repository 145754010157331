define('carnival-frontend/models/user', ['exports', 'ember-data', 'carnival-frontend/models/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    currentPassword: _emberData.default.attr('string'),
    confirm: _emberData.default.attr('string'),
    timeZone: _emberData.default.attr('string'),
    superViewer: _emberData.default.attr('boolean'),
    superAdmin: _emberData.default.attr('boolean'),
    signInCount: _emberData.default.attr('string'),
    currentSignInAt: _emberData.default.attr('date'),
    hasAcceptedTerms: _emberData.default.attr('boolean'),
    hasAuth0: _emberData.default.attr('boolean'),

    isSuperSomething: Ember.computed.or('superViewer', 'superAdmin'),
    notConfirmed: Ember.computed.empty('name'),
    confirmed: Ember.computed.not('notConfirmed'),

    timeZones: Ember.computed(function () {
      var adapter = this.get('store').adapterFor('user'),
          url = adapter.buildURL('user', 'time_zones');

      return _emberData.default.PromiseArray.create({ promise: adapter.ajax(url, 'GET') });
    }),

    subscriptions: Ember.computed(function () {
      var adapter = this.get('store').adapterFor('user'),
          url = adapter.buildURL('user', this.get('id')) + '/subscriptions';

      return _emberData.default.PromiseArray.create({ promise: adapter.ajax(url, 'GET') });
    }).volatile(), // eslint-disable-line ember/no-volatile-computed-properties

    updateSuperRights: function updateSuperRights() {
      var adapter = this.get('store').adapterFor('user'),
          url = adapter.buildURL('user', this.get('id')) + '/update_super_rights',
          data = {
        super_admin: this.get('superAdmin'),
        super_viewer: this.get('superViewer')
      };

      return adapter.ajax(url, 'POST', { data: data });
    },
    updateSubscriptions: function updateSubscriptions(subscriptions) {
      var adapter = this.get('store').adapterFor('user'),
          url = adapter.buildURL('user', this.get('id')) + '/subscriptions';

      return adapter.ajax(url, 'POST', {
        data: { subscriptions: subscriptions }
      });
    },
    sendInvite: function sendInvite() {
      var adapter = this.store.adapterFor('user'),
          url = adapter.buildURL('users', 'send_invite'),
          email = this.get('email');

      return adapter.ajax(url, 'POST', { data: { email: email } });
    },
    resetPassword: function resetPassword() {
      var adapter = this.store.adapterFor('user'),
          url = adapter.buildURL('users', 'reset_password');

      return adapter.ajax(url, 'POST');
    }
  });
});