define('carnival-frontend/instance-initializers/ajax-csrf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    Ember.$.ajaxPrefilter(function (options, originalOptions, xhr) {
      var session = instance.lookup('session:main');

      if (!session || !session.csrf) return;
      session.userTimeZone ? window.sessionStorage.setItem('currentUserTimezone', session.userTimeZone) : window.sessionStorage.setItem('currentUserTimezone', 'UTC');

      xhr.setRequestHeader('X-CSRF-Token', session.csrf);
    });
  }

  exports.default = {
    name: 'ajax-csrf',
    initialize: initialize
  };
});