define('carnival-frontend/components/audiences/filter-criteria/date-criteria', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['date-criteria'],
    type: 'from',
    date: null,
    stringDateFormat: 'YYYY-MM-DD',
    isShowingDatePicker: false,

    dateString: Ember.computed('date', {
      get: function get() {
        if (!this.get('date')) return '';
        return this.get('date').format(this.get('stringDateFormat'));
      }
    }),

    toggle: function toggle() {
      this.set('isShowingDatePicker', !this.get('isShowingDatePicker'));
    },


    actions: {
      pickDate: function pickDate() {
        this.sendAction('pickDate');
      },
      toggleDatePicker: function toggleDatePicker() {
        if (this.isDestroyed) return;

        this.toggle();
      },
      removeSelection: function removeSelection() {
        if (this.isDestroyed) return;

        this.toggle();
      }
    }
  });
});