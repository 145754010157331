define('carnival-frontend/components/stats/date-picker', ['exports', 'carnival-frontend/components/dropdown-revealer', 'moment'], function (exports, _dropdownRevealer, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _dropdownRevealer.default.extend({
    layoutName: 'components/date-range-picker',
    classNames: ['stats-datepicker'],

    toggleClassNames: 'btn btn-default btn-icon btn-sm',
    iconClassNames: 'glyphicon glyphicon-chevron-down',
    dateFormat: 'll',
    selectedDayEls: [],
    hoveredDayEls: [],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthIndex: 0,
    yearIndex: (0, _moment.default)(new Date()).year(),
    drpState: 'default',

    // eslint-disable-next-line ember/no-observers
    rangeChanged: Ember.observer('range', function () {
      this.unHighlightDaysInBetween();
      this.updateIndexes(this.get('range.from').month(), this.get('range.from').year());
      this.updateCalendarMonths();
      this.updateFromToElPointers();
      this.highlightDaysInBetween();
    }),

    // eslint-disable-next-line ember/no-observers
    drpStateChanged: Ember.observer('drpState', function () {
      var drpState = this.get('drpState');

      if (drpState === 'default') {
        this.set('isOpen', false);
      }
    }),

    range: Ember.computed('nativeRange', {
      get: function get() {
        return {
          from: (0, _moment.default)(this.get('nativeRange.from')),
          to: (0, _moment.default)(this.get('nativeRange.to'))
        };
      },
      set: function set(key, value) {
        this.set('nativeRange', value);

        return {
          from: (0, _moment.default)(this.get('nativeRange.from')),
          to: (0, _moment.default)(this.get('nativeRange.to'))
        };
      }
    }),

    fromDateText: Ember.computed('range.from', 'dateFormat', function () {
      return this.get('range.from').format(this.get('dateFormat'));
    }),

    toDateText: Ember.computed('range.to', 'dateFormat', function () {
      return this.get('range.to').format(this.get('dateFormat'));
    }),

    yearName: Ember.computed('yearIndex', function () {
      return this.get('yearIndex');
    }),

    adjacentYearName: Ember.computed('adjacentYearIndex', function () {
      return this.get('adjacentYearIndex');
    }),

    monthName: Ember.computed('months', 'monthIndex', function () {
      return this.get('months')[this.get('monthIndex')];
    }),

    adjacentMonthName: Ember.computed('months', 'adjacentMonthIndex', function () {
      return this.get('months')[this.get('adjacentMonthIndex')];
    }),

    adjacentMonthIndex: Ember.computed('monthIndex', function () {
      var monthIndex = this.get('monthIndex');

      return monthIndex === 11 ? 0 : monthIndex + 1;
    }),

    adjacentYearIndex: Ember.computed('monthIndex', 'yearIndex', function () {
      var monthIndex = this.get('monthIndex'),
          yearIndex = this.get('yearIndex');

      return monthIndex === 11 ? yearIndex + 1 : yearIndex;
    }),

    findCalendarDayEl: function findCalendarDayEl(date) {
      var day = date.date(),
          month = date.month(),
          year = date.year();

      return Ember.$('.calendar td[data-day="' + day + '"][data-month="' + month + '"][data-year="' + year + '"]:not(".prev-month"):not(".next-month")');
    },
    unHighlightDaysInBetween: function unHighlightDaysInBetween() {
      var day = void 0;
      while (day = this.selectedDayEls.pop()) {
        Ember.$(day).removeClass('highlight');
      }
    },
    highlightDaysInBetween: function highlightDaysInBetween() {
      var dayEls = Ember.$('td'),
          i = dayEls.index(this.findCalendarDayEl(this.get('range.from'))),
          n = dayEls.index(this.findCalendarDayEl(this.get('range.to')));

      if (i === -1 && n === -1) {
        var shownDate = (0, _moment.default)(new Date(this.get('yearIndex'), this.get('monthIndex'))),
            fromDate = this.get('range.from'),
            toDate = this.get('range.to');

        // out of bounds
        if (shownDate.unix() < fromDate.unix() || shownDate.unix() > toDate.unix()) {
          return;
        }
      }

      i++;

      if (n === -1) {
        n = dayEls.length - 1;
      }

      for (; i < n; i++) {
        var day = Ember.$(dayEls[i]);

        if (!day.hasClass('prev-month') && !day.hasClass('next-month')) {
          day.addClass('highlight');
          this.selectedDayEls.push(day);
        }
      }
    },
    highlightHoveredDaysInbetween: function highlightHoveredDaysInbetween(elA, elB) {
      var dayEls = Ember.$('td:not(".prev-month, .next-month, .highlight")'),
          i = dayEls.index(elA),
          n = dayEls.index(elB),
          drpState = this.get('drpState');

      if (drpState === 'from') {
        if (i >= n || i === -1) {
          return;
        }
      } else if (drpState === 'to') {
        if (n <= i) {
          return;
        }
      }

      if (i === -1 && n === -1) {
        var shownDate = (0, _moment.default)(new Date(this.get('yearIndex'), this.get('monthIndex'))),
            fromDate = this.get('range.from'),
            toDate = this.get('range.to');

        // out of bounds
        if (shownDate.unix() < fromDate.unix() || shownDate.unix() > toDate.unix()) {
          return;
        }
      }

      i++;

      if (n === -1) {
        n = dayEls.length - 1;
      }

      for (; i < n; i++) {
        var day = Ember.$(dayEls[i]);

        if (!day.hasClass('from-date') || !day.hasClass('to-date')) {
          day.addClass('hovered-highlight');
          this.hoveredDayEls.push(day);
        }
      }
    },
    unHighlightHoveredDaysInBetween: function unHighlightHoveredDaysInBetween() {
      var day = void 0;
      while (day = this.hoveredDayEls.pop()) {
        Ember.$(day).removeClass('hovered-highlight');
      }
    },
    updateIndexes: function updateIndexes(monthIndex, yearIndex) {
      this.set('monthIndex', monthIndex);
      this.set('yearIndex', yearIndex);
    },
    updateCalendarMonths: function updateCalendarMonths() {
      // remove old references to prev and next months
      this.updatePrevNextElPointers();

      // update days
      this.setCalendarDays(Ember.$('#calendar-a').find('td'), this.get('monthIndex'), this.get('yearIndex'));
      this.setCalendarDays(Ember.$('#calendar-b').find('td'), this.get('adjacentMonthIndex'), this.get('adjacentYearIndex'));
    },
    updatePrevNextElPointers: function updatePrevNextElPointers() {
      Ember.$('.prev-month').removeClass('prev-month');
      Ember.$('.next-month').removeClass('next-month');
    },
    updateFromToElPointers: function updateFromToElPointers() {
      this.updateDateElPointer('.from-date', 'range.from');
      this.updateDateElPointer('.to-date', 'range.to');
    },
    updateDateElPointer: function updateDateElPointer(selector, dateType) {
      var dateEl = Ember.$(selector);

      // find old one and remove class
      if (dateEl.length > 1) {
        Ember.$(dateEl[0]).removeClass(selector.substr(1));
      } else if (dateEl.length === 1) {
        dateEl.removeClass(selector.substr(1));
      }

      // find new one
      dateEl = this.findCalendarDayEl(this.get(dateType));

      // add class if found
      if (dateEl.length > 1) {
        Ember.$(dateEl[0]).addClass(selector.substr(1));
      } else {
        dateEl.addClass(selector.substr(1));
      }

      return dateEl;
    },
    init: function init() {
      this._super();

      this.updateIndexes(this.get('range.from').month(), this.get('range.from').year());
    },
    didInsertElement: function didInsertElement() {
      this.updateCalendarMonths();
      this.updateFromToElPointers();
      this.highlightDaysInBetween();

      this.addEventListeners();
    },
    addEventListeners: function addEventListeners() {
      var self = this;

      // Hacky solution, need to wait till dropdown finishes bubbling before calling focus
      this.$('.toggle').click(function (e) {
        var btn = Ember.$(e.currentTarget);

        if (!btn.parent().hasClass('open')) {
          setTimeout(function () {
            Ember.$('#from-input').focus();
          }, 100);
        }
      });

      this.$('#from-input').on('focus', function () {
        self.set('drpState', 'from');

        var $calendarContainer = Ember.$('.datepicker-body');

        $calendarContainer.addClass('from');
        self.$().addClass('from');

        if ($calendarContainer.hasClass('to')) {
          $calendarContainer.removeClass('to');
          self.$().removeClass('to');
        }
      });

      this.$('#to-input').on('focus', function () {
        self.set('drpState', 'to');

        var $calendarContainer = Ember.$('.datepicker-body');

        $calendarContainer.addClass('to');
        self.$().addClass('to');

        if ($calendarContainer.hasClass('from')) {
          $calendarContainer.removeClass('from');
          self.$().removeClass('from');
        }
      });

      var $dayEls = this.$('.calendar td');

      $dayEls.click(function (e) {
        var target = e.target,
            day = target.getAttribute('data-day'),
            month = target.getAttribute('data-month'),
            year = target.getAttribute('data-year'),
            date = (0, _moment.default)(new Date(year, month, day));

        e.preventDefault();

        if (self.get('drpState') === 'from') {
          e.stopPropagation(); // stops Bootsrap dropdown from receiving the event that causes it to collapse
          // update range object for firing computed properties and observes
          self.set('range', { from: date, to: self.get('range.to') });
          self.updateDateElPointer('.from-date', 'range.from');

          // can't select a FROM date that is greater than TO
          if (date.unix() >= self.get('range.to').unix()) {
            // update range object for firing computed properties and observes
            self.set('range', { from: self.get('range.from'), to: date });
            self.updateDateElPointer('.to-date', 'range.to');
          }

          self.unHighlightHoveredDaysInBetween();
          self.unHighlightDaysInBetween();
          self.highlightDaysInBetween();
          Ember.$('#to-input').focus();
        } else if (self.get('drpState') === 'to') {
          if (date.unix() >= self.get('range.from').unix()) {
            // set the TO date to the end of the day
            date = date.endOf('day');

            // update range object for firing computed properties and observes
            self.set('range', { from: self.get('range.from'), to: date });
            self.updateDateElPointer('.to-date', 'range.to');
            self.unHighlightHoveredDaysInBetween();
            self.unHighlightDaysInBetween();
            self.highlightDaysInBetween();
            self.set('drpState', 'default');
          }
        }
      });

      $dayEls.mouseover(function (e) {
        var target = e.currentTarget;

        if (self.drpState === 'from') {
          // get the to date
          var dateFromEl = self.findCalendarDayEl(self.get('range.from'));

          self.highlightHoveredDaysInbetween(target, dateFromEl);

          // highlight all cells inbetween
        } else if (self.drpState === 'to') {
          var dateToEl = self.findCalendarDayEl(self.get('range.to'));

          self.highlightHoveredDaysInbetween(dateToEl, target);
        }
      });

      $dayEls.mouseout(function () {
        if (self.drpState === 'from' || self.drpState === 'to') {
          self.unHighlightHoveredDaysInBetween();
        }
      });

      this.$('.btn-step').click(function (e) {
        e.stopPropagation();

        var target = Ember.$(e.target);

        // Step the months
        // make sure they don't go over or under 12 months

        if (target.hasClass('btn-prev') || target.parent().hasClass('btn-prev')) {
          // change month from jan to dec
          // and decrement the year
          // otherwise just change to the previous month
          if (self.get('monthIndex') <= 0) {
            self.set('monthIndex', self.get('months').length - 1);
            //if (self.get('yearIndex') > 0) {
            self.set('yearIndex', self.get('yearIndex') - 1);
            //}
          } else {
            self.set('monthIndex', self.get('monthIndex') - 1);
          }
        } else if (target.hasClass('btn-next') || target.parent().hasClass('btn-next')) {
          // change month from dec to jan
          // and increment the year
          // otherwise just change to the next month
          if (self.get('monthIndex') >= self.get('months').length - 1) {
            self.set('monthIndex', 0);
            //if (self.get('yearIndex') < self.get('years').length) {
            self.set('yearIndex', self.get('yearIndex') + 1);
            //}
          } else {
            self.set('monthIndex', self.get('monthIndex') + 1);
          }
        }

        self.unHighlightDaysInBetween();
        self.updateCalendarMonths();

        // update FROM and TO pointers
        // must do this after updating calendar months
        self.updateFromToElPointers();
        self.highlightDaysInBetween();
      });
    },
    setCalendarDays: function setCalendarDays(dayEls, month, year) {
      var previousMonth = month - 1 < 0 ? 11 : month - 1,
          nextMonth = month + 1 > 11 ? 0 : month + 1,
          numDaysInMonth = this.daysInMonth(month, year),
          numDaysInPrevMonth = this.daysInMonth(previousMonth, year),

      //      numDaysInNextMonth = this.daysInMonth(nextMonth, year),
      startDay = new Date(year, month).getDay(),
          totalCells = 42,
          dayNum = void 0,
          dayMonth = void 0,
          dayYear = void 0,
          el = void 0;

      for (var i = 0; i < totalCells; i++) {
        el = dayEls[i];

        if (i < startDay) {
          dayNum = numDaysInPrevMonth - startDay + 1 + i;
          dayMonth = previousMonth;
          dayYear = month - 1 < 0 ? year - 1 : year;
          Ember.$(el).addClass('prev-month');
        } else if (i >= numDaysInMonth + startDay) {
          dayNum = i + 1 - numDaysInMonth - startDay;
          dayMonth = nextMonth;
          dayYear = month + 1 > 11 ? year + 1 : year;
          Ember.$(el).addClass('next-month');
        } else {
          dayNum = i - startDay + 1;
          dayMonth = month;
          dayYear = year;
        }

        el.innerHTML = dayNum;
        el.setAttribute('data-day', dayNum);
        el.setAttribute('data-month', dayMonth);
        el.setAttribute('data-year', dayYear);
      }
    },
    daysInMonth: function daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },


    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('isOpen');
      },
      handleSetDate: function handleSetDate(timePeriod) {
        timePeriod = timePeriod.toLowerCase();
        var validPeriods = ['day', 'week', 'month', 'year'];

        if (validPeriods.indexOf(timePeriod) !== -1) {
          var range = {};
          range.from = (0, _moment.default)().startOf(timePeriod);
          range.to = (0, _moment.default)().endOf(timePeriod);
          this.set('range', range);
        }

        this.set('drpState', 'default');
      }
    }
  });
});