define('carnival-frontend/controllers/settings/developer/advanced', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      deleteAttribute: function deleteAttribute(key) {
        var app = this.get('model');

        app.get('messageAttributeKeys').removeObject(key);
        app.save();
      }
    }
  });
});