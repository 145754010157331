define('carnival-frontend/components/messages/reactive-action-picker', ['exports', 'carnival-frontend/components/popup-revealer'], function (exports, _popupRevealer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _popupRevealer.default.extend({
    message: null,

    texts: {
      entered: 'Audience Entry',
      entered_incl_audience: 'Audience Entry (Incl. Audience)',
      exited: 'Audience Exit'
    },

    glyphicons: {
      entered: 'glyphicon-trigger-enter',
      entered_incl_audience: 'glyphicon-trigger-enter',
      exited: 'glyphicon-trigger-exit'
    },

    text: Ember.computed('message.reactiveAction.type', function () {
      return this.get('texts')[this.get('message.reactiveAction.type')];
    }),

    glyph: Ember.computed('message.reactiveAction.type', function () {
      return this.get('glyphicons')[this.get('message.reactiveAction.type')];
    }),

    actions: {
      setReactiveActionType: function setReactiveActionType(reactiveAction, type) {
        reactiveAction.set('type', type);
      }
    }
  });
});