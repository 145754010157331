define('carnival-frontend/controllers/device-preview', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    saveModel: function saveModel(model) {
      var _this = this;

      model.save().then(function () {
        _this.send('saveSuccess', model);
      }, function (error) {
        _this.handleError(error);
      });
    },
    handleError: function handleError(error) {
      // if it's not a validation error let the error bubble
      // and get handled at the `application` route level
      if (!(error instanceof _emberData.default.InvalidError)) throw error;
    },


    actions: {
      prev: function prev() {
        history.back();
      },
      next: function next(model) {
        this.saveModel(model);
      },


      // cancel action for messages/message/schedule-time-past-confirmation
      cancel: function cancel() {
        this.send('closeModal');
      }
    }
  });
});