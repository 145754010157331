define('carnival-frontend/routes/audiences/edit', ['exports', 'carnival-frontend/routes/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    controllerName: 'audiences.crud',

    model: function model(params) {
      return this.store.findRecord('audience', params.audience_id);
    },
    afterModel: function afterModel(resolvedModel) {
      if (resolvedModel.get('isTheRestrictedParent')) {
        this.transitionTo('audiences');
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('audiences.new', {
        into: 'application',
        outlet: 'modal'
      });
    }
  });
});